import React, { Component } from 'react';
import { Toast } from 'primereact/toast';
import { OsnovniPodaciView } from './OsnovniPodaciView';
import { OsnovniPodaciEdit } from './OsnovniPodaciEdit';
    
export class OsnovniPodaciContainer extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();

        this.state = {
            loading: true,
            editMode: false,
            tipoviPredmeta: []
        };

        this.setEditMode = this.setEditMode.bind(this);
        this.saveClicked = this.saveClicked.bind(this);
    }

    async componentDidMount() {
        let tipoviPredmeta = this.props.tipoviPredmeta ?? await this.props.onTipoviPredmetaMount();
        let statusiPredmeta = this.props.statusiPredmeta ?? await this.props.onStatusiPredmetaMount();
        let tipoviSuda = this.props.tipoviSuda ?? await this.props.onTipoviSudaMount();
        let nosiociPredmeta = this.props.nosiociPredmeta ?? await this.props.onNosiociPredmetaMount();

        this.setState({
            predmet: this.props.predmet,
            loading: false,
            editMode: !this.props.predmet || !this.props.predmet.id ? true : false,
            tipoviPredmeta: tipoviPredmeta,
            statusiPredmeta: statusiPredmeta,
            tipoviSuda: tipoviSuda,
            nosiociPredmeta: nosiociPredmeta
        });
    }

    setEditMode(editMode) {
        this.setState({
            editMode: editMode
        });
    }

    saveClicked(predmet) {
        this.setState({
            predmet: predmet
        }, () => {
            this.setEditMode(false);
            this.toastRef.current.show({ severity: 'success', summary: 'Uspešno sačuvano', detail: "Uspešno ste sačuvali predmet." });
        });

        this.props.onPredmetUpdate(predmet);
    }

    render() {
        let predmetOsnovniPodaciView = this.state.editMode

            ? <OsnovniPodaciEdit predmet={this.state.predmet}
                tipoviPredmeta={this.state.tipoviPredmeta} statusiPredmeta={this.state.statusiPredmeta} tipoviSuda={this.state.tipoviSuda}
                nosiociPredmeta={this.state.nosiociPredmeta}
                klijentAdvokataId={this.props.klijentAdvokataId} imePrezimeKlijenta={this.props.imePrezimeKlijenta}
                onCancelClick={() => this.setEditMode(false)} onSaveClick={(predmet) => this.saveClicked(predmet)} />

            : <OsnovniPodaciView predmet={this.state.predmet}
                tipoviPredmeta={this.state.tipoviPredmeta} statusiPredmeta={this.state.statusiPredmeta} tipoviSuda={this.state.tipoviSuda}
                nosiociPredmeta={this.state.nosiociPredmeta}
                klijentAdvokataId={this.props.klijentAdvokataId} imePrezimeKlijenta={this.props.imePrezimeKlijenta}
                onAzurirajClick={() => this.setEditMode(true)} />;

        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : predmetOsnovniPodaciView;

        return (
            <div>
                {contents}
                <Toast ref={this.toastRef} />
            </div>
        );
    }
}
