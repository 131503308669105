import React, { Component } from 'react';
import fetchService from '../FetchService';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import CloseLineWhiteIcon from '../icons/close-line-white.svg';
import SaveLineWhiteIcon from '../icons/save-line-white.svg';
import DeleteBinLineIcon from '../icons/delete-bin-line.svg';
import DeleteBinLineWhiteIcon from '../icons/delete-bin-line-white.svg';
import ImageEditIcon from '../icons/image-edit.svg';
import EditIcon from '../icons/edit.svg';
import DownloadIcon from '../icons/download-2.svg';
import ArrowGoBackWhiteIcon from '../icons/arrow-go-back-white.svg';
import PenciLineWhiteIcon from '../icons/pencil-line-white.svg';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ImageEditor } from './ImageEditor';
import { HelpTooltip } from '../HelpTooltip';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';

export class DokumenatEdit extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();
        this.filesRef = React.createRef();
        this.formRef = React.createRef();
        this.imageToEditRef = React.createRef();

        this.state = {
            loading: true,
            dokumenatPredmeta: {
                id: '',
                predmetId: '',
                naziv: '',
                opis: '',
                postojeciFajlovi: [],
                odgovornoLiceId: ''
            },
            imePrezimeKlijenta: '',
            brojPredmeta: '',
            filesToDelete: [],
            imageToEditUrl: '',
            editFascikla: false,
            newFascikla: false,
            renameFileVisible: false,
            renameFileValue: '',
            renameFileOriginalValue: ''
        };

        this.saveDataToApi = this.saveDataToApi.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.removePostojeciFajl = this.removePostojeciFajl.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.editImage = this.editImage.bind(this);
        this.onUpload = this.onUpload.bind(this);
        this.onFileDelete = this.onFileDelete.bind(this);
        this.initEditFascikla = this.initEditFascikla.bind(this);
        this.cancelEditFascikla = this.cancelEditFascikla.bind(this);
    }

    componentDidMount() {
        if (this.props.dokumenatPredmeta.id) {
            this.setState({
                dokumenatPredmeta: {
                    ...this.props.dokumenatPredmeta
                },

                imePrezimeKlijenta: this.props.imePrezimeKlijenta,
                brojPredmeta: this.props.brojPredmeta,
                loading: false
            });
        } else {
            this.setState({
                dokumenatPredmeta: {
                    ...this.state.dokumenatPredmeta,
                    predmetId: this.props.predmetId
                },
                newFascikla: true,
                editFascikla: true
            })
        }

        this.setState({
            imePrezimeKlijenta: this.props.imePrezimeKlijenta,
            brojPredmeta: this.props.brojPredmeta,
            loading: false
        });
    }

    initEditFascikla() {
        this.setState({ editFascikla: true });
    }

    async saveDataToApi() {
        if (!this.formRef.current.checkValidity()) {
            this.formRef.current.classList.add('was-validated');
            return;
        }

        this.setState({ loading: true });

        const method = this.state.dokumenatPredmeta.id ? 'PUT' : 'POST';
        const bodyInput = {
            "DokumenatPredmetaId": this.state.dokumenatPredmeta.id,
            "PredmetId": this.state.dokumenatPredmeta.predmetId,
            "Naziv": this.state.dokumenatPredmeta.naziv,
            "Opis": this.state.dokumenatPredmeta.opis,
            "OdgovornoLiceId": this.state.dokumenatPredmeta.odgovornoLiceId
        };

        let response = await fetchService.save('dokumenatPredmeta', bodyInput, method);

        this.setState({ loading: false });

        if (!response.success) {
            let errorMessage = response.message ? 'Greška: ' + response.message : '';
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom čuvanja dokumenta predmeta. " + errorMessage, sticky: true });
        }
        else {
            this.props.onSaveClick(response.responseValue.id);
        }
    }

    async deleteItem() {
        this.setState({ loading: true });

        let response = await fetchService.delete(`dokumenatPredmeta?dokumenatPredmetaId=${this.state.dokumenatPredmeta.id}`);

        this.setState({ loading: false });

        if (!response.success) {
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom brisanja dokumenta predmeta. Greška: " + response.message, sticky: true });
        }
        else {
            this.props.onSaveClick();
        }
    }

    cancelEdit() {
        this.props.onCancelClick();
    }

    cancelEditFascikla() {
        if (this.state.newFascikla) {
            this.props.onCancelClick();
        }
        else {
            this.setState({
                editFascikla: false,
                dokumenatPredmeta: {
                    ...this.props.dokumenatPredmeta
                }
            });
        }
    }

    confirmDelete(event) {
        confirmDialog({
            trigger: event.currentTarget,
            message: 'Da li želite da obrišete ovaj dokument predmeta?',
            header: 'Brisanje dokumenta',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => { this.deleteItem() },
            acceptLabel: 'Da',
            rejectLabel: 'Ne'
        });
    };

    renderForm() {
        return (
            <div>
                <div hidden={this.state.newFascikla ? 'hidden' : ''}>
                    <div className="mb-3 form-header">
                        <FileUpload id='fajlovi' name="fajlovi" maxFileSize={15000000}
                            className="btn btn-primary btns btn-upload"
                            ref={this.filesRef}

                            multiple
                            chooseLabel="Dodaj dokumenta"
                            uploadOptions={{ icon: '', iconOnly: true, className: 'display-none' }}
                            cancelOptions={{ icon: '', iconOnly: true, className: 'display-none' }}
                            onSelect={this.onUpload}
                        />
                        <button className="btn btn-secondary btns" onClick={this.cancelEdit}>
                            <img alt="" src={ArrowGoBackWhiteIcon} />
                            &nbsp;
                            Nazad
                        </button>
                    </div>
                    <div className="form-container mb-5">
                        <div className="mb-3">
                            <label htmlFor="fajlovi" className="form-label">Postojeća dokumenta</label>
                            <ul className='list-group'>
                                {this.renderExistingFiles()}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="mb-3 form-header">
                    <button id='btnAzurirajFasciklu' className="btn btn-primary btns"
                        onClick={this.initEditFascikla}
                        hidden={this.state.editFascikla ? 'hidden' : ''}
                    >
                        <img alt="" src={PenciLineWhiteIcon} />
                        &nbsp;
                        Ažuriraj fasciklu
                    </button>

                    <button id='btnSacuvajFasciklu' className="btn btn-primary btns"
                        onClick={this.saveDataToApi}
                        hidden={this.state.editFascikla ? '' : 'hidden'}
                    >
                        <img alt="" src={SaveLineWhiteIcon} />
                        &nbsp;
                        Sačuvaj
                    </button>
                    <button id='btnBrisiFasciklu' className="btn btn-danger btns"
                        onClick={this.confirmDelete}
                        hidden={this.state.editFascikla && this.state.dokumenatPredmeta.id ? '' : 'hidden'}
                    >
                        <img alt="" src={DeleteBinLineWhiteIcon} />
                        &nbsp;
                        Briši
                    </button>
                    <button id='btnOdustaniFasciklu' className="btn btn-secondary btns"
                        onClick={this.cancelEditFascikla}
                        hidden={this.state.editFascikla ? '' : 'hidden'}
                    >
                        <img alt="" src={CloseLineWhiteIcon} />
                        &nbsp;
                        Odustani
                    </button>
                </div>


                <div className="form-container">
                    <form className='form-group' ref={this.formRef}>
                        <div className="mb-3">
                            <label htmlFor="naziv" className="form-label">Naziv fascikle *</label>
                            <textarea className="form-control" id="naziv" value={this.state.dokumenatPredmeta.naziv ?? ''}
                                maxLength="100" rows="3" required
                                readOnly={this.state.editFascikla ? '' : 'readOnly'}
                                onChange={(e) => this.setState({ dokumenatPredmeta: { ...this.state.dokumenatPredmeta, naziv: e.target.value } })}>
                            </textarea>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="opis" className="form-label">Opis</label>
                            <textarea className="form-control" id="opis" value={this.state.dokumenatPredmeta.opis ?? ''} rows="3"
                                readOnly={this.state.editFascikla ? '' : 'readOnly'}
                                onChange={(e) => this.setState({ dokumenatPredmeta: { ...this.state.dokumenatPredmeta, opis: e.target.value } })}>
                            </textarea>
                        </div>
                    </form>

                    <div className="mb-3 col-md-4">
                        <label htmlFor="odgovornoLiceId" className="form-label">Odgovorno lice</label>
                        <HelpTooltip>
                            <p className="mb-3">
                                Advokat kancelarije odgovoran za zapis predmeta.
                            </p>
                        </HelpTooltip>
                        <div>
                            <Dropdown id="odgovornoLiceId"
                                value={this.state.dokumenatPredmeta.odgovornoLiceId}
                                onChange={(e) => this.setState({ dokumenatPredmeta: { ...this.state.dokumenatPredmeta, odgovornoLiceId: e.target.value } })}
                                options={this.props.odgovornaLica}
                                optionLabel="imePrezime"
                                optionValue="id"
                                filter
                                showClear
                                disabled={this.state.editFascikla ? '' : 'readOnly'}
                                className="w-full p-dropdown-fullwidth" />
                        </div>
                    </div>


                </div>

            </div>
        );
    }

    async onUpload(e) {
        this.setState({ loading: true });

        let form = new FormData();
        let files = e.files;
        for (var index = 0; index < files.length; index++) {
            var element = files[index];
            form.append('attachedFiles', element);
        }
        form.append('dokumenatPredmetaId', this.state.dokumenatPredmeta.id);

        let response = await fetchService.saveForm('dokumenatPredmetaUpload', form, 'POST');
        if (!response.success) {
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom čuvanja dokumenta predmeta. Greška: " + response.message, sticky: true });
        }
        else {
            this.setState({
                dokumenatPredmeta: {
                    ...this.state.dokumenatPredmeta,
                    postojeciFajlovi: response.responseValue.postojeciFajlovi
                },
                loading: false
            });
            this.props.onSaveClick(this.state.dokumenatPredmeta.id);
        }
    }

    confirmFileDelete(fileName) {
        confirmDialog({
            message: 'Da li želite da obrišete fajl "' + fileName + '" ?',
            header: 'Brisanje fajla',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => { this.onFileDelete(fileName) },
            acceptLabel: 'Da',
            rejectLabel: 'Ne'
        });
    };

    async onFileDelete(fileName) {
        this.setState({ loading: true });

        const method = 'DELETE';
        const bodyInput = {
            "DokumenatPredmetaId": this.state.dokumenatPredmeta.id,
            "FilesToDelete": [fileName]
        };

        let response = await fetchService.save('dokumenatPredmetaUpload', bodyInput, method);

        this.setState({ loading: false });

        if (!response.success) {
            let errorMessage = response.message ? 'Greška: ' + response.message : '';
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom čuvanja dokumenta predmeta. " + errorMessage, sticky: true });
        }
        else {
            this.props.onSaveClick(this.state.dokumenatPredmeta.id);
        }
    }

    async onFileRename() {
        this.setState({ loading: true });

        let extension = this.getFileNameExtension(this.state.renameFileOriginalValue);
        let newFileName = this.state.renameFileValue + extension;

        const method = 'PUT';
        const bodyInput = {
            "DokumenatPredmetaId": this.state.dokumenatPredmeta.id,
            "OldFileName": this.state.renameFileOriginalValue,
            "NewFileName": newFileName
        };

        let response = await fetchService.save('dokumenatPredmetaUpload', bodyInput, method);

        this.setState({ loading: false });

        if (!response.success) {
            let errorMessage = response.message ? 'Greška: ' + response.message : '';
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom čuvanja dokumenta predmeta. " + errorMessage, sticky: true });
        }
        else {
            this.props.onSaveClick(this.state.dokumenatPredmeta.id);
        }
    }

    getFileNameWithoutExtension(fileNameWithExtension) {
        let extension = this.getFileNameExtension(fileNameWithExtension);
        if (extension == '')
            return fileNameWithExtension;

        let lastIndexOfExtension = fileNameWithExtension.lastIndexOf(extension);
        let fileNameWithoutExtension = fileNameWithExtension.substring(0, lastIndexOfExtension);
        return fileNameWithoutExtension;
    }

    getFileNameExtension(fileNameWithExtension) {
        let containsExtension = fileNameWithExtension.indexOf('.') > -1;
        if (!containsExtension)
            return '';

        return '.' + fileNameWithExtension.split('.').pop();
    }

    async getBlobFromApi(fileName) {
        let requestUrl = `dokumenatPredmetaFile?dokumenatPredmetaId=${this.state.dokumenatPredmeta.id}&fileName=${fileName}`;
        const dataBlob = await fetchService.getBlob(requestUrl);

        return dataBlob.responseValue;
    }

    async previewPostojeciFajl(fileName, preview) {
        if (!preview) {
            this.downloadPostojeciFajl(fileName);
            return;
        }

        const dataBlob = await this.getBlobFromApi(fileName);

        var objectURL = URL.createObjectURL(dataBlob);

        const w = window.open(objectURL, '_blank');
        //const w = window.open(objectURL, '', 'fullscreen=yes,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no');
        w && w.focus();
    }

    async downloadPostojeciFajl(fileName) {
        const dataBlob = await this.getBlobFromApi(fileName);

        var objectURL = URL.createObjectURL(dataBlob);

        const link = document.createElement('a');
        link.href = objectURL;
        link.download = fileName;
        link.click();
    }

    removePostojeciFajl(fileName) {
        let existingFiles = this.state.dokumenatPredmeta.postojeciFajlovi;
        let newFiles = existingFiles.filter(file => file !== fileName);

        this.setState({
            dokumenatPredmeta: {
                ...this.state.dokumenatPredmeta,
                postojeciFajlovi: newFiles
            }
        });

        this.state.filesToDelete.push(fileName);
    }

    renderExistingFiles() {
        if (this.state.dokumenatPredmeta.postojeciFajlovi.length == 0) {
            return <li className='list-group-item'>Fascikla još uvek nema sačuvane fajlove.</li>;
        }

        let previewExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.svg', '.bmp', '.webp', '.pdf', '.txt'];
        let editableExtensions = ['.jpg', '.jpeg', '.png'];

        return this.state.dokumenatPredmeta.postojeciFajlovi.map((postojeciFajlName, index) => {
            let previewAvailable = false;
            previewExtensions.forEach(ext => { previewAvailable = previewAvailable || postojeciFajlName.endsWith(ext); });

            let editAvailable = false;
            editableExtensions.forEach(ext => { editAvailable = editAvailable || postojeciFajlName.endsWith(ext); });

            //let visibleClass = previewAvailable ? '' : 'hidden-visibility';
            let editAvailableClass = editAvailable ? '' : 'hidden-visibility';

            return (
                <li key={index} className='list-group-item'>
                    <button className="btn btn-existing-document col" onClick={() => this.downloadPostojeciFajl(postojeciFajlName)}>
                        <img alt="" src={DownloadIcon} />
                    </button>
                    <button className={`btn btn-existing-document col ${editAvailableClass}`} onClick={() => this.editImage(postojeciFajlName)}>
                        <img alt="" src={ImageEditIcon} />
                    </button>
                    <button className="btn btn-existing-document col" onClick={() => this.setState({
                        renameFileVisible: true,
                        renameFileValue: this.getFileNameWithoutExtension(postojeciFajlName),
                        renameFileOriginalValue: postojeciFajlName
                    })}
                    >
                        <img alt="" src={EditIcon} />
                    </button>
                    <button className="btn btn-existing-document col" onClick={() => this.confirmFileDelete(postojeciFajlName)}>
                        <img alt="" src={DeleteBinLineIcon} />
                    </button>
                    <button className="btn btn-existing-document dokument-item col-7" onClick={() => this.previewPostojeciFajl(postojeciFajlName, previewAvailable)}>
                        {postojeciFajlName}
                    </button>
                </li>
            );
        });
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : this.renderForm();

        return (
            <div>
                {contents}

                <Toast ref={this.toastRef} />
                <ConfirmDialog />

                <ImageEditor
                    imageToEditUrl={this.state.imageToEditUrl}
                    imageToEditName={this.state.imageToEditName}
                    dokumenatPredmetaId={this.state.dokumenatPredmeta.id}
                    onClose={() => this.setState({ imageToEditUrl: '', imageToEditName: '' })} />

                {this.renderRenameFileDialog()}
            </div>
        );
    }

    async editImage(fileName) {
        this.setState({ imageToEditName: fileName }, async () => {
            const dataBlob = await this.getBlobFromApi(fileName);

            var urlCreator = window.URL || window.webkitURL;
            var imageToEditUrl = urlCreator.createObjectURL(dataBlob);

            this.setState({ imageToEditUrl: imageToEditUrl });
        });
    }

    renderRenameFileDialog() {
        return (
            <Dialog
                header='Promena imena fajla'
                visible={this.state.renameFileVisible}
                style={{ width: '40vw' }}
                breakpoints={{ '1200px': '50vw', '850px': '75vw', '500px': '100vw' }}
                position='top'
                onHide={() => this.setState({ renameFileVisible: false })}
                footer={<div>
                    <button className="btn btn-primary btns"
                        onClick={async () => {
                            await this.onFileRename();
                            this.setState({ renameFileVisible: false, renameFileValue: '', renameFileOriginalValue: '' });
                        }}
                    ><img alt="" src={SaveLineWhiteIcon} />&nbsp;Sačuvaj</button>
                    <button className="btn btn-secondary btns" onClick={() => this.setState({ renameFileVisible: false, renameFileValue: '', renameFileOriginalValue: '' })}><img alt="" src={CloseLineWhiteIcon} />&nbsp;Odustani</button>
                </div>}
            >
                <div>
                    <input autoFocus className="form-control" id="renameFileValue" value={this.state.renameFileValue ?? ''}
                        onChange={(e) => this.setState({ renameFileValue: e.target.value })} />
                </div>
            </Dialog>
        );
    }
}
