import ConstantsClass from './constantsclass.js';
import Popper from './popper';

class Poppers {
    subMenuPoppers = [];
    ConstantsInstance = new ConstantsClass();

    constructor() {
        this.init();
    }

    init() {
        var SUB_MENU_ELS = this.ConstantsInstance.SUB_MENU_ELS();
        SUB_MENU_ELS.forEach((element) => {
            this.subMenuPoppers.push(new Popper(element, element.lastElementChild));
            this.closePoppers();
        });
    }

    togglePopper(target) {
        if (window.getComputedStyle(target).visibility === 'hidden')
            target.style.visibility = 'visible';
        else target.style.visibility = 'hidden';
    }

    updatePoppers() {
        this.subMenuPoppers.forEach((element) => {
            element.instance.state.elements.popper.style.display = 'none';
            element.instance.update();
        });
    }

    closePoppers() {
        this.subMenuPoppers.forEach((element) => {
            element.hide();
        });
    }
}

export default Poppers;
