import React, { Component } from 'react';
import { Sidebar } from 'primereact/sidebar';
import LogoBlackWithTitleIcon from '../logo/logo-black-with-title.png';
import MenuIcon from './menu.svg';
import './home.css';
import MobileHand from './mobile-hand.png';
import { Dialog } from 'primereact/dialog';
import PlayIcon from './play-circle-white.svg';

export class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuVisible: false,
            videoModalVisible: false,
            deferredPrompt: null,
            installAppVisible: false
        };

        window.addEventListener('beforeinstallprompt', (e) => {
            this.setState({
                installAppVisible: true,
                deferredPrompt: e
            });
        });

        this.addToHomeScreen = this.addToHomeScreen.bind(this);
    }

    render() {
        return (
            <div>
                {this.renderMenu()}

                {this.renderFirstPart()}

                {this.renderVideoModal()}
            </div>
        );
    }

    renderMenu() {
        return (
            <div className="container land-page-menu">
                <header className="d-flex flex-wrap justify-content-between py-3 mb-4 border-bottom">
                    <a href="/" className="d-flex align-items-center mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                        <img alt="" src={LogoBlackWithTitleIcon} className='home-logo' />
                    </a>

                    <ul className="nav nav-pills hide-on-mobile mt-1 lead">
                        <li className="nav-item"><a href="/" className="nav-link">Početak</a></li>
                        <li className="nav-item"><a href="#section-features" className="nav-link">Osobine</a></li>
                        <li className="nav-item"><a href="#section-pricing" className="nav-link">Cenovnik</a></li>
                        <li className="nav-item"><a href="#najcesca-pitanja" className="nav-link">Najčešća pitanja</a></li>
                        <li className="nav-item"><a href="#section-contact" className="nav-link">Kontakt</a></li>
                        <li className="nav-item"><a href="/Identity/Account/Login?isDemoView=1&ReturnUrl=/dashboard" className="nav-link active" style={{ marginLeft: '2rem' }}>Isprobaj demo</a></li>
                    </ul>


                    <button className="navbar-toggler show-on-mobile" type="button"
                        onClick={() => { this.setState({ menuVisible: true }); }}>
                        <span className="navbar-toggler-icon"><img alt="" src={MenuIcon} /></span>
                    </button>

                    <Sidebar className='navbar-sidebar' visible={this.state.menuVisible} position="right" onHide={() => { this.setState({ menuVisible: false }); }}>
                        <ul className="nav nav-pills nav-menu-list lead">
                            <li className="nav-item mb-3"><a href="/" className="nav-link" onClick={() => { this.setState({ menuVisible: false }); }}>Početak</a></li>
                            <li className="nav-item mb-3"><a href="#section-features" className="nav-link" onClick={() => { this.setState({ menuVisible: false }); }}>Osobine</a></li>
                            <li className="nav-item mb-3"><a href="#section-pricing" className="nav-link" onClick={() => { this.setState({ menuVisible: false }); }}>Cenovnik</a></li>
                            <li className="nav-item mb-3"><a href="#najcesca-pitanja" className="nav-link" onClick={() => { this.setState({ menuVisible: false }); }}>Najčešća pitanja</a></li>
                            <li className="nav-item mb-5"><a href="#section-contact" className="nav-link" onClick={() => { this.setState({ menuVisible: false }); }}>Kontakt</a></li>

                            <hr />
                            <li className="nav-item mb-3"><a href="/Identity/Account/Login?isDemoView=1&ReturnUrl=/dashboard" className="nav-link active">Isprobaj demo</a></li>
                            <hr />
                            <li className="nav-item mb-3"><a href="/dashboard" className="nav-link active">Prijavi se</a></li>
                            <li className="nav-item"><a href="/Identity/Account/Register" className="nav-link active">Napravi nalog</a></li>

                            <span hidden={this.state.installAppVisible ? '' : 'hidden'} >
                                <hr />
                                <li className="nav-item">
                                    <a onClick={(e) => this.addToHomeScreen()} className='nav-link active cursor-pointer'>Instaliraj aplikaciju</a>
                                </li>
                            </span>
                        </ul>
                    </Sidebar>
                </header>
            </div>
        );
    }


    renderFirstPart() {
        return (
            <div className="container pt-4 px-2 py-2 section1-container">
                <div className="row flex-lg-row-reverse g-5 py-5 mx-1">
                    <div className="col-lg-6 mt-0">
                        <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-1 rokovnik-u-telefonu">Rokovnik u telefonu</h1>
                        <p className="lead mt-5 omogucava-advokatima">ZAPISNIK.RS OMOGUĆAVA ADVOKATIMA DA PRISTUPE SVOJOJ KANCELARIJI ODAKLE GOD ŽELE</p>
                        <div>&nbsp;</div>
                        <p className="lead mb-2 mt-4s">Priključi se advokatima koji koriste Zapisnik.rs već danas</p>

                        <div className='row mt-5'>
                            <div className="col-6 col-md-12 mt-0">
                                <div className="d-grid gap-2 d-md-flex justify-content-end mt-3" >
                                    <a href="/dashboard" className="btn btn-primary btn-lg px-4 me-md-2 home-screen-button">Prijavi se</a>
                                    <a href="/Identity/Account/Register" className="btn btn-primary btn-lg px-4 me-md-2 home-screen-button">Napravi nalog</a>
                                </div>
                            </div>


                            <div className="col-6 col-md-12 mt-0">
                                <div className="d-grid gap-2 d-md-flex justify-content-end mt-3">
                                    <a href="/Identity/Account/Login?isDemoView=1&ReturnUrl=/dashboard" className="btn btn-primary btn-lg px-4 me-md-2 home-screen-button show-on-mobile">Isprobaj demo</a>
                                    <button className="btn btn-primary btn-lg px-4 me-md-2 home-screen-button"
                                        onClick={() => { this.setState({ videoModalVisible: true }); }} >
                                        <img alt="" src={PlayIcon} />
                                        &nbsp;
                                        Video
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-10 col-sm-8 col-lg-6">
                        <img src={MobileHand} className="d-block mx-lg-auto img-fluid" loading="lazy" />
                    </div>
                </div>
            </div>
        );
    }

    renderVideoModal() {
        return (
            <Dialog header='Video prezentacija' visible={this.state.videoModalVisible} style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                onHide={() => { this.setState({ videoModalVisible: false }); }}>
                <div className="m-0">
                    <video controls width="100%">
                        <source src='/videoTutorijal/kratak-pregled-funkcionalnosti.mp4' type="video/mp4" />
                    </video>
                </div>
            </Dialog>
        );
    }

    async addToHomeScreen() {
        let deferredPrompt = this.state.deferredPrompt;

        if (deferredPrompt !== null) {
            deferredPrompt.prompt();
            const { outcome } = await deferredPrompt.userChoice;
            if (outcome === 'accepted') {
                this.setState({
                    deferredPrompt: null,
                    installAppVisible: false
                });
            }
        }
    }
}
