import React, { Component } from 'react';
import fetchService from '../FetchService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import AddLineWhiteIcon from '../icons/add-line-white.svg';
import { HelpTooltip } from '../HelpTooltip';

export class TipoviRacuna extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tipoviRacuna: [],
            loading: true,
            tableLoading: true,
            lazyState: {
                first: 0,
                rows: 10,
                page: 0,
                sortField: 'naziv',
                sortOrder: 1,
                filter: ''
            },
            typingTimeout: 0,
            totalRecords: 0
        };
        this.filterReference = React.createRef();
    }

    componentDidMount() {
        this.populateTipoviRacuna();
    }

    renderTipoviRacuna(tipoviRacuna) {
        const footer = `Ukupno stavki: ${this.state.totalRecords}.`;

        return (
            <div>
                <DataTable value={tipoviRacuna}
                    dataKey="id"
                    lazy totalRecords={this.state.totalRecords}
                    first={this.state.lazyState.first}
                    onPage={this.onPage}
                    onSort={this.onSort} sortField={this.state.lazyState.sortField} sortOrder={this.state.lazyState.sortOrder}
                    header={this.renderHeader}
                    footer={footer}
                    paginator rows={10}
                    tableStyle={{ minWidth: '50rem' }} className="custom-datatable"
                    tableClassName='table'
                    rowClassName={this.rowClass}
                    loading={this.state.tableLoading}
                    emptyMessage="Nema unetih podataka."
                    selectionMode="single" onSelectionChange={(e) => window.location = '/tip-racuna?id=' + e.value.id} >

                    <Column field="naziv" sortable header="Naziv" style={{ width: '80%' }}></Column>
                    <Column field="aktivan" body={this.aktivanTemplate} sortable header="Aktivan" style={{ width: '20%' }}></Column>
                </DataTable>
            </div>
        );
    }

    aktivanTemplate = (tipRacuna) => {
        return tipRacuna.aktivan === true ? 'Da' : 'Ne';
    };

    rowClass(tipRacunaDataItem) {
        if (tipRacunaDataItem.aktivan !== true) {
            return 'table-danger';
        }

        return '';
    }

    renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <a className="btn btn-primary" href="/tip-racuna">
                    <img alt="" src={AddLineWhiteIcon} />
                    &nbsp;
                    Tip
                </a>

                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={this.state.lazyState.filter} placeholder="Traži..." ref={this.filterReference} className='search-table'
                        onChange={this.onFilterChange} onKeyDown={this.onFilterKeyDown} />
                </span>
            </div>
        );
    };

    render() {
        let contents =
            this.state.loading
                ? <p><em>Učitavanje...</em></p>
                : this.renderTipoviRacuna(this.state.tipoviRacuna);

        return (
            <div>
                <h2 className='mb-3 page-title' id="tabelLabel">
                    Tipovi Računa
                    <HelpTooltip>
                        <p>
                            Tipovi računa su entiteti po kojima možete kategorizovati račune predmeta.
                        </p>
                        <p>
                            Svakom računu možete dodeliti po jedan tip, i kasnije vršiti lakšu pretragu i filtriranje.
                        </p>
                    </HelpTooltip>
                </h2>
                {contents}
            </div>
        );
    }

    async populateTipoviRacuna() {
        this.setState({
            tableLoading: true
        });

        let rows = this.state.lazyState.rows;
        let page = this.state.lazyState.page + 1;
        let sortField = this.state.lazyState.sortField;
        let sortOrder = this.state.lazyState.sortOrder;
        let filter = this.state.lazyState.filter;

        let requestUrl = `tipoviRacuna?rows=${rows}&page=${page}&sortField=${sortField}&sortOrder=${sortOrder}&filter=${filter}`;

        const response = await fetchService.get(requestUrl);
        const data = response.success ? response.responseValue : [];

        this.setState({
            tipoviRacuna: data.tipoviRacuna,
            totalRecords: data.totalCount,
            loading: false,
            tableLoading: false
        });
    }

    onPage = (event) => {
        this.setState(
            {
                lazyState: {
                    first: event.first,
                    rows: event.rows,
                    page: event.page,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                    filter: this.state.lazyState.filter
                }
            },
            () => this.populateTipoviRacuna()
        );
    }

    onSort = (event) => {
        this.setState(
            {
                lazyState: {
                    first: event.first,
                    rows: event.rows,
                    page: this.state.lazyState.page,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                    filter: this.state.lazyState.filter
                }
            },
            () => this.populateTipoviRacuna()
        );
    }

    onFilterChange = (event) => {
        const value = event.target.value;
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            lazyState: {
                ...this.state.lazyState,
                filter: value
            },
            typingTimeout: setTimeout(function () {
                self.populateTipoviRacuna();
            }, 800)
        });
    }

    onFilterKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (this.state.typingTimeout) {
                clearTimeout(this.state.typingTimeout);
            }

            this.setState({
                typingTimeout: 0
            });

            this.populateTipoviRacuna();
        }
    }
}
