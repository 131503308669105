import React, { Component } from 'react';
import { ObavezeDanasSutra } from './ObavezeDanasSutra';
import { BrzaPretragaKlijenata } from './BrzaPretragaKlijenata';
import { BrzaPretragaPredmeta } from './BrzaPretragaPredmeta';
import { IzabraniPredmeti } from './IzabraniPredmeti';
import { AktivnaRocistaPredmeta } from './AktivnaRocistaPredmeta';
import LogoBlackWithTitleIcon from '../logo/logo-black-with-title.png';

export class Dashboard extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <img alt="" src={LogoBlackWithTitleIcon} className='home-logo show-on-mobile' />
                <h1 className="mb-4 page-title hide-on-mobile">
                    Dobro došli na platformu 'Zapisnik'!
                </h1>
                <div className="form-container">
                    <ObavezeDanasSutra />

                    <hr className="mx-3" />

                    <div className="row">
                        <div className="mb-3 col-xl-6">
                            <BrzaPretragaKlijenata />
                        </div>

                        <div className="mb-3 col-xl-6">
                            <BrzaPretragaPredmeta />
                        </div>
                    </div>

                    <hr className="mx-3" />

                    <div className="row">
                        <div className="mb-3 col-xl-6">
                            <IzabraniPredmeti />
                        </div>

                        <div className="mb-3 col-xl-6">
                            <AktivnaRocistaPredmeta />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
