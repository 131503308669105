import React, { Component } from 'react';
import fetchService from '../FetchService';
import CloseLineWhiteIcon from '../icons/close-line-white.svg';
import SaveLineWhiteIcon from '../icons/save-line-white.svg';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Dialog } from 'primereact/dialog';
import { Slider } from 'primereact/slider';
import { InputNumber } from "primereact/inputnumber";
import { Message } from 'primereact/message';

export class ImageEditor extends Component {
    constructor(props) {
        super(props);
        this.imageToEditRef = React.createRef();

        this.state = {
            loading: false,
            imageToEdit: null,
            croppedImage: {
                unit: '%',
                width: 100,
                height: 100,
                x: 0,
                y: 0
            },
            imageToEditRotate: 0,
            imageToEditScale: 1
        };

        this.saveEditedImage = this.saveEditedImage.bind(this);
        this.resetEditImage = this.resetEditImage.bind(this);
        this.getCroppedImage = this.getCroppedImage.bind(this);
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
                {this.renderImageEditor()}
            </div>
        );
    }

    renderImageEditor() {
        let contents =
            this.props.imageToEditUrl && !this.state.loading
                ? this.renderImageEditorContent()
                : <p><em>Učitavanje...</em></p>;

        return (
            <div>
                <Dialog
                    header={`Uređivanje slike - ${this.props.imageToEditName} `}
                    style={{ width: '60vw' }}
                    breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                    visible={this.props.imageToEditName ? true : false}
                    position='top'
                    onHide={() => this.resetEditImage()}

                    footer={<div>
                        <button className="btn btn-primary btns" onClick={() => this.saveEditImageConfirm()}><img alt="" src={SaveLineWhiteIcon} />&nbsp;Sačuvaj</button>
                        <button className="btn btn-secondary btns" autoFocus onClick={() => this.resetEditImage()}><img alt="" src={CloseLineWhiteIcon} />&nbsp;Odustani</button>
                    </div>}
                >
                    {contents}
                </Dialog>

            </div>
        );
    }

    renderImageEditorContent() {
        return (<div className="m-0">
            <Message className="mb-3" severity="warn" style={{ display: 'block' }} text="Preporuka je da se za uređivanje slika koriste programi specijalizovani za to." />

            <div className="row">
                <div className="mb-3 col-sm-6">
                    <label className="form-label">Uvećanje</label>&nbsp;&nbsp;
                    <InputNumber min={0.1} max={10} step={0.1} value={this.state.imageToEditScale} onChange={(e) => this.setState({ imageToEditScale: e.value ? e.value : 1 })} className="w-full mb-3" />
                    <Slider min={0.1} max={10} step={0.1} value={this.state.imageToEditScale} onChange={(e) => this.setState({ imageToEditScale: e.value })} className="w-full" />
                </div>

                <div className="mb-3 col-sm-6">
                    <label className="form-label">Rotacija</label>&nbsp;&nbsp;
                    <InputNumber min={-180} max={180} value={this.state.imageToEditRotate} onChange={(e) => this.setState({ imageToEditRotate: e.value ? e.value : 0 })} className="w-full mb-3" />
                    <Slider min={-180} max={180} value={this.state.imageToEditRotate} onChange={(e) => this.setState({ imageToEditRotate: e.value })} className="w-full" />
                </div>
            </div>

            <div className='mx-4'>
                <ReactCrop crop={this.state.croppedImage}
                    onChange={(c) => this.setState({ croppedImage: c })}>
                    <img ref={this.imageToEditRef} src={this.props.imageToEditUrl} style={{ transform: `scale(${this.state.imageToEditScale}) rotate(${this.state.imageToEditRotate}deg)` }} />
                </ReactCrop>
            </div>
        </div>);
    }


    saveEditImageConfirm() {
        confirmDialog({
            message: 'Da li ste sigurni da želite da sačuvate promene? Kada se promene na slici jednom sačuvaju, vraćanje originala više nije moguće.',
            header: 'Čuvanje promena slike',
            icon: 'pi pi-info-circle',
            accept: () => { this.saveEditedImage() },
            acceptLabel: 'Da',
            rejectLabel: 'Ne'
        });
    }

    async saveEditedImage() {
        this.setState({
            loading: true
        });

        let imageInfo = this.getCroppedImage();

        const bodyInput = {
            "DokumenatPredmetaId": this.props.dokumenatPredmetaId,
            "FileName": this.props.imageToEditName,
            "StartX": imageInfo.StartX,
            "StartY": imageInfo.StartY,
            "Width": imageInfo.Width,
            "Height": imageInfo.Height,
            "Rotate": imageInfo.Rotate,
            "Scale": imageInfo.Scale
        };

        let response = await fetchService.save('dokumenatPredmetaFile', bodyInput, 'PUT');

        this.setState({
            loading: false
        });

        if (!response.success) {
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom čuvanja slike. Greška: " + response.message, sticky: true });
        }
        else {
            this.resetEditImage();
        }
    }

    resetEditImage() {
        this.setState({
            imageToEdit: null,
            croppedImage: {
                unit: '%',
                width: 100,
                height: 100,
                x: 0,
                y: 0
            },
            imageToEditRotate: 0,
            imageToEditScale: 1
        });

        this.props.onClose();
    }

    getCroppedImage() {
        let image = this.imageToEditRef.current;
        let crop = this.state.croppedImage;

        if (crop.unit == '%') {
            crop.width = (crop.width * image.width) / 100;
            crop.height = (crop.height * image.height) / 100;
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const cropX = Math.floor(crop.x * scaleX);
        const cropY = Math.floor(crop.y * scaleY);
        const cropWidth = Math.floor(crop.width * scaleX);
        const cropHeight = Math.floor(crop.height * scaleY);

        let result = {
            "StartX": cropX,
            "StartY": cropY,
            "Width": cropWidth,
            "Height": cropHeight,
            "Rotate": this.state.imageToEditRotate,
            "Scale": this.state.imageToEditScale
        };

        return result;
    }
}
