import React, { Component } from 'react';
import './home.css';

import { Carousel } from 'primereact/carousel';
import { Galleria } from 'primereact/galleria';
import { Accordion, AccordionTab } from 'primereact/accordion';

import FeatureCalendar from './feature-calendar.png';
import FeatureDokumenta from './feature-dokumenta.png';
import FeatureFiltriranje from './feature-filtriranje.png';
import FeaturePretraga from './feature-pretraga.png';
import FeatureRacuni from './feature-racuni.png';
import FeatureTim from './feature-tim.png';
import FeatureSaradnja from './feature-saradnja.png';
import FeatureBezbednost from './feature-bezbednost.png';
import FeatureIstorija from './feature-istorija.png';
import ArrowDownDoubleIcon from './arrow-down-double.svg';

export class ZastoIzabratiZapisnik extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.renderSection4()}
            </div>
        );
    }

    renderSection4() {
        return (
            <div id='section-features' className="container px-4 py-5">
                <div className='mx-3'>
                    <h2 className="display-5 fw-bold lh-1 text-body-emphasis pb-2 border-bottom">Zašto izabrati Zapisnik?</h2>

                    {this.renderGaleria()}

                    <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                        <div className="feature col px-5 text-center">
                            <div className="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
                                <img src={FeatureCalendar} className="feature-icon-image" />
                            </div>
                            <p className="lead mb-2">
                                Prikaz svih događaja u kalendarskom formatu - dnevnom, nedeljnom, mesečnom
                            </p>
                        </div>

                        <div className="feature col px-5 text-center">
                            <div className="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
                                <img src={FeatureDokumenta} className="feature-icon-image" />
                            </div>
                            <p className="lead mb-2">
                                Brzo i lako dodavanje dokumenata, slika ili fajlova
                            </p>
                        </div>

                        <div className="feature col px-5 text-center">
                            <div className="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
                                <img src={FeaturePretraga} className="feature-icon-image" />
                            </div>
                            <p className="lead mb-2">
                                Brza pretraga klijenata i predmeta kako bi što pre našao ono što ti treba!
                            </p>
                        </div>
                    </div>

                    <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">

                        <div className="feature col px-5 text-center">
                            <div className="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
                                <img src={FeatureFiltriranje} className="feature-icon-image" />
                            </div>
                            <p className="lead mb-2">
                                Uz opciju filtriranja lako dođeš do željenog klijenta, predmeta ili ročišta
                            </p>
                        </div>

                        <div className="feature col px-5 text-center">
                            <div className="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
                                <img src={FeatureTim} className="feature-icon-image" />
                            </div>
                            <p className="lead mb-2">
                                Ceo tim radi zajedno na predmetu? Nema problema, delite fajlove i zapise sa ročišta!
                            </p>
                        </div>

                        <div className="feature col px-5 text-center">
                            <div className="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
                                <img src={FeatureSaradnja} className="feature-icon-image" />
                            </div>
                            <p className="lead mb-2">
                                Mogućnost rada na zajedničkim predmetima sa drugim advokatskim kancelarijama
                            </p>
                        </div>

                    </div>

                    <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                        <div className="feature col px-5 text-center">
                            <div className="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
                                <img src={FeatureBezbednost} className="feature-icon-image" />
                            </div>
                            <p className="lead mb-2">
                                Podaci su sigurni - deliš samo one podatke koje ti odobriš
                            </p>
                        </div>

                        <div className="feature col px-5 text-center">
                            <div className="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
                                <img src={FeatureIstorija} className="feature-icon-image" />
                            </div>
                            <p className="lead mb-2">
                                Mogućnost pregleda istorije kako bi imao uvid u detalje o poslednjim izmenama podataka
                            </p>
                        </div>

                        <div className="feature col px-5 text-center">
                            <div className="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
                                <img src={FeatureRacuni} className="feature-icon-image" />
                            </div>
                            <p className="lead mb-2">
                                Opcija računi ti omogućava da vodiš evidenciju svih računa i plaćanja
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderGaleria() {
        let carouselItems = this.getItems();
        let page = Math.floor(Math.random() * 13);

        return (
            <div className='card home-carousel mt-4 mb-5'>
                
                <Galleria value={carouselItems} numVisible={5} circular className='story-carousel'
                    showItemNavigators showItemNavigatorsOnHover showIndicators
                    showThumbnails={false} item={this.galeriaTemplate}
                    activeIndex={page} />
            </div>
        );
    }

    galeriaTemplate(item) {
        let itemHeader = <div className='text-center'><div>Da li Vam se nekad desilo da</div><div>{item.title}</div><div><img alt="" src={ArrowDownDoubleIcon} /></div></div>;

        return (
            <div className='home-accordion accordion-zasto-izabrati mt-4'>
                <Accordion>
                    <AccordionTab header={itemHeader} className='lead'>
                        <div className="border-1 surface-border border-round lead custom-carousel-item-container">
                            <div className='custom-carousel-item'>
                                <div className="mb-4 carousel-content">
                                    {item.problem}
                                </div>
                                <div className='carousel-content'>
                                    {item.solution}
                                </div>
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>
        );
    }

    renderCarousel() {
        let carouselItems = this.getItems();
        let page = Math.floor(Math.random() * 13);

        return (
            <div className='card home-carousel mt-5 mb-5'>
                <div className='text-center mt-5'>
                    <h2>
                        Da li Vam se nekad desilo da
                    </h2>
                </div>
                <Carousel value={carouselItems}
                    className='carousel-main'
                    numVisible={1} numScroll={1}
                    itemTemplate={this.itemTemplate}
                    page={page}
                    circular={true} autoplayInterval={1000} />
            </div>
        );
    }

    itemTemplate(item) {
        return (
            <div>
                <div className='text-center mb-3'>
                    <h2>
                        {item.title}
                    </h2>
                </div>

                <div className="border-1 surface-border border-round m-2 pt-3 pb-2 px-3 lead custom-carousel-item-container">
                    <div className='custom-carousel-item'>
                        <div className="mb-4 carousel-content">
                            {item.problem}
                        </div>
                        <div className='carousel-content'>
                            {item.solution}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    getItems() {
        let items = [
            {
                'title': 'propustite ročište?',
                'problem': 'Klijent Vas je angažovao za predmet koji je bio klasičan primer čistog dobitnog slučaja velike vrednosti. Zajedno sa klijentom ste pokrenuli tužbu i ročište je zakazano za nekoliko meseci. Pošto je jasno bilo da ćete slučaj dobiti, niste ulagali previše vremena za njegovu pripremu, i fokusirali ste se na druge predmete. Medjutim, narednih meseci ste preuzeli previše drugih predmeta koji su zauzeli mesto u Vašem rokovniku, i Vi ste zaboravili da odete na ročište, a pošto zastupate tužioca, tužba se smatra povučenom. Samim tim ste zakomplikovali i moguće izgubili Vaš siguran slučaj.',
                'solution': 'Da ste imali "Zapisnik", on bi Vas podsetio kada je ročište, i Vi ga ne biste propustili, ne biste izgubili siguran predmete velike vrednosti.'
            },
            {
                'title': 'pretražujete ceo arhiv zbog male ali važne informacije?',
                'problem': 'Imate veliki broj slučajeva koji su trenutno aktivni. U gužvi ste svakog dana i jedva postižete da se pripremite za sve Vaše predmete. U tom trenutku ulazi osoba na vrata Vaše kancelarije, koja Vam je poznata ali ne možete da se setite odakle. On se predstavlja kao Vaš stari klijent, kojem ste vodili predmet pre 2 godine. On je doneo nalog od izvršitelja po Vašem zahtevu za naplatu, iako on tvrdi da je sve obaveze već platio, a Vi se ničega od toga ne sećate. Ono što Vi morate u tom trenutku je da odete do Vaše arhive, kopate po starim predmetima kako bi našli predmet tog klijenta, podsetite se predmeta i njegovog toka, potražite sva dokumenta i račune i tek onda bi mogli da date neki odgovori Vašem starom klijentu. I zbog obaveza koje imate oko aktivnih predmeta, sve to može da traje i više dana.',
                'solution': 'Da ste imali "Zapisnik", Vi biste pomoću Vašeg kompjutera ili telefona našli o kom predmetu je reč, pregledali tok predmeta, dokumenta i račune, i dali odgovor Vašem klijentu. I sve to za manje od jednog minuta.'
            },
            {
                'title': 'propustite priliku?',
                'problem': 'U gradu ste sa poslovnim partnerima. Razgovarate o nekim predmetima i pravite planove za sledeće obaveze. Sledeće nedelje morate da održite jako važan sastanak, odluka o terminu mora da se donese što pre, ali Vi ne možete da se setite svih Vaših ročišta i drugih obaveza za sledeću nedelju. Rokovnik Vam je u kancelariji, a trenutno nikog nema tamo. Prilika za zakazivanje novog sastanka je propala i odlaže se, što još više produžuje Vaše poslovne obaveze.',
                'solution': 'Da ste imali "Zapisnik", na Vašem mobilnom telefonu odmah biste videli koji termini su Vam slobodni sledeće nedelje, i sastanak bi bio zakazan istog trenutka.'
            },
            {
                'title': 'propustite rok?',
                'problem': 'Radite na važnom predmetu. Sledeće ročište je zakazano za mesec dana. Potrebno je angažovati veštaka radi veštačenja koje je potrebno predati sudu dve nedelje pre ročišta, pri čemu je od velikog značaja da to veštačenje bude predmet razgovora na tom ročištu, jer bi na sledećem već bilo kasno. Medjutim, zbog svih Vaših obaveza, Vi niste imali vremena da odete do veštaka ove nedelje, a sledeće ste već zaboravili na to. Kada ste se setili da veštačenje treba da bude uradjeno, već je bilo kasno jer je ročište za dva dana. Propustili ste Vaš rok za predaju veštačenja i bitan faktor da ono bude spremno za ročište.',
                'solution': 'Da ste imali "Zapisnik", on bi Vas podsetio da imate rok do kada veštačenje treba da bude spremno, i podsetnik bi stajao sve dok Vi sami ne pripremite veštačenje i markirate rok kao ispunjen.'
            },
            {
                'title': 'ostavite loš utisak i izgubite klijenta?',
                'problem': 'Imate važnog klijenta kod kog želite da ostavite dobar utisak ozbiljnog advokata, jer on Vam može doneti još drugih klijenata i predmeta. Jednog dana ste službeno van grada, i klijent Vas zove, pita Vas za izvesne informacije o njegovom predmetu i kada je njegovo sledeće ročište. Vi nemate njegov predmet sa Vama, moguće je da nemate ni rokovnik, i kažete mu da ćete ga sutra pozvati i dati mu sve tražene informacije. Medjutim, sutra je još jedan ispunjen dan, gužva i moguće još jedno odsustvo iz grada. Zaboravili ste da pozovete klijenta, i on Vas opet zove. Vi opet niste u kancelariji, i opet ne možete da mu date tražene informacije. Ostavili ste utisak neozbiljnog advokata.',
                'solution': 'Da ste imali "Zapisnik", Vi biste na Vašem mobilnom telefonu našli njegov predmet, sva njegova dokumenta, ročišta, i dali bi mu sve tražene informacije istog trenutka.'
            },
            {
                'title': 'budete okupirani poslom dok ste na odmoru?',
                'problem': 'Radili ste naporno cele godine i odlučili ste da odete na letovanje. Tamo ste deset dana sa namerom da sa porodicom provedete lepe dane i ne razmišljate o svojim predmetima i obavezama. Medjutim, u glavi Vam je konstantan osećaj da ste nešto zaboravili u jednom predmetu, za koji imate ročište čim se vratite sa odmora. Medjutim, Vi ne nosite sve Vaše predmete sa sobom kada idete na letovanje, pa ne možete nikako da proverite taj problem koji je u Vašim mislima. I tako tokom celog letovanja.',
                'solution': 'Da ste imali "Zapisnik", sve informacije o svim predmetima bili bi Vam na dohvat ruke u Vašem mobilnom telefonu. Eventualne zaboravljene stvari bi odmah proverili istog trenutka, i ostatak Vašeg letovanja bi proveli u pravom odmoru.'
            },
            {
                'title': 'imate potrebu da odmah skenirate dokumenta?',
                'problem': 'Radite na predmetu i sa klijentom ste otišli na sastanak i pregovore sa protivničkom stranom. Pokazuju Vam izvesna dokumenta, i Vi tražite kopiju ili skeniranu verziju dokumenata. Medjutim, oni Vam daju to samo na uvid, a kopije ni skenove Vam nisu pripremili. Velika je verovatnoća da nećete biti u prilici da dobijete kopiju dokumenata.',
                'solution': 'Da ste imali "Zapisnik", Vi bi predmet otvorili sa Vašeg mobilnog telefona, i kamerom Vašeg telefona brzo i jednostavno uslikali i skenirali željena dokumenta i odmah ih smestili u odgovarajući predmet.'
            },
            {
                'title': 'saradjujete sa kolegom na istom predmetu?',
                'problem': 'Radite sa kolegom na istom predmetu. Kolega nije u istoj kancelariji gde i Vi, štaviše, njegova kancelarija se nalazi na drugom kraju grada. Dokumenta kao i ostale informacije o predmetu fizički mogu da budu samo na jednom mestu. Saradnja i razmena informacija je veoma složena.',
                'solution': 'Da ste imali "Zapisnik", Vi biste pristup predmetu dali Vašem kolegi i sve promene koje Vi unesete bile bi vidljive Vašem kolegi istog trenutka. Saradnja bi bila veoma laka i jednostavna, a uspeh bi bio zagarantovan.'
            },
            {
                'title': 'radite na masovnom broju predmeta u timu?',
                'problem': 'Dobili ste priliku da zajedno sa još dvojicom kolega radite na velikom broju istih predmeta - popularni "masovni predmeti". U pitanju je 150 predmeta u 3 različita grada. Sa Vašim kolegama morate organizovati ko radi na kom predmetu i ko ide na koje ročište. Medjutim, 150 predmeta i ročišta je veliki broj, nastala je pometnja i ročišta za jedan ceo dan su propuštena. Propustili ste priliku, a uz to ste pokvarili odnos sa Vašim kolegama.',
                'solution': 'Da ste imali "Zapisnik", Vi i Vaše kolege biste imali pristup svim predmetima na kojima zajedno radite. Za svaki predmet znao bi se advokat nosilac predmeta, a za svako ročište znalo bi se odgovorno lice. Odgovornosti bi bile jasno podeljene, ročišta ne bi bila propuštena, a odnos sa kolegama nikad bolji.'
            },
            {
                'title': 'Vam je potrebna zamena za ročište?',
                'problem': 'Radite na predmetu i imate zakazano ročište za sutra. Medjutim, kasno popodne saznajete da imate jako važne i neodložne privatne obaveze za sutra i nećete moći da odete na ročište. Morate pripremiti zamenu, ali Vi niste u kancelariji, kao ni kolega koji bi Vas menjao i nemoguće je da mu Vi date sve informacije o predmetu do ročišta na kom bi on išao da Vas menja.',
                'solution': 'Da ste imali "Zapisnik", istog trenutka biste dali pristup predmetu Vašem kolegi sa Vašeg mobilnog telefona. Kolega bi mogao da se upozna sa svim informacijama, dokumentima, i otišao bi spreman na sutrašnje ročište.'
            },
            {
                'title': 'budete u prilici da umalo izgubite sve predmete i ceo arhiv?',
                'problem': 'Vaša kancelarija se nalazi u starom tržnom centru. Sve Vaše predmete, kao i rokovnik, čuvate u kancelariji. Zbog starih instalacija, prethodne noći je došlo do požara u tržnom centru, pri čemu je požar zahvatio pola zgrade. Na sreću, u zgradi nije bilo nikog i nema povredjenih. A na još veću sreću, Vaša kancelarija se nalazi u polovini koju požar nije zahvatio i svi Vaši predmeti su ostali netaktnuti. Ali šta bi bilo da niste te sreće?!',
                'solution': 'Da imate "Zapisnik", ukoliko bi se i desio najgori scenario i Vi izgubite sve Vaše predmete, ne biste morali da brinete koliko brzo bi se oporavili od te nesreće. Sve informacije o Vašim predmetima bi bile smeštene u "Zapisnik", a Vi ne biste propustili ni ročišta od sledećeg dana.'
            },
            {
                'title': 'provedete puno vremena praveći troškovnik?',
                'problem': 'Radili ste na predmetu koji se uspešno završio. Došlo je vreme da naplatite svoje usluge, kao i sva uložena sredstva - troškove veštačenja, konsultacija, administrativne troškove... Priprema svih informacija i pravljenje svih računa može trajati i ceo dan.',
                'solution': 'Da imate "Zapisnik", sve informacije o broju ročišta i uloženih sredstava imali biste u okviru računa predmeta i svakog trenutka biste znali koliki su Vaši troškovi za taj predmet.'
            },
            {
                'title': 'pravite izveštaj za poresku upravu?',
                'problem': 'Došao je kraj godine i Vi morate da podnesete izveštaj poreskoj upravi. Po Vašim proračunima, Vi niste prešli granicu za plaćanje dodatnog poreza. Medjutim, poreski inspektor se ne slaže sa tim i on traži od Vas da pripremite izveštaje i dokaze o svim Vašim prihodima. To znači da morate da prodjete kroz sve Vaše predmete, proverite troškove za svaki predmet, proverite šta je od troškova uplaćeno i kada i uskladite sve te podatke sa izvodima Vašeg bankovnog računa. Pored toga što ovo uzima ogromnu količinu energije, može da traje i par nedelja, a da opet ne budete sigurni da li ste pregledali sve podatke.',
                'solution': 'Da imate "Zapisnik", Vi biste u svakom trenutku imali uvid u Vaše troškove, znali biste da li ste blizu granice za dodatni porez ili ne, a priprema i štampa ovakvog izveštaja trajala bi par minuta. Uštedeli biste ogromno vreme i energiju i ne bi imali rizik da budete kažnjeni od strane poreskih inspektora.'
            }
        ];

        return items;
    }
}
