import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import FileAddWhiteIcon from '../icons/file-add-white.svg';
import { AdvokatKancelarijeEdit } from './AdvokatKancelarijeEdit';

export class AdvokatiKancelarije extends Component {
    constructor(props) {
        super(props);
        this.state = {
            advokati: [],
            loading: true,
            editMode: false,
            editingItem: {}
        };

        this.rowSelected = this.rowSelected.bind(this);
        this.newItemTriggered = this.newItemTriggered.bind(this);
        this.onAdvokatEditSaveClick = this.onAdvokatEditSaveClick.bind(this);
        this.onAdvokatEditCancelClick = this.onAdvokatEditCancelClick.bind(this);
    }

    async componentDidMount() {
        let advokati = this.props.advokati ?? await this.props.onAdvokatiMount();

        this.setState({
            advokati: advokati,
            loading: false
        });
    }

    renderAdvokati(advokati) {
        const footer = `Ukupno stavki: ${advokati ? advokati.length : 0}.`;

        return (
            <div>
                <div className="form-header">
                    <button className="btn btn-primary btns" onClick={this.newItemTriggered}>
                        <img alt="" src={FileAddWhiteIcon} />
                        &nbsp;
                        Advokat
                    </button>
                </div>

                <DataTable value={advokati}
                    dataKey="id"
                    sortField="ime" sortOrder={1}
                    tableStyle={{ minWidth: '50rem' }} className="custom-datatable"
                    emptyMessage="Nema unetih podataka."
                    footer={footer}
                    selectionMode="single" onSelectionChange={(e) => this.rowSelected(e.value)} 
                >
                    <Column field="ime" header="Ime" sortable style={{ width: '15%' }}></Column>
                    <Column field="prezime" header="Prezime" sortable style={{ width: '15%' }}></Column>
                    <Column field="email" header="Email" sortable style={{ width: '15%' }}></Column>
                    <Column field="isAdmin" body={this.isAdminTemplate} sortable header="Administrator" style={{ width: '10%' }}></Column>
                    <Column field="kancelarijaTimNazivi" header="Timovi" style={{ width: '45%' }}></Column>
                </DataTable>
            </div>
        );
    }

    isAdminTemplate = (advokat) => {
        return advokat.isAdmin === true ? 'Da' : 'Ne';
    };

    rowSelected(item) {
        this.setState({
            editingItem: item,
            editMode: true
        });
    }

    newItemTriggered() {
        this.setState({
            editingItem: {},
            editMode: true
        });
    }

    onAdvokatEditSaveClick() {
        this.setState({
            loading: true,
            editMode: false
        }, async () => {
            let kancelarija = await this.props.onAdvokatiMount();
            this.setState({
                advokati: kancelarija.advokati,
                loading: false
            });
        });
    }

    onAdvokatEditCancelClick() {
        this.setState({
            editMode: false
        });
    }

    render() {
        let displayDataContent = this.state.editMode
            ? <AdvokatKancelarijeEdit advokat={this.state.editingItem} kancelarija={this.props.kancelarija}
                onSaveClick={this.onAdvokatEditSaveClick} onCancelClick={this.onAdvokatEditCancelClick} />
            : this.renderAdvokati(this.state.advokati);

        let contents =
            this.state.loading
                ? <p><em>Učitavanje...</em></p>
                : displayDataContent;

        return (
            <div>
                {contents}
            </div>
        );
    }
}
