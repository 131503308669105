import React, { Component } from 'react';
import './home.css';

import { Galleria } from 'primereact/galleria';

import ScreenshotPocetnaStrana from './Screenshot_PocetnaStrana.png';

import ScreenshotPredmeti1 from './Screenshot_Predmeti1.png';
import ScreenshotPredmeti2 from './Screenshot_Predmeti2.png';
import ScreenshotPredmeti3 from './Screenshot_Predmeti3.png';

import ScreenshotKalendar1 from './Screenshot_Kalendar1.png';
import ScreenshotKalendar2 from './Screenshot_Kalendar2.png';
import ScreenshotKalendar3 from './Screenshot_Kalendar3.png';


export class StaJeZapisnikScreenshots extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imagesPocetna: [ScreenshotPredmeti1],
            imagesPredmeti: [ScreenshotPredmeti1, ScreenshotPredmeti2, ScreenshotPredmeti3],
            imagesKalendar: [ScreenshotKalendar1, ScreenshotKalendar2, ScreenshotKalendar3]
        }
    }

    render() {
        return (
            <div>
                {this.renderSection3_1()}
            </div>
        );
    }

    renderSection3_1() {
        return (
            <div className="container px-1">

                <div className="row flex-lg-row align-items-center mx-1 mb-4">
                    {this.homePageText(false)}

                    <div className="col-lg-7">
                        <div className="row align-items-center">
                            <Galleria value={this.state.imagesPocetna} numVisible={5} circular
                                showIndicators
                                showThumbnails={false} item={this.imageTemplate} />
                        </div>
                    </div>

                    {this.homePageText(true)}
                </div>



                <div className="row flex-lg-row align-items-center mx-1 mb-4">
                    <div className="col-lg-7">
                        <div className="row align-items-center">
                            <Galleria value={this.state.imagesPredmeti} numVisible={5} circular
                                showItemNavigators showItemNavigatorsOnHover showIndicators
                                showThumbnails={false} item={this.imageTemplate} />
                        </div>
                    </div>
                    <div className="col-lg-5 px-5 feature-text-container">
                        <ul className='checked-circle-list lead'>
                            <li className='feature-list-item'>
                                Pregled predmeta, klijenata i protivnika
                            </li>
                            <li className='feature-list-item'>
                                Pretraga podataka uz pomoć filtera
                            </li>
                            <li className='feature-list-item'>
                                Jednostavno ubacivanje novih podataka klikom na dugme
                            </li>
                        </ul>

                        <div className="pt-3 text-center" >
                            <a href="/Identity/Account/Login?isDemoView=1&ReturnUrl=/dashboard" className="btn btn-primary btn-lg px-4 me-md-2 home-screen-button">Isprobaj demo</a>
                        </div>
                    </div>
                </div>


                <div className="row flex-lg-row align-items-center mx-1 mb-4">
                    {this.kalendarText(false)}

                    <div className="col-lg-7">
                        <div className="row align-items-center">
                            <Galleria value={this.state.imagesKalendar} numVisible={5} circular
                                showItemNavigators showItemNavigatorsOnHover showIndicators
                                showThumbnails={false} item={this.imageTemplate} />
                        </div>
                    </div>

                    {this.kalendarText(true)}
                </div>
            </div>
        );
    }

    imageTemplate = (image) => {
        return <img src={image} className="d-block mx-lg-auto img-fluid feature-img" loading="lazy" />;
    };

    homePageText(isMobile) {
        let containerClassName = isMobile ? 'show-on-mobile' : 'hide-on-mobile';

        return (
            <div className={`col-lg-5 px-5 feature-text-container ${containerClassName}`}>
                <ul className='checked-circle-list lead'>
                    <li className='feature-list-item'>
                        Brza pretraga klijenata ili predmeta
                    </li>
                    <li className='feature-list-item'>
                        Pregled predstojećih događaja
                    </li>
                    <li className='feature-list-item'>
                        Pristup najvažnijim podacima iz menija
                    </li>
                </ul>

                <div className="pt-3 text-center" >
                    <a href="/Identity/Account/Login?isDemoView=1&ReturnUrl=/dashboard" className="btn btn-primary btn-lg px-4 me-md-2 home-screen-button">Isprobaj demo</a>
                </div>
            </div>);
    }

    kalendarText(isMobile) {
        let containerClassName = isMobile ? 'show-on-mobile' : 'hide-on-mobile';

        return (
            <div className={`col-lg-5 px-5 feature-text-container ${containerClassName}`}>
                <ul className='checked-circle-list lead'>
                    <li className='feature-list-item'>
                        Pregled svih ročišta, rokova i termina
                    </li>
                    <li className='feature-list-item'>
                        Prikaz kalendara na više načina
                    </li>
                    <li className='feature-list-item'>
                        Mogućnost prikaza po satu, danu, za više dana ili za ceo mesec
                    </li>
                </ul>

                <div className="pt-3 text-center" >
                    <a href="/Identity/Account/Login?isDemoView=1&ReturnUrl=/dashboard" className="btn btn-primary btn-lg px-4 me-md-2 home-screen-button">Isprobaj demo</a>
                </div>
            </div>);
    }
}
