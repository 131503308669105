import React, { Component } from 'react';
import fetchService from '../FetchService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { HelpTooltip } from '../HelpTooltip';
import { DateTimeService } from '../DateTimeService';

export class AktivnaRocistaPredmeta extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rocista: [],
            tableLoading: true,
            lazyState: {
                first: 0,
                rows: 10,
                page: 0
            },
            totalRecords: 0
        };
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return (
            <div>
                <div className="p-3">
                    <div className="mx-3 mb-3 border-bottom border-danger">
                        <h3>
                            <b>Aktivna ročišta</b>
                            <HelpTooltip>
                                <p className='mb-3'>
                                    Ročišta predmeta koja su aktivna i kojima je prošao termin.
                                </p>
                                <p>
                                    Uobičajeno je da nakon svakog izvršenog ročišta treba uraditi određene korake - zakazati novo ročište, postaviti novi rok, skenirati zapisnik, zatvoriti predmet...
                                </p>
                                <p>
                                    Predlog je da ročište predmeta bude aktivno sve dok se ti koraci ne izvrše i upišu u aplikaciju.
                                </p>
                                <p>
                                    Ukoliko je ročište aktivno, a termin ročišta je prošao, ročište će biti prikazano u listi aktivnih ročišta na početnoj strani.
                                </p>
                                <p>
                                    Ta lista je ujedno i podsetnik korisniku aplikacije da mora da unese nedostajuće podatke.
                                </p>
                                <p>
                                    Ukoliko je korisnik obavio sve željene korake i uneo podatke, on može da postavi ročište predmeta kao neaktivno. Time će se ročište automatski ukloniti sa liste na početnom ekranu.
                                </p>
                            </HelpTooltip>
                        </h3>
                    </div>

                    {this.renderRocista()}
                </div>
            </div>
        );
    }

    renderRocista() {
        return (
            <div className='dashboard-aktivna-rocista'>
                <DataTable value={this.state.rocista}
                    dataKey="id"
                    lazy totalRecords={this.state.totalRecords}
                    first={this.state.lazyState.first}
                    onPage={this.onPage}
                    paginator rows={10}
                    tableStyle={{ minWidth: '20rem' }} className="custom-datatable"
                    loading={this.state.tableLoading}
                    emptyMessage="Nema unetih podataka."
                    selectionMode="single" onSelectionChange={(e) => window.location = '/predmet?id=' + e.value.predmetId}
                >
                    <Column field="klijentImeIPrezime" style={{ width: '25%' }}></Column>
                    <Column field="brojPredmeta" style={{ width: '25%' }}></Column>
                    <Column field="datum" body={this.datumTemplate} header="Vreme" style={{ width: '25%' }}></Column>
                    <Column field="naziv" header="Naziv" style={{ width: '25%' }}></Column>
                </DataTable>
            </div>
        );
    }

    datumTemplate = (rocistePredmeta) => {
        return DateTimeService.formatDateTimeToString(rocistePredmeta.datum);
    }

    async loadData() {
        this.setState({
            tableLoading: true
        });

        let bodyInput =
        {
            "Rows": this.state.lazyState.rows,
            "Page": this.state.lazyState.page + 1
        };

        const response = await fetchService.post('aktivnaRocistaDashboard', bodyInput);

        this.setState({
            rocista: response.success ? response.responseValue.rocistaPredmeta : [],
            totalRecords: response.success ? response.responseValue.totalCount : 0
        });

        this.setState({
            tableLoading: false
        });
    }

    onPage = (event) => {
        this.setState(
            {
                lazyState: {
                    first: event.first,
                    rows: event.rows,
                    page: event.page
                }
            },
            () => this.loadData()
        );
    }
}
