import React, { Component } from 'react';
import fetchService from '../FetchService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Sidebar } from 'primereact/sidebar';
import { Calendar } from 'primereact/calendar';
import { Badge } from 'primereact/badge';
import AddLineWhiteIcon from '../icons/add-line-white.svg';
import Download2FillIcon from '../icons/download-2-fill.svg';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import EqualizerLineIcon from '../icons/equalizer-line.svg';

export class Predmeti extends Component {
    constructor(props) {
        super(props);
        this.state = {
            predmeti: [],
            loading: true,
            tableLoading: true,
            lazyState: {
                first: 0,
                rows: 10,
                page: 0,
                sortField: 'redniBroj',
                sortOrder: -1,
                filter: ''
            },
            typingTimeout: 0,
            totalRecords: 0,
            tipoviPredmeta: [],
            nosiociPredmeta: [],
            statusiPredmeta: [],
            tipoviSuda: [],
            advancedFilterValues: {
                selectedTipoviPredmeta: [],
                selectedNosiociPredmeta: [],
                selectedStatusiPredmeta: [],
                klijent: '',
                selectedTipoviSuda: [],
                mesto: '',
                upisnik: '',
                brojPredmeta: '',
                godina: null,
                opis: '',
                sudija: '',
                sudnica: ''
            },
            lastSavedAdvancedFilterValues: {},
            advancedFilterOpen: false,
            advancedFilterBadgeValue: 0
        };

        this.filterReference = React.createRef();

        this.confirmDownloadIzvozPredmeta = this.confirmDownloadIzvozPredmeta.bind(this);
        this.downloadIzvozPredmeta = this.downloadIzvozPredmeta.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    renderPredmeti(predmeti) {
        const footer = `Ukupno stavki: ${this.state.totalRecords}.`;

        return (
            <div>
                <DataTable value={predmeti}
                    dataKey="id"
                    lazy totalRecords={this.state.totalRecords}
                    first={this.state.lazyState.first}
                    onPage={this.onPage}
                    onSort={this.onSort} sortField={this.state.lazyState.sortField} sortOrder={this.state.lazyState.sortOrder}
                    header={this.renderHeader}
                    footer={footer}
                    paginator rows={10}
                    tableStyle={{ minWidth: '50rem' }} className="custom-datatable"
                    loading={this.state.tableLoading}
                    emptyMessage="Nema unetih podataka."
                    selectionMode="single" onSelectionChange={(e) => window.location = '/predmet?id=' + e.value.id}
                >
                    <Column field="redniBroj" header="RB" sortable style={{ minWidth: '35px' }}></Column>
                    <Column field="imeKlijenta" header="Klijent" body={this.imePrezimeTemplate} sortable style={{ minWidth: '250px' }}></Column>
                    <Column field="tipPredmeta" header="Tip" sortable style={{ minWidth: '250px' }}></Column>

                    <Column field="sud" header="Sud" style={{ minWidth: '250px' }}></Column>
                    <Column field="mesto" header="Mesto" sortable style={{ minWidth: '150px' }}></Column>

                    <Column field="upisnik" header="Upisnik" sortable style={{ minWidth: '35px' }}></Column>
                    <Column field="brojPredmeta" header="Broj" sortable style={{ minWidth: '150px' }}></Column>
                    <Column field="godina" header="Godina" sortable style={{ minWidth: '35px' }}></Column>

                    <Column field="sudija" header="Sudija" sortable style={{ minWidth: '200px' }}></Column>
                    <Column field="sudnica" header="Sudnica" sortable style={{ minWidth: '50px' }}></Column>

                    <Column field="opis" header="Opis" style={{ minWidth: '300px' }}></Column>
                    <Column field="trenutniStatusPredmeta" header="Status" style={{ minWidth: '100px' }}></Column>
                    <Column field="imePrezimeNosiocaPredmeta" header="Nosilac" style={{ minWidth: '250px' }}></Column>
                </DataTable>
            </div>
        );
    }

    imePrezimeTemplate = (klijent) => {
        return `${klijent.imeKlijenta} ${klijent.prezimeKlijenta}`;
    };

    renderHeader = () => {
        const customIcons = (
            <React.Fragment>
                <h2>Filteri</h2>
                <div>
                    <button className="btn btn-primary btns" onClick={() => { this.populatePredmeti(); this.setState({ advancedFilterOpen: false }); }}>
                        Primeni
                    </button>
                    <button className="btn btn-secondary btns" onClick={() => {
                        this.setState({
                            advancedFilterOpen: false,
                            advancedFilterValues: {
                                selectedTipoviPredmeta: [],
                                selectedStatusiPredmeta: [],
                                selectedNosiociPredmeta: [],
                                klijent: '',
                                selectedTipoviSuda: [],
                                mesto: '',
                                upisnik: '',
                                brojPredmeta: '',
                                godina: null,
                                opis: '',
                                sudija: '',
                                sudnica: ''
                            }
                        }, () => { this.populatePredmeti(); });
                    }}>
                        Očisti
                    </button>
                </div>

            </React.Fragment>
        );

        return (
            <div className="form-header flex justify-content-between">
                <div className='mb-3-for-less-than-400'>
                    <a className="btn btn-primary" href="/predmet">
                        <img alt="" src={AddLineWhiteIcon} />
                        <span className="hide-on-xs">
                            &nbsp;Predmet
                        </span>
                    </a>
                    &nbsp;
                    <button className="btn btn-primary" onClick={this.confirmDownloadIzvozPredmeta}>
                        <img alt="" src={Download2FillIcon} />
                    </button>
                </div>
                <div className="flex justify-content-end">
                    <div className="p-input-icon-left mr-3">
                        <i className="pi pi-search" />
                        <InputText value={this.state.lazyState.filter} placeholder="Traži..." ref={this.filterReference} className='search-table'
                            onChange={this.onFilterChange} onKeyDown={this.onFilterKeyDown} />
                    </div>
                    <div>
                        <button className="btn btn-primary btns p-overlay-badge" onClick={() => { this.setState({ advancedFilterOpen: true }); }}>
                            <img alt="" src={EqualizerLineIcon} />
                            <span className="hide-on-xs">
                                &nbsp;Filteri
                            </span>
                            <Badge value={this.state.advancedFilterBadgeValue} severity="warning" hidden={this.state.advancedFilterBadgeValue == 0} ></Badge>
                        </button>
                    </div>

                    <Sidebar visible={this.state.advancedFilterOpen} position="right" icons={customIcons}
                        className='advanced-filter-container'
                        onHide={() => { this.setState({ advancedFilterOpen: false, advancedFilterValues: this.state.lastSavedAdvancedFilterValues }); }}>

                        <div>
                            <label htmlFor="filterTipPredmetaId" className="form-label">Tip predmeta</label>
                            <MultiSelect id='filterTipPredmetaId'
                                value={this.state.advancedFilterValues.selectedTipoviPredmeta}
                                options={this.state.tipoviPredmeta}
                                optionLabel="naziv"
                                optionValue="id"
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, selectedTipoviPredmeta: e.target.value } })}
                                display="chip"
                                filter
                                showClear
                                className="w-full advanced-filter-item mb-3" />

                            <label htmlFor="filterNosilacPredmetaId" className="form-label">Nosilac predmeta</label>
                            <MultiSelect id='filterNosilacPredmetaId'
                                value={this.state.advancedFilterValues.selectedNosiociPredmeta}
                                options={this.state.nosiociPredmeta}
                                optionLabel="imePrezime"
                                optionValue="id"
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, selectedNosiociPredmeta: e.target.value } })}
                                display="chip"
                                filter
                                showClear
                                className="w-full advanced-filter-item mb-3" />

                            <label htmlFor="filterStatusPredmetaId" className="form-label">Status predmeta</label>
                            <MultiSelect id='filterStatusPredmetaId'
                                value={this.state.advancedFilterValues.selectedStatusiPredmeta}
                                options={this.state.statusiPredmeta}
                                optionLabel="naziv"
                                optionValue="id"
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, selectedStatusiPredmeta: e.target.value } })}
                                display="chip"
                                filter
                                showClear
                                className="w-full advanced-filter-item mb-3" />

                            <label htmlFor="filterKlijent" className="form-label">Klijent</label>
                            <InputText id='filterKlijent'
                                value={this.state.advancedFilterValues.klijent}
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, klijent: e.target.value } })}
                                onKeyDown={this.onAdvancedFilterKeyDown}
                                className='w-full advanced-filter-item mb-3' />

                            <label htmlFor="filterTipSudaId" className="form-label">Tip suda</label>
                            <MultiSelect id='filterTipSudaId'
                                value={this.state.advancedFilterValues.selectedTipoviSuda}
                                options={this.state.tipoviSuda}
                                optionLabel="naziv"
                                optionValue="id"
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, selectedTipoviSuda: e.target.value } })}
                                display="chip"
                                filter
                                showClear
                                className="w-full advanced-filter-item mb-3" />

                            <label htmlFor="filterMesto" className="form-label">Mesto</label>
                            <InputText id='filterMesto'
                                value={this.state.advancedFilterValues.mesto}
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, mesto: e.target.value } })}
                                onKeyDown={this.onAdvancedFilterKeyDown}
                                className='w-full advanced-filter-item mb-3' />

                            <label htmlFor="filterUpisnik" className="form-label">Upisnik</label>
                            <InputText id='filterUpisnik'
                                value={this.state.advancedFilterValues.upisnik}
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, upisnik: e.target.value } })}
                                onKeyDown={this.onAdvancedFilterKeyDown}
                                className='w-full advanced-filter-item mb-3' />

                            <label htmlFor="filterBrojPredmeta" className="form-label">Broj predmeta</label>
                            <InputText id='filterBrojPredmeta'
                                value={this.state.advancedFilterValues.brojPredmeta}
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, brojPredmeta: e.target.value } })}
                                onKeyDown={this.onAdvancedFilterKeyDown}
                                className='w-full advanced-filter-item mb-3' />

                            <label htmlFor="filterGodina" className="form-label">Godina</label>
                            <Calendar id="filterGodina"
                                value={this.state.advancedFilterValues.godina ?? ''}
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, godina: e.target.value } })}
                                className='w-full advanced-filter-item mb-3'
                                view="year" dateFormat="yy" />

                            <label htmlFor="filterSudija" className="form-label">Sudija</label>
                            <InputText id='filterSudija'
                                value={this.state.advancedFilterValues.sudija}
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, sudija: e.target.value } })}
                                onKeyDown={this.onAdvancedFilterKeyDown}
                                className='w-full advanced-filter-item mb-3' />

                            <label htmlFor="filterSudnica" className="form-label">Sudnica</label>
                            <InputText id='filterSudnica'
                                value={this.state.advancedFilterValues.sudnica}
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, sudnica: e.target.value } })}
                                onKeyDown={this.onAdvancedFilterKeyDown}
                                className='w-full advanced-filter-item mb-3' />

                            <label htmlFor="filterOpis" className="form-label">Opis</label>
                            <InputText id='filterOpis'
                                value={this.state.advancedFilterValues.opis}
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, opis: e.target.value } })}
                                onKeyDown={this.onAdvancedFilterKeyDown}
                                className='w-full advanced-filter-item mb-3' />
                        </div>
                    </Sidebar>
                </div>
            </div>
        );
    };

    render() {
        let contents =
            this.state.loading
                ? <p><em>Učitavanje...</em></p>
                : this.renderPredmeti(this.state.predmeti);

        return (
            <div>
                <h2 className='mb-3 page-title' id="tabelLabel">Predmeti</h2>
                {contents}

                <ConfirmDialog />
            </div>
        );
    }

    async loadData() {
        this.setState({
            tableLoading: true
        });

        await this.getTipoviPredmeta();
        await this.getNosiociPredmeta();
        await this.getStatusiPredmeta();
        await this.getTipoviSuda();
        await this.getPredmeti();

        this.setState({
            loading: false,
            tableLoading: false
        });
    }

    async populatePredmeti() {
        this.setState({
            tableLoading: true
        });

        await this.getPredmeti();

        this.setState({
            loading: false,
            tableLoading: false
        });
    }

    getBodyInput() {
        let bodyInput =
        {
            "Rows": this.state.lazyState.rows,
            "Page": this.state.lazyState.page + 1,
            "SortField": this.state.lazyState.sortField,
            "SortOrder": this.state.lazyState.sortOrder,
            "Filter": this.state.lazyState.filter,
            "AdditionalFilters": {
                "TipoviPredmetaIds": this.state.advancedFilterValues.selectedTipoviPredmeta ?? [],
                "NosiociPredmetaIds": this.state.advancedFilterValues.selectedNosiociPredmeta ?? [],
                "StatusiPredmetaIds": this.state.advancedFilterValues.selectedStatusiPredmeta ?? [],
                "Klijent": this.state.advancedFilterValues.klijent,
                "TipoviSudovaIds": this.state.advancedFilterValues.selectedTipoviSuda ?? [],
                "Mesto": this.state.advancedFilterValues.mesto,
                "Upisnik": this.state.advancedFilterValues.upisnik,
                "BrojPredmeta": this.state.advancedFilterValues.brojPredmeta,
                "Godina": this.state.advancedFilterValues.godina?.getFullYear(),
                "Opis": this.state.advancedFilterValues.opis,
                "Sudija": this.state.advancedFilterValues.sudija,
                "Sudnica": this.state.advancedFilterValues.sudnica
            }
        };

        return bodyInput;
    }

    async getPredmeti() {
        let bodyInput = this.getBodyInput();

        this.state.lastSavedAdvancedFilterValues = this.state.advancedFilterValues;

        let advancedFilterBadgeValue = 0;
        if (this.state.advancedFilterValues.selectedTipoviPredmeta.length > 0) advancedFilterBadgeValue++;
        if (this.state.advancedFilterValues.selectedNosiociPredmeta.length > 0) advancedFilterBadgeValue++;
        if (this.state.advancedFilterValues.selectedStatusiPredmeta.length > 0) advancedFilterBadgeValue++;
        if (this.state.advancedFilterValues.klijent.length > 0) advancedFilterBadgeValue++;
        if (this.state.advancedFilterValues.selectedTipoviSuda.length > 0) advancedFilterBadgeValue++;
        if (this.state.advancedFilterValues.mesto.length > 0) advancedFilterBadgeValue++;
        if (this.state.advancedFilterValues.upisnik.length > 0) advancedFilterBadgeValue++;
        if (this.state.advancedFilterValues.brojPredmeta.length > 0) advancedFilterBadgeValue++;
        if (this.state.advancedFilterValues.godina) advancedFilterBadgeValue++;
        if (this.state.advancedFilterValues.opis.length > 0) advancedFilterBadgeValue++;
        if (this.state.advancedFilterValues.sudija.length > 0) advancedFilterBadgeValue++;
        if (this.state.advancedFilterValues.sudnica.length > 0) advancedFilterBadgeValue++;

        this.state.advancedFilterBadgeValue = advancedFilterBadgeValue;

        let response = await fetchService.post('predmetiAdvokataAll', bodyInput);
        const data = response.success ? response.responseValue : [];

        this.setState({
            predmeti: data.predmeti,
            totalRecords: data.totalCount
        });
    }

    async getTipoviPredmeta() {
        let requestUrl = `tipoviPredmeta?rows=500&page=1&sortField=naziv&sortOrder=1&active=true`;

        const response = await fetchService.get(requestUrl);
        const data = response.success ? response.responseValue : [];

        let emptyTipPredmeta = [{ id: '', naziv: '< bez tipa >' }];

        this.setState({
            tipoviPredmeta: emptyTipPredmeta.concat(data.tipoviPredmeta)
        });
    }

    async getNosiociPredmeta() {
        let requestUrl = `nosiociPredmeta`;

        const response = await fetchService.get(requestUrl);
        const data = response.success ? response.responseValue : [];

        let emptyNosilacPredmeta = [{ id: '', imePrezime: '< bez nosioca >' }];

        this.setState({
            nosiociPredmeta: emptyNosilacPredmeta.concat(data)
        });
    }

    async getStatusiPredmeta() {
        let data = [
            { id: 1, naziv: 'Aktivan' },
            { id: 2, naziv: 'Završen' }
        ];

        this.setState({
            statusiPredmeta: data
        });
    }

    async getTipoviSuda() {
        let data = [
            { id: 1, naziv: 'Neodredjen' },
            { id: 2, naziv: 'Vrhovni kasacioni' },
            { id: 3, naziv: 'Apelacioni' },
            { id: 4, naziv: 'Viši' },
            { id: 5, naziv: 'Osnovni' },
            { id: 6, naziv: 'Upravni' },
            { id: 7, naziv: 'Privredni' },
            { id: 8, naziv: 'Prekršajni' },
            { id: 9, naziv: 'Prekršajni apelacioni' }
        ];

        this.setState({
            tipoviSuda: data
        });

        return data;
    }

    onPage = (event) => {
        this.setState(
            {
                lazyState: {
                    first: event.first,
                    rows: event.rows,
                    page: event.page,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                    filter: this.state.lazyState.filter
                }
            },
            () => this.populatePredmeti()
        );
    }

    onSort = (event) => {
        this.setState(
            {
                lazyState: {
                    first: event.first,
                    rows: event.rows,
                    page: this.state.lazyState.page,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                    filter: this.state.lazyState.filter
                }
            },
            () => this.populatePredmeti()
        );
    }

    onFilterChange = (event) => {
        const value = event.target.value;
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            lazyState: {
                ...this.state.lazyState,
                filter: value
            },
            typingTimeout: setTimeout(function () {
                self.populatePredmeti();
            }, 800)
        });
    }

    onFilterKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (this.state.typingTimeout) {
                clearTimeout(this.state.typingTimeout);
            }

            this.setState({
                typingTimeout: 0
            });

            this.populatePredmeti();
        }
    }

    onAdvancedFilterKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.populatePredmeti();
            this.setState({ advancedFilterOpen: false });
        }
    }

    confirmDownloadIzvozPredmeta(event) {
        confirmDialog({
            trigger: event.currentTarget,
            message: 'Da li želite da preuzmete spisak predmeta i sačuvate ga na disk?',
            header: 'Preuzimanje spiska predmeta',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-info',
            accept: () => { this.downloadIzvozPredmeta() },
            acceptLabel: 'Da',
            rejectLabel: 'Ne'
        });
    };

    async downloadIzvozPredmeta() {
        let bodyInput = this.getBodyInput();

        const dataBlob = await fetchService.getBlob('predmetiAdvokataExport', bodyInput, 'POST');
        this.pushBlobToUser(dataBlob.responseValue, "predmeti.csv");
    }

    pushBlobToUser(dataBlob, fileName) {
        var objectURL = URL.createObjectURL(dataBlob);
        const link = document.createElement('a');
        link.href = objectURL;
        link.download = fileName;
        link.click();
    }
}
