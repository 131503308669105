import React, { Component } from 'react';
import fetchService from '../FetchService';
import { TabView, TabPanel } from 'primereact/tabview';
import { ProtivnikOsnovniPodaciContainer } from './ProtivnikOsnovniPodaciContainer';
import { ProtivnikPredmeti } from './ProtivnikPredmeti';
    
export class ProtivnikContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            protivnikId: null,
            protivnik: {
                isPravnoLice: false,
                kancelarijaTimInfos: []
            },
            predmeti: null
        };
    }

    componentDidMount() {
        const id = (new URLSearchParams(document.location.search)).get('id');
        this.setState(
            {
                protivnikId: id ?? ""
            },
            () => this.loadDataFromApi()
                .then(() => { this.setState({ loading: false }); })
        );
    }

    async loadDataFromApi() {
        if (!this.state.protivnikId) return;

        let requestUrl = "protivniciApi/" + this.state.protivnikId;
        const response = await fetchService.get(requestUrl);

        this.setState({
            protivnik: response.success ? response.responseValue : {}
        });
    }

    async loadPredmeti() {
        let protivnikId = this.state.protivnikId;

        let requestUrl = `predmetiProtivnika?protivnikId=${protivnikId}`;
        const response = await fetchService.get(requestUrl);
        let data = response.success ? response.responseValue : [];

        this.setState({
            predmeti: data
        });

        return data;
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : <ProtivnikOsnovniPodaciContainer protivnik={this.state.protivnik} onProtivnikUpdate={(protivnik) => this.setState({ protivnik: protivnik })} />;

        let predmetiProtivnika = this.state.loading || !this.state.protivnikId ? '' :
            <TabPanel header="Predmeti">
                <ProtivnikPredmeti protivnikId={this.state.protivnikId} predmeti={this.state.predmeti} onPredmetiMount={() => this.loadPredmeti()} />
            </TabPanel>;

        let title = this.state.protivnikId
            ? `Protivnik ${this.state.protivnik.ime} ${this.state.protivnik.prezime}`
            : 'Novi protivnik';

        return (
            <div>
                <h2 id="tabelLabel" className='mb-3 page-title'>{title}</h2>

                <TabView>
                    <TabPanel header="Osnovni podaci">
                        {contents}
                    </TabPanel>
                    {predmetiProtivnika}
                </TabView>
            </div>
        );
    }
}
