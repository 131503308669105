import React, { Component } from 'react';
import fetchService from '../FetchService';
import { TabView, TabPanel } from 'primereact/tabview';
import { OsnovniPodaciContainer } from './OsnovniPodaciContainer';
import { ZapisiPredmeta } from './ZapisiPredmeta';
import { RocistaPredmeta } from './RocistaPredmeta';
import { RokoviPredmeta } from './RokoviPredmeta';
import { DokumentaPredmeta } from './DokumentaPredmeta';
import { RacuniPredmeta } from './RacuniPredmeta';
import { IstorijaPredmeta } from './IstorijaPredmeta';
import { ValutaService } from '../ValutaService';

export class PredmetContainer extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();

        this.state = {
            loading: true,
            predmetId: null,
            predmet: {
                kancelarijaTimInfos: []
            },
            klijentAdvokataId: null,
            imePrezimeKlijenta: null,
            zapisi: null,
            rocista: null,
            rokovi: null,
            dokumenta: null,
            tipoviPredmeta: null,
            statusiPredmeta: null,
            tipoviSuda: null,
            racuni: null,
            tipoviRacuna: null,
            valute: null,
            protivnikId: null,
            nosiociPredmeta: null
        };

        this.onPredmetUpdate = this.onPredmetUpdate.bind(this);
    }

    componentDidMount() {
        const id = (new URLSearchParams(document.location.search)).get('id');
        const klijentAdvokataId = (new URLSearchParams(document.location.search)).get('klijentAdvokataId');
        const kopijaPredmetaId = (new URLSearchParams(document.location.search)).get('kopijaPredmetaId');
        this.setState(
            {
                predmetId: id ?? null,
                klijentAdvokataId: klijentAdvokataId ?? null,
                kopijaPredmetaId: kopijaPredmetaId ?? null
            },
            () => this.loadDataFromApi().then(() => { this.setState({ loading: false }); })
        );
    }

    async loadDataFromApi() {
        if (this.state.predmetId) {
            let requestUrl = `predmetiAdvokata?predmetId=${this.state.predmetId}`;
            const response = await fetchService.get(requestUrl);

            const data = response.success ? response.responseValue : {};

            data.brojPredmetaFull = data.brojPredmeta;
            if (data.godina) {
                data.brojPredmetaFull = data.brojPredmetaFull + '/' + data.godina;
            }

            this.setState({
                predmet: data,
                imePrezimeKlijenta: `${data.imeKlijenta} ${data.prezimeKlijenta}`
            });

            return;
        }

        if (this.state.kopijaPredmetaId) {
            let requestUrl = `predmetiAdvokata?predmetId=${this.state.kopijaPredmetaId}`;
            const response = await fetchService.get(requestUrl);

            const data = response.success ? response.responseValue : {};

            delete data.id;
            data.kopijaPredmetaId = this.state.kopijaPredmetaId;

            this.setState({
                predmet: data
            });

            return;
        }

        if (this.state.klijentAdvokataId) {
            let requestUrl = `klijentiAdvokata/${this.state.klijentAdvokataId}`;
            const response = await fetchService.get(requestUrl);
            this.setState({
                imePrezimeKlijenta: response.success ? `${response.responseValue.ime} ${response.responseValue.prezime}` : ''
            });

            return;
        }
    }

    async loadZapisi() {
        let predmetId = this.state.predmet.id;
        let requestUrl = `zapisPredmetaAll?predmetId=${predmetId}`;

        const response = await fetchService.get(requestUrl);
        const data = response.success ? response.responseValue : [];

        this.setState({
            zapisi: data
        });

        return data;
    }

    async loadRocista() {
        let predmetId = this.state.predmet.id;
        let requestUrl = `rocistePredmetaAll?predmetId=${predmetId}`;

        const response = await fetchService.get(requestUrl);
        const data = response.success ? response.responseValue : [];

        this.setState({
            rocista: data
        });

        return data;
    }

    async loadRokovi() {
        let predmetId = this.state.predmet.id;
        let requestUrl = `rokPredmetaAll?predmetId=${predmetId}`;

        const response = await fetchService.get(requestUrl);
        const data = response.success ? response.responseValue : [];

        this.setState({
            rokovi: data
        });

        return data;
    }

    async loadDokumenta() {
        let predmetId = this.state.predmet.id;
        let requestUrl = `dokumenatPredmetaAll?predmetId=${predmetId}`;

        const response = await fetchService.get(requestUrl);
        const data = response.success ? response.responseValue : [];

        this.setState({
            dokumenta: data
        });

        return data;
    }

    loadStatusiPredmeta() {
        let data = [
            { id: 1, naziv: 'Aktivan' },
            { id: 2, naziv: 'Završen' }
        ];

        this.setState({
            statusiPredmeta: data
        });

        return data;
    }

    loadTipoviSuda() {
        let data = [
            { id: 1, naziv: 'Neodredjen' },
            { id: 2, naziv: 'Vrhovni kasacioni' },
            { id: 3, naziv: 'Apelacioni' },
            { id: 4, naziv: 'Viši' },
            { id: 5, naziv: 'Osnovni' },
            { id: 6, naziv: 'Upravni' },
            { id: 7, naziv: 'Privredni' },
            { id: 8, naziv: 'Prekršajni' },
            { id: 9, naziv: 'Prekršajni apelacioni' }
        ];

        this.setState({
            tipoviSuda: data
        });

        return data;
    }

    async loadRacuni() {
        let predmetId = this.state.predmet.id;
        let requestUrl = `racunPredmetaAll?predmetId=${predmetId}`;

        const response = await fetchService.get(requestUrl);
        const data = response.success ? response.responseValue : { racuni: [], totalIznos: 0 };

        this.setState({
            racuni: data
        });

        return data;
    }

    async loadTipoviPredmeta() {
        let requestUrl = `tipoviPredmeta?rows=500&page=1&sortField=naziv&sortOrder=1&active=true`;

        const response = await fetchService.get(requestUrl);
        const data = response.success ? response.responseValue : [];

        this.setState({
            tipoviPredmeta: data.tipoviPredmeta
        });

        return data.tipoviPredmeta;
    }

    async loadTipoviRacuna() {
        let requestUrl = `tipoviRacuna?rows=500&page=1&sortField=naziv&sortOrder=1&active=true`;

        const response = await fetchService.get(requestUrl);
        const data = response.success ? response.responseValue : [];

        this.setState({
            tipoviRacuna: data.tipoviRacuna
        });

        return data.tipoviRacuna;
    }

    loadValute() {
        let data = ValutaService.loadValute();

        this.setState({
            valute: data
        });

        return data;
    }

    async loadNosiociPredmeta() {
        let requestUrl = `nosiociPredmeta`;

        const response = await fetchService.get(requestUrl);
        const data = response.success ? response.responseValue : [];

        this.setState({
            nosiociPredmeta: data
        });

        return data;
    }

    onPredmetUpdate(predmet) {
        this.setState({
            predmet: predmet,
            klijentAdvokataId: predmet.klijentAdvokataId,
            imePrezimeKlijenta: `${predmet.imeKlijenta} ${predmet.prezimeKlijenta}`
        });
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : <OsnovniPodaciContainer predmet={this.state.predmet} klijentAdvokataId={this.state.klijentAdvokataId}
                imePrezimeKlijenta={this.state.imePrezimeKlijenta} onPredmetUpdate={this.onPredmetUpdate}
                tipoviPredmeta={this.state.tipoviPredmeta} onTipoviPredmetaMount={() => this.loadTipoviPredmeta()}
                statusiPredmeta={this.state.statusiPredmeta} onStatusiPredmetaMount={() => this.loadStatusiPredmeta()}
                tipoviSuda={this.state.tipoviSuda} onTipoviSudaMount={() => this.loadTipoviSuda()}
                nosiociPredmeta={this.state.nosiociPredmeta} onNosiociPredmetaMount={() => this.loadNosiociPredmeta()}
            />;

        let zapisiPredmeta = this.state.loading || !this.state.predmetId ? '' :
            <TabPanel header="Zapisi">
                <ZapisiPredmeta predmet={this.state.predmet} imePrezimeKlijenta={this.state.imePrezimeKlijenta}
                    zapisi={this.state.zapisi} onZapisiMount={() => this.loadZapisi()}
                    odgovornaLica={this.state.nosiociPredmeta} onOdgovornaLicaMount={() => this.loadNosiociPredmeta()}
                />
            </TabPanel>;

        let rocistaPredmeta = this.state.loading || !this.state.predmetId ? '' :
            <TabPanel header="Ročišta">
                <RocistaPredmeta predmet={this.state.predmet} imePrezimeKlijenta={this.state.imePrezimeKlijenta}
                    sudnicaPredmeta={this.state.predmet.sudnica}
                    rocista={this.state.rocista} onRocistaMount={() => this.loadRocista()}
                    odgovornaLica={this.state.nosiociPredmeta} onOdgovornaLicaMount={() => this.loadNosiociPredmeta()}
                />
            </TabPanel>;

        let rokoviPredmeta = this.state.loading || !this.state.predmetId ? '' :
            <TabPanel header="Rokovi">
                <RokoviPredmeta predmet={this.state.predmet} imePrezimeKlijenta={this.state.imePrezimeKlijenta}
                    rokovi={this.state.rokovi} onRokoviMount={() => this.loadRokovi()}
                    odgovornaLica={this.state.nosiociPredmeta} onOdgovornaLicaMount={() => this.loadNosiociPredmeta()}
                />
            </TabPanel>;

        let dokumentaPredmeta = this.state.loading || !this.state.predmetId ? '' :
            <TabPanel header="Dokumenta">
                <DokumentaPredmeta predmet={this.state.predmet} imePrezimeKlijenta={this.state.imePrezimeKlijenta}
                    dokumenta={this.state.dokumenta} onDokumentaMount={() => this.loadDokumenta()}
                    odgovornaLica={this.state.nosiociPredmeta} onOdgovornaLicaMount={() => this.loadNosiociPredmeta()}
                />
            </TabPanel>;

        let racuniPredmeta = this.state.loading || !this.state.predmetId ? '' :
            <TabPanel header="Računi">
                <RacuniPredmeta predmet={this.state.predmet} imePrezimeKlijenta={this.state.imePrezimeKlijenta}
                    racuni={this.state.racuni} onRacuniMount={() => this.loadRacuni()}
                    tipoviRacuna={this.state.tipoviRacuna} onTipoviRacunaMount={() => this.loadTipoviRacuna()}
                    valute={this.state.valute} onValuteMount={() => this.loadValute()}
                    odgovornaLica={this.state.nosiociPredmeta} onOdgovornaLicaMount={() => this.loadNosiociPredmeta()}
                />
            </TabPanel>;

        let istorijaPredmeta = this.state.loading || !this.state.predmetId ? '' :
            <TabPanel header="Istorija promena">
                <IstorijaPredmeta predmet={this.state.predmet} />
            </TabPanel>;

        let title = this.state.predmetId
            ? `Predmet ${this.state.predmet.brojPredmeta}`
            : 'Novi predmet';

        return (
            <div>
                <h2 id="tabelLabel" className='mb-3 page-title'>{title}</h2>

                <TabView>
                    <TabPanel header="Osnovni podaci">
                        {contents}
                    </TabPanel>
                    {zapisiPredmeta}
                    {rocistaPredmeta}
                    {rokoviPredmeta}
                    {dokumentaPredmeta}
                    {racuniPredmeta}
                    {istorijaPredmeta}
                </TabView>
            </div>
        );
    }
}
