import React, { Component } from 'react';
import { ScrollTop } from 'primereact/scrolltop';

import { Header } from './01Header';
import { StaJeZapisnik } from './02StaJeZapisnik';
import { StaJeZapisnikScreenshots } from './02StaJeZapisnikScreenshots';
import { ZastoIzabratiZapisnik } from './03ZastoIzabratiZapisnik';
//import { NasaPrica } from './04NasaPrica';
import { JosUvekNisteSigurni } from './055JosUvekNisteSigurni';
import { Cenovnik } from './05Cenovnik';
import { NajcescaPitanja } from './065NajcescaPitanja';
import { Kontakt } from './06Kontakt';

import './home.css';

export class Home extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="main-wrapper">
                {/*{this.renderSiteUnderConstruction()}*/}

                <Header />

                <StaJeZapisnik />

                <ZastoIzabratiZapisnik />

                <StaJeZapisnikScreenshots />

                <JosUvekNisteSigurni />

                <Cenovnik />

                <NajcescaPitanja />

                <Kontakt />

                <ScrollTop />
            </div>
        );
    }

    renderSiteUnderConstruction() {
        return (<div className='site-under-construction text-center'>WEB STRANICA JE JOŠ UVEK U IZRADI</div>);
    }
}
