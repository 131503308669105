import React, { Component } from 'react';
import fetchService from '../FetchService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AddLineWhiteIcon from '../icons/add-line-white.svg';

export class Kancelarije extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kancelarije: [],
            loading: true,
            tableLoading: true
        };
    }

    componentDidMount() {
        this.populateKancelarije();
    }

    renderKancelarije(kancelarije) {
        const footer = `Ukupno stavki: ${kancelarije ? kancelarije.length : 0}.`;

        return (
            <div>
                <DataTable value={kancelarije}
                    dataKey="id"
                    header={this.renderHeader}
                    footer={footer}
                    sortField='naziv' sortOrder='1'
                    tableStyle={{ minWidth: '50rem' }} className="custom-datatable"
                    loading={this.state.tableLoading}
                    emptyMessage="Nema unetih podataka."
                    selectionMode="single" onSelectionChange={(e) => window.location = '/kancelarija?id=' + e.value.id} >

                    <Column field="naziv" sortable header="Naziv" style={{ width: '40%' }}></Column>
                    <Column field="adresa" sortable header="Adresa" style={{ width: '60%' }}></Column>
                </DataTable>
            </div>
        );
    }

    renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <a className="btn btn-primary" href="/kancelarija">
                    <img alt="" src={AddLineWhiteIcon} />
                    &nbsp;
                    Kancelarija
                </a>
            </div>
        );
    };

    render() {
        let contents =
            this.state.loading
                ? <p><em>Učitavanje...</em></p>
                : this.renderKancelarije(this.state.kancelarije);

        return (
            <div>
                <h2 className='mb-3 page-title' id="tabelLabel">Kancelarije</h2>
                {contents}
            </div>
        );
    }

    async populateKancelarije() {
        this.setState({
            tableLoading: true
        });

        let requestUrl = `kancelarijeAdvokata`;
        const response = await fetchService.get(requestUrl);

        this.setState({
            kancelarije: response.success ? response.responseValue : [],
            loading: false,
            tableLoading: false
        });
    }
}
