import React, { Component } from 'react';
import { UvozPredmeta } from './UvozPredmeta';
import { IzvozPredmeta } from './IzvozPredmeta';

export class UvozIzvozPredmetaContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <UvozPredmeta />
                <IzvozPredmeta />
            </div>
        );
    }
}
