import React, { Component } from 'react';
import fetchService from '../FetchService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { HelpTooltip } from '../HelpTooltip';

export class IzabraniPredmeti extends Component {
    constructor(props) {
        super(props);

        this.state = {
            predmeti: [],
            tableLoading: true,
            lazyState: {
                first: 0,
                rows: 10,
                page: 0
            },
            totalRecords: 0
        };
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return (
            <div>
                <div className="p-3">
                    <div className="mx-3 mb-3 border-bottom border-danger">
                        <h3>
                            <b>Izabrani predmeti</b>
                            <HelpTooltip>
                                <p className="mb-3">
                                    Spisak predmeta koji su obeleženi za brzi pristup na početnom ekranu.
                                </p>
                                <p>
                                    Ukoliko želite da predmet bude prikazan na ovom spisku, prilikom ažuriranja predmeta, uključite opciju 'Brzi pristup na početnom ekranu'.
                                </p>
                                <p>
                                    Predlog je da ovde prikazujete trenutno aktuelne predmete kako biste im brzo pristupili.
                                </p>
                            </HelpTooltip>
                        </h3>
                    </div>

                    {this.renderPredmeti()}
                </div>
            </div>
        );
    }

    renderPredmeti() {
        return (
            <div className='dashboard-izabrani-predmeti'>
                <DataTable value={this.state.predmeti}
                    dataKey="id"
                    lazy totalRecords={this.state.totalRecords}
                    first={this.state.lazyState.first}
                    onPage={this.onPage}
                    paginator rows={10}
                    tableStyle={{ minWidth: '20rem' }} className="custom-datatable"
                    loading={this.state.tableLoading}
                    emptyMessage="Nema unetih podataka."
                    selectionMode="single" onSelectionChange={(e) => window.location = '/predmet?id=' + e.value.id}
                >
                    <Column field="redniBroj" style={{ width: '10%' }}></Column>
                    <Column field="imeKlijenta" body={this.imePrezimeTemplate} style={{ width: '50%' }}></Column>
                    <Column field="brojPredmeta" style={{ width: '40%' }}></Column>
                </DataTable>
            </div>
        );
    }

    imePrezimeTemplate = (klijent) => {
        return `${klijent.imeKlijenta} ${klijent.prezimeKlijenta}`;
    };

    async loadData() {
        this.setState({
            tableLoading: true
        });

        let bodyInput =
        {
            "Rows": this.state.lazyState.rows,
            "Page": this.state.lazyState.page + 1
        };

        const response = await fetchService.post('predmetiAdvokataDashboard', bodyInput);

        this.setState({
            predmeti: response.success ? response.responseValue.predmeti : [],
            totalRecords: response.success ? response.responseValue.totalCount : 0
        });

        this.setState({
            tableLoading: false
        });
    }

    onPage = (event) => {
        this.setState(
            {
                lazyState: {
                    first: event.first,
                    rows: event.rows,
                    page: event.page
                }
            },
            () => this.loadData()
        );
    }
}
