import React, { Component } from 'react';
import PenciLineWhiteIcon from '../icons/pencil-line-white.svg';
import ArrowGoBackWhiteIcon from '../icons/arrow-go-back-white.svg';
import PhoneFillIcon from '../icons/phone-fill.svg';
import { ItemPermissions } from '../permissions/ItemPermissions';
import { RadioButton } from "primereact/radiobutton";

export class ProtivnikOsnovniPodaciView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            protivnik: {
                id: '',
                jmbg: '',
                ime: '',
                prezime: '',
                adresa: '',
                opis: '',
                telefon: '',
                maticniBroj: '',
                pib: '',
                isPravnoLice: false,
                kancelarijaTimInfos: []
            }
        };
    }

    componentDidMount() {
        this.setState({
            protivnik: this.props.protivnik
        });
    }

    render() {
        let imeClass = 'col-sm-6';
        let imeName = 'Ime';
        if(this.state.protivnik.isPravnoLice) {
            imeClass = '';
            imeName = 'Naziv';
        }

        return (
            <div>
                <div className="mb-3 form-header">
                    <button className="btn btn-primary btns" onClick={this.props.onAzurirajClick}>
                        <img alt="" src={PenciLineWhiteIcon} />
                        &nbsp;
                        Ažuriraj
                    </button>
                    <a className="btn btn-primary btns" href="/protivnici">
                        <img alt="" src={ArrowGoBackWhiteIcon} />
                        &nbsp;
                        Protivnici
                    </a>
                </div>

                <div className="form-container">
                    <div className="mb-3">
                        <RadioButton inputId="ingredient1" readOnly checked={this.state.protivnik.isPravnoLice === false} />
                        &nbsp;
                        <label htmlFor="ingredient1" className="ml-2">Fizičko lice</label>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <RadioButton inputId="ingredient2" readOnly checked={this.state.protivnik.isPravnoLice === true} />
                        &nbsp;
                        <label htmlFor="ingredient2" className="ml-2">Pravno lice</label>
                    </div>

                    <div className="row" hidden={this.state.protivnik.isPravnoLice ? false : true}>
                        <div className="mb-3 col-sm-6">
                            <label htmlFor="maticniBroj" className="form-label">Matični broj</label>
                            <input readOnly className="form-control" id="maticniBroj" value={this.state.protivnik.maticniBroj ?? ''} />
                        </div>
                        <div className="mb-3 col-sm-6">
                            <label htmlFor="pib" className="form-label">Pib</label>
                            <input readOnly className="form-control" id="pib" value={this.state.protivnik.pib ?? ''} />
                        </div>
                    </div>

                    <div className="mb-3" hidden={this.state.protivnik.isPravnoLice ? true : false }>
                        <label htmlFor="jmbg" className="form-label">JMBG</label>
                        <input readOnly className="form-control" id="jmbg" value={this.state.protivnik.jmbg ?? ''} />
                    </div>
                    <div className="row">
                        <div className={`mb-3 ${imeClass}`}>
                            <label htmlFor="ime" className="form-label">{imeName}</label>
                            <input readOnly className="form-control" id="ime" value={this.state.protivnik.ime ?? ''} />
                        </div>
                        <div className="mb-3 col-sm-6" hidden={this.state.protivnik.isPravnoLice ? true : false }>
                            <label htmlFor="prezime" className="form-label">Prezime</label>
                            <input readOnly className="form-control" id="prezime" value={this.state.protivnik.prezime ?? ''} />
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="adresa" className="form-label">Adresa</label>
                        <input readOnly className="form-control" id="adresa" value={this.state.protivnik.adresa ?? ''} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="opis" className="form-label">Opis</label>
                        <textarea readOnly className="form-control" id="opis" value={this.state.protivnik.opis ?? ''} rows="3"></textarea>
                    </div>
                    <div className="mb-3">
                        <div className="flex justify-content-between">
                            <label htmlFor="telefon" className="form-label">Telefon</label>
                            &nbsp;&nbsp;
                            <a hidden={this.state.protivnik.telefon ? false : true } className="phone-number-call-icon mb-2 btn visible-sm"
                                href={this.state.protivnik.telefon ? 'tel:' + this.state.protivnik.telefon : ''}>
                                <img alt="" src={PhoneFillIcon} /> Pozovi&nbsp;
                            </a>
                        </div>
                        <textarea readOnly className="form-control" id="telefon" value={this.state.protivnik.telefon ?? ''} rows="3"></textarea>
                    </div>

                    <div className="mb-3">
                        <hr />
                        <label className="form-label">Dozvola pristupa</label>
                        <ItemPermissions items={this.state.protivnik.kancelarijaTimInfos}
                            editMode={false} scope={2}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
