import React, { Component } from 'react';
import fetchService from '../FetchService';
import { Toast } from 'primereact/toast';
import './home.css';
import { Dialog } from 'primereact/dialog';

import JosUvekNisteSigurniPicture from './jos-uvek-niste-sigurni.png';

export class JosUvekNisteSigurni extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();
        this.formRef = React.createRef();

        this.state = {
            formVisible: false,
            ime: '',
            telefon: '',
            email: '',
            komentar: ''
        }

        this.saveDataToApi = this.saveDataToApi.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
    }

    render() {
        return (
            <div id='section-niste-sigurni' className="container px-5 py-5">
                <div className='mx-3'>
                    <h2 className="mb-3 display-5 fw-bold lh-1 text-body-emphasis pb-2 border-bottom">Još uvek niste sigurni?</h2>

                    <div className="row flex-lg-row align-items-center">

                        <div className="col-lg-8 px-3 align-items-center mb-3">
                            <img src={JosUvekNisteSigurniPicture} className="d-block mx-lg-auto img-fluid niste-sigurni-picture" />
                        </div>

                        <div className="col-lg-4 px-3 align-items-center">

                            <div className='lead mb-5 text-center'>
                                <p>
                                    Iskoristi mogućnost i besplatno isprobaj sve opcije koje Zapisnik.rs nudi
                                </p>

                                <div className="pt-3 text-center" >
                                    <a href="/Identity/Account/Login?isDemoView=1&ReturnUrl=/dashboard" className="btn btn-primary btn-lg px-4 me-md-2 home-screen-button">Isprobaj demo</a>
                                </div>
                            </div>

                            <div className='lead text-center'>
                                <p>
                                    Prijavi se za online prezentaciju kako bi saznao više o Zapisnik.rs
                                </p>

                                <div className="pt-3 text-center" >
                                    <button className="btn btn-primary btn-lg px-4 me-md-2 home-screen-button" onClick={() => this.setState({ formVisible: true })}>Prijavi se ovde</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <Dialog header="Prijava za online prezentaciju"
                    visible={this.state.formVisible}
                    dismissableMask
                    draggable={false}
                    style={{ width: '60vw' }}
                    breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                    position='top'
                    onHide={() => this.cancelEdit()}>

                    <div>
                        <form className='form-group' ref={this.formRef}>
                            <div className="form-container">

                                <div className="row">
                                    <div className='mb-3'>
                                        <label htmlFor="ime" className="form-label">Ime i prezime *</label>
                                        <input className="form-control" id="ime" required value={this.state.ime ?? ''} 
                                            maxLength="200"
                                            onChange={(e) => this.setState({ ime: e.target.value })} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className='mb-3 col-sm-6'>
                                        <label htmlFor="telefon" className="form-label">Telefon *</label>
                                        <input className="form-control" id="telefon" required value={this.state.telefon ?? ''} 
                                            maxLength="100"
                                            onChange={(e) => this.setState({ telefon: e.target.value })} />
                                    </div>

                                    <div className='mb-3 col-sm-6'>
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input className="form-control" id="email" value={this.state.email ?? ''} 
                                            maxLength="100"
                                            onChange={(e) => this.setState({ email: e.target.value })} />
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="komentar" className="form-label">Komentar</label>
                                    <textarea className="form-control" id="komentar" value={this.state.komentar ?? ''} rows="3" onChange={(e) => this.setState({ komentar: e.target.value })}></textarea>
                                </div>
                            </div>
                        </form>

                        <div className="mb-3 mx-3">
                            <button className="btn btn-primary btn-lg px-4 me-md-2 home-screen-button" onClick={this.saveDataToApi}>
                                Pošalji
                            </button>
                        </div>

                    </div>
                </Dialog>

                <Toast ref={this.toastRef} />
            </div>
        );
    }

    async saveDataToApi() {
        if (!this.formRef.current.checkValidity()) {
            this.formRef.current.classList.add('was-validated');
            return;
        }

        this.setState({ loading: true });

        const method = 'POST';
        const bodyInput = {
            "Tip": 2,
            "Ime": this.state.ime,
            "Telefon": this.state.telefon,
            "Email": this.state.email,
            "Komentar": this.state.komentar
        };

        let response = await fetchService.save('porukaSaSajta', bodyInput, method);

        this.setState({ loading: false });

        if (!response.success) {
            let errorMessage = response.message ? 'Greška: ' + response.message : '';
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: errorMessage, sticky: true });
        }
        else {
            this.setState({
                formVisible: false,
                ime: '',
                telefon: '',
                email: '',
                komentar: ''
            });
            this.toastRef.current.show({ severity: 'success', summary: 'Prijava uspešna', detail: "Uspešno ste poslali poruku. Kontaktiraćemo Vas u što kraćem roku. Hvala!", sticky: true });
        }
    }

    cancelEdit() {
        this.setState({
            formVisible: false,
            ime: '',
            telefon: '',
            email: '',
            komentar: ''
        });
    }
}
