import React, { Component } from 'react';
import fetchService from '../FetchService';
import { Toast } from 'primereact/toast';
import { InputSwitch } from 'primereact/inputswitch';
import CloseLineWhiteIcon from '../icons/close-line-white.svg';
import SaveLineWhiteIcon from '../icons/save-line-white.svg';

export class TipRacunaOsnovniPodaciEdit extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();
        this.formRef = React.createRef();

        this.state = {
            loading: true,
            tipRacuna: {
                id: '',
                naziv: '',
                aktivan: true
            }
        };

        this.saveDataToApi = this.saveDataToApi.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
    }

    componentDidMount() {
        this.setState({
            tipRacuna: this.props.tipRacuna,
            loading: false
        });
    }

    async saveDataToApi() {
        if (!this.formRef.current.checkValidity()) {
            this.formRef.current.classList.add('was-validated');
            return;
        }

        this.setState({ loading: true });

        let isNewTipRacuna = this.state.tipRacuna.id === null;
        const method = this.state.tipRacuna.id ? 'PUT' : 'POST';

        const bodyInput = {
            "Id": this.state.tipRacuna.id,
            "Naziv": this.state.tipRacuna.naziv,
            "Aktivan": this.state.tipRacuna.aktivan
        };

        let tipRacunaIdParameter = this.state.tipRacuna.id ? "/" + this.state.tipRacuna.id : '';
        let response = await fetchService.save('tipoviRacuna' + tipRacunaIdParameter, bodyInput, method);

        this.setState({ loading: false });

        if (response.success) {
            if (isNewTipRacuna) {
                window.location = '/tipRacuna?id=' + response.responseValue.id;
                return;
            }

            this.props.onSaveClick(response.responseValue);
        } else {
            let errorMessage = response.message ? 'Greška: ' + response.message : '';
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom čuvanja tipa računa. " + errorMessage, sticky: true });
        }
    }

    cancelEdit() {
        if (!this.state.tipRacuna.id) {
            window.location = '/tipovi-racuna';
            return;
        }

        this.props.onCancelClick();
    }

    renderForm() {
        return (
            <div>
                <div className="mb-3 form-header">
                    <button className="btn btn-primary btns" onClick={this.saveDataToApi}>
                        <img alt="" src={SaveLineWhiteIcon} />
                        &nbsp;
                        Sačuvaj
                    </button>
                    <button className="btn btn-secondary btns" onClick={this.cancelEdit}>
                        <img alt="" src={CloseLineWhiteIcon} />
                        &nbsp;
                        Odustani
                    </button>
                </div>

                <form className='form-group' ref={this.formRef}>
                    <div className="form-container">
                        <div className="mb-3">
                            <label htmlFor="naziv" className="form-label">Naziv *</label>
                            <input className="form-control" required id="naziv" value={this.state.tipRacuna.naziv ?? ''} 
                                maxLength="100"
                                onChange={(e) => this.setState({ tipRacuna: { ...this.state.tipRacuna, naziv: e.target.value } })} />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="aktivan" className="form-label">Aktivan</label>
                            &nbsp;&nbsp;
                            <InputSwitch id="aktivan" checked={this.state.tipRacuna.aktivan} className="form-control" 
                                onChange={(e) => this.setState({ tipRacuna: { ...this.state.tipRacuna, aktivan: e.value } })} />
                        </div>


                        
                    </div>
                </form>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : this.renderForm();

        return (
            <div>
                {contents}
                <Toast ref={this.toastRef} />
            </div>
        );
    }
}
