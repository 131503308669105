import React, { Component } from 'react';
import fetchService from '../FetchService';
export class IzvozKlijenata extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <h2 className='mb-3 page-title'>Izvoz klijenata</h2>

                <div className="form-container">
                    <div className="mb-3">
                        <p>
                            U ovom delu možete izesti svoje klijente u eksternu datoteku u ".csv" formatu.
                        </p>
                        <div className="form-container">
                            <div className="mb-3">
                                <button className="btn btn-primary" onClick={() => this.downloadIzvozKlijenata()}>
                                    Izvoz klijenata
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async downloadIzvozKlijenata() {
        const dataBlob = await fetchService.getBlob('uvozIzvozKlijenataAdvokata');
        this.pushBlobToUser(dataBlob.responseValue, "klijenti.csv");
    }

    pushBlobToUser(dataBlob, fileName) {
        var objectURL = URL.createObjectURL(dataBlob);
        const link = document.createElement('a');
        link.href = objectURL;
        link.download = fileName;
        link.click();
    }
}
