import React, { Component } from 'react';
import fetchService from '../FetchService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AddLineWhiteIcon from '../icons/add-line-white.svg';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

export class RadneKancelarije extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kancelarije: [],
            loading: true,
            tableLoading: true
        };
    }

    componentDidMount() {
        this.populateKancelarije();
    }

    renderKancelarije(kancelarije) {
        const footer = `Ukupno stavki: ${kancelarije ? kancelarije.length : 0}.`;

        return (
            <div>
                <DataTable value={kancelarije}
                    dataKey="id"
                    footer={footer}
                    sortField='naziv' sortOrder='1'
                    tableStyle={{ minWidth: '50rem' }} className="custom-datatable"
                    loading={this.state.tableLoading}
                    emptyMessage="Nema unetih podataka."
                    selectionMode="single" onSelectionChange={(e) => this.confirmSetRadnaKancelarija(e.value.id)} >

                    <Column field="radna" sortable header="Radna" style={{ width: '15%' }}></Column>
                    <Column field="naziv" sortable header="Naziv" style={{ width: '35%' }}></Column>
                    <Column field="adresa" sortable header="Adresa" style={{ width: '50%' }}></Column>
                </DataTable>
            </div>
        );
    }

    render() {
        let contents =
            this.state.loading
                ? <p><em>Učitavanje...</em></p>
                : this.renderKancelarije(this.state.kancelarije);

        return (
            <div>
                <h2 className='mb-3 page-title' id="tabelLabel">Kancelarije</h2>
                {contents}
                <ConfirmDialog />
            </div>
        );
    }

    async populateKancelarije() {
        this.setState({
            tableLoading: true
        });

        let requestUrl = `radneKancelarijeAdvokata`;
        const response = await fetchService.get(requestUrl);

        this.setState({
            kancelarije: response.success ? response.responseValue : [],
            loading: false,
            tableLoading: false
        });
    }

    confirmSetRadnaKancelarija(kancelarijaId) {
        confirmDialog({
            message: 'Da li ste sigurni da želite da postavite izabranu kancelariju kao radnu?',
            header: 'Postavljanje radne kancelarije',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-info',
            accept: () => { this.setRadnaKancelarija(kancelarijaId) },
            acceptLabel: 'Da',
            rejectLabel: 'Ne'
        });
    };

    async setRadnaKancelarija(kancelarijaId) {
        this.setState({ loading: true });

        const method = 'POST';

        let requestUrl = 'radnaKancelarijaAdvokata?kancelarijaId=' + kancelarijaId;
        let response = await fetchService.save(requestUrl, null, method);

        this.setState({ loading: false });

        if (response.success) {
            window.location = '/radne-kancelarije';
        } else {
            let errorMessage = response.message ? 'Greška: ' + response.message : '';
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom postavljanja radne kancelarije. " + errorMessage, sticky: true });
        }
    }
}
