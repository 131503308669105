import React, { Component } from 'react';
import fetchService from '../FetchService';

export class IzvozPredmeta extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <h2 className='mb-3 page-title'>Izvoz predmeta</h2>

                <div className="form-container">
                    <div className="mb-3">
                        <p>
                            U ovom delu možete izesti svoje predmete u eksternu datoteku u ".csv" formatu.
                        </p>
                        <div className="form-container">
                            <div className="mb-3">
                                <button className="btn btn-primary" onClick={() => this.downloadIzvozPredmeta()}>
                                    Izvoz predmeta
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async downloadIzvozPredmeta() {
        const dataBlob = await fetchService.getBlob('uvozIzvozPredmetaAdvokata');
        this.pushBlobToUser(dataBlob.responseValue, "predmeti.csv");
    }

    pushBlobToUser(dataBlob, fileName) {
        var objectURL = URL.createObjectURL(dataBlob);
        const link = document.createElement('a');
        link.href = objectURL;
        link.download = fileName;
        link.click();
    }
}
