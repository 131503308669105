import React, { Component } from 'react';
import fetchService from '../FetchService';
import { Toast } from 'primereact/toast';
import CloseLineWhiteIcon from '../icons/close-line-white.svg';
import SaveLineWhiteIcon from '../icons/save-line-white.svg';
import DeleteBinLineWhiteIcon from '../icons/delete-bin-line-white.svg';
import { confirmDialog } from 'primereact/confirmdialog';

export class KancelarijaOsnovniPodaciEdit extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();
        this.formRef = React.createRef();

        this.state = {
            loading: true,
            kancelarija: {
                id: '',
                naziv: '',
                adresa: ''
            }
        };

        this.saveDataToApi = this.saveDataToApi.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }

    componentDidMount() {
        this.setState({
            kancelarija: this.props.kancelarija,
            loading: false
        });
    }

    async saveDataToApi() {
        if (!this.formRef.current.checkValidity()) {
            this.formRef.current.classList.add('was-validated');
            return;
        }

        await this.saveDataToApiFinalize();
    }

    async saveDataToApiFinalize() {
        this.setState({ loading: true });

        let isNewKancelarija = this.state.kancelarija.id ? false : true;
        const method = this.state.kancelarija.id ? 'PUT' : 'POST';

        const bodyInput = {
            "Id": this.state.kancelarija.id,
            "Naziv": this.state.kancelarija.naziv,
            "Adresa": this.state.kancelarija.adresa
        };

        let requestUrl = 'kancelarijeAdvokata' + (this.state.kancelarija.id ? "/" + this.state.kancelarija.id : '');
        let response = await fetchService.save(requestUrl, bodyInput, method);

        this.setState({ loading: false });

        if (response.success) {
            if (isNewKancelarija) {
                window.location = '/kancelarija?id=' + response.responseValue.id;
                return;
            }

            this.props.onSaveClick(response.responseValue);
        } else {
            let errorMessage = response.message ? 'Greška: ' + response.message : '';
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom čuvanja kancelarije. " + errorMessage, sticky: true });
        }
    }

    async deleteItem() {
        this.setState({ loading: true });

        let response = await fetchService.delete(`kancelarijeAdvokata?kancelarijaId=${this.state.kancelarija.id}`);

        this.setState({ loading: false });

        if (!response.success) {
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom brisanja kancelarije. Greška: " + response.message, sticky: true });
        }
        else {
            this.props.onSaveClick();
            window.location = '/kancelarije';
        }
    }

    cancelEdit() {
        if (!this.state.kancelarija.id) {
            window.location = '/kancelarije';
            return;
        }

        this.props.onCancelClick();
    }

    confirmDelete(event) {
        confirmDialog({
            trigger: event.currentTarget,
            message: 'Da li želite da obrišete ovu kancelariju?',
            header: 'Brisanje kancelarije',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => { this.deleteItem() },
            acceptLabel: 'Da',
            rejectLabel: 'Ne'
        });
    };

    renderForm() {
        return (
            <div>
                <div className="mb-3 form-header">
                    <button className="btn btn-primary btns" onClick={this.saveDataToApi}>
                        <img alt="" src={SaveLineWhiteIcon} />
                        &nbsp;
                        Sačuvaj
                    </button>
                    <button hidden={this.state.kancelarija.id ? false : true} className="btn btn-danger btns" onClick={this.confirmDelete}>
                        <img alt="" src={DeleteBinLineWhiteIcon} />
                        &nbsp;
                        Briši
                    </button>
                    <button className="btn btn-secondary btns" onClick={this.cancelEdit}>
                        <img alt="" src={CloseLineWhiteIcon} />
                        &nbsp;
                        Odustani
                    </button>
                </div>

                <form className='form-group' ref={this.formRef}>
                    <div className="form-container">
                        <div className="mb-3">
                            <label htmlFor="naziv" className="form-label">Naziv *</label>
                            <input className="form-control" id="naziv" maxLength="100" required value={this.state.kancelarija.naziv ?? ''} onChange={(e) => this.setState({ kancelarija: { ...this.state.kancelarija, naziv: e.target.value } })} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="adresa" className="form-label">Adresa</label>
                            <input className="form-control" maxLength="100" id="adresa" value={this.state.kancelarija.adresa ?? ''} onChange={(e) => this.setState({ kancelarija: { ...this.state.kancelarija, adresa: e.target.value } })} />
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : this.renderForm();

        return (
            <div>
                {contents}
                <Toast ref={this.toastRef} />
                
            </div>
        );
    }
}
