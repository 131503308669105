import React, { Component } from 'react';
import { UvozKlijenata } from './UvozKlijenata';
import { IzvozKlijenata } from './IzvozKlijenata';

export class UvozIzvozKlijenataContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <UvozKlijenata />
                <IzvozKlijenata />
            </div>
        );
    }
}
