import React, { Component } from 'react';
import fetchService from '../FetchService';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import CloseLineWhiteIcon from '../icons/close-line-white.svg';
import SaveLineWhiteIcon from '../icons/save-line-white.svg';
import DeleteBinLineWhiteIcon from '../icons/delete-bin-line-white.svg';
import { DateTimeService } from '../DateTimeService';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputMask } from "primereact/inputmask";
import { HelpTooltip } from '../HelpTooltip';
import { Dropdown } from 'primereact/dropdown';

export class ZapisEdit extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();
        this.formRef = React.createRef();

        this.state = {
            loading: true,
            zapisPredmeta: {
                zapisPredmetaId: '',
                predmetId: '',
                opis: '',
                datumUnosa: '',
                vremeUnosa: '',
                odgovornoLiceId: ''
            },
            imePrezimeKlijenta: '',
            brojPredmeta: ''
        };

        this.saveDataToApi = this.saveDataToApi.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }

    componentDidMount() {
        if (this.props.zapisPredmeta.id) {
            let dateTime = new Date(this.props.zapisPredmeta.vremeUnosa);
            this.setState({
                zapisPredmeta: {
                    ...this.props.zapisPredmeta,
                    datumUnosa: dateTime,
                    vremeUnosa: DateTimeService.formatDateTimeToTimeString(dateTime),
                },
                imePrezimeKlijenta: this.props.imePrezimeKlijenta,
                brojPredmeta: this.props.brojPredmeta,
                loading: false
            });
        } else {
            let dateTime = new Date();
            this.setState({
                zapisPredmeta: {
                    ...this.state.zapisPredmeta,
                    predmetId: this.props.predmetId,
                    datumUnosa: dateTime,
                    vremeUnosa: DateTimeService.formatDateTimeToTimeString(dateTime),
                }
            })
        }

        this.setState({
            imePrezimeKlijenta: this.props.imePrezimeKlijenta,
            brojPredmeta: this.props.brojPredmeta,
            loading: false
        });
    }

    async saveDataToApi() {
        if (!this.formRef.current.checkValidity()) {
            this.formRef.current.classList.add('was-validated');
            return;
        }

        let time = DateTimeService.getDateFromTimeString(this.state.zapisPredmeta.vremeUnosa);
        if (!time) {
            this.setState({ zapisPredmeta: { ...this.state.zapisPredmeta, vremeUnosa: '' } })
            this.formRef.current.classList.add('was-validated');
            return;
        }

        this.setState({ loading: true });

        const method = this.state.zapisPredmeta.id ? 'PUT' : 'POST';
        const bodyInput = {
            "ZapisPredmetaId": this.state.zapisPredmeta.id,
            "PredmetId": this.state.zapisPredmeta.predmetId,
            "Opis": this.state.zapisPredmeta.opis,
            "VremeUnosa": DateTimeService.getCombinedDateTime(this.state.zapisPredmeta.datumUnosa, time),
            "OdgovornoLiceId": this.state.zapisPredmeta.odgovornoLiceId
        };

        let response = await fetchService.save('zapisPredmeta', bodyInput, method);

        this.setState({ loading: false });

        if (!response.success) {
            let errorMessage = response.message ? 'Greška: ' + response.message : '';
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom čuvanja zapisa predmeta. " + errorMessage, sticky: true });
        }
        else {
            this.props.onSaveClick();
        }
    }

    async deleteItem() {
        this.setState({ loading: true });

        let response = await fetchService.delete(`zapisPredmeta?zapisPredmetaId=${this.state.zapisPredmeta.id}`);

        this.setState({ loading: false });

        if (!response.success) {
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom brisanja zapisa predmeta. Greška: " + response.message, sticky: true });
        }
        else {
            this.props.onSaveClick();
        }
    }

    cancelEdit() {
        this.props.onCancelClick();
    }

    confirmDelete(event) {
        confirmDialog({
            trigger: event.currentTarget,
            message: 'Da li želite da obrišete ovaj zapis predmeta?',
            header: 'Brisanje zapisa',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => { this.deleteItem() },
            acceptLabel: 'Da',
            rejectLabel: 'Ne'
        });
    };

    renderForm() {
        return (
            <div>
                <div className="mb-3 form-header">
                    <button className="btn btn-primary btns" onClick={this.saveDataToApi}>
                        <img alt="" src={SaveLineWhiteIcon} />
                        &nbsp;
                        Sačuvaj
                    </button>
                    <button hidden={this.state.zapisPredmeta.id ? false : true} className="btn btn-danger btns" onClick={this.confirmDelete}>
                        <img alt="" src={DeleteBinLineWhiteIcon} />
                        &nbsp;
                        Briši
                    </button>
                    <button className="btn btn-secondary btns" onClick={this.cancelEdit}>
                        <img alt="" src={CloseLineWhiteIcon} />
                        &nbsp;
                        Odustani
                    </button>
                </div>

                <form className='form-group' ref={this.formRef}>
                    <div className="form-container">
                        <div className="mb-3">
                            <label htmlFor="imePrezimeKlijenta" className="form-label">Klijent</label>
                            <input readOnly className="form-control" id="imePrezimeKlijenta" value={this.state.imePrezimeKlijenta ?? ''} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="brojPredmeta" className="form-label">Broj predmeta</label>
                            <input readOnly className="form-control" id="brojPredmeta" value={this.state.brojPredmeta ?? ''} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="opis" className="form-label">Opis</label>
                            <textarea className="form-control" id="opis" value={this.state.zapisPredmeta.opis ?? ''} rows="3"
                                onChange={(e) => this.setState({ zapisPredmeta: { ...this.state.zapisPredmeta, opis: e.target.value } })}>
                            </textarea>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Vreme unosa *</label>
                            <br />
                            <Calendar id="datumUnosa" className={`control-max-width-200 ${this.state.zapisPredmeta.datumUnosa ? '' : 'p-invalid'}`} value={this.state.zapisPredmeta.datumUnosa ?? ''}
                                onChange={(e) => this.setState({ zapisPredmeta: { ...this.state.zapisPredmeta, datumUnosa: e.target.value } })}
                                dateFormat="dd.mm.yy" required  />
                            &nbsp;
                            <InputMask id="vremeUnosa" className={`control-max-width-200 ${this.state.zapisPredmeta.vremeUnosa ? '' : 'p-invalid'}`} value={this.state.zapisPredmeta.vremeUnosa ?? ''}
                                onChange={(e) => this.setState({ zapisPredmeta: { ...this.state.zapisPredmeta, vremeUnosa: e.target.value } })}
                                required mask='99:99' placeholder='__:__' />
                        </div>

                        <div className="mb-3 col-md-4">
                            <label htmlFor="odgovornoLiceId" className="form-label">Odgovorno lice</label>
                            <HelpTooltip>
                                <p className="mb-3">
                                    Advokat kancelarije odgovoran za zapis predmeta.
                                </p>
                            </HelpTooltip>
                            <div>
                                <Dropdown id="odgovornoLiceId"
                                    value={this.state.zapisPredmeta.odgovornoLiceId}
                                    onChange={(e) => this.setState({ zapisPredmeta: { ...this.state.zapisPredmeta, odgovornoLiceId: e.target.value } })}
                                    options={this.props.odgovornaLica}
                                    optionLabel="imePrezime"
                                    optionValue="id"
                                    filter
                                    showClear
                                    className="w-full p-dropdown-fullwidth" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : this.renderForm();

        return (
            <div>
                {contents}

                <Toast ref={this.toastRef} />
                <ConfirmDialog />
            </div>
        );
    }
}
