import React, { Component } from 'react';
import { Toast } from 'primereact/toast';
import { KancelarijaOsnovniPodaciView } from './KancelarijaOsnovniPodaciView';
import { KancelarijaOsnovniPodaciEdit } from './KancelarijaOsnovniPodaciEdit';
import { ConfirmDialog } from 'primereact/confirmdialog';
    
export class KancelarijaOsnovniPodaciContainer extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();

        this.state = {
            loading: true,
            editMode: false
        };

        this.setEditMode = this.setEditMode.bind(this);
        this.saveClicked = this.saveClicked.bind(this);
    }

    componentDidMount() {
        this.setState({
            kancelarija: this.props.kancelarija,
            loading: false,
            editMode: !this.props.kancelarija || !this.props.kancelarija.id ? true : false
        });
    }

    setEditMode(editMode) {
        this.setState({
            editMode: editMode
        });
    }

    saveClicked(kancelarija) {
        this.setState({
            kancelarija: kancelarija
        }, () => {
            this.setEditMode(false);
            this.toastRef.current.show({ severity: 'success', summary: 'Uspešno sačuvano', detail: "Uspešno ste sačuvali kancelariju." });
        });

        this.props.onKancelarijaUpdate(kancelarija);
    }

    render() {
        let kancelarijaOsnovniPodaciView = this.state.editMode
            ? <KancelarijaOsnovniPodaciEdit kancelarija={this.state.kancelarija} onCancelClick={() => this.setEditMode(false)} onSaveClick={(kancelarija) => this.saveClicked(kancelarija)} />
            : <KancelarijaOsnovniPodaciView kancelarija={this.state.kancelarija} onAzurirajClick={() => this.setEditMode(true)} />;

        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : kancelarijaOsnovniPodaciView;

        return (
            <div>
                {contents}
                <Toast ref={this.toastRef} />
                <ConfirmDialog />
            </div>
        );
    }
}
