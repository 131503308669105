import React, { Component } from 'react';
import fetchService from '../FetchService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import FileAddWhiteIcon from '../icons/file-add-white.svg';
import DeleteBinLineIcon from '../icons/delete-bin-line.svg';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

export class ItemPermissions extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();
        this.state = {
            loading: true,

            yesNoValues: [
                { name: 'Da', value: true },
                { name: 'Ne', value: false }
            ]
        };

        this.actionsTemplate = this.actionsTemplate.bind(this);
        this.loadAvailableTimovi = this.loadAvailableTimovi.bind(this);
    }

    async componentDidMount() {
        this.loadAvailableTimovi();

        this.setState({
            loading: false
        });
    }

    renderItems(items) {
        let header = this.props.editMode ?
            <div className="form-header">
                <button className="btn btn-primary btns" onClick={this.props.onAddNewItem}>
                    <img alt="" src={FileAddWhiteIcon} />
                </button>
            </div>
            : '' ;

        let editMode = this.props.editMode ? 'cell' : 'row';

        return (
            <div>
                {header}

                <DataTable value={items}
                    dataKey="id"
                    tableStyle={{ minWidth: '50rem' }} className="custom-datatable"
                    emptyMessage="Nema unetih podataka."
                    editMode={editMode}
                >
                    <Column field="naziv" header="Tim" editor={(options) => this.timEditor(options)} style={{ width: '45%' }}></Column>
                    <Column field="pregled" header="Pregled" body={this.pregledTemplate} editor={(options) => this.pregledEditor(options)} style={{ width: '15%' }}></Column>
                    <Column field="azuriranje" header="Ažuriranje" body={this.azuriranjeTemplate} editor={(options) => this.azuriranjeEditor(options)} style={{ width: '15%' }}></Column>
                    <Column body={this.actionsTemplate} style={{ width: '15%' }}></Column>
                </DataTable>
            </div>
        );
    }

    render() {
        let contents =
            this.state.loading
                ? <p><em>Učitavanje...</em></p>
                : this.renderItems(this.props.items);

        return (
            <div>
                {contents}

                <Toast ref={this.toastRef} />
            </div>
        );
    }

    timEditor(options) {
        return (
            <Dropdown
                options={this.state.availableTimovi}
                optionLabel="naziv"
                optionValue="id"
                value={options.rowData.kancelarijaTimId}
                onChange={(e) => { this.onTimDropChange(e, options) }}
                placeholder="Izaberi tim..."
            />
        );
    }

    pregledEditor(options) {
        return (
            <Dropdown
                options={this.state.yesNoValues}
                optionLabel="name"
                optionValue="value"
                value={options.rowData.pregled}
                onChange={(e) => { this.onPregledChange(e, options) }}
                placeholder="Izaberi tim..."
            />
        );
    }

    azuriranjeEditor(options) {
        return (
            <Dropdown
                options={this.state.yesNoValues}
                optionLabel="name"
                optionValue="value"
                value={options.rowData.azuriranje}
                onChange={(e) => { this.onAzuriranjeChange(e, options) }}
                placeholder="Izaberi tim..."
            />
        );
    }

    actionsTemplate(rowData) {
        let visibility = this.props.editMode ? '' : 'hidden';
        return (
            <button className="btn btns" onClick={() => { this.props.onDeleteItem(rowData.id); }} style={{ visibility: visibility }}>
                <img alt="" src={DeleteBinLineIcon} />
            </button>
        );
    }

    pregledTemplate(rowData) {
        return rowData.pregled === true ? 'Da' : 'Ne';
    }

    azuriranjeTemplate(rowData) {
        return rowData.azuriranje === true ? 'Da' : 'Ne';
    }

    onTimDropChange(e, options) {
        let selectedItem = this.state.availableTimovi.filter(function (el) {
            return el.id === e.value;
        })[0];

        let alreadyExistingTim = this.props.items.filter(function (el) {
            return el.kancelarijaTimId === e.value;
        });
        if (alreadyExistingTim.length > 0) {
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Izabrani tim već postoji na spisku." });
            return;
        }


        this.props.items[options.rowIndex].naziv = selectedItem.naziv;
        this.props.items[options.rowIndex].kancelarijaTimId = selectedItem.id;
    }

    onPregledChange(e, options) {
        this.props.items[options.rowIndex].pregled = e.value;
    }

    onAzuriranjeChange(e, options) {
        this.props.items[options.rowIndex].azuriranje = e.value;
    }

    async loadAvailableTimovi() {
        const response = await fetchService.get('availableKancelarijaTim?scope=' + this.props.scope);
        let data = response.success ? response.responseValue : [];

        this.setState({
            availableTimovi: data
        });

        return data;
    }
}
