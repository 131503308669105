import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { Dashboard } from "./components/dashboard/Dashboard";
import { LogoutSuccess } from './components/LogoutSuccess';
import { Klijenti } from './components/klijenti/Klijenti';
import { KlijentContainer } from './components/klijenti/KlijentContainer';
import { Predmeti } from './components/predmeti/Predmeti';
import { PredmetContainer } from './components/predmeti/PredmetContainer';
import { KalendarContainer } from './components/kalendar/KalendarContainer';
import { SeedDate } from './components/seed/seed';
import { TipoviPredmeta } from './components/tipovi-predmeta/TipoviPredmeta';
import { TipPredmetaContainer } from './components/tipovi-predmeta/TipPredmetaContainer';
import { TipoviRacuna } from './components/tipovi-racuna/TipoviRacuna';
import { TipRacunaContainer } from './components/tipovi-racuna/TipRacunaContainer';
import { UvozIzvozKlijenataContainer } from './components/mass-operacija-podataka/UvozIzvozKlijenataContainer';
import { UvozIzvozPredmetaContainer } from './components/mass-operacija-podataka/UvozIzvozPredmetaContainer';
import { UvozIzvozTipovaPredmetaContainer } from './components/mass-operacija-podataka/UvozIzvozTipovaPredmetaContainer';
import { PregledZapisa } from './components/pregled-podataka/PregledZapisa';
import { PregledRocista } from './components/pregled-podataka/PregledRocista';
import { PregledRokova } from './components/pregled-podataka/PregledRokova';
import { PregledDokumenata } from './components/pregled-podataka/PregledDokumenata';
import { PregledRacuna } from './components/pregled-podataka/PregledRacuna';
import { Protivnici } from './components/protivnici/Protivnici';
import { ProtivnikContainer } from './components/protivnici/ProtivnikContainer';
import { Kancelarije } from './components/kancelarije/Kancelarije';
import { KancelarijaContainer } from './components/kancelarije/KancelarijaContainer';
import { RadneKancelarije } from './components/kancelarije/RadneKancelarije';

function generateTitle(pathTitle) {
    return pathTitle + ' - Zapisnik';
}

const AppRoutes = [
    {
        path: '/dashboard',
        requireAuth: true,
        title: generateTitle('Dashboard'),
        element: <Dashboard />
    },
    {
        path: '/logout-success',
        title: generateTitle('Logout'),
        element: <LogoutSuccess />
    },
    {
        path: '/klijenti',
        requireAuth: true,
        title: generateTitle('Klijenti'),
        element: <Klijenti />
    },
    {
        path: '/klijent',
        requireAuth: true,
        title: generateTitle('Klijent'),
        element: <KlijentContainer />
    },
    {
        path: '/predmeti',
        requireAuth: true,
        title: generateTitle('Predmeti'),
        element: <Predmeti />
    },
    {
        path: '/predmet',
        requireAuth: true,
        title: generateTitle('Predmet'),
        element: <PredmetContainer />
    },
    {
        path: '/kalendar',
        requireAuth: true,
        title: generateTitle('Kalendar'),
        element: <KalendarContainer />
    },
    {
        path: '/seed-data',
        requireAuth: true,
        element: <SeedDate />
    },
    {
        path: '/tipovi-predmeta',
        requireAuth: true,
        title: generateTitle('Tipovi predmeta'),
        element: <TipoviPredmeta />
    },
    {
        path: '/tip-predmeta',
        requireAuth: true,
        title: generateTitle('Tip predmeta'),
        element: <TipPredmetaContainer />
    },
    {
        path: '/tipovi-racuna',
        requireAuth: true,
        title: generateTitle('Tipovi računa'),
        element: <TipoviRacuna />
    },
    {
        path: '/tip-racuna',
        requireAuth: true,
        title: generateTitle('Tip računa'),
        element: <TipRacunaContainer />
    },
    {
        path: '/uvoz-izvoz-klijenata',
        requireAuth: true,
        element: <UvozIzvozKlijenataContainer />
    },
    {
        path: '/uvoz-izvoz-predmeta',
        requireAuth: true,
        element: <UvozIzvozPredmetaContainer />
    },
    {
        path: '/uvoz-izvoz-tipova-predmeta',
        requireAuth: true,
        element: <UvozIzvozTipovaPredmetaContainer />
    },
    {
        path: '/pregled-podataka/zapisi',
        requireAuth: true,
        title: generateTitle('Pregled zapisa'),
        element: <PregledZapisa />
    },
    {
        path: '/pregled-podataka/rocista',
        requireAuth: true,
        title: generateTitle('Pregled ročišta'),
        element: <PregledRocista />
    },
    {
        path: '/pregled-podataka/rokovi',
        requireAuth: true,
        title: generateTitle('Pregled rokova'),
        element: <PregledRokova />
    },
    {
        path: '/pregled-podataka/dokumenta',
        requireAuth: true,
        title: generateTitle('Pregled dokumenata'),
        element: <PregledDokumenata />
    },
    {
        path: '/pregled-podataka/racuni',
        requireAuth: true,
        title: generateTitle('Pregled računa'),
        element: <PregledRacuna />
    },
    {
        path: '/protivnici',
        requireAuth: true,
        title: generateTitle('Protivnici'),
        element: <Protivnici />
    },
    {
        path: '/protivnik',
        requireAuth: true,
        title: generateTitle('Protivnik'),
        element: <ProtivnikContainer />
    },
    {
        path: '/kancelarije',
        requireAuth: true,
        title: generateTitle('Kancelarije'),
        element: <Kancelarije />
    },
    {
        path: '/kancelarija',
        requireAuth: true,
        title: generateTitle('Kancelarija'),
        element: <KancelarijaContainer />
    },
    {
        path: '/radne-kancelarije',
        requireAuth: true,
        title: generateTitle('Radna kancelarija'),
        element: <RadneKancelarije />
    },
    ...ApiAuthorzationRoutes
];

export default AppRoutes;
