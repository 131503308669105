import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { KalendarZaMesec } from './KalendarZaMesec';
import { KalendarZaDan } from './KalendarZaDan';
import { KalendarZaViseDana } from './KalendarZaViseDana';
import { KalendarPoSatu } from './KalendarPoSatu';
import { Dialog } from 'primereact/dialog';
import CloseLineWhiteIcon from '../icons/close-line-white.svg';
import PenciLineWhiteIcon from '../icons/pencil-line-white.svg';
import { DateTimeService } from '../DateTimeService';
import { TerminEdit } from './TerminEdit';
import { HelpTooltip } from '../HelpTooltip';

export class KalendarContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTabIndex: 0,
            itemDetailsVisible: false,
            itemDetailsHeader: '',
            selectedKalendarDataItem: null,
            editMode: false,
            editingItem: {},
            firstDatumKalendara: new Date(),
            secondDatumKalendara: new Date(),
            monthKalendarSelectedMonth: null,
            monthKalendarSelectedYear: null
        };

        this.rowSelected = this.rowSelected.bind(this);
        this.onNewTerminTriggered = this.onNewTerminTriggered .bind(this);
        this.onTerminEditSaveClick = this.onTerminEditSaveClick.bind(this);
        this.onTerminEditCancelClick = this.onTerminEditCancelClick.bind(this);
        this.onDatumKalendaraChanged = this.onDatumKalendaraChanged.bind(this);
        this.monthKalendarDatumSelected = this.monthKalendarDatumSelected.bind(this);
        this.monthKalendarDatumChanged = this.monthKalendarDatumChanged.bind(this);
    }

    componentDidMount() {
    }

    render() {
        let displayDataContent = this.state.editMode
            ? <TerminEdit termin={this.state.editingItem} newItemDatum={this.state.firstDatumKalendara}
                onSaveClick={this.onTerminEditSaveClick} onCancelClick={this.onTerminEditCancelClick} />
            : this.renderKalendar();

        let title = this.state.editMode
            ? (this.state.editingItem.id ? 'Ažuriranje termina' : 'Novi termin')
            : 'Kalendar';

        let terminInfo = this.state.editMode
            ?
            <HelpTooltip>
                <p>
                    Termini su ručno unešene informacije o obavezama i dešavanjima vezanih za neki vremenski trenutak.
                </p>
                <p>
                    Ovde možete unositi informacije o sastancima, privatnim obavezama i slično.
                </p>
                <p>
                    Svi upisani termini će se automatski pojaviti u kalendar.
                </p>
            </HelpTooltip>
            : '';

        return (
            <div>
                <h2 className='mb-3 page-title' id="tabelLabel">{title}{terminInfo}</h2>

                {displayDataContent}
            </div>
        );
    }

    renderKalendar() {
        return (<div>
            <TabView activeIndex={this.state.activeTabIndex} onTabChange={(e) => this.setState({ activeTabIndex: e.index })}>
                <TabPanel header="Satnica">
                    <KalendarPoSatu
                        onRowSelected={this.rowSelected}
                        onNewTerminTriggered={this.onNewTerminTriggered}
                        onDatumKalendaraChanged={this.onDatumKalendaraChanged}
                        initDatumKalendara={this.state.firstDatumKalendara}
                        secondDatumKalendara={this.state.secondDatumKalendara}
                    />
                </TabPanel>
                <TabPanel header="Mesec">
                    <KalendarZaMesec
                        onDatumChanged={this.monthKalendarDatumChanged}
                        onDatumSelected={this.monthKalendarDatumSelected}
                        initMonth={this.state.monthKalendarSelectedMonth}
                        initYear={this.state.monthKalendarSelectedYear}
                    />
                </TabPanel>
                {/*<TabPanel header="Za dan">*/}
                {/*    <KalendarZaDan*/}
                {/*        onRowSelected={this.rowSelected}*/}
                {/*        onNewTerminTriggered={this.onNewTerminTriggered}*/}
                {/*        onDatumKalendaraChanged={this.onDatumKalendaraChanged}*/}
                {/*        initDatumKalendara={this.state.firstDatumKalendara}*/}
                {/*    />*/}
                {/*</TabPanel>*/}
                <TabPanel header="Spisak">
                    <KalendarZaViseDana
                        onRowSelected={this.rowSelected}
                        onNewTerminTriggered={this.onNewTerminTriggered}
                        onDatumKalendaraChanged={this.onDatumKalendaraChanged}
                        initDatumKalendara={this.state.firstDatumKalendara}
                        secondDatumKalendara={this.state.secondDatumKalendara}
                    />
                </TabPanel>
            </TabView>

            {this.renderItemDetails()}
        </div>);
    }

    rowSelected(kalendarDataItem) {
        let itemDetailsHeader = '';

        if (kalendarDataItem.tip === 'rok')
            itemDetailsHeader = 'Rok';
        else if (kalendarDataItem.tip === 'rociste')
            itemDetailsHeader = 'Ročište';

        else if (kalendarDataItem.tip === 'termin')
            itemDetailsHeader = 'Termin';

        this.setState({
            itemDetailsHeader: itemDetailsHeader,
            selectedKalendarDataItem: kalendarDataItem,
            itemDetailsVisible: true
        });
    }

    renderItemDetails() {
        let isTermin = this.state.selectedKalendarDataItem?.tip === 'termin';
        let isRociste = this.state.selectedKalendarDataItem?.tip === 'rociste';

        return (
            <Dialog
                header={this.state.itemDetailsHeader}
                visible={this.state.itemDetailsVisible}
                style={{ width: '50vw' }}
                breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                position='top'
                onHide={() => this.setState({ itemDetailsVisible: false })}
                footer={<div>
                    <a className="btn btn-primary btns" hidden={isTermin} href={`/predmet?id=${this.state.selectedKalendarDataItem?.predmetId}`}><img alt="" src={PenciLineWhiteIcon} />&nbsp;Predmet</a>
                    <button className="btn btn-primary btns" hidden={!isTermin} onClick={() => this.existingTerminEditTriggered()}><img alt="" src={PenciLineWhiteIcon} />&nbsp;Ažuriraj</button>
                    <button className="btn btn-secondary btns" autoFocus onClick={() => this.setState({ itemDetailsVisible: false, editingItem: {} })}><img alt="" src={CloseLineWhiteIcon} />&nbsp;Zatvori</button>
                </div>}
            >
                <div className="m-0">
                    <div className="mb-3">
                        <label htmlFor="datum" className="form-label">Vreme</label>
                        <input readOnly className="form-control" id="datum" value={this.state.selectedKalendarDataItem?.datum ? DateTimeService.formatDateTimeToString(this.state.selectedKalendarDataItem.datum) : ''} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="naziv" className="form-label">Naziv</label>
                        <input readOnly className="form-control" id="naziv" value={this.state.selectedKalendarDataItem?.naziv ?? ''} />
                    </div>
                    <div className="mb-3" hidden={!isRociste}>
                        <label htmlFor="sudnica" className="form-label">Sudnica</label>
                        <input readOnly className="form-control" id="sudnica" value={this.state.selectedKalendarDataItem?.sudnica ?? ''} />
                    </div>
                    <div className="row" hidden={isTermin}>
                        <div className="mb-3 col-sm-6">
                            <label htmlFor="predmet" className="form-label">Predmet</label>
                            <input readOnly className="form-control" id="predmet" value={this.state.selectedKalendarDataItem?.brojPredmeta ?? ''} />
                        </div>
                        <div className="mb-3 col-sm-6">
                            <label htmlFor="klijent" className="form-label">Klijent</label>
                            <input readOnly className="form-control" id="klijent" value={this.state.selectedKalendarDataItem?.klijent ?? ''} />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="opis" className="form-label">Opis</label>
                        <textarea readOnly className="form-control" id="opis" value={this.state.selectedKalendarDataItem?.opis ?? ''} rows="3"></textarea>
                    </div>
                </div>
            </Dialog>
        );
    }

    onNewTerminTriggered() {
        this.setState({
            editingItem: {},
            editMode: true
        });
    }

    existingTerminEditTriggered() {
        this.setState({
            editingItem: this.state.selectedKalendarDataItem,
            editMode: true,
            itemDetailsVisible: false
        });
    }

    onTerminEditSaveClick() {
        this.setState({
            loading: true,
            editMode: false
        });
    }

    onTerminEditCancelClick() {
        this.setState({
            editMode: false
        });
    }

    onDatumKalendaraChanged(newDatumKalendara, newSecondDatumKalendara) {
        this.setState({
            firstDatumKalendara: newDatumKalendara,
            secondDatumKalendara: newSecondDatumKalendara ? newSecondDatumKalendara : newDatumKalendara
        });
    }

    monthKalendarDatumChanged(selectedMonth, selectedYear) {
        this.setState({
            monthKalendarSelectedMonth: selectedMonth,
            monthKalendarSelectedYear: selectedYear
        });
    }

    monthKalendarDatumSelected(datumKalendara) {
        this.onDatumKalendaraChanged(datumKalendara);
        this.setState({ activeTabIndex: 0 });
    }
}
