import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import UserIcon from '../icons/user-line.svg';
import UserAddIcon from '../icons/user-add-line.svg';
import LoginIcon from '../icons/login-box-line.svg';
import LogoutIcon from '../icons/logout-box-line.svg';
import fetchService from '../FetchService';


export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
            fullName: null
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        let fullName = await this.getFullName(!isAuthenticated);
        this.setState({
            isAuthenticated,
            userName: user && user.name,
            fullName: fullName
        });

        
    }

    render() {
        const { isAuthenticated, userName, fullName } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = `${ApplicationPaths.LogOut}`;
            const logoutState = { local: true };
            return this.authenticatedView(fullName, profilePath, logoutPath, logoutState);
        }
    }

    async getFullName(clean) {
        if (clean == true) {
            return null;
        }
        else {
            let result = await fetchService.get('userInfo');
            return result.responseValue.fullName;
        }
    }

    //authenticatedView(userName, profilePath, logoutPath, logoutState) {
    //    return (<Fragment>
    //        <NavItem>
    //            <NavLink tag={Link} className="text-dark" to={profilePath}>Hello {userName}</NavLink>
    //        </NavItem>
    //        <NavItem>
    //            <NavLink replace tag={Link} className="text-dark" to={logoutPath} state={logoutState}>Logout</NavLink>
    //        </NavItem>
    //    </Fragment>);
    //}

    //anonymousView(registerPath, loginPath) {
    //    return (<Fragment>
    //        <NavItem>
    //            <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>
    //        </NavItem>
    //        <NavItem>
    //            <NavLink tag={Link} className="text-dark" to={loginPath}>Login</NavLink>
    //        </NavItem>
    //    </Fragment>);
    //}

    authenticatedView(userName, profilePath, logoutPath, logoutState) {
        return (<Fragment>
            <NavItem className="menu-item">
                <NavLink tag={Link} to={profilePath}>
                    <span className="menu-icon">
                        <img src={UserIcon} />
                    </span>
                    <span className="menu-title">
                        {userName}
                    </span>
                </NavLink>
            </NavItem>
            <NavItem className="menu-item">
                <NavLink replace tag={Link} to={logoutPath} state={logoutState}>
                    <span className="menu-icon">
                        <img src={LogoutIcon} />
                    </span>
                    <span className="menu-title">
                        Odjava
                    </span>
                </NavLink>
            </NavItem>
        </Fragment>);
    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <NavItem className="menu-item">
                <NavLink tag={Link} to={registerPath}>
                    <span className="menu-icon">
                        <img src={UserAddIcon} />
                    </span>
                    <span className="menu-title">
                        Registracija
                    </span>
                </NavLink>
            </NavItem>
            <NavItem className="menu-item">
                <NavLink tag={Link} to={loginPath}>
                    <span className="menu-icon">
                        <img src={LoginIcon} />
                    </span>
                    <span className="menu-title">
                        Prijava
                    </span>
                </NavLink>
            </NavItem>
        </Fragment>);
    }
}
