import React, { Component } from 'react';
import fetchService from '../FetchService';
import { TipRacunaOsnovniPodaciContainer } from './TipRacunaOsnovniPodaciContainer';
    
export class TipRacunaContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            tipRacunaId: null,
            tipRacuna: {
                id: '',
                naziv: '',
                aktivan: true
            },
            racuni: null
        };
    }

    componentDidMount() {
        const id = (new URLSearchParams(document.location.search)).get('id');
        this.setState(
            {
                tipRacunaId: id ?? ""
            },
            () => this.loadDataFromApi()
                .then(() => { this.setState({ loading: false }); })
        );
    }

    async loadDataFromApi() {
        if (!this.state.tipRacunaId) return;

        let requestUrl = "tipoviRacuna/" + this.state.tipRacunaId;
        const response = await fetchService.get(requestUrl);

        const data = response.success ? response.responseValue : {};

        this.setState({
            tipRacuna: data
        });
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : <TipRacunaOsnovniPodaciContainer tipRacuna={this.state.tipRacuna} onTipRacunaUpdate={(tipRacuna) => this.setState({ tipRacuna: tipRacuna })} />;

        let title = this.state.tipRacunaId
            ? `Tip računa ${this.state.tipRacuna.naziv}`
            : 'Novi tip računa';

        return (
            <div>
                <h2 id="tabelLabel" className='mb-3 page-title'>{title}</h2>
                {contents}
            </div>
        );
    }
}
