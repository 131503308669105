import React, { Component } from 'react';
import { Toast } from 'primereact/toast';
import { KlijentOsnovniPodaciView } from './KlijentOsnovniPodaciView';
import { KlijentOsnovniPodaciEdit } from './KlijentOsnovniPodaciEdit';
import { ConfirmDialog } from 'primereact/confirmdialog';
    
export class KlijentOsnovniPodaciContainer extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();

        this.state = {
            loading: true,
            editMode: false
        };

        this.setEditMode = this.setEditMode.bind(this);
        this.saveClicked = this.saveClicked.bind(this);
    }

    componentDidMount() {
        this.setState({
            klijent: this.props.klijent,
            loading: false,
            editMode: !this.props.klijent || !this.props.klijent.id ? true : false
        });
    }

    setEditMode(editMode) {
        this.setState({
            editMode: editMode
        });
    }

    saveClicked(klijent) {
        this.setState({
            klijent: klijent
        }, () => {
            this.setEditMode(false);
            this.toastRef.current.show({ severity: 'success', summary: 'Uspešno sačuvano', detail: "Uspešno ste sačuvali klijenta." });
        });

        this.props.onKlijentUpdate(klijent);
    }

    render() {
        let klijentOsnovniPodaciView = this.state.editMode
            ? <KlijentOsnovniPodaciEdit klijent={this.state.klijent} onCancelClick={() => this.setEditMode(false)} onSaveClick={(klijent) => this.saveClicked(klijent)} externalNewEntry={false} />
            : <KlijentOsnovniPodaciView klijent={this.state.klijent} onAzurirajClick={() => this.setEditMode(true)} />;

        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : klijentOsnovniPodaciView;

        return (
            <div>
                {contents}
                <Toast ref={this.toastRef} />
                <ConfirmDialog />
            </div>
        );
    }
}
