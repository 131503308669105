import React, { Component } from 'react';
import fetchService from '../FetchService';
import { Toast } from 'primereact/toast';
import CloseLineWhiteIcon from '../icons/close-line-white.svg';
import SaveLineWhiteIcon from '../icons/save-line-white.svg';
import DeleteBinLineWhiteIcon from '../icons/delete-bin-line-white.svg';
import { confirmDialog } from 'primereact/confirmdialog';
import { ItemPermissions } from '../permissions/ItemPermissions';
import { RadioButton } from "primereact/radiobutton";

export class KlijentOsnovniPodaciEdit extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();
        this.formRef = React.createRef();

        this.state = {
            loading: true,
            klijent: {
                id: '',
                jmbg: '',
                ime: '',
                prezime: '',
                adresa: '',
                opis: '',
                telefon: '',
                maticniBroj: '',
                pib: '',
                isPravnoLice: false,
                kancelarijaTimInfos: []
            },
            timovi: []
        };

        this.saveDataToApi = this.saveDataToApi.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }

    componentDidMount() {
        this.setState({
            klijent: this.props.klijent,
            timovi: JSON.parse(JSON.stringify(this.props.klijent.kancelarijaTimInfos)),
            loading: false
        });
    }

    async saveDataToApi(event) {
        if (!this.formRef.current.checkValidity()) {
            this.formRef.current.classList.add('was-validated');
            return;
        }

        if (this.state.klijent.jmbg) {
            await this.saveDataToApiFinalize();
            return;
        }

        let kancelarijaTims = this.state.timovi.filter(function (el) {
            if (el.kancelarijaTimId)
                return el;
        });

        const bodyInput = {
            "Id": this.state.klijent.id,
            "Ime": this.state.klijent.ime,
            "Prezime": this.state.klijent.prezime,
            "IsPravnoLice": this.state.klijent.isPravnoLice,
            "KancelarijaTimInfos": kancelarijaTims
        };
        let responseValidated = await fetchService.post('validateKlijentAdvokata', bodyInput);

        if (!responseValidated.success) {
            let errorMessage = responseValidated.message ? 'Greška: ' + responseValidated.message : '';
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom validacije klijenta. " + errorMessage, sticky: true });
            return;
        }

        if (responseValidated.responseValue === true) {
            await this.saveDataToApiFinalize();
        }
        else {
            confirmDialog({
                trigger: event.currentTarget,
                message: 'Niste uneli JMBG, a u bazi već postoji klijent sa istim imenom i prezimenom. Da li ste sigurni da želite da kreirate novog klijenta?',
                header: 'Potvrda kreiranja klijenta',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                accept: () => { this.saveDataToApiFinalize(); },
                acceptLabel: 'Da',
                rejectLabel: 'Ne'
            });
        }
    }

    async saveDataToApiFinalize() {
        this.setState({ loading: true });

        let isNewKlijent = this.state.klijent.id ? false : true;
        const method = this.state.klijent.id ? 'PUT' : 'POST';

        let kancelarijaTims = this.state.timovi.filter(function (el) {
            if (el.kancelarijaTimId)
                return el;
        });

        const bodyInput = {
            "Id": this.state.klijent.id,
            "Jmbg": this.state.klijent.jmbg,
            "Ime": this.state.klijent.ime,
            "Prezime": this.state.klijent.prezime,
            "Adresa": this.state.klijent.adresa,
            "Opis": this.state.klijent.opis,
            "Telefon": this.state.klijent.telefon,
            "MaticniBroj": this.state.klijent.maticniBroj,
            "Pib": this.state.klijent.pib,
            "IsPravnoLice": this.state.klijent.isPravnoLice,
            "KancelarijaTimInfos": kancelarijaTims
        };

        let requestUrl = 'klijentiAdvokata' + (this.state.klijent.id ? "/" + this.state.klijent.id : '');
        let response = await fetchService.save(requestUrl, bodyInput, method);

        this.setState({ loading: false });

        if (response.success) {
            if (isNewKlijent && !this.props.externalNewEntry) {
                window.location = '/klijent?id=' + response.responseValue.id;
                return;
            }

            this.props.onSaveClick(response.responseValue);
        } else {
            let errorMessage = response.message ? 'Greška: ' + response.message : '';
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom čuvanja klijenta. " + errorMessage, sticky: true });
        }
    }

    async deleteItem() {
        this.setState({ loading: true });

        let response = await fetchService.delete(`klijentiAdvokata?klijentAdvokataId=${this.state.klijent.id}`);

        this.setState({ loading: false });

        if (!response.success) {
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom brisanja klijenta. Greška: " + response.message, sticky: true });
        }
        else {
            this.props.onSaveClick();
            window.location = '/klijenti';
        }
    }

    cancelEdit() {
        if (!this.state.klijent.id && !this.props.externalNewEntry) {
            window.location = '/klijenti';
            return;
        }

        this.props.onCancelClick();
    }

    confirmDelete(event) {
        confirmDialog({
            trigger: event.currentTarget,
            message: 'Da li želite da obrišete ovog klijenta?',
            header: 'Brisanje klijenta',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => { this.deleteItem() },
            acceptLabel: 'Da',
            rejectLabel: 'Ne'
        });
    };

    renderForm() {
        let imeClass = 'col-sm-6';
        let imeName = 'Ime';
        if(this.state.klijent.isPravnoLice) {
            imeClass = '';
            imeName = 'Naziv';
        }

        return (
            <div>
                <div className="mb-3 form-header">
                    <button className="btn btn-primary btns" onClick={this.saveDataToApi}>
                        <img alt="" src={SaveLineWhiteIcon} />
                        &nbsp;
                        Sačuvaj
                    </button>
                    <button hidden={this.state.klijent.id ? false : true} className="btn btn-danger btns" onClick={this.confirmDelete}>
                        <img alt="" src={DeleteBinLineWhiteIcon} />
                        &nbsp;
                        Briši
                    </button>
                    <button className="btn btn-secondary btns" onClick={this.cancelEdit}>
                        <img alt="" src={CloseLineWhiteIcon} />
                        &nbsp;
                        Odustani
                    </button>
                </div>

                <form className='form-group' ref={this.formRef}>
                    <div className="form-container">
                        <div className="mb-3">
                            <RadioButton inputId="ingredient1" onChange={() => this.setState({ klijent: { ...this.state.klijent, isPravnoLice: false } })} checked={this.state.klijent.isPravnoLice === false} />
                            &nbsp;
                            <label htmlFor="ingredient1" className="ml-2">Fizičko lice</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <RadioButton inputId="ingredient2" onChange={() => this.setState({ klijent: { ...this.state.klijent, isPravnoLice: true } })} checked={this.state.klijent.isPravnoLice === true} />
                            &nbsp;
                            <label htmlFor="ingredient2" className="ml-2">Pravno lice</label>
                        </div>

                        <div className="row" hidden={this.state.klijent.isPravnoLice ? false : true }>
                            <div className="mb-3 col-sm-6">
                                <label htmlFor="maticniBroj" className="form-label">Matični broj</label>
                                <input className="form-control" id="maticniBroj" value={this.state.klijent.maticniBroj ?? ''} 
                                    maxLength="20"
                                    onChange={(e) => this.setState({ klijent: { ...this.state.klijent, maticniBroj: e.target.value } })} />
                            </div>
                            <div className="mb-3 col-sm-6">
                                <label htmlFor="pib" className="form-label">Pib</label>
                                <input className="form-control" id="pib" value={this.state.klijent.pib ?? ''} 
                                    maxLength="20"
                                    onChange={(e) => this.setState({ klijent: { ...this.state.klijent, pib: e.target.value } })} />
                            </div>
                        </div>

                        <div className="mb-3" hidden={this.state.klijent.isPravnoLice ? true : false }>
                            <label htmlFor="jmbg" className="form-label">JMBG</label>
                            <input className="form-control" id="jmbg" value={this.state.klijent.jmbg ?? ''} 
                                minLength="13" maxLength="13" 
                                onChange={(e) => this.setState({ klijent: { ...this.state.klijent, jmbg: e.target.value } })} />
                        </div>
                        <div className="row">
                            <div className={`mb-3 ${imeClass}`}>
                                <label htmlFor="ime" className="form-label">{imeName} *</label>
                                <input className="form-control" id="ime" required value={this.state.klijent.ime ?? ''} 
                                    maxLength="100"
                                    onChange={(e) => this.setState({ klijent: { ...this.state.klijent, ime: e.target.value } })} />
                            </div>
                            <div className="mb-3 col-sm-6" hidden={this.state.klijent.isPravnoLice ? true : false }>
                                <label htmlFor="prezime" className="form-label">Prezime</label>
                                <input className="form-control" id="prezime" value={this.state.klijent.prezime ?? ''} 
                                    maxLength="100"
                                    onChange={(e) => this.setState({ klijent: { ...this.state.klijent, prezime: e.target.value } })} />
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="adresa" className="form-label">Adresa</label>
                            <input className="form-control" id="adresa" value={this.state.klijent.adresa ?? ''} 
                                maxLength="100"
                                onChange={(e) => this.setState({ klijent: { ...this.state.klijent, adresa: e.target.value } })} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="opis" className="form-label">Opis</label>
                            <textarea className="form-control" id="opis" value={this.state.klijent.opis ?? ''} rows="3" 
                                onChange={(e) => this.setState({ klijent: { ...this.state.klijent, opis: e.target.value } })}></textarea>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="telefon" className="form-label">Telefon</label>
                            <textarea className="form-control" id="telefon" value={this.state.klijent.telefon ?? ''} rows="3" 
                                maxLength="100"
                                onChange={(e) => this.setState({ klijent: { ...this.state.klijent, telefon: e.target.value } })}></textarea>
                        </div>
                    </div>
                </form>

                <div className="form-container">
                    <div className="mb-3">
                        <hr />
                        <label className="form-label">Dozvola pristupa</label>
                        <ItemPermissions
                            items={this.state.timovi}
                            onAddNewItem={() => this.addNewPermissionItem()}
                            onDeleteItem={(id) => this.deleteNewPermissionItem(id)}
                            editMode={true} scope={1}
                        />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : this.renderForm();

        return (
            <div>
                {contents}
                <Toast ref={this.toastRef} />

            </div>
        );
    }

    addNewPermissionItem() {
        let newPermissionItem = {
            id: Math.floor(Math.random() * -1000000).toString(),
            naziv: 'Izaberi tim...',
            pregled: true,
            azuriranje: false
        };

        this.setState({
            timovi: [
                ...this.state.timovi,
                newPermissionItem
            ]
        })
    }

    deleteNewPermissionItem(id) {
        let newArray = this.state.timovi.filter(function (el) {
            return el.id !== id;
        });

        this.setState({
            timovi: newArray
        })
    }
}
