import React, { Component } from 'react';
import fetchService from '../FetchService';
import { TabView, TabPanel } from 'primereact/tabview';
import { KlijentOsnovniPodaciContainer } from './KlijentOsnovniPodaciContainer';
import { KlijentPredmeti } from './KlijentPredmeti';
    
export class KlijentContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            klijentAdvokataId: null,
            klijent: {
                isPravnoLice: false,
                kancelarijaTimInfos: []
            },
            predmeti: null
        };
    }

    componentDidMount() {
        const id = (new URLSearchParams(document.location.search)).get('id');
        this.setState(
            {
                klijentAdvokataId: id ?? ""
            },
            () => this.loadDataFromApi()
                .then(() => { this.setState({ loading: false }); })
        );
    }

    async loadDataFromApi() {
        if (!this.state.klijentAdvokataId) return;

        let requestUrl = "klijentiAdvokata/" + this.state.klijentAdvokataId;
        const response = await fetchService.get(requestUrl);

        this.setState({
            klijent: response.success ? response.responseValue : {}
        });
    }

    async loadPredmeti() {
        let klijentAdvokataId = this.state.klijentAdvokataId;

        let requestUrl = `predmetiKlijentaAdvokata?klijentAdvokataId=${klijentAdvokataId}`;
        const response = await fetchService.get(requestUrl);
        let data = response.success ? response.responseValue : [];

        this.setState({
            predmeti: data
        });

        return data;
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : <KlijentOsnovniPodaciContainer klijent={this.state.klijent} onKlijentUpdate={(klijent) => this.setState({ klijent: klijent })} />;

        let predmetiKlijentaAdvokata = this.state.loading || !this.state.klijentAdvokataId ? '' :
            <TabPanel header="Predmeti">
                <KlijentPredmeti klijentAdvokataId={this.state.klijentAdvokataId} predmeti={this.state.predmeti} onPredmetiMount={() => this.loadPredmeti()} />
            </TabPanel>;

        let title = this.state.klijentAdvokataId
            ? `Klijent ${this.state.klijent.ime} ${this.state.klijent.prezime}`
            : 'Novi klijent';

        return (
            <div>
                <h2 id="tabelLabel" className='mb-3 page-title'>{title}</h2>

                <TabView>
                    <TabPanel header="Osnovni podaci">
                        {contents}
                    </TabPanel>
                    {predmetiKlijentaAdvokata}
                </TabView>
            </div>
        );
    }
}
