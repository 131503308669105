import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import FileAddWhiteIcon from '../icons/file-add-white.svg';
import { TimKancelarijeEdit } from './TimKancelarijeEdit';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

export class TimoviKancelarije extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timovi: [],
            loading: true,
            editMode: false,
            editingItem: {}
        };

        this.rowSelected = this.rowSelected.bind(this);
        this.newItemTriggered = this.newItemTriggered.bind(this);
        this.onTimEditSaveClick = this.onTimEditSaveClick.bind(this);
        this.onTimEditCancelClick = this.onTimEditCancelClick.bind(this);
    }

    async componentDidMount() {
        let timovi = this.props.timovi ?? await this.props.onTimoviMount();

        this.setState({
            timovi: timovi,
            loading: false
        });
    }

    renderTimovi(timovi) {
        const footer = `Ukupno stavki: ${timovi ? timovi.length : 0}.`;

        const headerGroup = (
            <ColumnGroup>
                <Row>
                    <Column header="Ime" rowSpan={2} sortable field="naziv" />
                    <Column header="Klijent" colSpan={3} className='table-grouped-column-header' />
                    <Column header="Protivnik" colSpan={3} className='table-grouped-column-header' />
                    <Column header="Predmet" colSpan={3} className='table-grouped-column-header' />
                </Row>

                <Row>

                    <Column header="Kreiranje" />
                    <Column header="Pregled" />
                    <Column header="Azuriranje" />

                    <Column header="Kreiranje" />
                    <Column header="Pregled" />
                    <Column header="Azuriranje" />

                    <Column header="Kreiranje" />
                    <Column header="Pregled" />
                    <Column header="Azuriranje" />
                </Row>
            </ColumnGroup>
        );

        return (
            <div>
                <div className="form-header">
                    <button className="btn btn-primary btns" onClick={this.newItemTriggered}>
                        <img alt="" src={FileAddWhiteIcon} />
                        &nbsp;
                        Tim
                    </button>
                </div>

                <DataTable value={timovi}
                    dataKey="id"
                    sortField="naziv" sortOrder={1}
                    tableStyle={{ minWidth: '50rem' }} className="custom-datatable"
                    emptyMessage="Nema unetih podataka."
                    headerColumnGroup={headerGroup}
                    footer={footer}
                    selectionMode="single" onSelectionChange={(e) => this.rowSelected(e.value)} 
                >
                    <Column field="naziv" header="Ime" style={{ width: '37%' }}></Column>

                    <Column field="kreiranjeKlijenata" body={this.kreiranjeKlijenataTemplate} header="Kreiranje klijenata" style={{ width: '7%' }}></Column>
                    <Column field="pregledSvihKlijenata" body={this.pregledSvihKlijenataTemplate} header="Pregled klijenata" style={{ width: '7%' }}></Column>
                    <Column field="azuriranjeSvihKlijenata" body={this.azuriranjeSvihKlijenataTemplate} header="Ažuriranje klijenata" style={{ width: '7%' }}></Column>

                    <Column field="kreiranjeProtivnika" body={this.kreiranjeProtivnikaTemplate} header="Kreiranje protivnika" style={{ width: '7%' }}></Column>
                    <Column field="pregledSvihProtivnika" body={this.pregledSvihProtivnikaTemplate} header="Pregled protivnika" style={{ width: '7%' }}></Column>
                    <Column field="azuriranjeSvihProtivnika" body={this.azuriranjeSvihProtivnikaTemplate} header="Ažuriranje protivnika" style={{ width: '7%' }}></Column>

                    <Column field="kreiranjePredmeta" body={this.kreiranjePredmetaTemplate} header="Kreiranje predmeta" style={{ width: '7%' }}></Column>
                    <Column field="pregledSvihPredmeta" body={this.pregledSvihPredmetaTemplate} header="Pregled predmeta" style={{ width: '7%' }}></Column>
                    <Column field="azuriranjeSvihPredmeta" body={this.azuriranjeSvihPredmetaTemplate} header="Ažuriranje predmeta" style={{ width: '7%' }}></Column>
                </DataTable>
            </div>
        );
    }

    kreiranjePredmetaTemplate = (tim) => {
        return tim.kreiranjePredmeta === true ? 'Da' : 'Ne';
    };

    kreiranjeKlijenataTemplate = (tim) => {
        return tim.kreiranjeKlijenata === true ? 'Da' : 'Ne';
    };

    kreiranjeProtivnikaTemplate = (tim) => {
        return tim.kreiranjeProtivnika === true ? 'Da' : 'Ne';
    };

    pregledSvihPredmetaTemplate = (tim) => {
        return tim.pregledSvihPredmeta === true ? 'Da' : 'Ne';
    };

    pregledSvihKlijenataTemplate = (tim) => {
        return tim.pregledSvihKlijenata === true ? 'Da' : 'Ne';
    };

    pregledSvihProtivnikaTemplate = (tim) => {
        return tim.pregledSvihProtivnika === true ? 'Da' : 'Ne';
    };

    azuriranjeSvihPredmetaTemplate = (tim) => {
        return tim.azuriranjeSvihPredmeta === true ? 'Da' : 'Ne';
    };

    azuriranjeSvihKlijenataTemplate = (tim) => {
        return tim.azuriranjeSvihKlijenata === true ? 'Da' : 'Ne';
    };

    azuriranjeSvihProtivnikaTemplate = (tim) => {
        return tim.azuriranjeSvihProtivnika === true ? 'Da' : 'Ne';
    };

    rowSelected(item) {
        this.setState({
            editingItem: item,
            editMode: true
        });
    }

    newItemTriggered() {
        this.setState({
            editingItem: {},
            editMode: true
        });
    }

    onTimEditSaveClick() {
        this.setState({
            loading: true,
            editMode: false
        }, async () => {
            let kancelarija = await this.props.onTimoviMount();
            this.setState({
                timovi: kancelarija.timovi,
                loading: false
            });
        });
    }

    onTimEditCancelClick() {
        this.setState({
            editMode: false
        });
    }

    render() {
        let displayDataContent = this.state.editMode
            ? <TimKancelarijeEdit tim={this.state.editingItem} kancelarija={this.props.kancelarija}
                onSaveClick={this.onTimEditSaveClick} onCancelClick={this.onTimEditCancelClick} />
            : this.renderTimovi(this.state.timovi);

        let contents =
            this.state.loading
                ? <p><em>Učitavanje...</em></p>
                : displayDataContent;

        return (
            <div>
                {contents}
            </div>
        );
    }
}
