import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import fetchService from '../FetchService';
import { InputText } from 'primereact/inputtext';

export class BrzaPretragaPredmeta extends Component {
    constructor(props) {
        super(props);

        this.state = {
            predmeti: [],
            typingTimeout: 0,
            filter: '',
        };

        this.filterReference = React.createRef();
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return (
            <div>
                <div className="p-3">
                    <div className="mx-3 mb-3 border-bottom border-primary">
                        <h3><b>Pretraga predmeta</b></h3>
                    </div>

                    <div className="px-3 p-inputgroup flex-1">
                        <Dropdown
                            options={this.state.predmeti}
                            placeholder="Predmet..."
                            emptyMessage="Nema rezultata"
                            filter
                            className="dashboard-dropdown-primary w-full md:w-14rem"
                            dropdownIcon="pi pi-search"
                            itemTemplate={this.predmetTemplate}
                            filterTemplate={this.filterTemplate}
                            onChange={(e) => window.location = '/predmet?id=' + e.value.id}
                            onShow={() => this.filterReference.current.focus()}
                        />
                    </div>
                </div>
            </div>
        );
    }

    predmetTemplate = (predmet) => {
        return `${predmet.redniBroj} ${predmet.brojPredmeta}  ${predmet.imeKlijenta} ${predmet.prezimeKlijenta}`;
    };

    filterTemplate = () => {
        return (
            <div>
                <InputText className="w-100" value={this.state.filter} placeholder="Traži..." ref={this.filterReference}
                    onChange={this.onFilterChange} onKeyDown={this.onFilterKeyDown} />
            </div>
        )
    }

    async loadData() {
        let bodyInput =
        {
            "Rows": 10,
            "Page": 1,
            "SortField": 'redniBroj',
            "SortOrder": 1,
            "Filter": this.state.filter ?? '',
            "TipoviPredmetaIds": [],
            "StatusiPredmetaIds": []
        };

        const response = await fetchService.post('predmetiAdvokataAll', bodyInput);

        this.setState({
            predmeti: response.success ? response.responseValue.predmeti : []
        });
    }

    onFilterChange = (event) => {
        const value = event.target.value;
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            filter: value,
            typingTimeout: setTimeout(function () {
                self.loadData();
            }, 800)
        });
    }

    onFilterKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (this.state.typingTimeout) {
                clearTimeout(this.state.typingTimeout);
            }

            this.setState({
                typingTimeout: 0
            });

            this.loadData();
        }
    }
}
