import authService from './api-authorization/AuthorizeService'

export class FetchService {

    async get(requestUrl) {
        const token = await authService.getAccessToken();
        const headers = !token ? {} : { 'Authorization': `Bearer ${token}` };
        
        const fetchOptions = {
            headers: headers
        };

        const response = await fetch(requestUrl, fetchOptions).then(res => {
            return this.handleResponse(res);
        });

        return response;
    }

    async post(requestUrl, bodyInput) {
        return await this.save(requestUrl, bodyInput, 'POST');
    }

    async put(requestUrl, bodyInput) {
        return await this.save(requestUrl, bodyInput, 'PUT');
    }

    async save(requestUrl, bodyInput, method) {
        let stringifiedBodyInput = JSON.stringify(bodyInput);
        let headers = { 'Content-Type': 'application/json' };

        return await this.saveForm(requestUrl, stringifiedBodyInput, method, headers);
    }

    async saveForm(requestUrl, bodyInput, method, headers) {
        if (!headers) {
            headers = {};
        }
        const token = await authService.getAccessToken();
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        const fetchOptions = {
            headers: headers,
            method: method,
            body: bodyInput
        };

        const response = await fetch(requestUrl, fetchOptions).then(res => {
            return this.handleResponse(res);
        });

        return response;
    }

    async delete(requestUrl) {
        let headers = { 'Content-Type': 'application/json' };
        const token = await authService.getAccessToken();
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        const fetchOptions = {
            headers: headers,
            method: 'DELETE'
        };

        const response = await fetch(requestUrl, fetchOptions).then(res => {
            return this.handleResponse(res);
        });

        return response;
    }

    async handleResponse(response) {
        let actionResponse = {
            success: false,
            message: '',
            responseValue: null
        };

        if (response.status == 401) {
            if (this.shouldRedirectToLoginPage(response)) {
                localStorage.clear();
                setTimeout(async () => {
                    window.location = '/authentication/login';
                }, 1000);
            }

            actionResponse = {
                success: false,
                message: 'Unauthorized access',
                responseValue: null
            };
        }

        else if (response.status >= 400) {
            let textResult = await response.text();
            let jsonResult = textResult.length ? JSON.parse(textResult) : {};

            actionResponse = {
                success: false,
                message: jsonResult.errorMessage,
                responseValue: null
            };
        }

        else {
            let textResult = await response.text();
            let jsonResult = textResult.length ? JSON.parse(textResult) : {};

            if (!response?.ok) {
                actionResponse = {
                    success: false,
                    message: jsonResult.errorMessage,
                    responseValue: jsonResult
                };
            }
            else {
                actionResponse = {
                    success: true,
                    message: '',
                    responseValue: jsonResult
                };
            }
        }
        
        return actionResponse;
    }

    async getBlob(requestUrl, bodyInput, method) {
        const token = await authService.getAccessToken();
        let headers = !token ? {} : { 'Authorization': `Bearer ${token}` };

        let fetchOptions = { };

        if (bodyInput) {
            let stringifiedBodyInput = JSON.stringify(bodyInput);
            fetchOptions['body'] = stringifiedBodyInput;

            headers['Content-Type'] = 'application/json';
        }

        if (method) {
            fetchOptions['method'] = method;
        }

        fetchOptions['headers'] = headers;

        const response = await fetch(requestUrl, fetchOptions).then(res => {
            return this.handleBlobResponse(res);
        });

        return response;
    }

    async handleBlobResponse(response) {
        let actionResponse = {
            success: false,
            message: '',
            responseValue: null
        };


        if (response.status == 401) {
            setTimeout(async () => {
                window.location = '/authentication/login';
            }, 1000);

            actionResponse = {
                success: false,
                message: 'Unauthorized access',
                responseValue: null
            };
        }

        else if (response.status >= 400) {
            let textResult = await response.text();
            let jsonResult = textResult.length ? JSON.parse(textResult) : {};

            actionResponse = {
                success: false,
                message: jsonResult.errorMessage,
                responseValue: null
            };
        }

        else {
            if (!response?.ok) {
                let textResult = await response.text();
                let jsonResult = textResult.length ? JSON.parse(textResult) : {};

                actionResponse = {
                    success: false,
                    message: jsonResult.errorMessage,
                    responseValue: jsonResult
                };
            }
            else {
                let blobResult = await response.blob();

                actionResponse = {
                    success: true,
                    message: '',
                    responseValue: blobResult
                };
            }
        }

        return actionResponse;
    }

    shouldRedirectToLoginPage(response) {
        if (response.url.toUpperCase().includes('RADNAKANCELARIJAADVOKATA')) {
            return false;
        }
        return true;
    }
}

const fetchService = new FetchService();

export default fetchService;
