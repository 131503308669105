import React, { Component } from 'react';
import fetchService from '../FetchService';
import { Toast } from 'primereact/toast';
import CloseLineWhiteIcon from '../icons/close-line-white.svg';
import SaveLineWhiteIcon from '../icons/save-line-white.svg';
import DeleteBinLineWhiteIcon from '../icons/delete-bin-line-white.svg';
import AddLineWhiteIcon from '../icons/add-line-white.svg';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
import { DateTimeService } from '../DateTimeService';
import { HelpTooltip } from '../HelpTooltip';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { KlijentOsnovniPodaciEdit } from '../klijenti/KlijentOsnovniPodaciEdit';
import { ProtivnikOsnovniPodaciEdit } from '../protivnici/ProtivnikOsnovniPodaciEdit';
import { ItemPermissions } from '../permissions/ItemPermissions';

export class OsnovniPodaciEdit extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();
        this.formRef = React.createRef();
        this.filterKlijentiReference = React.createRef();
        this.filterProtivniciReference = React.createRef();

        this.state = {
            loading: true,
            predmet: {
                id: '',
                klijentAdvokataId: '',
                brojPredmeta: '',
                opis: '',
                trenutniStatusPredmetaId: 1,
                tipPredmetaId: '',
                redniBroj: '',
                brziPristup: false,
                tipSuda: 1,
                tipSudaDodatak: '',
                upisnik: '',
                mesto: '',
                godina: null,
                protivnikId: '',
                sudnica: '',
                sudija: '',
                kancelarijaTimInfos: [],
                nosilacId: ''
            },
            timovi: [],

            imePrezimeKlijenta: '',
            klijentiAdvokata: [],
            noviKlijent: { isPravnoLice: false, kancelarijaTimInfos: [] },
            noviKlijentVisible: false,
            originalKlijentId: null,

            imePrezimeProtivnika: '',
            protivnici: [],
            noviProtivnik: { isPravnoLice: false, kancelarijaTimInfos: [] },
            noviProtivnikVisible: false,
            originalProtivnikId: null,

            filterKlijenti: '',
            filterProtivnici: ''
        };

        this.saveDataToApi = this.saveDataToApi.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }

    async componentDidMount() {
        let originalKlijentId = this.props.predmet.id ? this.props.predmet.klijentAdvokataId : null;
        let originalProtivnikId = this.props.predmet.id ? this.props.predmet.protivnikId : null;

        this.setState({
            predmet: { ...this.props.predmet, godina: DateTimeService.getFirstDateOfYear(this.props.predmet.godina) },
            imePrezimeKlijenta: this.props.imePrezimeKlijenta,
            originalKlijentId: originalKlijentId,
            originalProtivnikId: originalProtivnikId,
            timovi: JSON.parse(JSON.stringify(this.props.predmet.kancelarijaTimInfos)),
            loading: false
        }, () => { this.loadKlijenti(); this.loadProtivnici(); });

        if (!this.props.predmet.id && this.props.klijentAdvokataId) {
            this.setState({
                predmet: { ...this.state.predmet, klijentAdvokataId: this.props.klijentAdvokataId }
            })
        }
    }

    async saveDataToApi() {
        if (!this.formRef.current.checkValidity()) {
            this.formRef.current.classList.add('was-validated');
            return;
        }

        let kancelarijaTims = this.state.timovi.filter(function (el) {
            if (el.kancelarijaTimId)
                return el;
        });

        const bodyInput = {
            "Id": this.state.predmet.id,
            "KancelarijaTimInfos": kancelarijaTims
        };
        let responseValidated = await fetchService.post('validatePredmet', bodyInput);

        if (!responseValidated.success) {
            let errorMessage = responseValidated.message ? 'Greška: ' + responseValidated.message : '';
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom validacije predmeta. " + errorMessage, sticky: true });
            return;
        }

        if (responseValidated.responseValue === true) {
            await this.saveDataToApiFinalize();
        }
    }

    async saveDataToApiFinalize() {
        this.setState({ loading: true });

        let isNewItem = this.state.predmet.id ? false : true;
        const method = this.state.predmet.id ? 'PUT' : 'POST';

        let kancelarijaTims = this.state.timovi.filter(function (el) {
            if (el.kancelarijaTimId)
                return el;
        });

        const bodyInput = {
            "PredmetId": this.state.predmet.id,
            "KlijentAdvokataId": this.state.predmet.klijentAdvokataId,
            "BrojPredmeta": this.state.predmet.brojPredmeta,
            "Opis": this.state.predmet.opis,
            "TrenutniStatusPredmetaId": this.state.predmet.trenutniStatusPredmetaId,
            "TipPredmetaId": this.state.predmet.tipPredmetaId === '' ? null : this.state.predmet.tipPredmetaId,
            "BrziPristup": this.state.predmet.brziPristup,
            "TipSuda": this.state.predmet.tipSuda,
            "TipSudaDodatak": this.state.predmet.tipSudaDodatak,
            "Upisnik": this.state.predmet.upisnik,
            "Mesto": this.state.predmet.mesto,
            "Godina": this.state.predmet.godina?.getFullYear(),
            "ProtivnikId": this.state.predmet.protivnikId === '' ? null : this.state.predmet.protivnikId,
            "Sudnica": this.state.predmet.sudnica,
            "Sudija": this.state.predmet.sudija,
            "KancelarijaTimInfos": kancelarijaTims,
            "NosilacId": this.state.predmet.nosilacId
        };

        if (this.state.predmet.kopijaPredmetaId) {
            const copyZapisi = (new URLSearchParams(document.location.search)).get('copyZapisi');
            const copyRocista = (new URLSearchParams(document.location.search)).get('copyRocista');
            const copyRokovi = (new URLSearchParams(document.location.search)).get('copyRokovi');
            const copyDokumenta = (new URLSearchParams(document.location.search)).get('copyDokumenta');
            const copyRacuni = (new URLSearchParams(document.location.search)).get('copyRacuni');

            bodyInput['KopijaPredmeta'] = {
                "KopijaPredmetaId": this.state.predmet.kopijaPredmetaId,
                "Zapisi": copyZapisi ? (copyZapisi.toLowerCase() === 'true') : false,
                "Rocista": copyRocista ? (copyRocista.toLowerCase() === 'true') : false,
                "Rokovi": copyRokovi ? (copyRokovi.toLowerCase() === 'true') : false,
                "Dokumenta": copyDokumenta ? (copyDokumenta.toLowerCase() === 'true') : false,
                "Racuni": copyRacuni ? (copyRacuni.toLowerCase() === 'true') : false
            };
        }

        let response = await fetchService.save('predmetiAdvokata', bodyInput, method);

        this.setState({ loading: false });

        if (response.success) {
            if (isNewItem) {
                window.location = '/predmet?id=' + response.responseValue.id;
                return;
            }

            this.props.onSaveClick(response.responseValue);
        } else {
            let errorMessage = response.message ? 'Greška: ' + response.message : '';
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom čuvanja predmeta. " + errorMessage, sticky: true });
        }
    }

    async deleteItem() {
        this.setState({ loading: true });

        let response = await fetchService.delete(`predmetiAdvokata?predmetId=${this.state.predmet.id}`);

        this.setState({ loading: false });

        if (!response.success) {
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom brisanja predmeta. Greška: " + response.message, sticky: true });
        }
        else {
            window.location = '/predmeti';
        }
    }

    cancelEdit() {
        if (this.state.predmet.id) {
            this.props.onCancelClick();
            return;
        }

        if (this.props.klijentAdvokataId) {
            window.location = '/klijent?id=' + this.props.klijentAdvokataId;
            return;
        }

        window.location = '/predmeti';
    }

    confirmDelete(event) {
        confirmDialog({
            trigger: event.currentTarget,
            message: 'Da li želite da obrišete ovaj predmet?',
            header: 'Brisanje predmeta',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => { this.deleteItem() },
            acceptLabel: 'Da',
            rejectLabel: 'Ne'
        });
    };

    renderForm() {
        return (
            <div>
                <div className="mb-3 form-header">
                    <button className="btn btn-primary btns" onClick={this.saveDataToApi}>
                        <img alt="" src={SaveLineWhiteIcon} />
                        &nbsp;
                        Sačuvaj
                    </button>
                    <button hidden={this.state.predmet.id ? false : true} className="btn btn-danger btns" onClick={this.confirmDelete}>
                        <img alt="" src={DeleteBinLineWhiteIcon} />
                        &nbsp;
                        Briši
                    </button>
                    <button className="btn btn-secondary btns" onClick={this.cancelEdit}>
                        <img alt="" src={CloseLineWhiteIcon} />
                        &nbsp;
                        Odustani
                    </button>
                </div>

                <form className='form-group' ref={this.formRef}>
                    <div className="form-container">
                        <div className="row">
                            <div className="mb-3 col-md-6">
                                <label htmlFor="imePrezimeKlijenta" className="form-label">Klijent *</label>

                                <button className="btn btn-secondary btns mx-4 mb-2 py-1" onClick={(e) => { this.setState({ noviKlijentVisible: true }); e.preventDefault(); }}>
                                    <img alt="" src={AddLineWhiteIcon} />
                                </button>

                                <Dropdown id="imePrezimeKlijenta"
                                    value={this.state.predmet.klijentAdvokataId}
                                    options={this.state.klijentiAdvokata}
                                    optionValue="id"
                                    emptyMessage="Nema rezultata"
                                    filter
                                    showClear
                                    required
                                    className="w-full p-dropdown-fullwidth"
                                    optionLabel="imePrezime"
                                    filterTemplate={this.filterTemplateKlijenti}
                                    onChange={(e) => {
                                        this.setState({ predmet: { ...this.state.predmet, klijentAdvokataId: e.target.value } });
                                    }}
                                    onShow={() => this.filterKlijentiReference.current.focus()}
                                    onHide={() => this.setState({ filterKlijenti: null }, () => this.loadKlijenti())}
                                />
                            </div>

                            <div className="mb-3 col-md-6">
                                <label htmlFor="imePrezimeProtivnika" className="form-label">Protivnik</label>

                                <button className="btn btn-secondary btns mx-4 mb-2 py-1" onClick={(e) => { this.setState({ noviProtivnikVisible: true }); e.preventDefault(); }}>
                                    <img alt="" src={AddLineWhiteIcon} />
                                </button>

                                <Dropdown id="imePrezimeProtivnika"
                                    value={this.state.predmet.protivnikId}
                                    options={this.state.protivnici}
                                    optionValue="id"
                                    emptyMessage="Nema rezultata"
                                    filter
                                    showClear
                                    className="w-full p-dropdown-fullwidth"
                                    optionLabel="imePrezime"
                                    filterTemplate={this.filterTemplateProtivnici}
                                    onChange={(e) => {
                                        this.setState({ predmet: { ...this.state.predmet, protivnikId: e.target.value } });
                                    }}
                                    onShow={() => this.filterProtivniciReference.current.focus()}
                                    onHide={() => this.setState({ filterProtivnici: null }, () => this.loadProtivnici())}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="mb-3 col-md-4">
                                <label htmlFor="tipSuda" className="form-label">Vrsta suda</label>
                                <div>
                                    <Dropdown id="tipSuda"
                                        value={this.state.predmet.tipSuda}
                                        onChange={(e) => this.setState({ predmet: { ...this.state.predmet, tipSuda: e.target.value } })}
                                        options={this.props.tipoviSuda}
                                        optionLabel="naziv"
                                        optionValue="id"
                                        className="w-full p-dropdown-fullwidth" />
                                </div>
                            </div>

                            <div className="mb-3 col-md-4">
                                <label htmlFor="tipSudaDodatak" className="form-label">Vrsta suda dodatak</label>
                                <HelpTooltip>
                                    Dodatni opis vrste suda, naročito ako je izabrana vrsta suda 'Neodređen'.
                                </HelpTooltip>
                                <input className="form-control" id="tipSudaDodatak" value={this.state.predmet.tipSudaDodatak ?? ''}
                                    maxLength="40"
                                    onChange={(e) => this.setState({ predmet: { ...this.state.predmet, tipSudaDodatak: e.target.value } })} />
                            </div>

                            <div className="mb-3 col-md-4">
                                <label htmlFor="mesto" className="form-label">Mesto</label>
                                <input className="form-control" id="mesto" value={this.state.predmet.mesto ?? ''}
                                    maxLength="50"
                                    onChange={(e) => this.setState({ predmet: { ...this.state.predmet, mesto: e.target.value } })} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="mb-3 col-md-4">
                                <label htmlFor="upisnik" className="form-label">Upisnik</label>
                                <input className="form-control" id="upisnik" value={this.state.predmet.upisnik ?? ''}
                                    maxLength="20"
                                    onChange={(e) => this.setState({ predmet: { ...this.state.predmet, upisnik: e.target.value } })} />
                            </div>

                            <div className="mb-3 col-md-4">
                                <label htmlFor="brojPredmeta" className="form-label">Broj predmeta *</label>
                                <input required className="form-control" id="brojPredmeta" value={this.state.predmet.brojPredmeta ?? ''}
                                    maxLength="20"
                                    onChange={(e) => this.setState({ predmet: { ...this.state.predmet, brojPredmeta: e.target.value } })} />
                            </div>

                            <div className="mb-3 col-md-4">
                                <label htmlFor="godina" className="form-label">Godina</label>
                                <div>
                                    <Calendar id="godina" className='control-max-width-200' value={this.state.predmet.godina ?? ''}
                                        onChange={(e) => this.setState({ predmet: { ...this.state.predmet, godina: e.target.value } })}
                                        view="year" dateFormat="yy" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="mb-3 col-md-4">
                                <label htmlFor="sudija" className="form-label">Sudija</label>
                                <input className="form-control" id="sudija" value={this.state.predmet.sudija ?? ''}
                                    maxLength="200"
                                    onChange={(e) => this.setState({ predmet: { ...this.state.predmet, sudija: e.target.value } })} />
                            </div>

                            <div className="mb-3 col-md-4">
                                <label htmlFor="sudnica" className="form-label">Sudnica</label>
                                <input className="form-control" id="sudnica" value={this.state.predmet.sudnica ?? ''}
                                    maxLength="20"
                                    onChange={(e) => this.setState({ predmet: { ...this.state.predmet, sudnica: e.target.value } })} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="mb-3 col-md-4">
                                <label htmlFor="tipPredmetaId" className="form-label">Tip predmeta</label>
                                <HelpTooltip>
                                    <p className="mb-3">
                                        Tip predmeta iz prethodno definisane liste.
                                    </p>
                                    <p>
                                        Predmete možete kategorizovati po tipu. Svakom predmetu možete dodeliti po jedan tip, i kasnije vršiti lakšu pretragu i filtriranje.
                                    </p>
                                    <p>
                                        Tipovi predmeta se mogu definisati u administrativnom delu osnovnih podataka aplikacije.
                                    </p>
                                </HelpTooltip>
                                <div>
                                    <Dropdown id="tipPredmetaId"
                                        value={this.state.predmet.tipPredmetaId}
                                        onChange={(e) => this.setState({ predmet: { ...this.state.predmet, tipPredmetaId: e.target.value } })}
                                        options={this.props.tipoviPredmeta}
                                        optionLabel="naziv"
                                        optionValue="id"
                                        filter
                                        showClear
                                        className="w-full p-dropdown-fullwidth" />
                                </div>
                            </div>

                            <div className="mb-3 col-md-4">
                                <label htmlFor="nosilacPredmetaId" className="form-label">Nosilac predmeta</label>
                                <HelpTooltip>
                                    <p className="mb-3">
                                        Advokat kancelarije koji je zadužen za predmet.
                                    </p>
                                </HelpTooltip>
                                <div>
                                    <Dropdown id="nosilacPredmetaId"
                                        value={this.state.predmet.nosilacId}
                                        onChange={(e) => this.setState({ predmet: { ...this.state.predmet, nosilacId: e.target.value } })}
                                        options={this.props.nosiociPredmeta}
                                        optionLabel="imePrezime"
                                        optionValue="id"
                                        filter
                                        showClear
                                        className="w-full p-dropdown-fullwidth" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="mb-3 col-md-4">
                                <label htmlFor="trenutniStatusPredmetaId" className="form-label">Status</label>
                                <div>
                                    <Dropdown id="trenutniStatusPredmetaId"
                                        value={this.state.predmet.trenutniStatusPredmetaId}
                                        onChange={(e) => this.setState({ predmet: { ...this.state.predmet, trenutniStatusPredmetaId: e.target.value } })}
                                        options={this.props.statusiPredmeta}
                                        optionLabel="naziv"
                                        optionValue="id"
                                        className="w-full" />
                                </div>
                            </div>
                            <div className="mb-3 col-md-4">
                                <div>
                                    <label htmlFor="brziPristup" className="form-label">Brzi pristup na početnom ekranu</label>
                                    <HelpTooltip>
                                        <p className="mb-3">
                                            Izaberite ovu opciju ukoliko želite da predmet bude prikazan na početnom ekranu u sekciji 'Izabrani predmeti'.
                                        </p>
                                        <p>
                                            Predlog je da se na tom spisku prikazuju trenutno aktuelni predmeti kako biste im brzo pristupili.
                                        </p>
                                    </HelpTooltip>
                                </div>
                                <div>
                                    <InputSwitch id="brziPristup" checked={this.state.predmet.brziPristup} className="form-control"
                                        onChange={(e) => this.setState({ predmet: { ...this.state.predmet, brziPristup: e.value } })} />
                                </div>
                            </div>

                            <div className="mb-3 col-sm-4" hidden={this.state.predmet.id ? '' : 'hidden'} >
                                <label htmlFor="redniBroj" className="form-label">Redni broj</label>
                                <HelpTooltip>
                                    Automatski dodeljen redni broj predmetu od početka korišćenja aplikacije za nalog korisnika koji je krerirao predmet.
                                </HelpTooltip>
                                <input readOnly className="form-control" id="redniBroj" value={this.state.predmet.redniBroj ?? ''} />
                            </div>
                        </div>



                        <div className="mb-3">
                            <label htmlFor="opis" className="form-label">Opis</label>
                            <HelpTooltip>
                                Dodatni komentar za predmet, koji može da se ažurira u svakom trenutku.
                            </HelpTooltip>
                            <textarea className="form-control" id="opis" value={this.state.predmet.opis ?? ''} rows="3"
                                onChange={(e) => this.setState({ predmet: { ...this.state.predmet, opis: e.target.value } })}>
                            </textarea>
                        </div>
                    </div>
                </form>

                <div className="form-container">
                    <div className="mb-3">
                        <hr />
                        <label className="form-label">Dozvola pristupa</label>
                        <ItemPermissions items={this.state.timovi}
                            onAddNewItem={() => this.addNewPermissionItem()}
                            onDeleteItem={(id) => this.deleteNewPermissionItem(id)}
                            editMode={true} scope={2}
                        />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : this.renderForm();

        return (
            <div>
                {contents}
                <Toast ref={this.toastRef} />
                <ConfirmDialog />

                <Dialog
                    header="Novi klijent" visible={this.state.noviKlijentVisible} style={{ width: '75vw' }} onHide={() => this.setState({ noviKlijentVisible: false })}>
                    <KlijentOsnovniPodaciEdit externalNewEntry={true}
                        klijent={this.state.noviKlijent}
                        onCancelClick={() => this.setState({ noviKlijentVisible: false })}
                        onSaveClick={(noviKlijent) => this.noviKlijentSaved(noviKlijent)}
                    />
                </Dialog>

                <Dialog
                    header="Novi protivnik" visible={this.state.noviProtivnikVisible} style={{ width: '75vw' }} onHide={() => this.setState({ noviProtivnikVisible: false })}>
                    <ProtivnikOsnovniPodaciEdit externalNewEntry={true}
                        protivnik={this.state.noviProtivnik}
                        onCancelClick={() => this.setState({ noviProtivnikVisible: false })}
                        onSaveClick={(noviProtivnik) => this.noviProtivnikSaved(noviProtivnik)}
                    />
                </Dialog>
            </div>
        );
    }

    noviKlijentSaved(noviKlijent) {
        this.setState({
            predmet: { ...this.state.predmet, klijentAdvokataId: noviKlijent.id },
            noviKlijentVisible: false
        }, () => { this.loadKlijenti() });
    }

    async loadKlijenti() {
        let rows = 10;
        let page = 1;
        let sortField = 'ime';
        let sortOrder = 1;
        let filter = this.state.filterKlijenti ?? '';

        let selectedItemId = this.state.filterKlijenti ? null : this.state.predmet.klijentAdvokataId;
        let originalItemId = this.state.originalKlijentId;

        let requestUrl = `klijentiAdvokataShortSummary?rows=${rows}&page=${page}&sortField=${sortField}&sortOrder=${sortOrder}&filter=${filter}&selectedItemId=${selectedItemId}&originalItemId=${originalItemId}`;
        const response = await fetchService.get(requestUrl);

        const data = response.success ? response.responseValue : [];

        this.setState({
            klijentiAdvokata: data.klijentiAdvokata
        });

        return data.klijentiAdvokata;
    }

    noviProtivnikSaved(noviProtivnik) {
        this.setState({
            predmet: { ...this.state.predmet, protivnikId: noviProtivnik.id },
            noviProtivnikVisible: false
        }, () => { this.loadProtivnici() });
    }

    async loadProtivnici() {
        let rows = 10;
        let page = 1;
        let sortField = 'ime';
        let sortOrder = 1;
        let filter = this.state.filterProtivnici ?? '';

        let selectedItemId = this.state.filterProtivnici ? null : this.state.predmet.protivnikId;
        let originalItemId = this.state.originalProtivnikId;

        let requestUrl = `protivniciShortSummary?rows=${rows}&page=${page}&sortField=${sortField}&sortOrder=${sortOrder}&filter=${filter}&selectedItemId=${selectedItemId}&originalItemId=${originalItemId}`;
        const response = await fetchService.get(requestUrl);

        const data = response.success ? response.responseValue : [];

        this.setState({
            protivnici: data.protivnici
        });

        return data.protivnici;
    }

    filterTemplateKlijenti = () => {
        return (
            <div>
                <InputText className="w-100" placeholder="Traži..." ref={this.filterKlijentiReference}
                    onChange={this.onFilterKlijentChange} onKeyDown={this.onFilterKlijentKeyDown} />
            </div>
        )
    }

    onFilterKlijentChange = (event) => {
        const value = event.target.value;
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            filterKlijenti: value,
            typingTimeout: setTimeout(function () {
                self.loadKlijenti();
            }, 800)
        });
    }

    onFilterKlijentKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (this.state.typingTimeout) {
                clearTimeout(this.state.typingTimeout);
            }

            this.setState({
                typingTimeout: 0
            });

            this.loadKlijenti();
        }
    }

    filterTemplateProtivnici = () => {
        return (
            <div>
                <InputText className="w-100" placeholder="Traži..." ref={this.filterProtivniciReference}
                    onChange={this.onFilterProtivnikChange} onKeyDown={this.onFilterProtivnikKeyDown} />
            </div>
        )
    }

    onFilterProtivnikChange = (event) => {
        const value = event.target.value;
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            filterProtivnici: value,
            typingTimeout: setTimeout(function () {
                self.loadProtivnici();
            }, 800)
        });
    }

    onFilterProtivnikKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (this.state.typingTimeout) {
                clearTimeout(this.state.typingTimeout);
            }

            this.setState({
                typingTimeout: 0
            });

            this.loadProtivnici();
        }
    }

    addNewPermissionItem() {
        let newPermissionItem = {
            id: Math.floor(Math.random() * -1000000).toString(),
            naziv: 'Izaberi tim...',
            pregled: true,
            azuriranje: false
        };

        this.setState({
            timovi: [
                ...this.state.timovi,
                newPermissionItem
            ]
        })
    }

    deleteNewPermissionItem(id) {
        let newArray = this.state.timovi.filter(function (el) {
            return el.id !== id;
        });

        this.setState({
            timovi: newArray
        })
    }
}
