import React, { Component } from 'react';
import PenciLineWhiteIcon from '../icons/pencil-line-white.svg';
import ArrowGoBackWhiteIcon from '../icons/arrow-go-back-white.svg';
import { InputSwitch } from 'primereact/inputswitch';

export class TipRacunaOsnovniPodaciView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tipRacuna: {
                id: '',
                naziv: '',
                aktivan: true
            }
        };
    }

    componentDidMount() {
        this.setState({
            tipRacuna: this.props.tipRacuna
        });
    }

    render() {
        return (
            <div>
                <div className="mb-3 form-header">
                    <button className="btn btn-primary btns" onClick={this.props.onAzurirajClick}>
                        <img alt="" src={PenciLineWhiteIcon} />
                        &nbsp;
                        Ažuriraj
                    </button>
                    <a className="btn btn-primary btns" href="/tipovi-racuna">
                        <img alt="" src={ArrowGoBackWhiteIcon} />
                        &nbsp;
                        Tipovi računa
                    </a>
                </div>

                <div className="form-container">
                    <div className="mb-3">
                        <label htmlFor="naziv" className="form-label">Naziv</label>
                        <input readOnly className="form-control" id="naziv" value={this.state.tipRacuna.naziv ?? ''} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="aktivan" className="form-label">Aktivan</label>
                        &nbsp;&nbsp;
                        <InputSwitch disabled id="aktivan" checked={this.state.tipRacuna.aktivan} className="form-control" />
                    </div>
                </div>
            </div>
        );
    }
}
