import React, { Component } from 'react';
import { Sidebar } from 'primereact/sidebar';
import LogoBlackWithTitleIcon from '../logo/logo-black-with-title.png';
import MenuIcon from './menu.svg';
import './home.css';

export class TutorijalHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuVisible: false
        };
    }

    render() {
        return (
            <div>
                {this.renderMenu()}
            </div>
        );
    }

    renderMenu() {
        return (
            <div className="container land-page-menu">
                <header className="d-flex flex-wrap justify-content-between py-3 mb-4 border-bottom">
                    <a href="/" className="d-flex align-items-center mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                        <img alt="" src={LogoBlackWithTitleIcon} className='home-logo' />
                    </a>

                    <ul className="nav nav-pills hide-on-mobile mt-1 lead">
                        <li className="nav-item"><a href="/" className="nav-link">Početak</a></li>
                        
                        <li className="nav-item"><a href="/Identity/Account/Login?isDemoView=1&ReturnUrl=/dashboard" className="nav-link active" style={{ marginLeft: '2rem' }}>Isprobaj demo</a></li>
                        <li className="nav-item"><a href="dashboard" className="nav-link active" style={{ marginLeft: '2rem' }}>Prijavi se</a></li>
                        <li className="nav-item"><a href="/Identity/Account/Register" className="nav-link active" style={{ marginLeft: '2rem' }}>Napravi nalog</a></li>
                    </ul>


                    <button className="navbar-toggler show-on-mobile" type="button"
                        onClick={() => { this.setState({ menuVisible: true }); }}>
                        <span className="navbar-toggler-icon"><img alt="" src={MenuIcon} /></span>
                    </button>

                    <Sidebar className='navbar-sidebar' visible={this.state.menuVisible} position="right" onHide={() => { this.setState({ menuVisible: false }); }}>
                        <ul className="nav nav-pills nav-menu-list lead">
                            <li className="nav-item mb-3"><a href="/" className="nav-link" onClick={() => { this.setState({ menuVisible: false }); }}>Početak</a></li>
                            <hr />
                            <li className="nav-item mb-3"><a href="/Identity/Account/Login?isDemoView=1&ReturnUrl=/dashboard" className="nav-link active">Isprobaj demo</a></li>
                            <hr />
                            <li className="nav-item mb-3"><a href="/dashboard" className="nav-link active">Prijavi se</a></li>
                            <li className="nav-item"><a href="/Identity/Account/Register" className="nav-link active">Napravi nalog</a></li>
                        </ul>
                    </Sidebar>
                </header>
            </div>
        );
    }
}
