import React, { Component } from 'react';
import fetchService from '../FetchService';
import { TipPredmetaOsnovniPodaciContainer } from './TipPredmetaOsnovniPodaciContainer';
    
export class TipPredmetaContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            tipPredmetaId: null,
            tipPredmeta: {
                id: '',
                naziv: '',
                aktivan: true
            },
            predmeti: null
        };
    }

    componentDidMount() {
        const id = (new URLSearchParams(document.location.search)).get('id');
        this.setState(
            {
                tipPredmetaId: id ?? ""
            },
            () => this.loadDataFromApi()
                .then(() => { this.setState({ loading: false }); })
        );
    }

    async loadDataFromApi() {
        if (!this.state.tipPredmetaId) return;

        let requestUrl = "tipoviPredmeta/" + this.state.tipPredmetaId;
        const response = await fetchService.get(requestUrl);

        const data = response.success ? response.responseValue : {};

        this.setState({
            tipPredmeta: data
        });
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : <TipPredmetaOsnovniPodaciContainer tipPredmeta={this.state.tipPredmeta} onTipPredmetaUpdate={(tipPredmeta) => this.setState({ tipPredmeta: tipPredmeta })} />;

        let title = this.state.tipPredmetaId
            ? `Tip predmeta ${this.state.tipPredmeta.naziv}`
            : 'Novi tip predmeta';

        return (
            <div>
                <h2 id="tabelLabel" className='mb-3 page-title'>{title}</h2>
                {contents}
            </div>
        );
    }
}
