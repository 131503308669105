import React, { Component } from 'react';
import './home.css';

export class StaJeZapisnik extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.renderSection2()}
            </div>
        );
    }

    renderSection2() {
        return (
            <div className="container my-5 px-5 py-3">
                
                    
                        <h2 className="display-5 fw-bold lh-1 text-body-emphasis pb-2 mb-4 border-bottom">Šta je Zapisnik?</h2>

                        <div className='text-center mb-5'>
                            <p className="lead mb-3">Zapisnik je aplikacija za advokate koja pomaže digitalizaciju poslovanja.</p>
                            <p className="lead mb-3">Smanjuje papirologiju i administrativne poslove.</p>
                            <p className="lead mb-3">Pomaže ti da se fokusiraš na bitne stvari!</p>
                        </div>

                        <div>
                            <ul className='checked-circle-list lead'>
                                <li className='mb-2'>
                                    Lakše i preglednije vođenje predmeta
                                </li>
                                <li className='mb-2'>
                                    Prikaz svih ročišta i rokova koji slede
                                </li>
                                <li className='mb-2'>
                                    Jednostavan unos podataka u aplikaciju
                                </li>
                                <li className='mb-2'>
                                    Brza pretraga podataka uz pomoć filtera
                                </li>
                                <li className='mb-2'>
                                    Brzo i jednostavno dodavanje dokumenata
                                </li>
                                <li className='mb-2'>
                                    Mogućnost modifikacije aplikacije shodno Vašim potrebama
                                </li>
                            </ul>
                        </div>
                    
                
            </div>
        );
    }
    
}
