import React, { Component } from 'react';

export class LogoutSuccess extends Component {
    componentDidMount() {
        setTimeout(() => {
            window.location = '/';
        }, "1000");
    }

    render() {
        return (
            <div>
                <div className="form-container">
                    <p>Uspešno ste se odjavili.</p>
                </div>
            </div>
        );
    }
}
