import React, { Component } from 'react';
import fetchService from '../FetchService';
import { Toast } from 'primereact/toast';
import './home.css';
import { Dialog } from 'primereact/dialog';

import LinkedinLogo from './linkedin-fill.svg';
import PhoneIcon from './phone.png';
import EnvelopeIcon from './envelope.png';
import LogoBlackWithTitleIcon from '../logo/logo-black-with-title.png';

export class Kontakt extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();
        this.formRef = React.createRef();

        this.state = {
            currentYear: new Date().getFullYear(),
            formVisible: false,
            ime: '',
            telefon: '',
            email: '',
            komentar: '',
            popupTitle: '',
            kontaktType: 0
        }

        this.saveDataToApi = this.saveDataToApi.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
    }

    render() {
        return (
            <div id='section-contact' className="container my-5 px-5 col-md-9">
                <h2 className="display-5 fw-bold lh-1 text-body-emphasis pb-2 mb-4 border-bottom">Kontaktiraj nas</h2>
                <footer className="py-2">
                    <div className="row lead mb-3">

                        <div className="col-lg-7 mb-5">
                            <div className='mb-5'>
                                <div className='mb-4'>
                                    <img alt="" src={PhoneIcon} width='30px' />
                                    <span className='mx-3'>+381 (0) 63 498003</span>
                                </div>

                                <div>
                                    <img alt="" src={EnvelopeIcon} width='30px' />
                                    <span className='mx-3'><a href='mailto:info@zapisnik.rs'>info@zapisnik.rs</a></span>
                                </div>
                            </div>

                            <div className='mb-5'>
                                <h2 className="display-6 lh-1 text-body-emphasis pb-2 pt-3 mb-4">Radno vreme</h2>
                                <div className='fw-bold' style={{ color: '#CA9442' }}>Ponedeljak - Petak</div>
                                <div>9:00 - 18:00</div>
                                <div>&nbsp;</div>
                                <div className='fw-bold' style={{ color: '#CA9442' }}>Subota</div>
                                <div>9:00 - 12:00</div>
                            </div>

                            <div>
                                <span className='fw-bold'>Društvene mreže</span>
                                <a className="link-body-emphasis px-3" href=""><img alt="" src={LinkedinLogo} /></a>
                            </div>
                        </div>

                        <div className="col-lg-5 mb-4">
                            {/*<h2 className="display-6 lh-1 text-body-emphasis pb-2 mb-4 text-center">Imaš pitanje za nas?</h2>*/}
                            <div className='lead text-center mb-5'>
                                <p>
                                    Ukoliko imaš pitanje za nas, popuni formu klikom na dugme i javićemo ti se u najkraćem roku!
                                </p>

                                <div className="pt-3 text-center" >
                                    <button className="btn btn-primary btn-lg px-4 me-md-2 home-screen-button" onClick={() => this.openPopup('Kontakt forma', 1)}>Popuni formu</button>
                                </div>
                            </div>

                            <div className='lead text-center mb-5'>
                                <p>
                                    Prijavi se za online prezentaciju kako bi saznao više o Zapisnik.rs
                                </p>

                                <div className="pt-3 text-center" >
                                    <button className="btn btn-primary btn-lg px-4 me-md-2 home-screen-button" onClick={() => this.openPopup('Prijava za online prezentaciju', 2)}>Prijavi se</button>
                                </div>
                            </div>

                            <div className="pt-3 px-5 flex justify-content-end hide-on-mobile">
                                <img alt="" src={LogoBlackWithTitleIcon} className='home-logo' />
                            </div>
                        </div>

                    </div>

                    <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
                        <p style={{ marginRight: '3rem'}}>&copy; {this.state.currentYear} Zapisnik.rs, Sva prava zadržana.</p>
                        <p style={{ marginRight: '3rem'}}><a style={{ color: '#000' }} href='/uslovi-koriscenja'>Uslovi korišćenja</a></p>
                        <p><a style={{ color: '#000' }} href='/pravila-privatnosti'>Pravila privatnosti</a></p>
                    </div>
                </footer>

                <Dialog header={this.state.popupTitle}
                    visible={this.state.formVisible}
                    dismissableMask
                    draggable={false}
                    style={{ width: '60vw' }}
                    breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                    position='top'
                    onHide={() => this.cancelEdit()}>

                    <div>
                        <form className='form-group' ref={this.formRef}>
                            <div className="form-container">

                                <div className="row">
                                    <div className='mb-3'>
                                        <label htmlFor="ime" className="form-label">Ime i prezime *</label>
                                        <input className="form-control" id="ime" required value={this.state.ime ?? ''} 
                                            maxLength="200"
                                            onChange={(e) => this.setState({ ime: e.target.value })} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className='mb-3 col-sm-6'>
                                        <label htmlFor="telefon" className="form-label">Telefon *</label>
                                        <input className="form-control" id="telefon" required value={this.state.telefon ?? ''} 
                                            maxLength="100"
                                            onChange={(e) => this.setState({ telefon: e.target.value })} />
                                    </div>

                                    <div className='mb-3 col-sm-6'>
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input className="form-control" id="email" value={this.state.email ?? ''} 
                                            maxLength="100"
                                            onChange={(e) => this.setState({ email: e.target.value })} />
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="komentar" className="form-label">Komentar</label>
                                    <textarea className="form-control" id="komentar" value={this.state.komentar ?? ''} rows="3" onChange={(e) => this.setState({ komentar: e.target.value })}></textarea>
                                </div>
                            </div>
                        </form>

                        <div className="mb-3 mx-3">
                            <button className="btn btn-primary btn-lg px-4 me-md-2 home-screen-button" onClick={this.saveDataToApi}>
                                Pošalji
                            </button>
                        </div>

                    </div>
                </Dialog>

                <Toast ref={this.toastRef} />
            </div>
        );
    }

    openPopup(title, type) {
        this.setState({
            popupTitle: title,
            kontaktType: type,
            formVisible: true
        });
    }

    async saveDataToApi() {
        if (!this.formRef.current.checkValidity()) {
            this.formRef.current.classList.add('was-validated');
            return;
        }

        this.setState({ loading: true });

        const method = 'POST';
        const bodyInput = {
            "Tip": this.state.kontaktType,
            "Ime": this.state.ime,
            "Telefon": this.state.telefon,
            "Email": this.state.email,
            "Komentar": this.state.komentar
        };

        let response = await fetchService.save('porukaSaSajta', bodyInput, method);

        this.setState({ loading: false });

        if (!response.success) {
            let errorMessage = response.message ? 'Greška: ' + response.message : '';
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: errorMessage, sticky: true });
        }
        else {
            this.setState({
                formVisible: false,
                ime: '',
                telefon: '',
                email: '',
                komentar: '',
                popupTitle: '',
                kontaktType: 0
            });
            this.toastRef.current.show({ severity: 'success', summary: 'Slanje poruke uspešno', detail: "Uspešno ste poslali poruku. Kontaktiraćemo Vas u što kraćem roku. Hvala!", sticky: true });
        }
    }

    cancelEdit() {
        this.setState({
            formVisible: false,
            ime: '',
            telefon: '',
            email: '',
            komentar: '',
            popupTitle: '',
            kontaktType: 0
        });
    }
}
