import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import fetchService from '../FetchService'
import { InputText } from 'primereact/inputtext';

export class BrzaPretragaKlijenata extends Component {
    constructor(props) {
        super(props);

        this.state = {
            klijentiAdvokata: [],
            typingTimeout: 0,
            filter: '',
        };

        this.filterReference = React.createRef();
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return (
            <div>
                <div className="p-3">
                    <div className="mx-3 mb-3 border-bottom border-success">
                        <h3><b>Pretraga klijenata</b></h3>
                    </div>

                    <div className="px-3 p-inputgroup flex-1">
                        <Dropdown
                            options={this.state.klijentiAdvokata}
                            placeholder="Klijent..."
                            emptyMessage="Nema rezultata"
                            filter
                            className="dashboard-dropdown-success w-full md:w-14rem"
                            dropdownIcon="pi pi-search"
                            itemTemplate={this.imePrezimeTemplate}
                            filterTemplate={this.filterTemplate}
                            onChange={(e) => window.location = '/klijent?id=' + e.value.id}
                            onShow={() => this.filterReference.current.focus() }
                        />
                    </div>
                </div>
            </div>
        );
    }

    imePrezimeTemplate = (klijent) => {
        return `${klijent.ime} ${klijent.prezime}`;
    };

    filterTemplate = () => {
        return (
            <div>
                <InputText className="w-100" value={this.state.filter} placeholder="Traži..." ref={this.filterReference}
                    onChange={this.onFilterChange} onKeyDown={this.onFilterKeyDown} />
            </div>
        )
    }

    async loadData() {
        let rows = 10;
        let page = 1;
        let sortField = 'ime';
        let sortOrder = 1;
        let filter = this.state.filter ?? '';

        let requestUrl = `klijentiAdvokata?rows=${rows}&page=${page}&sortField=${sortField}&sortOrder=${sortOrder}&filter=${filter}`;
        const response = await fetchService.get(requestUrl);

        this.setState({
            klijentiAdvokata: response.success ? response.responseValue.klijentiAdvokata : []
        });
    }

    onFilterChange = (event) => {
        const value = event.target.value;
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            filter: value,
            typingTimeout: setTimeout(function () {
                self.loadData();
            }, 800)
        });
    }

    onFilterKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (this.state.typingTimeout) {
                clearTimeout(this.state.typingTimeout);
            }

            this.setState({
                typingTimeout: 0
            });

            this.loadData();
        }
    }
}
