import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DateTimeService } from '../DateTimeService';
import FileAddWhiteIcon from '../icons/file-add-white.svg';
import { ZapisEdit } from './ZapisEdit';
import { HelpTooltip } from '../HelpTooltip';

export class ZapisiPredmeta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zapisi: [],
            loading: true,
            editMode: false,
            editingItem: {}
        };

        this.rowSelected = this.rowSelected.bind(this);
        this.newItemTriggered = this.newItemTriggered.bind(this);
        this.onZapisEditSaveClick = this.onZapisEditSaveClick.bind(this);
        this.onZapisEditCancelClick = this.onZapisEditCancelClick.bind(this);
    }

    async componentDidMount() {
        let zapisi = this.props.zapisi ?? await this.props.onZapisiMount();
        let odgovornaLica = this.props.odgovornaLica ?? await this.props.onOdgovornaLicaMount();

        this.setState({
            zapisi: zapisi,
            odgovornaLica: odgovornaLica,
            loading: false
        });
    }

    renderZapisiPredmeta(zapisi) {
        const footer = `Ukupno stavki: ${zapisi ? zapisi.length : 0}.`;

        return (
            <div>
                <div className="form-header">
                    <button className="btn btn-primary btns" onClick={this.newItemTriggered}>
                        <img alt="" src={FileAddWhiteIcon} />
                        &nbsp;
                        Zapis
                    </button>
                    <HelpTooltip>
                        <p>
                            Zapisi predmeta su informacije o predmetu u određenom vremenskom trenutku.
                        </p>
                        <p>
                            Ovde možete unositi sve zvanične i nezvanične podatke o predmetu, koji su vezani za određeno vreme.
                        </p>
                        <p>
                            Ovu sekciju možete ujedno koristiti i kao podsetnik o dešavanjima vezanih za predmet.
                        </p>
                        <p>
                            Korišćenje zapisa predmeta se može uporediti sa korišćenjem portala sudova, dela za praćenje toka predmeta.
                        </p>
                    </HelpTooltip>
                </div>

                <DataTable value={zapisi}
                    dataKey="id"
                    sortField="vremeUnosa" sortOrder={-1}
                    tableStyle={{ minWidth: '50rem' }} className="custom-datatable"
                    selectionMode="single" onSelectionChange={(e) => this.rowSelected(e.value)} 
                    emptyMessage="Nema unetih podataka."
                    footer={footer}
                >
                    <Column field="vremeUnosa" body={this.vremeUnosaTemplate} sortable header="Vreme" style={{ width: '20%' }}></Column>
                    <Column field="opis" header="Opis" sortable style={{ width: '60%' }}></Column>
                    <Column field="imePrezimeOdgovornogLica" header="Odgovorno lice" style={{ width: '20%' }}></Column>
                </DataTable>
            </div>
        );
    }

    vremeUnosaTemplate = (zapisPredmeta) => {
        return DateTimeService.formatDateTimeToString(zapisPredmeta.vremeUnosa);
    };

    rowSelected(item) {
        this.setState({
            editingItem: item,
            editMode: true
        });
    }

    newItemTriggered() {
        this.setState({
            editingItem: {},
            editMode: true
        });
    }

    onZapisEditSaveClick() {
        this.setState({
            loading: true,
            editMode: false
        }, async () => {
            let zapisi = await this.props.onZapisiMount();
            this.setState({
                zapisi: zapisi,
                loading: false
            });
        });
    }

    onZapisEditCancelClick() {
        this.setState({
            editMode: false
        });
    }

    render() {
        let displayDataContent = this.state.editMode
            ? <ZapisEdit zapisPredmeta={this.state.editingItem} predmetId={this.props.predmet.id}
                imePrezimeKlijenta={this.props.imePrezimeKlijenta} brojPredmeta={this.props.predmet.brojPredmetaFull}
                onSaveClick={this.onZapisEditSaveClick} onCancelClick={this.onZapisEditCancelClick}
                odgovornaLica={this.state.odgovornaLica}
            />
            : this.renderZapisiPredmeta(this.state.zapisi);

        let contents =
            this.state.loading
                ? <p><em>Učitavanje...</em></p>
                : displayDataContent;

        return (
            <div>
                {contents}
            </div>
        );
    }
}
