import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import FileAddWhiteIcon from '../icons/file-add-white.svg';

export class KlijentPredmeti extends Component {
    constructor(props) {
        super(props);
        this.state = {
            predmeti: [],
            loading: true
        };
    }

    async componentDidMount() {
        let predmeti = this.props.predmeti ?? await this.props.onPredmetiMount();
        
        this.setState({
            predmeti: predmeti,
            loading: false
        });
    }

    renderPredmeti(predmeti) {
        const footer = `Ukupno stavki: ${predmeti ? predmeti.length : 0}.`;

        return (
            <div>
                <div className="form-header">
                    <a className="btn btn-primary btns" href={'/predmet?klijentAdvokataId=' + this.props.klijentAdvokataId}>
                        <img alt="" src={FileAddWhiteIcon} />
                        &nbsp;
                        Predmet
                    </a>
                </div>

                <DataTable value={predmeti}
                    dataKey="id"
                    sortField="redniBroj" sortOrder={-1}
                    tableStyle={{ minWidth: '50rem' }} className="custom-datatable"
                    emptyMessage="Nema unetih podataka."
                    footer={footer}
                    selectionMode="single" onSelectionChange={(e) => window.location = '/predmet?id=' + e.value.id} 
                >
                    <Column field="redniBroj" header="RB" sortable style={{ width: '5%', minWidth: '35px' }}></Column>
                    <Column field="tipPredmeta" header="Tip" sortable style={{ width: '15%', minWidth: '250px' }}></Column>

                    <Column field="sud" header="Sud" style={{ width: '15%', minWidth: '250px' }}></Column>
                    <Column field="mesto" header="Mesto" style={{ width: '15%', minWidth: '150px' }}></Column>

                    <Column field="upisnik" header="Upisnik" style={{ width: '5%', minWidth: '35px' }}></Column>
                    <Column field="brojPredmeta" header="Broj" sortable style={{ width: '10%', minWidth: '150px' }}></Column>
                    <Column field="godina" header="Godina" style={{ width: '5%', minWidth: '35px' }}></Column>

                    <Column field="opis" header="Opis" style={{ width: '25%', minWidth: '300px' }}></Column>
                    <Column field="trenutniStatusPredmeta" header="Status" style={{ width: '5%', minWidth: '100px' }}></Column>


                </DataTable>
            </div>
        );
    }

    render() {
        let contents =
            this.state.loading
                ? <p><em>Učitavanje...</em></p>
                : this.renderPredmeti(this.state.predmeti);

        return (
            <div>
                {contents}
            </div>
        );
    }
}
