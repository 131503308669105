import React, { Component } from 'react';
import PenciLineWhiteIcon from '../icons/pencil-line-white.svg';
import ArrowGoBackWhiteIcon from '../icons/arrow-go-back-white.svg';

export class KancelarijaOsnovniPodaciView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            kancelarija: {
                id: '',
                naziv: '',
                adresa: ''
            }
        };
    }

    componentDidMount() {
        this.setState({
            kancelarija: this.props.kancelarija
        });
    }

    render() {
        return (
            <div>
                <div className="mb-3 form-header">
                    <button className="btn btn-primary btns" onClick={this.props.onAzurirajClick}>
                        <img alt="" src={PenciLineWhiteIcon} />
                        &nbsp;
                        Ažuriraj
                    </button>
                    <a className="btn btn-primary btns" href="/kancelarije">
                        <img alt="" src={ArrowGoBackWhiteIcon} />
                        &nbsp;
                        Kancelarije
                    </a>
                </div>

                <div className="form-container">
                    <div className="mb-3">
                        <label htmlFor="naziv" className="form-label">Naziv</label>
                        <input readOnly className="form-control" id="naziv" value={this.state.kancelarija.naziv ?? ''} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="adresa" className="form-label">Adresa</label>
                        <input readOnly className="form-control" id="adresa" value={this.state.kancelarija.adresa ?? ''} />
                    </div>
                </div>
            </div>
        );
    }
}
