import React, { Component } from 'react';
import { Toast } from 'primereact/toast';
import { ProtivnikOsnovniPodaciView } from './ProtivnikOsnovniPodaciView';
import { ProtivnikOsnovniPodaciEdit } from './ProtivnikOsnovniPodaciEdit';
import { ConfirmDialog } from 'primereact/confirmdialog';
    
export class ProtivnikOsnovniPodaciContainer extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();

        this.state = {
            loading: true,
            editMode: false
        };

        this.setEditMode = this.setEditMode.bind(this);
        this.saveClicked = this.saveClicked.bind(this);
    }

    componentDidMount() {
        this.setState({
            protivnik: this.props.protivnik,
            loading: false,
            editMode: !this.props.protivnik || !this.props.protivnik.id ? true : false
        });
    }

    setEditMode(editMode) {
        this.setState({
            editMode: editMode
        });
    }

    saveClicked(protivnik) {
        this.setState({
            protivnik: protivnik
        }, () => {
            this.setEditMode(false);
            this.toastRef.current.show({ severity: 'success', summary: 'Uspešno sačuvano', detail: "Uspešno ste sačuvali protivnika." });
        });

        this.props.onProtivnikUpdate(protivnik);
    }

    render() {
        let protivnikOsnovniPodaciView = this.state.editMode
            ? <ProtivnikOsnovniPodaciEdit protivnik={this.state.protivnik} onCancelClick={() => this.setEditMode(false)} onSaveClick={(protivnik) => this.saveClicked(protivnik)} externalNewEntry={false} />
            : <ProtivnikOsnovniPodaciView protivnik={this.state.protivnik} onAzurirajClick={() => this.setEditMode(true)} />;

        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : protivnikOsnovniPodaciView;

        return (
            <div>
                {contents}
                <Toast ref={this.toastRef} />
                <ConfirmDialog />
            </div>
        );
    }
}
