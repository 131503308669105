import React, { Component } from 'react';
import fetchService from '../FetchService';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import FileAddWhiteIcon from '../icons/file-add-white.svg';
import { DokumenatEdit } from './DokumenatEdit';
import { FileUpload } from 'primereact/fileupload';
import { HelpTooltip } from '../HelpTooltip';

export class DokumentaPredmeta extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();

        this.state = {
            dokumenta: [],
            loading: true,
            editMode: false,
            editingItem: {}
        };

        this.rowSelected = this.rowSelected.bind(this);
        this.newItemTriggered = this.newItemTriggered.bind(this);
        this.onDokumenatEditSaveClick = this.onDokumenatEditSaveClick.bind(this);
        this.onDokumenatEditCancelClick = this.onDokumenatEditCancelClick.bind(this);
        this.onFastUpload = this.onFastUpload.bind(this);
    }

    async componentDidMount() {
        let dokumenta = this.props.dokumenta ?? await this.props.onDokumentaMount();
        let odgovornaLica = this.props.odgovornaLica ?? await this.props.onOdgovornaLicaMount();

        this.setState({
            dokumenta: dokumenta,
            odgovornaLica: odgovornaLica,
            loading: false
        });
    }

    async onFastUpload(e) {
        this.setState({ loading: true });

        let form = new FormData();
        let files = e.files;
        for (var index = 0; index < files.length; index++) {
            var element = files[index];
            form.append('attachedFiles', element);
        }
        form.append('predmetId', this.props.predmet.id);

        let response = await fetchService.saveForm('dokumenatPredmetaFastUpload', form, 'POST');
        if (!response.success) {
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom čuvanja dokumenta predmeta. Greška: " + response.message, sticky: true });
        }

        let dokumenta = await this.props.onDokumentaMount();
        this.setState({
            dokumenta: dokumenta,
            loading: false
        });
    }

    renderDokumentaPredmeta(dokumenta) {
        const footer = `Ukupno stavki: ${dokumenta ? dokumenta.length : 0}.`;

        return (
            <div>
                <div className="form-header">
                    <button className="btn btn-primary btns" onClick={this.newItemTriggered}>
                        <img alt="" src={FileAddWhiteIcon} />
                        &nbsp;
                        Fascikla
                    </button>
                    <FileUpload id='fastUpload' name="fastUpload" maxFileSize={15000000} className="btn btn-primary btns btn-upload"
                        ref={this.fastUploadRef}
                        multiple
                        chooseLabel="Brzo dodavanje"
                        onSelect={this.onFastUpload}
                        uploadOptions={{ icon: '', iconOnly: true, className: 'display-none' }}
                        cancelOptions={{ icon: '', iconOnly: true, className: 'display-none' }}
                    />
                    <HelpTooltip>
                        <p>
                            Dokumenta predmeta su sva dokumenta koja želite da čuvate u arhivi predmeta.
                        </p>
                        <p>
                            Ovde možete čuvati sva zvanična i nezvanična dokumenta predmeta.
                        </p>
                        <p>
                            Dokumenta mogu biti skenirani zapisnici, dokazi, prilozi...
                        </p>
                        <p>
                            Ukoliko je dokument već skeniran, on se može dodati u aplikaciju standardima predviđenim za web aplikacije.
                        </p>
                        <p>
                            Ukoliko aplikaciju koristite sa mobilnog telefona, dokument možete dodati i direktnim 'skeniranjem' kamerom, odnosno slikanjem dokumenta. Telefoni novijih generacija imaju podršku za ovakav vid direktnog slikanja u aplikaciju. Ukoliko je potrebno, sliku možete i dodatno prilagoditi tako što ćete joj iseći suvišne delove i okrenuti u određeni položaj.
                        </p>
                    </HelpTooltip>
                </div>

                <DataTable value={dokumenta}
                    dataKey="id"
                    sortField="naziv" sortOrder={1}
                    tableStyle={{ minWidth: '50rem' }} className="custom-datatable"
                    emptyMessage="Nema unetih podataka."
                    footer={footer}
                    selectionMode="single" onSelectionChange={(e) => this.rowSelected(e.value)} 
                >
                    <Column field="naziv" header="Naziv" sortable style={{ width: '30%' }}></Column>
                    <Column field="opis" header="Opis" sortable style={{ width: '50%' }}></Column>
                    <Column field="imePrezimeOdgovornogLica" header="Odgovorno lice" style={{ width: '20%' }}></Column>
                </DataTable>
            </div>
        );
    }

    rowSelected(item) {
        this.setState({
            editingItem: item,
            editMode: true
        });
    }

    newItemTriggered() {
        this.setState({
            editingItem: {},
            editMode: true
        });
    }

    onDokumenatEditSaveClick(keepEditModeOfDokumenatWithId) {
        this.setState({
            loading: true,
            editMode: keepEditModeOfDokumenatWithId ? true : false
        }, async () => {
            let dokumenta = await this.props.onDokumentaMount();
            let editingItem = keepEditModeOfDokumenatWithId ? dokumenta.filter(dok => dok.id === keepEditModeOfDokumenatWithId)[0] : {};
            this.setState({
                dokumenta: dokumenta,
                editingItem: editingItem,
                loading: false
            });
        });
    }

    onDokumenatEditCancelClick() {
        this.setState({
            editMode: false
        });
    }

    render() {
        let displayDataContent = this.state.editMode
            ? <DokumenatEdit dokumenatPredmeta={this.state.editingItem} predmetId={this.props.predmet.id}
                imePrezimeKlijenta={this.props.imePrezimeKlijenta} brojPredmeta={this.props.predmet.brojPredmetaFull}
                onSaveClick={this.onDokumenatEditSaveClick} onCancelClick={this.onDokumenatEditCancelClick}
                odgovornaLica={this.state.odgovornaLica}
            />
            : this.renderDokumentaPredmeta(this.state.dokumenta);

        let contents =
            this.state.loading
                ? <p><em>Učitavanje...</em></p>
                : displayDataContent;

        return (
            <div>
                {contents}

                <Toast ref={this.toastRef} />
            </div>
        );
    }
}
