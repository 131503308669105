import React, { Component } from 'react';
import fetchService from '../FetchService';
import { Card } from 'primereact/card';
import { DateTimeService } from '../DateTimeService';
import { HelpTooltip } from '../HelpTooltip';

export class ObavezeDanasSutra extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rokoviForToday: [],
            rocistaForToday: [],
            terminiForToday: [],
            rokoviForTomorrow: [],
            rocistaForTomorrow: [],
            terminiForTomorrow: []
        };
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        const timeNow = new Date();
        let timeTomorrow = new Date();
        timeTomorrow.setDate(timeTomorrow.getDate() + 1);

        let rokoviForTodayDisplay = this.state.rokoviForToday.map((item) => <li key={item.id}>{item.value}</li>);
        let rocistaForTodayDisplay = this.state.rocistaForToday.map((item) => <li key={item.id}>{item.value}</li>);
        let terminiForTodayDisplay = this.state.terminiForToday.map((item) => <li key={item.id}>{item.value}</li>);

        let rokoviForTomorrowDisplay = this.state.rokoviForTomorrow.map((item) => <li key={item.id}>{item.value}</li>);
        let rocistaForTomorrowDisplay = this.state.rocistaForTomorrow.map((item) => <li key={item.id}>{item.value}</li>);
        let terminiForTomorrowDisplay = this.state.terminiForTomorrow.map((item) => <li key={item.id}>{item.value}</li>);

        return (
            <div className="dashboard-obaveze-danas-sutra">
                <div className="row">
                    <div className="p-3">
                        <div className="mx-3 mb-3 border-bottom border-info flex justify-content-between">
                            <div>
                                <h3>
                                    <b>Danas - {DateTimeService.formatDateTimeToDateString(timeNow)}</b>
                                    <HelpTooltip>
                                        <p className="mb-3">
                                            Termini obaveza za danas.
                                        </p>
                                        <p>
                                            Za više detalja o terminima obaveza, idite u 'Kalendar'.
                                        </p>
                                    </HelpTooltip>
                                </h3>
                            </div>
                            <div>
                                <h3><a className="link-info" href="/kalendar">Kalendar</a></h3>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <Card title="Rokovi">
                                    <div className="m-0">
                                        <ul>{rokoviForTodayDisplay}</ul>
                                    </div>
                                </Card>
                            </div>
                            <div className="col-md-4">
                                <Card title="Ročišta">
                                    <div className="m-0">
                                        <ul>{rocistaForTodayDisplay}</ul>
                                    </div>
                                </Card>
                            </div>
                            <div className="col-md-4">
                                <Card title="Termini">
                                    <div className="m-0">
                                        <ul>{terminiForTodayDisplay}</ul>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="p-3">
                        <div className="mx-3 mb-3 border-bottom border-warning">
                            <h3>
                                <b>Sutra - {DateTimeService.formatDateTimeToDateString(timeTomorrow)}</b>
                                <HelpTooltip>
                                    <p className="mb-3">
                                        Termini obaveza za sutra.
                                    </p>
                                    <p>
                                        Za više detalja o terminima obaveza, idite u 'Kalendar'.
                                    </p>
                                </HelpTooltip>
                            </h3>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <Card title="Rokovi">
                                    <div className="m-0">
                                        <ul>{rokoviForTomorrowDisplay}</ul>
                                    </div>
                                </Card>
                            </div>
                            <div className="col-md-4">
                                <Card title="Ročišta">
                                    <div className="m-0">
                                        <ul>{rocistaForTomorrowDisplay}</ul>
                                    </div>
                                </Card>
                            </div>
                            <div className="col-md-4">
                                <Card title="Termini">
                                    <div className="m-0">
                                        <ul>{terminiForTomorrowDisplay}</ul>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async loadData() {
        let datumKalendara = new Date();
        let datumKalendaraQueryString = DateTimeService.getCombinedDateTime(datumKalendara).toUTCString();
        let requestUrl = `advokatDashboardKalendar?date=${datumKalendaraQueryString}`;

        const response = await fetchService.get(requestUrl);
        const kalendarData = response.success ? response.responseValue : {};

        this.setState({
            rokoviForToday: this.processData(kalendarData.rokoviForToday),
            rocistaForToday: this.processData(kalendarData.rocistaForToday),
            terminiForToday: this.processData(kalendarData.terminiForToday),
            rokoviForTomorrow: this.processData(kalendarData.rokoviForTomorrow),
            rocistaForTomorrow: this.processData(kalendarData.rocistaForTomorrow),
            terminiForTomorrow: this.processData(kalendarData.terminiForTomorrow)
        });
    }

    processData(dataItems) {
        let processedItems = [];
        let randomId = Math.floor(Math.random() * 99999).toString();

        if (!dataItems)
            return [{ id: randomId, value: 'Nema podataka.' }];

        dataItems.forEach(item => {
            let processedText = DateTimeService.formatDateTimeToTimeString(item.datum);

            if (item.sudnica) {
                if (processedText !== '') {
                    processedText = processedText + ', ';
                }
                processedText = processedText + 'sudnica ' + item.sudnica;
            }

            if (item.naziv) {
                if (processedText !== '') {
                    processedText = processedText + ', ';
                }
                processedText = processedText + item.naziv;
            }

            if (item.klijent) {
                if (processedText !== '') {
                    processedText = processedText + ', ';
                }
                processedText = processedText + item.klijent;
            }

            if (item.brojPredmeta) {
                if (processedText !== '') {
                    processedText = processedText + ', ';
                }
                processedText = processedText + item.brojPredmeta;
            }

            if (processedText !== '') {
                processedItems.push({ id: item.id, value: processedText });
            }
        });

        if (processedItems.length === 0) {
            return [{ id: randomId, value: 'Nema podataka.' }];
        }

        return processedItems;
    }
}
