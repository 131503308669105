import React, { Component } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import HelpIcon from './icons/help-line.svg';

export class HelpTooltip extends Component {
    constructor(props) {
        super(props);

        this.overlayPanelRef = React.createRef();
    }

    componentDidMount() {
    }

    render() {
        return (
            <span>
                <img className='help-tooltip-image' alt="" src={HelpIcon} onClick={(e) => this.overlayPanelRef.current.toggle(e)} />

                <OverlayPanel ref={this.overlayPanelRef}>
                    {this.props.children}
                </OverlayPanel>
            </span>
        );
    }
}
