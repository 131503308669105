import React, { Component } from 'react';
import PenciLineWhiteIcon from '../icons/pencil-line-white.svg';
import ArrowGoBackWhiteIcon from '../icons/arrow-go-back-white.svg';
import FileCopyWhiteIcon from '../icons/file-copy.svg';
import CloseLineWhiteIcon from '../icons/close-line-white.svg';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
import { DateTimeService } from '../DateTimeService';
import { HelpTooltip } from '../HelpTooltip';
import { Dialog } from 'primereact/dialog';
import { ItemPermissions } from '../permissions/ItemPermissions';

export class OsnovniPodaciView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            predmet: {
                id: '',
                klijentAdvokataId: '',
                brojPredmeta: '',
                opis: '',
                trenutniStatusPredmetaId: 1,
                tipPredmeta: '',
                redniBroj: '',
                brziPristup: false,
                tipSuda: 1,
                tipSudaDodatak: '',
                upisnik: '',
                mesto: '',
                godina: '',
                protivnikId: '',
                sudnica: '',
                sudija: '',
                kancelarijaTimInfos: []
            },
            imePrezimeKlijenta: '',
            imePrezimeProtivnika: '',

            kopijaPredmetaVisible: false,
            copyZapisi: false
        };
    }

    componentDidMount() {
        this.setState({
            predmet: { ...this.props.predmet, godina: DateTimeService.getFirstDateOfYear(this.props.predmet.godina) },
            imePrezimeKlijenta: this.props.imePrezimeKlijenta
        });
    }

    render() {
        let klijentOptions = [{ id: this.state.predmet.klijentAdvokataId, imePrezime: this.props.imePrezimeKlijenta }]
        let protivnikOptions = [{ id: this.state.predmet.protivnikId, imePrezime: `${this.state.predmet.imeProtivnika} ${this.state.predmet.prezimeProtivnika}` }]

        return (
            <div>
                <div className="mb-3 form-header">
                    <button className="btn btn-primary btns mb-3-for-less-than-350" onClick={this.props.onAzurirajClick}>
                        <img alt="" src={PenciLineWhiteIcon} />
                        <span className="hide-on-xs">
                            &nbsp;Ažuriraj
                        </span>
                    </button>
                    <button className="btn btn-primary btns mb-3-for-less-than-350" onClick={() => this.setState({ kopijaPredmetaVisible: true })}>
                        <img alt="" src={FileCopyWhiteIcon} />
                        <span className="hide-on-xs">
                            &nbsp;Kopiraj
                        </span>
                    </button>
                    <a className="btn btn-primary btns mb-3-for-less-than-350" href="/predmeti">
                        <span className="hide-on-xs">
                            <img alt="" src={ArrowGoBackWhiteIcon} />
                        </span>
                        &nbsp;Predmeti
                    </a>
                    <a className="btn btn-primary btns mb-3-for-less-than-350" href={"/klijent?id=" + this.state.predmet.klijentAdvokataId}>
                        <span className="hide-on-xs">
                            <img alt="" src={ArrowGoBackWhiteIcon} />
                        </span>
                        &nbsp;Klijent
                    </a>
                </div>

                <div className="form-container">
                    <div className="row">
                        <div className="mb-3 col-md-6">
                            <label htmlFor="imePrezimeKlijenta" className="form-label">Klijent *</label>
                            <button className="btn btn-secondary btns mx-4 mb-2 py-1" style={{ visibility: 'hidden' }} >
                                <img alt="" />
                            </button>
                            <Dropdown id="imePrezimeKlijenta"
                                disabled
                                value={this.state.predmet.klijentAdvokataId}
                                options={klijentOptions}
                                optionValue="id"
                                className="w-full p-dropdown-fullwidth"
                                optionLabel="imePrezime"
                            />
                        </div>

                        <div className="mb-3 col-md-6">
                            <label htmlFor="imePrezimeProtivnika" className="form-label">Protivnik</label>
                            <button className="btn btn-secondary btns mx-4 mb-2 py-1" style={{ visibility: 'hidden' }} >
                                <img alt="" />
                            </button>
                            <Dropdown id="imePrezimeProtivnika"
                                disabled
                                value={this.state.predmet.protivnikId}
                                options={protivnikOptions}
                                optionValue="id"
                                className="w-full p-dropdown-fullwidth"
                                optionLabel="imePrezime"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="mb-3 col-md-4">
                            <label htmlFor="tipSuda" className="form-label">Vrsta suda</label>
                            <div>
                                <Dropdown id="tipSuda"
                                    disabled
                                    value={this.state.predmet.tipSuda}
                                    options={this.props.tipoviSuda}
                                    optionLabel="naziv"
                                    optionValue="id"
                                    className="w-full p-dropdown-fullwidth" />
                            </div>
                        </div>

                        <div className="mb-3 col-md-4">
                            <label htmlFor="tipSudaDodatak" className="form-label">Vrsta suda dodatak</label>
                            <HelpTooltip>
                                Dodatni opis vrste suda, naročito ako je izabrana vrsta suda 'Neodređen'.
                            </HelpTooltip>
                            <input readOnly className="form-control" id="tipSudaDodatak" value={this.state.predmet.tipSudaDodatak ?? ''} />
                        </div>

                        <div className="mb-3 col-md-4">
                            <label htmlFor="mesto" className="form-label">Mesto</label>
                            <input readOnly className="form-control" id="mesto" value={this.state.predmet.mesto ?? ''} />
                        </div>
                    </div>


                    <div className="row">
                        <div className="mb-3 col-md-4">
                            <label htmlFor="upisnik" className="form-label">Upisnik</label>
                            <input readOnly className="form-control" id="upisnik" value={this.state.predmet.upisnik ?? ''} />
                        </div>
                        <div className="mb-3 col-md-4">
                            <label htmlFor="brojPredmeta" className="form-label">Broj predmeta</label>
                            <input readOnly className="form-control" id="brojPredmeta" value={this.state.predmet.brojPredmeta ?? ''} />
                        </div>
                        <div className="mb-3 col-md-4">
                            <label htmlFor="godina" className="form-label">Godina</label>
                            <div>
                                <Calendar disabled id="godina" className='control-max-width-200' value={this.state.predmet.godina ?? ''}
                                    view="year" dateFormat="yy" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="mb-3 col-md-4">
                            <label htmlFor="sudija" className="form-label">Sudija</label>
                            <input readOnly className="form-control" id="sudija" value={this.state.predmet.sudija ?? ''} />
                        </div>

                        <div className="mb-3 col-md-4">
                            <label htmlFor="sudnica" className="form-label">Sudnica</label>
                            <input readOnly className="form-control" id="sudnica" value={this.state.predmet.sudnica ?? ''} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="mb-3 col-md-4">
                            <label htmlFor="tipPredmetaId" className="form-label">Tip predmeta</label>
                            <HelpTooltip>
                                <p className="mb-3">
                                    Tip predmeta iz prethodno definisane liste.
                                </p>
                                <p>
                                    Predmete možete kategorizovati po tipu. Svakom predmetu možete dodeliti po jedan tip, i kasnije vršiti lakšu pretragu i filtriranje.
                                </p>
                                <p>
                                    Tipovi predmeta se mogu definisati u administrativnom delu osnovnih podataka aplikacije.
                                </p>
                            </HelpTooltip>
                            <div>
                                <Dropdown id="tipPredmetaId"
                                    disabled
                                    value={this.state.predmet.tipPredmetaId}
                                    options={this.props.tipoviPredmeta}
                                    optionLabel="naziv"
                                    optionValue="id"
                                    className="w-full p-dropdown-fullwidth" />
                            </div>
                        </div>

                        <div className="mb-3 col-md-4">
                            <label htmlFor="nosilacPredmetaId" className="form-label">Nosilac predmeta</label>
                            <HelpTooltip>
                                <p className="mb-3">
                                    Advokat kancelarije koji je zadužen za predmet.
                                </p>
                            </HelpTooltip>
                            <div>
                                <Dropdown id="nosilacPredmetaId"
                                    disabled
                                    value={this.state.predmet.nosilacId}
                                    options={this.props.nosiociPredmeta}
                                    optionLabel="imePrezime"
                                    optionValue="id"
                                    className="w-full p-dropdown-fullwidth" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="mb-3 col-md-4">
                            <label htmlFor="trenutniStatusPredmetaId" className="form-label">Status</label>
                            <div>
                                <Dropdown id="trenutniStatusPredmetaId"
                                    disabled
                                    value={this.state.predmet.trenutniStatusPredmetaId}
                                    options={this.props.statusiPredmeta}
                                    optionLabel="naziv"
                                    optionValue="id"
                                    className="w-full" />
                            </div>
                        </div>
                        <div className="mb-3 col-md-4">
                            <div>
                                <label htmlFor="brziPristup" className="form-label">Brzi pristup na početnom ekranu</label>
                                <HelpTooltip>
                                    <p className="mb-3">
                                        Izaberite ovu opciju ukoliko želite da predmet bude prikazan na početnom ekranu u sekciji 'Izabrani predmeti'.
                                    </p>
                                    <p>
                                        Predlog je da se na tom spisku prikazuju trenutno aktuelni predmeti kako biste im brzo pristupili.
                                    </p>
                                </HelpTooltip>
                            </div>
                            <div>
                                <InputSwitch disabled id="brziPristup" checked={this.state.predmet.brziPristup} className="form-control" />
                            </div>
                        </div>
                        <div className="mb-3 col-md-4">
                            <label htmlFor="redniBroj" className="form-label">Redni broj</label>
                            <HelpTooltip>
                                Automatski dodeljen redni broj predmetu od početka korišćenja aplikacije za nalog korisnika koji je krerirao predmet.
                            </HelpTooltip>
                            <input readOnly className="form-control" id="redniBroj" value={this.state.predmet.redniBroj ?? ''} />
                        </div>

                    </div>
                    <div className="mb-3">
                        <label htmlFor="opis" className="form-label">Opis</label>
                        <HelpTooltip>
                            Dodatni komentar za predmet, koji može da se ažurira u svakom trenutku.
                        </HelpTooltip>
                        <textarea readOnly className="form-control" id="opis" value={this.state.predmet.opis ?? ''} rows="3"></textarea>
                    </div>
                </div>

                <div className="form-container">
                    <div className="mb-3">
                        <hr />
                        <label className="form-label">Dozvola pristupa</label>
                        <ItemPermissions items={this.state.predmet.kancelarijaTimInfos}
                            editMode={false} scope={2}
                        />
                    </div>
                </div>

                {this.renderKopijaPredmetaDialog()}
            </div>
        );
    }

    renderKopijaPredmetaDialog() {
        return (
            <Dialog
                header='Kopija predmeta'
                visible={this.state.kopijaPredmetaVisible}
                style={{ width: '40vw' }}
                breakpoints={{ '1200px': '50vw', '850px': '75vw', '500px': '100vw' }}
                position='top'
                onHide={() => this.setState({ kopijaPredmetaVisible: false })}
                footer={<div>
                    <button className="btn btn-primary btns"
                        onClick={() => {
                            let copyUrl = `/predmet?kopijaPredmetaId=${this.state.predmet.id}`;
                            if (this.state.copyZapisi === true) {
                                copyUrl += `&copyZapisi=true`;
                            }
                            if (this.state.copyRocista === true) {
                                copyUrl += `&copyRocista=true`;
                            }
                            if (this.state.copyRokovi === true) {
                                copyUrl += `&copyRokovi=true`;
                            }
                            if (this.state.copyDokumenta === true) {
                                copyUrl += `&copyDokumenta=true`;
                            }
                            if (this.state.copyRacuni === true) {
                                copyUrl += `&copyRacuni=true`;
                            }

                            window.location = copyUrl;
                        }}
                        href={`/predmet?kopijaPredmetaId=${this.state.predmet.id}`}
                    ><img alt="" src={FileCopyWhiteIcon} />&nbsp;Kopiraj</button>
                    <button className="btn btn-secondary btns" autoFocus onClick={() => this.setState({ kopijaPredmetaVisible: false })}><img alt="" src={CloseLineWhiteIcon} />&nbsp;Odustani</button>
                </div>}
            >
                <div className="m-0">
                    <div className="mb-4">
                        <p>
                            Izaberite ukoliko želite da napravite i kopije zapisa, ročišta, rokova, dokumenata ili računa. Kopije dodatnih elemenata će se videti tek nakon što sačuvate osnove podatke novog predmeta.
                        </p>
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Napraviti kopije zapisa</label>
                        &nbsp;&nbsp;&nbsp;
                        <InputSwitch checked={this.state.copyZapisi} className="form-control" onChange={(e) => this.setState({ copyZapisi: e.value })} />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Napraviti kopije ročišta</label>
                        &nbsp;&nbsp;&nbsp;
                        <InputSwitch checked={this.state.copyRocista} className="form-control" onChange={(e) => this.setState({ copyRocista: e.value })} />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Napraviti kopije rokova</label>
                        &nbsp;&nbsp;&nbsp;
                        <InputSwitch checked={this.state.copyRokovi} className="form-control" onChange={(e) => this.setState({ copyRokovi: e.value })} />
                    </div>

                    {/*<div className="mb-3">*/}
                    {/*    <label className="form-label">Napraviti kopije dokumenata</label>*/}
                    {/*    &nbsp;&nbsp;&nbsp;*/}
                    {/*    <InputSwitch checked={this.state.copyDokumenta} className="form-control" onChange={(e) => this.setState({ copyDokumenta: e.value })} />*/}
                    {/*</div>*/}

                    <div className="mb-3">
                        <label className="form-label">Napraviti kopije računa</label>
                        &nbsp;&nbsp;&nbsp;
                        <InputSwitch checked={this.state.copyRacuni} className="form-control" onChange={(e) => this.setState({ copyRacuni: e.value })} />
                    </div>
                </div>
            </Dialog>
        );
    }
}
