import React, { Component } from 'react';
import { ScrollTop } from 'primereact/scrolltop';

import { TutorijalHeader } from './Tutorijal01Header';
import { TutorijalFirstRow } from './Tutorijal02FirstRow';
import { Kontakt } from './06Kontakt';

import './home.css';

export class Tutorijal extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = 'Zapisnik - Tutorijal';
    };

    render() {
        return (
            <div className="main-wrapper">
                <TutorijalHeader />

                <TutorijalFirstRow  />

                <Kontakt />

                <ScrollTop />
            </div>
        );
    }
}
