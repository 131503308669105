import React, { Component } from 'react';
import fetchService from '../FetchService';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import CloseLineWhiteIcon from '../icons/close-line-white.svg';
import SaveLineWhiteIcon from '../icons/save-line-white.svg';
import DeleteBinLineWhiteIcon from '../icons/delete-bin-line-white.svg';
import { DateTimeService } from '../DateTimeService';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputSwitch } from 'primereact/inputswitch';
import { HelpTooltip } from '../HelpTooltip';
import { InputMask } from "primereact/inputmask";
import { MultiSelect } from 'primereact/multiselect';
import { Fieldset } from 'primereact/fieldset';

export class TimKancelarijeEdit extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();
        this.formRef = React.createRef();

        this.state = {
            loading: true,
            tim: {
                naziv: '',

                kreiranjePredmeta: false,
                kreiranjeKlijenata: false,
                kreiranjeProtivnika: false,

                pregledSvihPredmeta: false,
                pregledSvihKlijenata: false,
                pregledSvihProtivnika: false,

                azuriranjeSvihPredmeta: false,
                azuriranjeSvihKlijenata: false,
                azuriranjeSvihProtivnika: false,

                advokati: []
            },
            nazivKancelarije: ''
        };

        this.saveDataToApi = this.saveDataToApi.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }

    componentDidMount() {
        if (this.props.tim.id) {
            this.setState({
                tim: {
                    ...this.props.tim
                },
                nazivKancelarije: this.props.kancelarija.naziv,
                loading: false
            });
        } else {
            this.setState({
                tim: {
                    ...this.state.tim,
                    kancelarijaId: this.props.kancelarija.id
                },
                nazivKancelarije: this.props.kancelarija.naziv
            })
        }

        this.setState({
            loading: false
        });
    }

    async saveDataToApi() {
        if (!this.formRef.current.checkValidity()) {
            this.formRef.current.classList.add('was-validated');
            return;
        }

        this.setState({ loading: true });

        const method = this.state.tim.id ? 'PUT' : 'POST';
        const bodyInput = {
            "Id": this.state.tim.id,
            "KancelarijaId": this.props.kancelarija.id,
            "Naziv": this.state.tim.naziv,

            "KreiranjePredmeta": this.state.tim.kreiranjePredmeta,
            "KreiranjeKlijenata": this.state.tim.kreiranjeKlijenata,
            "KreiranjeProtivnika": this.state.tim.kreiranjeProtivnika,

            "PregledSvihPredmeta": this.state.tim.pregledSvihPredmeta,
            "PregledSvihKlijenata": this.state.tim.pregledSvihKlijenata,
            "PregledSvihProtivnika": this.state.tim.pregledSvihProtivnika,

            "AzuriranjeSvihPredmeta": this.state.tim.azuriranjeSvihPredmeta,
            "AzuriranjeSvihKlijenata": this.state.tim.azuriranjeSvihKlijenata,
            "AzuriranjeSvihProtivnika": this.state.tim.azuriranjeSvihProtivnika
        };

        let response = await fetchService.save('kancelarijaTim', bodyInput, method);

        this.setState({ loading: false });

        if (!response.success) {
            let errorMessage = response.message ? 'Greška: ' + response.message : '';
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom čuvanja tima. " + errorMessage, sticky: true });
        }
        else {
            this.props.onSaveClick();
        }
    }

    async deleteItem() {
        this.setState({ loading: true });

        let response = await fetchService.delete(`kancelarijaTim?kancelarijaId=${this.props.kancelarija.id}&kancelarijaTimId=${this.state.tim.id}`);

        this.setState({ loading: false });

        if (!response.success) {
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom brisanja tima. Greška: " + response.message, sticky: true });
        }
        else {
            this.props.onSaveClick();
        }
    }

    cancelEdit() {
        this.props.onCancelClick();
    }

    confirmDelete(event) {
        confirmDialog({
            trigger: event.currentTarget,
            message: 'Da li želite da obrišete ovaj tim?',
            header: 'Brisanje tima',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => { this.deleteItem() },
            acceptLabel: 'Da',
            rejectLabel: 'Ne'
        });
    };

    renderForm() {
        const advokatiListItems = this.state.tim.advokati && this.state.tim.advokati.length > 0 ?
            this.state.tim.advokati.map((advokat, index) =>
                <li key={index}>
                    {advokat}
                </li>
            ) : <li>Nijedan advokat još uvek nije dodeljen timu.</li>;

        return (
            <div>
                <div className="mb-3 form-header">
                    <button className="btn btn-primary btns" onClick={this.saveDataToApi}>
                        <img alt="" src={SaveLineWhiteIcon} />
                        &nbsp;
                        Sačuvaj
                    </button>
                    <button hidden={this.state.tim.id ? false : true} className="btn btn-danger btns" onClick={this.confirmDelete}>
                        <img alt="" src={DeleteBinLineWhiteIcon} />
                        &nbsp;
                        Briši
                    </button>
                    <button className="btn btn-secondary btns" onClick={this.cancelEdit}>
                        <img alt="" src={CloseLineWhiteIcon} />
                        &nbsp;
                        Odustani
                    </button>
                </div>

                <form className='form-group' ref={this.formRef}>
                    <div className="form-container">
                        <div className="mb-3">
                            <label htmlFor="nazivKancelarije" className="form-label">Kancelarija</label>
                            <input readOnly className="form-control" id="nazivKancelarije" value={this.state.nazivKancelarije ?? ''} />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="naziv" className="form-label">Naziv *</label>
                            <input required className="form-control" id="naziv" value={this.state.tim.naziv ?? ''}
                                onChange={(e) => this.setState({ tim: { ...this.state.tim, naziv: e.target.value } })} />
                        </div>

                        <div className="row">
                            <div className="mb-3 col-md-4">
                                <Fieldset legend="Klijent">
                                    <div className="mb-3">
                                        <label htmlFor="kreiranjeKlijenata" className="form-label">Kreiranje novih</label>
                                        &nbsp;&nbsp;&nbsp;
                                        <InputSwitch id="kreiranjeKlijenata" checked={this.state.tim.kreiranjeKlijenata} className="form-control"
                                            onChange={(e) => this.setState({ tim: { ...this.state.tim, kreiranjeKlijenata: e.value } })} />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="pregledSvihKlijenata" className="form-label">Pregled svih postojećih</label>
                                        &nbsp;&nbsp;&nbsp;
                                        <InputSwitch id="pregledSvihKlijenata" checked={this.state.tim.pregledSvihKlijenata} className="form-control"
                                            onChange={(e) => this.setState({ tim: { ...this.state.tim, pregledSvihKlijenata: e.value } })} />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="azuriranjeSvihKlijenata" className="form-label">Ažuriranje svih postojećih</label>
                                        &nbsp;&nbsp;&nbsp;
                                        <InputSwitch id="azuriranjeSvihKlijenata" checked={this.state.tim.azuriranjeSvihKlijenata} className="form-control"
                                            onChange={(e) => this.setState({ tim: { ...this.state.tim, azuriranjeSvihKlijenata: e.value } })} />
                                    </div>
                                </Fieldset>
                            </div>

                            <div className="mb-3 col-md-4">
                                <Fieldset legend="Protivnik">
                                    <div className="mb-3">
                                        <label htmlFor="kreiranjeProtivnika" className="form-label">Kreiranje novih</label>
                                        &nbsp;&nbsp;&nbsp;
                                        <InputSwitch id="kreiranjeProtivnika" checked={this.state.tim.kreiranjeProtivnika} className="form-control"
                                            onChange={(e) => this.setState({ tim: { ...this.state.tim, kreiranjeProtivnika: e.value } })} />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="pregledSvihProtivnika" className="form-label">Pregled svih postojećih</label>
                                        &nbsp;&nbsp;&nbsp;
                                        <InputSwitch id="pregledSvihProtivnika" checked={this.state.tim.pregledSvihProtivnika} className="form-control"
                                            onChange={(e) => this.setState({ tim: { ...this.state.tim, pregledSvihProtivnika: e.value } })} />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="azuriranjeSvihProtivnika" className="form-label">Ažuriranje svih postojećih</label>
                                        &nbsp;&nbsp;&nbsp;
                                        <InputSwitch id="azuriranjeSvihProtivnika" checked={this.state.tim.azuriranjeSvihProtivnika} className="form-control"
                                            onChange={(e) => this.setState({ tim: { ...this.state.tim, azuriranjeSvihProtivnika: e.value } })} />
                                    </div>
                                </Fieldset>
                            </div>

                            <div className="mb-3 col-md-4">
                                <Fieldset legend="Predmet">
                                    <div className="mb-3">
                                        <label htmlFor="kreiranjePredmeta" className="form-label">Kreiranje novih</label>
                                        &nbsp;&nbsp;&nbsp;
                                        <InputSwitch id="kreiranjePredmeta" checked={this.state.tim.kreiranjePredmeta} className="form-control"
                                            onChange={(e) => this.setState({ tim: { ...this.state.tim, kreiranjePredmeta: e.value } })} />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="pregledSvihPredmeta" className="form-label">Pregled svih postojećih</label>
                                        &nbsp;&nbsp;&nbsp;
                                        <InputSwitch id="pregledSvihPredmeta" checked={this.state.tim.pregledSvihPredmeta} className="form-control"
                                            onChange={(e) => this.setState({ tim: { ...this.state.tim, pregledSvihPredmeta: e.value } })} />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="azuriranjeSvihPredmeta" className="form-label">Ažuriranje svih postojećih</label>
                                        &nbsp;&nbsp;&nbsp;
                                        <InputSwitch id="azuriranjeSvihPredmeta" checked={this.state.tim.azuriranjeSvihPredmeta} className="form-control"
                                            onChange={(e) => this.setState({ tim: { ...this.state.tim, azuriranjeSvihPredmeta: e.value } })} />
                                    </div>
                                </Fieldset>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="naziv" className="form-label">Advokati</label>
                            <ul>
                                {advokatiListItems}
                            </ul>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : this.renderForm();

        return (
            <div>
                {contents}

                <Toast ref={this.toastRef} />
                <ConfirmDialog />
            </div>
        );
    }
}
