import React, { Component } from 'react';
import './home.css';
import { Dialog } from 'primereact/dialog';
import PlayIcon from './play-circle-black.svg';

export class TutorijalFirstRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            videoModalVisible: false,
            videoHeader: '',
            videoUrl: ''
        };

        this.renderFirstPart = this.renderFirstPart.bind(this);
        this.renderListItem = this.renderListItem.bind(this);
    }

    render() {
        return (
            <div>
                {this.renderFirstPart()}

                {this.renderVideoModal()}
            </div>
        );
    }

    renderFirstPart() {
        return (
            <div className="container pt-4 px-2 py-2 section1-container">
                <div className="row justify-content-center g-5 py-5 mx-1">
                    <div className="col-lg-6 mt-0 mb-5">
                        <h2 className="mb-3 display-6 lh-1 text-body-emphasis pb-2 text-center lead">Pregled funkcionalnosti</h2>
                        <video controls width="100%">
                            <source src='/videoTutorijal/pregled-funkcionalnosti.mp4' type="video/mp4" />
                        </video>
                    </div>

                    <div className="col-lg-6 mt-4 mb-5">
                        <div>
                            <ul className='lead' style={{ listStyle: 'none' }}>
                                {this.renderListItem('Kreiranje naloga', 'kreiranje-naloga')}

                                {this.renderListItem('Kreiranje predmeta', 'kreiranje-predmeta')}

                                {this.renderListItem('Kreiranje zapisa', 'kreiranje-zapisa')}

                                {this.renderListItem('Kreiranje ročišta', 'kreiranje-rocista')}

                                {this.renderListItem('Kreiranje roka', 'kreiranje-roka')}

                                {this.renderListItem('Kreiranje i ažuriranje dokumenata', 'kreiranje-azuriranje-dokumenata')}

                                {this.renderListItem('Korišćenje kalendara', 'koriscenje-kalendara')}

                                {this.renderListItem('Pretraga, filtriranje i izvoz predmeta', 'pretraga-filtriranje-izvoz-predmeta')}


                                {this.renderListItem('Prijava sa mobilnog telefona', 'prijava-mobilni-telefon', 'mt-5')}

                                {this.renderListItem('Instaliranje aplikacije na mobilnom telefonu', 'instaliranje-aplikacije-mobilni-telefon')}

                                {this.renderListItem('Pregled aplikacije na mobilnom telefonu', 'pregled-aplikacije-mobilni-telefon')}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderListItem(header, url, liAddedClass) {
        let liClass = 'mb-3 cursor-pointer';
        if(liAddedClass) {
            liClass += ' ' + liAddedClass;
        }

        return (
            <li key={url} className={liClass}
                onClick={() => {
                    this.setState({
                        videoModalVisible: true,
                        videoHeader: header,
                        videoUrl: `/videoTutorijal/${url}.mp4`
                    });
                }}>
                <img alt="" src={PlayIcon} />
                &nbsp;
                {header}
            </li>
        );
    }

    renderVideoModal() {
        return (
            <Dialog header={this.state.videoHeader} visible={this.state.videoModalVisible} style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                onHide={() => { this.setState({ videoModalVisible: false }); }}>
                <div className="m-0">
                    <video controls width="100%">
                        <source src={this.state.videoUrl} type="video/mp4" />
                    </video>
                </div>
            </Dialog>
        );
    }
}
