import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DateTimeService } from '../DateTimeService';
import FileAddWhiteIcon from '../icons/file-add-white.svg';
import { RocisteEdit } from './RocisteEdit';
import { HelpTooltip } from '../HelpTooltip';

export class RocistaPredmeta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rocista: [],
            loading: true,
            editMode: false,
            editingItem: {}
        };

        this.rowSelected = this.rowSelected.bind(this);
        this.newItemTriggered = this.newItemTriggered.bind(this);
        this.onRocisteEditSaveClick = this.onRocisteEditSaveClick.bind(this);
        this.onRocisteEditCancelClick = this.onRocisteEditCancelClick.bind(this);
    }

    async componentDidMount() {
        let rocista = this.props.rocista ?? await this.props.onRocistaMount();
        let odgovornaLica = this.props.odgovornaLica ?? await this.props.onOdgovornaLicaMount();

        this.setState({
            rocista: rocista,
            odgovornaLica: odgovornaLica,
            loading: false
        });
    }

    renderRocistaPredmeta(rocista) {
        const footer = `Ukupno stavki: ${rocista ? rocista.length : 0}.`;

        return (
            <div>
                <div className="form-header">
                    <button className="btn btn-primary btns" onClick={this.newItemTriggered}>
                        <img alt="" src={FileAddWhiteIcon} />
                        &nbsp;
                        Ročiste
                    </button>
                    <HelpTooltip>
                        <p>
                            Ročišta predmeta su informacije o zakazanim ročištima vezanih za predmet.
                        </p>
                        <p>
                            Ovde možete unositi sve zvanične podatke o zakazanim ročištima predmeta.
                        </p>
                        <p>
                            Sva upisana ročišta će se automatski pojaviti u kalendar.
                        </p>
                    </HelpTooltip>
                </div>

                <DataTable value={rocista}
                    dataKey="id"
                    sortField="datum" sortOrder={-1}
                    tableStyle={{ minWidth: '50rem' }} className="custom-datatable"
                    emptyMessage="Nema unetih podataka."
                    footer={footer}
                    selectionMode="single" onSelectionChange={(e) => this.rowSelected(e.value)} 
                >
                    <Column field="datum" body={this.datumTemplate} sortable header="Vreme" style={{ width: '20%' }}></Column>
                    <Column field="sudnica" header="Sudnica" sortable style={{ width: '12%' }}></Column>
                    <Column field="naziv" header="Naziv" sortable style={{ width: '16%' }}></Column>
                    <Column field="opis" header="Opis" sortable style={{ width: '26%' }}></Column>
                    <Column field="aktivno" body={this.aktivanTemplate} sortable header="Aktivno" style={{ width: '8%' }}></Column>
                    <Column field="odrzano" body={this.odrzanoTemplate} sortable header="Održano" style={{ width: '8%' }}></Column>
                    <Column field="imePrezimeOdgovornogLica" header="Odgovorno lice" style={{ width: '10%' }}></Column>
                </DataTable>
            </div>
        );
    }

    aktivanTemplate = (rocistePredmeta) => {
        return rocistePredmeta.aktivno === true ? 'Da' : 'Ne';
    };

    odrzanoTemplate = (rocistePredmeta) => {
        return rocistePredmeta.odrzano === true ? 'Da' : 'Ne';
    };

    datumTemplate = (rocistePredmeta) => {
        return DateTimeService.formatDateTimeToString(rocistePredmeta.datum);
    };

    rowSelected(item) {
        this.setState({
            editingItem: item,
            editMode: true
        });
    }

    newItemTriggered() {
        this.setState({
            editingItem: {},
            editMode: true
        });
    }

    onRocisteEditSaveClick() {
        this.setState({
            loading: true,
            editMode: false
        }, async () => {
            let rocista = await this.props.onRocistaMount();
            this.setState({
                rocista: rocista,
                loading: false
            });
        });
    }

    onRocisteEditCancelClick() {
        this.setState({
            editMode: false
        });
    }

    render() {
        let displayDataContent = this.state.editMode
            ? <RocisteEdit rocistePredmeta={this.state.editingItem} predmetId={this.props.predmet.id}
                imePrezimeKlijenta={this.props.imePrezimeKlijenta} brojPredmeta={this.props.predmet.brojPredmetaFull}
                sudnicaPredmeta={this.props.sudnicaPredmeta}
                onSaveClick={this.onRocisteEditSaveClick} onCancelClick={this.onRocisteEditCancelClick}
                odgovornaLica={this.state.odgovornaLica}
            />
            : this.renderRocistaPredmeta(this.state.rocista);

        let contents =
            this.state.loading
                ? <p><em>Učitavanje...</em></p>
                : displayDataContent;

        return (
            <div>
                {contents}
            </div>
        );
    }
}
