import React, { Component } from 'react';
import fetchService from '../FetchService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import AddLineWhiteIcon from '../icons/add-line-white.svg';
import { DateTimeService } from '../DateTimeService';
import { Badge } from 'primereact/badge';
import Download2FillIcon from '../icons/download-2-fill.svg';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';


export class KalendarZaViseDana extends Component {
    constructor(props) {
        super(props);
        this.dataTableRef = React.createRef();
        this.confirmDownloadIzvozStavki = this.confirmDownloadIzvozStavki.bind(this);
        this.downloadIzvozStavki = this.downloadIzvozStavki.bind(this);

        this.state = {
            kalendarData: [],
            loading: true,
            datumKalendaraFrom: '',
            datumKalendaraTo: ''
        };
    }

    componentDidMount() {
        let datumKalendaraFrom = this.props.initDatumKalendara;
        let datumKalendaraTo = this.props.secondDatumKalendara;
        this.loadData(datumKalendaraFrom, datumKalendaraTo);
    }

    renderKalendar(kalendarData) {
        return (
            <div>
                <div className="form-header flex justify-content-between">
                    <div>
                        Od
                        &nbsp;
                        <Calendar id="datumKalendaraFrom" className="control-max-width-100" value={this.state.datumKalendaraFrom ?? ''}
                            onChange={(e) => {
                                let dateFrom = e.target.value;
                                let dateTo = this.state.datumKalendaraTo;

                                if (dateFrom > dateTo) {
                                    dateTo = dateFrom;
                                }

                                this.setState({ datumKalendaraFrom: dateFrom, datumKalendaraTo: dateTo });
                                this.loadData(dateFrom, dateTo);
                                this.props.onDatumKalendaraChanged(dateFrom, dateTo);
                            }}
                            dateFormat="dd.mm.yy" />
                        &nbsp;
                        do
                        &nbsp;
                        <Calendar id="datumKalendaraTo" className="control-max-width-100" value={this.state.datumKalendaraTo ?? ''}
                            onChange={(e) => {
                                let dateFrom = this.state.datumKalendaraFrom;
                                let dateTo = e.target.value;

                                if (dateFrom > dateTo) {
                                    dateFrom = dateTo;
                                }

                                this.setState({ datumKalendaraFrom: dateFrom, datumKalendaraTo: dateTo });
                                this.loadData(dateFrom, dateTo);
                                this.props.onDatumKalendaraChanged(dateFrom, dateTo);
                            }}
                            dateFormat="dd.mm.yy" />
                    </div>

                    <div>
                        <button className="btn btn-primary" onClick={this.confirmDownloadIzvozStavki}>
                            <img alt="" src={Download2FillIcon} />
                        </button>

                        &nbsp;

                        <button className="btn btn-primary btns" onClick={(e) => this.props.onNewTerminTriggered()}>
                            <img alt="" src={AddLineWhiteIcon} />
                            <span className="hide-on-xs">
                                &nbsp;Termin
                            </span>
                        </button>
                    </div>
                </div>

                <DataTable value={kalendarData}
                    dataKey="id" ref={this.dataTableRef}
                    sortField="Datum" sortOrder={1}
                    tableStyle={{ minWidth: '50rem' }} className="custom-datatable"
                    tableClassName='table'
                    emptyMessage="Nema unetih podataka."
                    selectionMode="single" onSelectionChange={(e) => this.props.onRowSelected(e.value)}
                    rowGroupMode="subheader"
                    groupRowsBy="dateOnly"
                    rowGroupHeaderTemplate={this.rowGroupHeaderTemplate}
                >
                    <Column field="datum" body={this.datumTemplate} header="Vreme" style={{ width: '13%' }}></Column>
                    <Column field="naziv" header="Naziv" style={{ width: '33%' }}></Column>
                    <Column field="klijent" header="Klijent" style={{ width: '23%' }}></Column>
                    <Column field="brojPredmeta" header="Predmet" style={{ width: '23%' }}></Column>
                    <Column field="sudnica" header="Sudnica" style={{ width: '8%' }}></Column>

                </DataTable>
            </div>
        );
    }

    datumTemplate = (kalendarDataItem) => {
        let tip = '';
        let badgeSeverity = '';

        if (kalendarDataItem.tip === 'rok') {
            tip = 'Rok';
            badgeSeverity = 'danger';
        }
        if (kalendarDataItem.tip === 'rociste') {
            tip = 'Ročište';
            badgeSeverity = 'warning';
        }
        if (kalendarDataItem.tip === 'termin') {
            tip = 'Termin';
            badgeSeverity = 'info';
        }

        return <div>
            <b>{DateTimeService.formatDateTimeToTimeString(kalendarDataItem.datum)}</b>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Badge value={tip} severity={badgeSeverity}></Badge>
        </div>;
    }

    rowGroupHeaderTemplate = (kalendarDataItem) => {
        return <div>Datum:&nbsp;&nbsp;<b>{DateTimeService.formatDateTimeToDateString(kalendarDataItem.dateOnly)}</b></div>;
    }

    render() {
        let contents =
            this.state.loading
                ? <p><em>Učitavanje...</em></p>
                : this.renderKalendar(this.state.kalendarData);

        return (
            <div>
                {contents}

                <ConfirmDialog />
            </div>
        );
    }

    generateLoadDataRequestUrlQuery(datumKalendaraFrom, datumKalendaraTo) {
        let datumKalendaraFromQueryString = DateTimeService.getCombinedDateTime(datumKalendaraFrom).toUTCString();

        var datumKalendaraToQuery = new Date(datumKalendaraTo);
        datumKalendaraToQuery.setDate(datumKalendaraTo.getDate() + 1);

        let datumKalendaraToQueryString = DateTimeService.getCombinedDateTime(datumKalendaraToQuery).toUTCString();

        let timeZoneName = DateTimeService.getTimeZoneName();

        let requestUrl = `dateFrom=${datumKalendaraFromQueryString}&dateTo=${datumKalendaraToQueryString}&timeZoneName=${timeZoneName}`;
        return requestUrl;
    }

    async loadData(datumKalendaraFrom, datumKalendaraTo) {
        this.setState({
            loading: true
        });

        this.setState({
            datumKalendaraFrom: datumKalendaraFrom,
            datumKalendaraTo: datumKalendaraTo
        });

        let requestUrl = `advokatDateRangeKalendar?${this.generateLoadDataRequestUrlQuery(datumKalendaraFrom, datumKalendaraTo)}`;

        const response = await fetchService.get(requestUrl);

        this.setState({
            kalendarData: response.success ? response.responseValue : [],
            loading: false
        });
    }

    confirmDownloadIzvozStavki(event) {
        confirmDialog({
            trigger: event.currentTarget,
            message: 'Da li želite da preuzmete podatke i sačuvate ih na disk?',
            header: 'Preuzimanje podataka',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-info',
            accept: () => { this.downloadIzvozStavki() },
            acceptLabel: 'Da',
            rejectLabel: 'Ne'
        });
    };

    async downloadIzvozStavki() {
        let requestUrl = `advokatDateRangeKalendarExport?${this.generateLoadDataRequestUrlQuery(this.state.datumKalendaraFrom, this.state.datumKalendaraTo)}`;
        const dataBlob = await fetchService.getBlob(requestUrl);
        this.pushBlobToUser(dataBlob.responseValue, "kalendar.csv");
    }

    pushBlobToUser(dataBlob, fileName) {
        var objectURL = URL.createObjectURL(dataBlob);
        const link = document.createElement('a');
        link.href = objectURL;
        link.download = fileName;
        link.click();
    }
}
