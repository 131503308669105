import SlideClass from './libs/slideclass.js';
import ConstantsClass from './libs/constantsclass.js';
import Poppers from './libs/poppers.js';
import Cookies from 'js-cookie';

class LayoutClass {
    SlideInstance = new SlideClass();
    slideUp = this.SlideInstance.slideUp;
    slideDown = this.SlideInstance.slideDown;

    mountLayout() {
        this.ConstantsInstance = new ConstantsClass();
        this.PoppersInstance = new Poppers();

        document.getElementById('btn-collapse').addEventListener('click', () => {
            this.onCollapseClick();
        });

        document.getElementById('btn-toggle').addEventListener('click', () => {
            this.onToggleClick();
        });

        document.getElementById('overlay').addEventListener('click', () => {
            this.onOverlayClick();
        });

        const defaultOpenMenus = document.querySelectorAll('.menu-item.sub-menu.open');
        defaultOpenMenus.forEach((element) => {
            element.lastElementChild.style.display = 'block';
        });

        this.onFirstSubmenuClick();

        this.onInnerSubmenuClick();
    };

    /**
     * wait for the current animation to finish and update poppers position
     */
    updatePoppersTimeout = () => {
        setTimeout(() => {
            this.PoppersInstance.updatePoppers();
        }, this.ConstantsInstance.ANIMATION_DURATION());
    };

    /**
     * sidebar collapse handler
     */
    onCollapseClick() {
        var SIDEBAR_EL = this.ConstantsInstance.SIDEBAR_EL();
        var FIRST_SUB_MENUS_BTN = this.ConstantsInstance.FIRST_SUB_MENUS_BTN();

        SIDEBAR_EL.classList.toggle('collapsed');
        this.PoppersInstance.closePoppers();

        let collapsed = SIDEBAR_EL.classList.contains('collapsed');
        if (collapsed)
            FIRST_SUB_MENUS_BTN.forEach((element) => {
                element.parentElement.classList.remove('open');
            });

        this.updatePoppersTimeout();

        Cookies.set('SideBarMenuCollapsed', collapsed);
    };

    /**
     * sidebar toggle handler (on break point )
     */
    onToggleClick() {
        var SIDEBAR_EL = this.ConstantsInstance.SIDEBAR_EL();
        SIDEBAR_EL.classList.toggle('toggled');

        this.updatePoppersTimeout();
    };

    /**
     * toggle sidebar on overlay click
     */
    onOverlayClick() {
        var SIDEBAR_EL = this.ConstantsInstance.SIDEBAR_EL();
        SIDEBAR_EL.classList.toggle('toggled');
    };

    /**
     * handle top level submenu click
     */
    onFirstSubmenuClick() {
        var FIRST_SUB_MENUS_BTN = this.ConstantsInstance.FIRST_SUB_MENUS_BTN();
        var SIDEBAR_EL = this.ConstantsInstance.SIDEBAR_EL();

        FIRST_SUB_MENUS_BTN.forEach((element) => {
            element.addEventListener('click', () => {
                if (SIDEBAR_EL.classList.contains('collapsed'))
                    this.PoppersInstance.togglePopper(element.nextElementSibling);
                else {
                    /**
                     * if menu has "open-current-only" class then only one submenu opens at a time
                     */
                    const parentMenu = element.closest('.menu.open-current-submenu');
                    if (parentMenu)
                        parentMenu
                            .querySelectorAll(':scope > ul > .menu-item.sub-menu > a')
                            .forEach(
                                (el) =>
                                    window.getComputedStyle(el.nextElementSibling).display !==
                                    'none' && this.slideUp(el.nextElementSibling)
                            );
                    this.slideToggle(element.nextElementSibling);
                }
            });
        });
    };

    /**
     * handle inner submenu click
     */
    onInnerSubmenuClick() {
        var INNER_SUB_MENUS_BTN = this.ConstantsInstance.INNER_SUB_MENUS_BTN();

        INNER_SUB_MENUS_BTN.forEach((element) => {
            element.addEventListener('click', () => {
                this.slideToggle(element.nextElementSibling);
            });
        });
    };

    slideToggle(target, duration = 300) {
        if (window.getComputedStyle(target).display === 'none') return this.slideDown(target, duration);
        return this.slideUp(target, duration);
    }
}

export default LayoutClass;