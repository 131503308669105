import React, { Component } from 'react';
import fetchService from '../FetchService';
import { TabView, TabPanel } from 'primereact/tabview';
import { KancelarijaOsnovniPodaciContainer } from './KancelarijaOsnovniPodaciContainer';
import { AdvokatiKancelarije } from './AdvokatiKancelarije';
import { TimoviKancelarije } from './TimoviKancelarije';
    
export class KancelarijaContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            kancelarijaId: null,
            kancelarija: {}
        };
    }

    componentDidMount() {
        const id = (new URLSearchParams(document.location.search)).get('id');
        this.setState(
            {
                kancelarijaId: id ?? ""
            },
            () => this.loadDataFromApi()
                .then(() => { this.setState({ loading: false }); })
        );
    }

    async loadDataFromApi() {
        if (!this.state.kancelarijaId) return;

        let requestUrl = "kancelarijeAdvokata/" + this.state.kancelarijaId;
        const response = await fetchService.get(requestUrl);

        let result = response.success ? response.responseValue : {};

        this.setState({
            kancelarija: result
        });

        return result;
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : <KancelarijaOsnovniPodaciContainer kancelarija={this.state.kancelarija} onKancelarijaUpdate={(kancelarija) => this.setState({ kancelarija: kancelarija })} />;

        let advokatiKancelarije = this.state.loading || !this.state.kancelarijaId ? '' :
            <TabPanel header="Advokati">
                <AdvokatiKancelarije kancelarija={this.state.kancelarija} advokati={this.state.kancelarija.advokati} onAdvokatiMount={() => this.loadDataFromApi()} />
            </TabPanel>;

        let timoviKancelarije = this.state.loading || !this.state.kancelarijaId ? '' :
            <TabPanel header="Timovi">
                <TimoviKancelarije kancelarija={this.state.kancelarija} timovi={this.state.kancelarija.timovi} onTimoviMount={() => this.loadDataFromApi()} />
            </TabPanel>;

        let title = this.state.kancelarijaId
            ? `Kancelarija ${this.state.kancelarija.naziv}`
            : 'Nova kancelarija';

        return (
            <div>
                <h2 id="tabelLabel" className='mb-3 page-title'>{title}</h2>

                <TabView>
                    <TabPanel header="Osnovni podaci">
                        {contents}
                    </TabPanel>
                    {advokatiKancelarije}
                    {timoviKancelarije}
                </TabView>
            </div>
        );
    }
}
