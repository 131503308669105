import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DateTimeService } from '../DateTimeService';
import FileAddWhiteIcon from '../icons/file-add-white.svg';
import { RacunEdit } from './RacunEdit';
import { HelpTooltip } from '../HelpTooltip';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

export class RacuniPredmeta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            racuni: [],
            totalIznos: 0,
            loading: true,
            editMode: false,
            editingItem: {},
            valute: []
        };

        this.rowSelected = this.rowSelected.bind(this);
        this.newItemTriggered = this.newItemTriggered.bind(this);
        this.onRacunEditSaveClick = this.onRacunEditSaveClick.bind(this);
        this.onRacunEditCancelClick = this.onRacunEditCancelClick.bind(this);
    }

    async componentDidMount() {
        let racuniWithSum = this.props.racuni ?? await this.props.onRacuniMount();
        let racuni = racuniWithSum.racuni;
        let totalIznos = racuniWithSum.totalIznos;
        let valute = this.props.valute ?? await this.props.onValuteMount();
        let odgovornaLica = this.props.odgovornaLica ?? await this.props.onOdgovornaLicaMount();

        this.setState({
            racuni: racuni,
            totalIznos: totalIznos,
            valute: valute,
            odgovornaLica: odgovornaLica,
            loading: false
        });
    }

    renderRacuniPredmeta(racuni) {
        var totalIznosString = this.state.totalIznos ? this.state.totalIznos.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }) : 0;

        const footer = `Ukupno stavki: ${racuni ? racuni.length : 0}.`;

        const footerGroup = (
            <ColumnGroup>
                <Row>
                    <Column footer="Ukupno:" colSpan={2} footerStyle={{ textAlign: 'right' }} />
                    <Column footer={totalIznosString} />
                    <Column footer="" colSpan={4} />
                </Row>
            </ColumnGroup>
        );

        return (
            <div>
                <div className="form-header">
                    <button className="btn btn-primary btns" onClick={this.newItemTriggered}>
                        <img alt="" src={FileAddWhiteIcon} />
                        &nbsp;
                        Račun
                    </button>
                    <HelpTooltip>
                        <p>
                            Računi predmeta su informacije o finansijama predmeta.
                        </p>
                        <p>
                            Ovde možete unositi sva dugovanja klijenta, kao i ostale troškove predmeta.
                        </p>
                        <p>
                            Račune možete kategorizovati po tipu. Svakom računu možete dodeliti po jedan tip, i kasnije vršiti lakšu pretragu i filtriranje.
                        </p>
                        <p>
                            Tipovi računa se mogu definisati u administrativnom delu osnovnih podataka aplikacije.
                        </p>
                    </HelpTooltip>
                </div>

                <DataTable value={racuni}
                    dataKey="id"
                    sortField="datum" sortOrder={-1}
                    tableStyle={{ minWidth: '50rem' }} className="custom-datatable"
                    emptyMessage="Nema unetih podataka."
                    selectionMode="single" onSelectionChange={(e) => this.rowSelected(e.value)} 
                    footerColumnGroup={footerGroup}
                    footer={footer}
                >
                    <Column field="datum" body={this.datumTemplate} sortable header="Vreme" style={{ width: '15%' }}></Column>
                    <Column field="tipRacuna" header="Tip računa" sortable style={{ width: '20%' }}></Column>
                    <Column field="iznos" header="Iznos" body={this.iznosTemplate} sortable style={{ width: '14%' }}></Column>
                    <Column field="valuta" header="Valuta" body={this.valutaTemplate} sortable style={{ width: '8%' }}></Column>
                    <Column field="isplacen" header="Isplaćen" body={this.isplacenTemplate} sortable style={{ width: '8%' }}></Column>
                    <Column field="opis" header="Opis" sortable style={{ width: '25%' }}></Column>
                    <Column field="imePrezimeOdgovornogLica" header="Odgovorno lice" style={{ width: '10%' }}></Column>
                </DataTable>
            </div>
        );
    }

    datumTemplate = (racunPredmeta) => {
        return DateTimeService.formatDateTimeToDateString(racunPredmeta.datum);
    };

    valutaTemplate = (racunPredmeta) => {
        let valuta = this.state.valute.find(x => {
            return x.id === racunPredmeta.valuta;
        });
        return valuta.naziv;
    };

    iznosTemplate = (racunPredmeta) => {
        return racunPredmeta.iznos.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    isplacenTemplate = (racunPredmeta) => {
        return racunPredmeta.isplacen === true ? 'Da' : 'Ne';
    };

    rowSelected(item) {
        this.setState({
            editingItem: item,
            editMode: true
        });
    }

    newItemTriggered() {
        this.setState({
            editingItem: {},
            editMode: true
        });
    }

    onRacunEditSaveClick() {
        this.setState({
            loading: true,
            editMode: false
        }, async () => {
            let racuniWithSum = await this.props.onRacuniMount();
            let racuni = racuniWithSum.racuni;
            let totalIznos = racuniWithSum.totalIznos;

            this.setState({
                racuni: racuni,
                totalIznos: totalIznos,
                loading: false
            });
        });
    }

    onRacunEditCancelClick() {
        this.setState({
            editMode: false
        });
    }

    render() {
        let displayDataContent = this.state.editMode
            ? <RacunEdit racunPredmeta={this.state.editingItem} predmetId={this.props.predmet.id}
                imePrezimeKlijenta={this.props.imePrezimeKlijenta} brojPredmeta={this.props.predmet.brojPredmetaFull}
                onSaveClick={this.onRacunEditSaveClick} onCancelClick={this.onRacunEditCancelClick}
                tipoviRacuna={this.props.tipoviRacuna} onTipoviRacunaMount={() => this.props.onTipoviRacunaMount()}
                valute={this.props.valute} onValuteMount={() => this.props.onValuteMount()}
                odgovornaLica={this.state.odgovornaLica}
            />
            : this.renderRacuniPredmeta(this.state.racuni);

        let contents =
            this.state.loading
                ? <p><em>Učitavanje...</em></p>
                : displayDataContent;

        return (
            <div>
                {contents}
            </div>
        );
    }
}
