import React, { Component } from 'react';
import fetchService from './FetchService';
import { LoginMenu } from './api-authorization/LoginMenu';
import authService from './api-authorization/AuthorizeService';
import LogoBlackWithTitleIcon from './logo/logo-black-with-title.png';
import HomeIcon from './icons/home-2-line.svg';
import Group2Icon from './icons/group-2-line.svg';
import Group2FillIcon from './icons/group-2-fill.svg';
import ArticleIcon from './icons/article-line.svg';
import CalendarCheckIcon from './icons/calendar-check-line.svg';
import ArrowLeftDoubleBigIcon from './icons/arrow-left-double-line-big.svg';
import EditCircleLineIcon from './icons/edit-circle-line.svg';
import PriceTagLineIcon from './icons/price-tag-line.svg';
import InboxUnarchiveLineIcon from './icons/inbox-unarchive-line.svg';
import ArchiveDrawerLineIcon from './icons/archive-drawer-line.svg';
import CommandLineIcon from './icons/command-line.svg';
import BuildingLineIcon from './icons/building-line.svg';
import Building4FillIcon from './icons/building-4-fill.svg';
import FolderVideoIcon from './icons/folder-video.svg';

export class SidebarMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            deferredPrompt: null,
            installAppVisible: false
        };

        window.addEventListener('beforeinstallprompt', (e) => {
            this.setState({
                installAppVisible: true,
                deferredPrompt: e
            });
        });

        this.addToHomeScreen = this.addToHomeScreen.bind(this);
    }

    async componentDidMount() {
        let isAuthenticated = await authService.isAuthenticated();
        let radnaKancelarijaNaziv = await this.getRadnaKancelarijaNaziv();
        this.setState({
            isAuthenticated: isAuthenticated,
            radnaKancelarijaNaziv: radnaKancelarijaNaziv ? radnaKancelarijaNaziv : "Izaberite kancelariju..."
        });
    }

    render() {
        let pocetakActive = window.location.pathname === '/dashboard' ? 'active' : '';

        let klijentiActive = window.location.pathname === '/klijenti' ? 'active' : ''
            || window.location.pathname === '/klijent' ? 'active' : '';

        let predmetiActive = window.location.pathname === '/predmeti' ? 'active' : ''
            || window.location.pathname === '/predmet' ? 'active' : '';

        let pregledPodatakaActive = window.location.pathname.startsWith('/pregled-podataka/') ? 'open' : '';
        let pregledZapisaActive = window.location.pathname === '/pregled-podataka/zapisi' ? 'active' : ''
        let pregledRocistaActive = window.location.pathname === '/pregled-podataka/rocista' ? 'active' : ''
        let pregledRokovaActive = window.location.pathname === '/pregled-podataka/rokovi' ? 'active' : ''
        let pregledDokumenataActive = window.location.pathname === '/pregled-podataka/dokumenta' ? 'active' : ''
        let pregledRacunaActive = window.location.pathname === '/pregled-podataka/racuni' ? 'active' : ''

        let tipoviPredmetaActive = window.location.pathname === '/tipovi-predmeta' ? 'active' : ''
            || window.location.pathname === '/tip-predmeta' ? 'active' : '';

        let tipoviRacunaActive = window.location.pathname === '/tipovi-racuna' ? 'active' : ''
            || window.location.pathname === '/tip-racuna' ? 'active' : '';

        let kalendarActive = window.location.pathname === '/kalendar' ? 'active' : '';

        let masovneOperacijeActive = window.location.pathname === '/uvoz-izvoz-klijenata' ? 'active' : ''
            || window.location.pathname === '/uvoz-izvoz-predmeta' ? 'active' : ''
                || window.location.pathname === '/uvoz-izvoz-tipova-predmeta' ? 'active' : '';

        let protivniciActive = window.location.pathname === '/protivnici' ? 'active' : ''
            || window.location.pathname === '/protivnik' ? 'active' : '';

        let kancelarijeActive = window.location.pathname === '/kancelarije' ? 'active' : ''
            || window.location.pathname === '/kancelarija' ? 'active' : '';

        return (
            <div className="sidebar-layout">

                <a>
                    <div className="sidebar-header">
                        <div className="zastupnik-logo">
                            <div><img alt="" src={LogoBlackWithTitleIcon} /></div>
                        </div>
                    </div>
                </a>

                <div className="sidebar-content">
                    <nav className="menu open-current-submenu">
                        <ul>
                            <li hidden={this.state.radnaKancelarijaNaziv ? '' : 'hidden'} className={`menu-item`}>
                                <a href="/radne-kancelarije">
                                    <span className="menu-icon">
                                        <img alt="" src={Building4FillIcon} />
                                    </span>
                                    <span className="menu-title"><b>{this.state.radnaKancelarijaNaziv}</b></span>
                                </a>
                            </li>




                            <li className="menu-item">
                                <a id="btn-collapse" className="sidebar-collapser-2">
                                    <span className="menu-icon sidebar-collapser-2">
                                        <img src={ArrowLeftDoubleBigIcon} />
                                    </span>
                                </a>
                            </li>

                            <li className={`menu-item ${pocetakActive}`}>
                                <a href="/dashboard">
                                    <span className="menu-icon">
                                        <img alt="" src={HomeIcon} />
                                    </span>
                                    <span className="menu-title">Početak</span>
                                </a>
                            </li>

                            <li hidden={this.state.isAuthenticated ? '' : 'hidden'} className={`menu-item ${kalendarActive}`}>
                                <a href="/kalendar">
                                    <span className='menu-icon'>
                                        <img alt="" src={CalendarCheckIcon} />
                                    </span>
                                    <span className="menu-title">Kalendar</span>
                                </a>
                            </li>

                            <li hidden={this.state.isAuthenticated ? '' : 'hidden'} className={`menu-item ${predmetiActive}`}>
                                <a href="/predmeti">
                                    <span className="menu-icon">
                                        <img alt="" src={ArticleIcon} />
                                    </span>
                                    <span className="menu-title">Predmeti</span>
                                </a>
                            </li>

                            <li hidden={this.state.isAuthenticated ? '' : 'hidden'} className={`menu-item sub-menu ${pregledPodatakaActive}`}>
                                <a>
                                    <span className="menu-icon">
                                        <img alt="" src={ArchiveDrawerLineIcon} />
                                    </span>
                                    <span className="menu-title">Pregled podataka</span>
                                </a>
                                <div className="sub-menu-list">
                                    <ul>
                                        <li className={`menu-item ${pregledZapisaActive}`}>
                                            <a href="/pregled-podataka/zapisi">
                                                <span className="menu-title">Zapisi</span>
                                            </a>
                                        </li>
                                        <li className={`menu-item ${pregledRocistaActive}`}>
                                            <a href="/pregled-podataka/rocista">
                                                <span className="menu-title">Ročišta</span>
                                            </a>
                                        </li>
                                        <li className={`menu-item ${pregledRokovaActive}`}>
                                            <a href="/pregled-podataka/rokovi">
                                                <span className="menu-title">Rokovi</span>
                                            </a>
                                        </li>
                                        <li className={`menu-item ${pregledDokumenataActive}`}>
                                            <a href="/pregled-podataka/dokumenta">
                                                <span className="menu-title">Dokumenta</span>
                                            </a>
                                        </li>
                                        <li className={`menu-item ${pregledRacunaActive}`}>
                                            <a href="/pregled-podataka/racuni">
                                                <span className="menu-title">Računi</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li hidden={this.state.isAuthenticated ? '' : 'hidden'} className={`menu-item ${klijentiActive}`}>
                                <a href="/klijenti">
                                    <span className="menu-icon">
                                        <img alt="" src={Group2Icon} />
                                    </span>
                                    <span className="menu-title">Klijenti</span>
                                </a>
                            </li>

                            <li hidden={this.state.isAuthenticated ? '' : 'hidden'} className={`menu-item ${protivniciActive}`}>
                                <a href="/protivnici">
                                    <span className="menu-icon">
                                        <img alt="" src={Group2FillIcon} />
                                    </span>
                                    <span className="menu-title">Protivnici</span>
                                </a>
                            </li>

                            {/*<li hidden={this.state.isAuthenticated ? '' : 'hidden'} className="menu-item sub-menu">*/}
                            {/*    <a>*/}
                            {/*        <span className="menu-icon">*/}
                            {/*            <img alt="" src={FocusLineIcon} />*/}
                            {/*        </span>*/}
                            {/*        <span className="menu-title">Portali</span>*/}
                            {/*    </a>*/}
                            {/*    <div className="sub-menu-list">*/}
                            {/*        <ul>*/}
                            {/*            <li className="menu-item">*/}
                            {/*                <a href="https://tpson.portal.sud.rs/tposvs/" target='_blank'>*/}
                            {/*                    <span className="menu-title">Osnovni i viši</span>*/}
                            {/*                </a>*/}
                            {/*            </li>*/}
                            {/*            <li className="menu-item">*/}
                            {/*                <a href="https://tpson.portal.sud.rs/tpprvs/" target='_blank'>*/}
                            {/*                    <span className="menu-title">Privredni</span>*/}
                            {/*                </a>*/}
                            {/*            </li>*/}
                            {/*        </ul>*/}
                            {/*    </div>*/}
                            {/*</li>*/}

                            <li hidden={this.state.isAuthenticated ? '' : 'hidden'} className="menu-header"><span> Osnovni podaci </span></li>

                            <li hidden={this.state.isAuthenticated ? '' : 'hidden'} className={`menu-item ${tipoviPredmetaActive}`}>
                                <a href="/tipovi-predmeta">
                                    <span className="menu-icon">
                                        <img alt="" src={EditCircleLineIcon} />
                                    </span>
                                    <span className="menu-title">Tipovi predmeta</span>
                                </a>
                            </li>

                            <li hidden={this.state.isAuthenticated ? '' : 'hidden'} className={`menu-item ${tipoviRacunaActive}`}>
                                <a href="/tipovi-racuna">
                                    <span className="menu-icon">
                                        <img alt="" src={PriceTagLineIcon} />
                                    </span>
                                    <span className="menu-title">Tipovi računa</span>
                                </a>
                            </li>



                            {/*<li hidden={this.state.isAuthenticated ? '' : 'hidden'} className={`menu-item sub-menu ${masovneOperacijeActive}`}>*/}
                            {/*    <a>*/}
                            {/*        <span className="menu-icon">*/}
                            {/*            <img alt="" src={InboxUnarchiveLineIcon} />*/}
                            {/*        </span>*/}
                            {/*        <span className="menu-title">Uvoz/izvoz podataka</span>*/}
                            {/*    </a>*/}
                            {/*    <div className="sub-menu-list">*/}
                            {/*        <ul>*/}
                            {/*            <li className="menu-item">*/}
                            {/*                <a href="/uvoz-izvoz-klijenata">*/}
                            {/*                    <span className="menu-title">Klijenti</span>*/}
                            {/*                </a>*/}
                            {/*            </li>*/}
                            {/*            <li className="menu-item">*/}
                            {/*                <a href="/uvoz-izvoz-tipova-predmeta">*/}
                            {/*                    <span className="menu-title">Tipovi predmeta</span>*/}
                            {/*                </a>*/}
                            {/*            </li>*/}
                            {/*            <li className="menu-item">*/}
                            {/*                <a href="/uvoz-izvoz-predmeta">*/}
                            {/*                    <span className="menu-title">Predmeti</span>*/}
                            {/*                </a>*/}
                            {/*            </li>*/}
                            {/*        </ul>*/}
                            {/*    </div>*/}
                            {/*</li>*/}

                            <li className="menu-header"><span> Nalog </span></li>

                            <li hidden={this.state.isAuthenticated ? '' : 'hidden'} className={`menu-item ${kancelarijeActive}`}>
                                <a href="/kancelarije">
                                    <span className="menu-icon">
                                        <img alt="" src={BuildingLineIcon} />
                                    </span>
                                    <span className="menu-title">Kancelarije</span>
                                </a>
                            </li>

                            <LoginMenu>
                            </LoginMenu>

                            <hr />

                            <li className={`menu-item`}>
                                <a href="/tutorijal">
                                    <span className="menu-icon">
                                        <img alt="" src={FolderVideoIcon} />
                                    </span>
                                    <span className="menu-title">Tutorijal</span>
                                </a>
                            </li>

                            <span hidden={this.state.isAuthenticated && this.state.installAppVisible ? '' : 'hidden'} >
                                <li className={`menu-item`}>
                                    <a onClick={(e) => this.addToHomeScreen()} className='cursor-pointer'>
                                        <span className="menu-icon">
                                            <img alt="" src={CommandLineIcon} />
                                        </span>
                                        <span className="menu-title">Instaliraj aplikaciju</span>
                                    </a>
                                </li>
                            </span>
                        </ul>
                    </nav>
                </div>

            </div>
        );
    }

    async addToHomeScreen() {
        let deferredPrompt = this.state.deferredPrompt;

        if (deferredPrompt !== null) {
            deferredPrompt.prompt();
            const { outcome } = await deferredPrompt.userChoice;
            if (outcome === 'accepted') {
                this.setState({
                    deferredPrompt: null,
                    installAppVisible: false
                });
            }
        }
    }

    async getRadnaKancelarijaNaziv() {
        let requestUrl = `radnaKancelarijaAdvokata`;
        const response = await fetchService.get(requestUrl);
        return response.success ? response.responseValue.naziv : null;
    }
}
