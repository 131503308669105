export class ValutaService {

    static loadValute() {
        let data = [
            { id: 0, naziv: 'Ostalo' },
            { id: 1, naziv: 'RSD' },
            { id: 2, naziv: 'EUR' },
            { id: 3, naziv: 'USD' },
            { id: 4, naziv: 'CHF' },
            { id: 5, naziv: 'GBP' },
            { id: 6, naziv: 'AUD' },
            { id: 7, naziv: 'CAD' },
            { id: 8, naziv: 'HRK' },
            { id: 9, naziv: 'BAM' },
            { id: 10, naziv: 'HUF' },
            { id: 11, naziv: 'RUB' },
            { id: 12, naziv: 'BGN' },
            { id: 13, naziv: 'RON' }
        ];

        return data;
    }
}
