import React, { Component } from 'react';
import './home.css';
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from 'primereact/inputnumber';

export class Cenovnik extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedKancelarija: 0,
            selectedNumberOfAdministratori: 0,
            selectedNumberOfAdvokati: 0,
            selectedNumberOfPremiumAdvokati: 0,
            selectedTotalPrice: '',

            singleKancelarijaPrice: 1299,
            singleKancelarijaPlusPrice: 1599,
            singlePremiumKancelarijaPrice: 2499,
            singleAdministratorPrice: 599,
            singleAdvokatPrice: 399,
            singlePremiumAdvokatPrice: 1199,

            panelSamostalniAdvokatCollapsed: false,
            panelKancelarijaCollapsed: false
        };
    }

    componentDidMount() {
        this.calculatePrices();
    }

    setPanels(samostalniAdvokat, kancelarija) {
        this.setState({
            panelSamostalniAdvokatCollapsed: samostalniAdvokat,
            panelKancelarijaCollapsed: kancelarija
        });
    }

    render() {



        return (
            <div id='section-pricing' className="container my-5 px-5 py-5">
                <h2 className="display-5 fw-bold lh-1 text-body-emphasis pb-2 mb-4 border-bottom">Cenovnik</h2>

                <div className='lead'>
                    <p>
                        Ne posluju sve kancelarije na isti način. Iz tog razloga imate mogućnost da izabetere ili formirate paket po Vašim potrebama.
                    </p>
                    <p>
                        Vi birate koji tip kancelarije želite i naloge advokata u okviru kancelarije.
                    </p>
                    <p>
                        U svakom trenutku korišćenja aplikacije možete nadograditi Vašu kancelariju promenom paketa ili promenom broja advokata koji mogu pristupiti Vašoj kancelariji.
                    </p>
                </div>

                <center>
                    <div hidden={this.state.panelSamostalniAdvokatCollapsed === true || this.state.panelKancelarijaCollapsed === true}>
                        <button className="m-2 btn btn-primary btn-lg px-4 cenovnik-button" onClick={() => this.setPanels(true, false)} >Samostalni advokat</button>
                        <button className="m-2 btn btn-primary btn-lg px-4 cenovnik-button" onClick={() => this.setPanels(false, true)} >Advokatska kancelarija</button>
                    </div>
                </center>

                <div hidden={this.state.panelSamostalniAdvokatCollapsed === false}>
                    <button className="m-2 btn btn-primary btn-lg px-4 cenovnik-button" onClick={() => this.setPanels(false, false)} >Nazad</button>

                    <center><h2 className='mb-5'>Samostalni advokat</h2></center>

                    {this.section6_samostalniAdvokat()}
                    {this.section6_TabelaSamostalniAdvokat()}
                </div>
                <div hidden={this.state.panelKancelarijaCollapsed === false}>
                    <button className="m-2 btn btn-primary btn-lg px-4 cenovnik-button" onClick={() => this.setPanels(false, false)} >Nazad</button>

                    <center><h2 className='mb-5'>Kancelarija</h2></center>

                    {this.section6_kancelarija()}
                    {this.section6_TabelaKancelarija()}
                    {this.section6_TabelaKancelarijaAdvokati()}
                </div>
            </div>
        );
    }

    section6_samostalniAdvokat() {
        return (
            <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3 mb-3 text-center mt-3">

                <div className="col">
                    <div className="card mb-4 rounded-3 shadow-sm min-height-card-3">
                        <div className="card-header py-3">
                            <h4 className="my-0 fw-normal">Advokat početnik</h4>
                        </div>
                        <div className="card-body">
                            <h1 className="card-title pricing-card-title">0&nbsp;<small className="text-body-primary fw-light">rsd/mes</small></h1>
                            <ul className="list-unstyled mt-4 mb-4 min-height-card-1">
                                <li>Evidencija klijenata i predmeta</li>
                                <li>Evidencija zapisa predmeta</li>
                                <li>Vodjenje evidencije do 200 predmeta</li>
                                <li>Kalendar</li>
                                <li>&nbsp;</li>
                            </ul>
                            <a href="/Identity/Account/Register" className="w-100 btn btn-lg btn-outline-primary">Registruj se</a>
                        </div>
                        <p>Prva 3 meseca pristup svim funkcionalnostima aplikacije.</p>
                    </div>
                </div>

                <div className="col">
                    <div className="card mb-4 rounded-3 shadow-sm min-height-card-3">
                        <div className="card-header py-3">
                            <h4 className="my-0 fw-normal">Advokat saradnik</h4>
                        </div>
                        <div className="card-body">
                            <h1 className="card-title pricing-card-title">799,00&nbsp;<small className="text-body-primary fw-light">rsd/mes</small></h1>
                            <ul className="list-unstyled mt-4 mb-4 min-height-card-1">
                                {/*<li>Evidencija klijenata i predmeta</li>*/}
                                {/*<li>Evidencija zapisa, ročišta, rokova, dokumenta i računa predmeta</li>*/}
                                {/*<li>Kalendar</li>*/}
                                <li>Sve funkcionalnosti aplikacije</li>
                                <li>Evidencija do 200 predmeta</li>
                                <li>Mogućnost pristupa u 3 advokatske kancelarije kao deo tima</li>
                                <li>E-mail podrška</li>
                                <li>&nbsp;</li>
                            </ul>
                            <a href="#section-contact" className="w-100 btn btn-lg btn-primary">Kontaktiraj nas</a>
                        </div>
                        <p>Minimalni obračunski period je 6 meseci.</p>
                    </div>
                </div>

                <div className="col">
                    <div className="card mb-4 rounded-3 shadow-sm min-height-card-3">
                        <div className="card-header py-3">
                            <h4 className="my-0 fw-normal">Premium samostalni advokat</h4>
                        </div>
                        <div className="card-body">
                            <h1 className="card-title pricing-card-title">1.699,00&nbsp;<small className="text-body-primary fw-light">rsd/mes</small></h1>
                            <ul className="list-unstyled mt-4 mb-4 min-height-card-1">
                                <li>Sve funkcionalnosti aplikacije</li>
                                <li>Evidencija neograničenog broja predmeta</li>
                                <li>Mogućnost pristupa u neograničen broj advokatskih kancelarija kao deo tima</li>
                                <li>E-mail podrška</li>
                                <li>Podrška servisnog centra</li>
                            </ul>
                            <a href="#section-contact" className="w-100 btn btn-lg btn-primary">Kontaktiraj nas</a>
                        </div>
                        <p>Minimalni obračunski period je 6 meseci.</p>
                    </div>
                </div>

            </div>
        );
    }

    section6_kancelarija() {
        let singleKancelarijaPriceString = this.state.singleKancelarijaPrice.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        let singleKancelarijaPlusPriceString = this.state.singleKancelarijaPlusPrice.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        let singlePremiumKancelarijaPriceString = this.state.singlePremiumKancelarijaPrice.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });


        let singleAdministratorPriceString = this.state.singleAdministratorPrice.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        let singleAdvokatPriceString = this.state.singleAdvokatPrice.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        let singlePremiumAdvokatPriceString = this.state.singlePremiumAdvokatPrice.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        return (
            <div className="mt-3">

                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 mb-3 text-center mt-3">

                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                            <div className="card-header py-3">
                                <h4 className="my-0 fw-normal">Mala kancelarija</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">{singleKancelarijaPriceString}&nbsp;<small className="text-body-secondary fw-light">rsd/mes</small></h1>
                                <ul className="list-unstyled mt-4 mb-4">
                                    <li>Sve funkcionalnosti aplikacije</li>
                                    <li>Evidencija do 200 predmeta</li>
                                    <li>Jedan administratorski nalog</li>
                                    <li>Maksimalno 1 administrator, 2 tima, 5 advokata &nbsp;&nbsp;&nbsp;&nbsp;</li>
                                    <li>E-mail podrška</li>
                                    <li>&nbsp;</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                            <div className="card-header py-3">
                                <h4 className="my-0 fw-normal">Kancelarija</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">{singleKancelarijaPlusPriceString}&nbsp;<small className="text-body-secondary fw-light">rsd/mes</small></h1>
                                <ul className="list-unstyled mt-4 mb-4">
                                    <li>Sve funkcionalnosti aplikacije</li>
                                    <li>Evidencija neograničenog broja predmeta</li>
                                    <li>Jedan administratorski nalog</li>
                                    <li>Maksimalno 1 administrator, 2 tima, 5 advokata &nbsp;&nbsp;&nbsp;&nbsp;</li>
                                    <li>E-mail podrška</li>
                                    <li>&nbsp;</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                            <div className="card-header py-3">
                                <h4 className="my-0 fw-normal">Premium kancelarija</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">{singlePremiumKancelarijaPriceString}&nbsp;<small className="text-body-secondary fw-light">rsd/mes</small></h1>
                                <ul className="list-unstyled mt-4 mb-4">
                                    <li>Sve funkcionalnosti aplikacije</li>
                                    <li>Evidencija neograničenog broja predmeta</li>
                                    <li>Jedan administratorski nalog</li>
                                    <li>Neograničen broj administratora, timova i advokata</li>
                                    <li>E-mail podrška</li>
                                    <li>Podrška servisnog centra</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='lead mb-5'>
                    <p>
                        Uz svaku novootvorenu kancelariju jedan administratorski nalog je uračunat.
                    </p>
                    <p>
                        Ukoliko Vaša kancelarija ima više advokata, možete dodatno izabrati željeni broj onakvih naloga advokata koji odgovaraju Vašim potrebama.
                    </p>
                    <p>
                        Ispod možete pronaći i kalkulator cena i izračunati Vaše mesečne troškove.
                    </p>
                </div>

                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 mb-3 text-center mt-3">

                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                            <div className="card-header py-3">
                                <h4 className="my-0 fw-normal">Administrator kancelarije</h4>
                            </div>
                            <div className="card-body min-height-card-2">
                                <h1 className="card-title pricing-card-title">{singleAdministratorPriceString}&nbsp;<small className="text-body-secondary fw-light">rsd/mes</small></h1>
                                <ul className="list-unstyled mt-4 mb-4">
                                    <li>Mogućnost pristupa jednoj kancelariji kao administrator</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                            <div className="card-header py-3">
                                <h4 className="my-0 fw-normal">Advokat kancelarije</h4>
                            </div>
                            <div className="card-body min-height-card-2">
                                <h1 className="card-title pricing-card-title">{singleAdvokatPriceString}&nbsp;<small className="text-body-secondary fw-light">rsd/mes</small></h1>
                                <ul className="list-unstyled mt-4 mb-4">
                                    <li>Mogućnost pristupa jednoj kancelariji kao član tima</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                            <div className="card-header py-3">
                                <h4 className="my-0 fw-normal">Premium advokat</h4>
                            </div>
                            <div className="card-body min-height-card-2">
                                <h1 className="card-title pricing-card-title">{singlePremiumAdvokatPriceString}&nbsp;<small className="text-body-secondary fw-light">rsd/mes</small></h1>
                                <ul className="list-unstyled mt-4 mb-4">
                                    <li>Mogućnost pristupa neograničenom broju kancelarija kao administrator ili član tima</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>

                <hr />

                <div className="row row-cols-1 row-cols-lg-2 mb-3 text-center mt-5">
                    <div className="col offset-lg-3">
                        <div className="card mb-4 rounded-3 shadow-sm">
                            <div className="card-header py-3">
                                <h4 className="my-0 fw-normal"><b>Kalkulator</b></h4>
                            </div>
                            <div className="card-body kalkulator">
                                <div>
                                    <b>Tip kancelarije</b>

                                    <div className="flex flex-wrap gap-3 mt-3">
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="ingredient1" onChange={() => this.setState({ selectedKancelarija: 1 }, () => { this.calculatePrices() })} checked={this.state.selectedKancelarija === 1} />
                                            &nbsp;
                                            <label htmlFor="ingredient1" className="ml-2">Kancelarija</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="ingredient2" onChange={() => this.setState({ selectedKancelarija: 2 }, () => { this.calculatePrices() })} checked={this.state.selectedKancelarija === 2} />
                                            &nbsp;
                                            <label htmlFor="ingredient2" className="ml-2">Kancelarija+</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="ingredient3" onChange={() => this.setState({ selectedKancelarija: 3 }, () => { this.calculatePrices() })} checked={this.state.selectedKancelarija === 3} />
                                            &nbsp;
                                            <label htmlFor="ingredient3" className="ml-2">Premium kancelarija</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5">
                                    <b>Dodatni nalozi</b>
                                </div>

                                <div className="flex flex-wrap gap-3 mt-4">
                                    Administrator kancelarije
                                    <InputNumber className='number-selector'
                                        value={this.state.selectedNumberOfAdministratori}
                                        onValueChange={(e) => this.setState({ selectedNumberOfAdministratori: e.value }, () => { this.calculatePrices() })}
                                        showButtons buttonLayout="horizontal" step={1} min={0}
                                        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                                </div>

                                <div className="flex flex-wrap gap-3 mt-4">
                                    Advokat kancelarije&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <InputNumber className='number-selector'
                                        value={this.state.selectedNumberOfAdvokati}
                                        onValueChange={(e) => this.setState({ selectedNumberOfAdvokati: e.value }, () => { this.calculatePrices() })}
                                        showButtons buttonLayout="horizontal" step={1} min={0}
                                        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                                </div>

                                <div className="flex flex-wrap gap-3 mt-4">
                                    Premium advokat&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <InputNumber className='number-selector'
                                        value={this.state.selectedNumberOfPremiumAdvokati}
                                        onValueChange={(e) => this.setState({ selectedNumberOfPremiumAdvokati: e.value }, () => { this.calculatePrices() })}
                                        showButtons buttonLayout="horizontal" step={1} min={0}
                                        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                                </div>

                                <hr />

                                <div>
                                    Ukupno: <b>{this.state.selectedTotalPrice}</b> rsd/mes
                                </div>

                                <a href="#section-contact" className="w-100 btn btn-lg btn-primary mt-4">Kontaktiraj nas</a>
                            </div>
                            <p>Minimalni obračunski period je 6 meseci.</p>
                        </div>
                    </div>

                </div>

            </div>
        );
    }

    calculatePrices() {
        let kancelarijaPrice = 0;
        if (this.state.selectedKancelarija === 1) {
            kancelarijaPrice = this.state.singleKancelarijaPrice;
        }
        else if (this.state.selectedKancelarija === 2) {
            kancelarijaPrice = this.state.singleKancelarijaPlusPrice;
        }
        else if (this.state.selectedKancelarija === 3) {
            kancelarijaPrice = this.state.singlePremiumKancelarijaPrice;
        }

        let administratoriPrice = this.state.selectedNumberOfAdministratori * this.state.singleAdministratorPrice;
        let advokatiPrice = this.state.selectedNumberOfAdvokati * this.state.singleAdvokatPrice;
        let premiumAdvokatiPrice = this.state.selectedNumberOfPremiumAdvokati * this.state.singlePremiumAdvokatPrice;

        let totalPrice = kancelarijaPrice + administratoriPrice + advokatiPrice + premiumAdvokatiPrice;

        let totalPriceString = totalPrice.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        this.setState({ selectedTotalPrice: totalPriceString });
    }

    section6_TabelaSamostalniAdvokat() {
        return (
            <div className="table-responsive pricing">
                <table className="table text-center">
                    <thead>
                        <tr>
                            <th style={{ "width": "40%" }}></th>
                            <th style={{ "width": "12%" }}>Advokat početnik</th>
                            <th style={{ "width": "12%" }}>Advokat saradnik</th>
                            <th style={{ "width": "12%" }}>Premium samostalni advokat</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <th scope="row" className="text-start">Dashboard</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                    </tbody>

                    <tbody>
                        <tr>
                            <th scope="row" className="text-start">Evidencija klijenata</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                    </tbody>

                    <tbody>
                        <tr>
                            <th scope="row" className="text-start">Evidencija protivnika</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                    </tbody>

                    <tbody>
                        <tr>
                            <th scope="row" className="text-start">Evidencija predmeta</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Maksimalan broj predmeta</th>
                            <td>200</td>
                            <td>200</td>
                            <td>Neograničeno</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Zapisi predmeta</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Ročišta predmeta</th>
                            <td></td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Rokovi predmeta</th>
                            <td></td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Dokumenta predmeta</th>
                            <td></td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Računi predmeta</th>
                            <td></td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                    </tbody>

                    <tbody>
                        <tr>
                            <th scope="row" className="text-start">Evidencija termina</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Kalendar po danu</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Kalendar za više dana</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Kalendar po mesecu</th>
                            <td></td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Prikaz ročišta u kalendaru</th>
                            <td></td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Prikaz rokova u kalendaru</th>
                            <td></td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Prikaz termina u kalendaru</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                    </tbody>

                    <tbody>
                        <tr>
                            <th scope="row" className="text-start">Pristup drugim kancelarijama</th>
                            <td></td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Maksimalan broj pristupa drugim kancelarijama</th>
                            <td>0</td>
                            <td>3</td>
                            <td>Neograničeno</td>
                        </tr>
                    </tbody>

                    <tbody>
                        <tr>
                            <th scope="row" className="text-start">E-mail podrška</th>
                            <td></td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Podrška servisnog centra</th>
                            <td></td>
                            <td></td>
                            <td>X</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }


    section6_TabelaKancelarija() {
        return (
            <div className="table-responsive pricing">
                <table className="table text-center">
                    <thead>
                        <tr>
                            <th style={{ "width": "40%" }}></th>
                            <th style={{ "width": "12%" }}>Kancelarija</th>
                            <th style={{ "width": "12%" }}>Kancelarija+</th>
                            <th style={{ "width": "12%" }}>Premium kancelarija</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <th scope="row" className="text-start">Dashboard</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                    </tbody>

                    <tbody>
                        <tr>
                            <th scope="row" className="text-start">Evidencija klijenata</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                    </tbody>

                    <tbody>
                        <tr>
                            <th scope="row" className="text-start">Evidencija protivnika</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                    </tbody>

                    <tbody>
                        <tr>
                            <th scope="row" className="text-start">Evidencija predmeta</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Maksimalan broj predmeta</th>
                            <td>200</td>
                            <td>Neograničeno</td>
                            <td>Neograničeno</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Zapisi predmeta</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Ročišta predmeta</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Rokovi predmeta</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Dokumenta predmeta</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Računi predmeta</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                    </tbody>

                    <tbody>
                        <tr>
                            <th scope="row" className="text-start">Evidencija termina</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Kalendar po danu</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Kalendar za više dana</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Kalendar po mesecu</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Prikaz ročišta u kalendaru</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Prikaz rokova u kalendaru</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Prikaz termina u kalendaru</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                    </tbody>

                    <tbody>
                        <tr>
                            <th scope="row" className="text-start">Maksimalan broj administratora</th>
                            <td>1</td>
                            <td>1</td>
                            <td>Neograničeno</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Maksimalan broj timova</th>
                            <td>2</td>
                            <td>2</td>
                            <td>Neograničeno</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Maksimalan broj advokata u kancelariji</th>
                            <td>5</td>
                            <td>5</td>
                            <td>Neograničeno</td>
                        </tr>
                    </tbody>

                    <tbody>
                        <tr>
                            <th scope="row" className="text-start">E-mail podrška</th>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Podrška servisnog centra</th>
                            <td></td>
                            <td></td>
                            <td>X</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    section6_TabelaKancelarijaAdvokati() {
        return (
            <div className="table-responsive pricing">
                <table className="table text-center">
                    <thead>
                        <tr>
                            <th style={{ "width": "40%" }}></th>
                            <th style={{ "width": "12%" }}>Administrator kancelarije</th>
                            <th style={{ "width": "12%" }}>Advokat kancelarije</th>
                            <th style={{ "width": "12%" }}>Premium advokat</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <th scope="row" className="text-start">Maksimalan broj kancelarija kao administrator</th>
                            <td>1</td>
                            <td>0</td>
                            <td>Neograničeno</td>
                        </tr>

                        <tr>
                            <th scope="row" className="text-start">Maksimalan broj kancelarija kao član tima</th>
                            <td>0</td>
                            <td>1</td>
                            <td>Neograničeno</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
