import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import PrimeReact from 'primereact/api';
import { Layout } from './components/Layout';
import './custom.css';
import { Home } from "./components/home/Home";
import { Tutorijal } from "./components/home/Tutorijal";
import { UsloviKoriscenja } from "./components/home/UsloviKoriscenja";
import { locale, addLocale } from 'primereact/api';

export default class App extends Component {
    static displayName = App.name;

    componentDidMount() {
        addLocale('rs', {
            firstDayOfWeek: 1,
            dayNames: ['nedelja', 'ponedeljak', 'utorak', 'sreda', 'četvrtak', 'petak', 'subota'],
            dayNamesShort: ['n', 'p', 'u', 's', 'č', 'p', 's'],
            dayNamesMin: ['N', 'P', 'U', 'S', 'Č', 'P', 'S'],
            monthNames: ['januar', 'februar', 'mart', 'april', 'maj', 'jun', 'jul', 'avgust', 'septembar', 'oktobar', 'novembar', 'decembar'],
            monthNamesShort: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'avg', 'sep', 'okt', 'nov', 'dec'],
            today: 'Danas',
            clear: 'Briši'
        });

        locale('rs');
    }

    render() {
        PrimeReact.ComponentScale = 2;

        return (
            <Routes>
                <Route key={true} element={<Home />} path='/' />
                <Route key={true} element={<Tutorijal />} path='/tutorijal' />
                <Route key={true} element={<UsloviKoriscenja contenttype='uslovi-koriscenja' />} path='/uslovi-koriscenja' />
                <Route key={true} element={<UsloviKoriscenja contenttype='pravila-privatnosti' />} path='/pravila-privatnosti' />
                {AppRoutes.map((route, index) => {
                    const { element, requireAuth, title, ...rest } = route;
                    return <Route key={index} {...rest} element={requireAuth ? <Layout pageTitle={title}><AuthorizeRoute {...rest} element={element} /></Layout> : <Layout pageTitle={title}>{element}</Layout>} />;
                })}
            </Routes>
        );
    }
}
