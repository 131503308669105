import React, { Component } from 'react';
import fetchService from '../FetchService';

export class SeedDate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pt: '',
            finished: '',
            veza: '',
            vezaResult: ''
        };

        this.seedData = this.seedData.bind(this);
        this.deleteData = this.deleteData.bind(this);
        this.pregledVeza = this.pregledVeza.bind(this);
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <div className="mb-3">
                    <label htmlFor="pt" className="form-label">Pass through</label>
                    <input className="form-control" id="pt" type="password" value={this.state.pt ?? ''}
                        onChange={(e) => this.setState({ pt: e.target.value })} />
                </div>

                <button className="btn btn-primary btns mb-5" onClick={this.seedData}>
                    Sačuvaj
                </button>

                <button className="btn btn-primary btns mb-5" onClick={this.deleteData}>
                    Briši
                </button>


                <div className="mb-3">
                    <label htmlFor="pt" className="form-label">Veza</label>
                    <input className="form-control" id="veza" type="text" value={this.state.veza ?? ''}
                        onChange={(e) => this.setState({ veza: e.target.value })} />
                </div>
                <button className="btn btn-primary btns" onClick={this.pregledVeza}>
                    Veza
                </button>
                <div className="mb-5">
                    <a href={this.state.vezaResult}>{this.state.vezaResult}</a>
                </div>


                <h2>{this.state.finished}</h2>
            </div>
        );
    }

    async seedData() {
        this.setState({ finished: 'Started...' });

        let requestUrl = `seedAdvokatData?passThrough=${this.state.pt}`;

        const response = await fetchService.get(requestUrl);

        let errorMessage = response.message ? 'Greška: ' + response.message : '';

        const finished = response.success ? 'FINISHED SUCCESS' : 'FINISHED FAIL ' + errorMessage;

        this.setState({ finished: finished });
    }

    async deleteData() {
        this.setState({ finished: 'Started...' });

        let requestUrl = `seedAdvokatData?passThrough=${this.state.pt}`;

        const response = await fetchService.delete(requestUrl);

        let errorMessage = response.message ? 'Greška: ' + response.message : '';

        const finished = response.success ? 'FINISHED SUCCESS' : 'FINISHED FAIL ' + errorMessage;

        this.setState({ finished: finished });
    }

    async pregledVeza() {
        this.setState({ finished: 'Started...' });

        let requestUrl = `pregledVeza?passThrough=${this.state.pt}&userId=${this.state.veza}`;

        const response = await fetchService.get(requestUrl);

        let errorMessage = response.message ? 'Greška: ' + response.message : '';

        const finished = response.success ? 'FINISHED SUCCESS' : 'FINISHED FAIL ' + errorMessage;

        this.setState({ vezaResult: response.responseValue.veza, finished: finished });
    }
}
