import React, { Component } from 'react';
import fetchService from '../FetchService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import AddLineWhiteIcon from '../icons/add-line-white.svg';
import { DateTimeService } from '../DateTimeService';
import { Badge } from 'primereact/badge';


export class KalendarPoSatu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            kalendarData: [],
            tableColumns: [],
            loading: true,
            datumKalendaraFrom: '',
            datumKalendaraTo: ''
        };
    }

    componentDidMount() {
        let datumKalendaraFrom = this.props.initDatumKalendara;
        let datumKalendaraTo = this.props.secondDatumKalendara;
        this.loadData(datumKalendaraFrom, datumKalendaraTo);
    }

    renderKalendar(kalendarData) {
        let offsetItem = document.getElementById('calendar-data-to-offset').offsetTop + 20;
        let scrollHeight = `calc(100vh - ${offsetItem}px)`;

        return (
            <div>
                <DataTable value={kalendarData}
                    tableStyle={{ width: "500px", minWidth: '30rem', minHeight: '400px' }} className="custom-datatable table-satnica"
                    tableClassName='table'
                    emptyMessage="Nema unetih podataka."
                    showGridlines
                    scrollable scrollHeight={scrollHeight}
                >
                    <Column key="hourValue" field="hourValue" body={this.hourTemplate} frozen
                        style={{ width: "20px", minWidth: "20px" }} className='hour-column' />
                    {
                        this.state.tableColumns?.map((col, i) =>
                        (
                            <Column key={col.columnId} field={col.columnId} body={this.itemTemplate} header={this.headerTemplate(col)}
                                style={{ width: "240px", minWidth: "240px" }} />
                        ))
                    }

                </DataTable>
            </div>
        );
    }

    scrollToItem() {
        let tableWrapper = document.getElementsByClassName('p-datatable-wrapper')[0];
        tableWrapper.scrollTo({ top: '800' });
    }

    hourTemplate = (item) => {
        return <div>
            <b>{item.hourValue}</b>
            <div>
            </div>
        </div>;
    }

    headerTemplate = (col) => {
        return <div>
            {col.columnDayOfWeek} {col.columnDateString}
        </div>
    }

    itemTemplate = (item, e) => {
        let halfHourItems = item[e.field];
        if (halfHourItems.length === 0) {
            return <div></div>
        }

        let allItems = [];
        for (var i = 0; i < halfHourItems.length; i++) {
            let oneItem = halfHourItems[i];

            let tip = '';
            let badgeSeverity = '';

            if (oneItem.tip === 'rok') {
                tip = 'Rok';
                badgeSeverity = 'danger';
            }
            if (oneItem.tip === 'rociste') {
                tip = 'Ročište';
                badgeSeverity = 'warning';
            }
            if (oneItem.tip === 'termin') {
                tip = 'Termin';
                badgeSeverity = 'info';
            }

            let processedText = this.processItemText(oneItem);

            let oneItemElement = <div className='table-satnica-single-item' key={oneItem.id} onClick={() => { this.props.onRowSelected(oneItem); }}>
                <Badge value={tip} severity={badgeSeverity}></Badge> {processedText}
            </div>

            allItems.push(oneItemElement);
        }

        return <div>
            {allItems}
        </div>;
    }

    processItemText(item) {
        let processedText = DateTimeService.formatDateTimeToTimeString(item.datum);

        if (item.sudnica) {
            if (processedText !== '') {
                processedText = processedText + ', ';
            }
            processedText = processedText + 'sudnica ' + item.sudnica;
        }

        if (item.naziv) {
            if (processedText !== '') {
                processedText = processedText + ', ';
            }
            processedText = processedText + item.naziv;
        }

        if (item.klijent) {
            if (processedText !== '') {
                processedText = processedText + ', ';
            }
            processedText = processedText + item.klijent;
        }

        if (item.brojPredmeta) {
            if (processedText !== '') {
                processedText = processedText + ', ';
            }
            processedText = processedText + item.brojPredmeta;
        }

        return processedText;
    }

    render() {
        let contents =
            this.state.loading
                ? <p><em>Učitavanje...</em></p>
                : this.renderKalendar(this.state.kalendarData);

        return (
            <div>
                <div className="form-header flex justify-content-between">
                    <div>
                        Od
                        &nbsp;
                        <Calendar id="datumKalendaraFrom" className="control-max-width-100" value={this.state.datumKalendaraFrom ?? ''}
                            onChange={(e) => {
                                let dateFrom = e.target.value;
                                let dateTo = this.state.datumKalendaraTo;

                                if (dateFrom > dateTo) {
                                    dateTo = dateFrom;
                                }

                                this.setState({ datumKalendaraFrom: dateFrom, datumKalendaraTo: dateTo });
                                this.loadData(dateFrom, dateTo);
                                this.props.onDatumKalendaraChanged(dateFrom, dateTo);
                            }}
                            dateFormat="dd.mm.yy" />
                        &nbsp;
                        do
                        &nbsp;
                        <Calendar id="datumKalendaraTo" className="control-max-width-100" value={this.state.datumKalendaraTo ?? ''}
                            onChange={(e) => {
                                let dateFrom = this.state.datumKalendaraFrom;
                                let dateTo = e.target.value;

                                if (dateFrom > dateTo) {
                                    dateFrom = dateTo;
                                }

                                this.setState({ datumKalendaraFrom: dateFrom, datumKalendaraTo: dateTo });
                                this.loadData(dateFrom, dateTo);
                                this.props.onDatumKalendaraChanged(dateFrom, dateTo);
                            }}
                            dateFormat="dd.mm.yy" />
                    </div>

                    <div>
                        <button className="btn btn-primary btns" onClick={(e) => this.props.onNewTerminTriggered()}>
                            <img alt="" src={AddLineWhiteIcon} />
                            <span className="hide-on-xs">
                                &nbsp;Termin
                            </span>
                        </button>
                    </div>
                </div>

                <div id='calendar-data-to-offset'>
                    {contents}
                </div>
            </div>
        );
    }

    async loadData(datumKalendaraFrom, datumKalendaraTo) {
        this.setState({
            loading: true
        });

        this.setState({
            datumKalendaraFrom: datumKalendaraFrom,
            datumKalendaraTo: datumKalendaraTo
        });
        let datumKalendaraFromQueryString = DateTimeService.getCombinedDateTime(datumKalendaraFrom).toUTCString();

        var datumKalendaraToQuery = new Date(datumKalendaraTo);
        datumKalendaraToQuery.setDate(datumKalendaraTo.getDate() + 1);

        let datumKalendaraToQueryString = DateTimeService.getCombinedDateTime(datumKalendaraToQuery).toUTCString();

        let timeZoneName = DateTimeService.getTimeZoneName();

        let requestUrl = `advokatKalendarSatnica?dateFrom=${datumKalendaraFromQueryString}&dateTo=${datumKalendaraToQueryString}&timeZoneName=${timeZoneName}`;
        const response = await fetchService.get(requestUrl);

        this.setState({
            tableColumns: response.success ? response.responseValue.columns : [],
            kalendarData: response.success ? response.responseValue.data : [],
            loading: false
        }, () => { this.scrollToItem() });
    }
}
