import React, { Component } from 'react';
import fetchService from '../FetchService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import AddLineWhiteIcon from '../icons/add-line-white.svg';

export class Protivnici extends Component {
    constructor(props) {
        super(props);
        this.state = {
            protivnici: [],
            loading: true,
            tableLoading: true,
            lazyState: {
                first: 0,
                rows: 10,
                page: 0,
                sortField: 'ime',
                sortOrder: 1,
                filter: ''
            },
            typingTimeout: 0,
            totalRecords: 0
        };
        this.filterReference = React.createRef();
    }

    componentDidMount() {
        this.populateProtivnici();
    }

    renderProtivnici(protivnici) {
        const footer = `Ukupno stavki: ${this.state.totalRecords}.`;

        return (
            <div>
                <DataTable value={protivnici}
                    dataKey="id"
                    lazy totalRecords={this.state.totalRecords}
                    first={this.state.lazyState.first}
                    onPage={this.onPage}
                    onSort={this.onSort} sortField={this.state.lazyState.sortField} sortOrder={this.state.lazyState.sortOrder}
                    header={this.renderHeader}
                    footer={footer}
                    paginator rows={10}
                    tableStyle={{ minWidth: '50rem' }} className="custom-datatable"
                    loading={this.state.tableLoading}
                    emptyMessage="Nema unetih podataka."
                    selectionMode="single" onSelectionChange={(e) => window.location = '/protivnik?id=' + e.value.id} >

                    <Column field="ime" body={this.imePrezimeTemplate} sortable header="Ime i prezime" style={{ width: '25%' }}></Column>
                    <Column field="adresa" header="Adresa" style={{ width: '25%' }}></Column>
                    <Column field="telefon" header="Telefon" style={{ width: '15%' }}></Column>
                    <Column field="opis" header="Opis" style={{ width: '20%' }}></Column>
                    <Column field="jmbg" header="Jmbg" sortable style={{ width: '15%' }}></Column>
                </DataTable>
            </div>
        );
    }

    imePrezimeTemplate = (protivnik) => {
        return `${protivnik.ime} ${protivnik.prezime}`;
    };

    renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <a className="btn btn-primary" href="/protivnik">
                    <img alt="" src={AddLineWhiteIcon} />
                    &nbsp;
                    Protivnik
                </a>

                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={this.state.lazyState.filter} placeholder="Traži..." ref={this.filterReference} className='search-table'
                        onChange={this.onFilterChange} onKeyDown={this.onFilterKeyDown} />
                </span>
            </div>
        );
    };

    render() {
        let contents =
            this.state.loading
                ? <p><em>Učitavanje...</em></p>
                : this.renderProtivnici(this.state.protivnici);

        return (
            <div>
                <h2 className='mb-3 page-title' id="tabelLabel">Protivnici</h2>
                {contents}
            </div>
        );
    }

    async populateProtivnici() {
        this.setState({
            tableLoading: true
        });

        let rows = this.state.lazyState.rows;
        let page = this.state.lazyState.page + 1;
        let sortField = this.state.lazyState.sortField;
        let sortOrder = this.state.lazyState.sortOrder;
        let filter = this.state.lazyState.filter;

        let requestUrl = `protivniciApi?rows=${rows}&page=${page}&sortField=${sortField}&sortOrder=${sortOrder}&filter=${filter}`;
        const response = await fetchService.get(requestUrl);

        this.setState({
            protivnici: response.success ? response.responseValue.protivnici : [],
            totalRecords: response.success ? response.responseValue.totalCount : 0,
            loading: false,
            tableLoading: false
        });
    }

    onPage = (event) => {
        this.setState(
            {
                lazyState: {
                    first: event.first,
                    rows: event.rows,
                    page: event.page,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                    filter: this.state.lazyState.filter
                }
            },
            () => this.populateProtivnici()
        );
    }

    onSort = (event) => {
        this.setState(
            {
                lazyState: {
                    first: event.first,
                    rows: event.rows,
                    page: this.state.lazyState.page,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                    filter: this.state.lazyState.filter
                }
            },
            () => this.populateProtivnici()
        );
    }

    onFilterChange = (event) => {
        const value = event.target.value;
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            lazyState: {
                ...this.state.lazyState,
                filter: value
            },
            typingTimeout: setTimeout(function () {
                self.populateProtivnici();
            }, 800)
        });
    }

    onFilterKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (this.state.typingTimeout) {
                clearTimeout(this.state.typingTimeout);
            }

            this.setState({
                typingTimeout: 0
            });

            this.populateProtivnici();
        }
    }
}
