import React, { Component } from 'react';
import fetchService from '../FetchService';
import { Messages } from 'primereact/messages';
import { FileUpload } from 'primereact/fileupload';

export class UvozPredmeta extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();
        this.predmetiFileUploadRef = React.createRef();

        this.state = {

        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="mb-4">
                <h2 className='mb-3 page-title'>Uvoz predmeta</h2>

                <div className="form-container">
                    <div className="mb-3">
                        <p>
                            U ovom delu možete uvesti ranije pripremljene podatke. Podaci mogu da se pripreme korišćenjem Excel i slične aplikacije, dok format datoteke mora da bude ".csv".
                        </p>
                        <p>
                            Vrlo je bitan redosled kolona, kao i to da prvi red u tabeli budu nazivi polja/kolona koja se unose.
                            Preporuka je da se preuzme šablon, da se on popuni Vašim podacima, i da se nakon toga popunjena datoteka uveze u aplikaciju.
                            Ispod možete preuzeti i primer popunjenog šablona.
                        </p>
                        <p>
                            Svi klijenti predmeta moraju prethodno da se unesu u aplikaciju, i moraju da imaju odgovarajući JMBG kako bi se identifikovali.
                        </p>
                        <p>
                            Svi tipovi predmeta moraju prethodno da se unesu u aplikaciju, a identifikacija tipa se vrši po njegovom nazivu.
                        </p>
                    </div>
                    <div className="mb-3">
                        <div className="form-container">
                            <div className="mb-3">
                                <a className="btn btn-primary" href="/download/uvoz-predmeta-sablon.csv">
                                    Preuzmite šablon
                                </a>
                                &nbsp;&nbsp;
                                <a className="btn btn-primary" href="/download/uvoz-predmeta-primer.csv">
                                    Preuzmite popunjeni primer
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <form className='form-group' ref={this.formRef}>
                    <div className="form-container">
                        <div className="mb-3">
                            <FileUpload id='predmetiFileUpload' name="predmetiFileUpload" maxFileSize={15000000}
                                ref={this.predmetiFileUploadRef}
                                emptyTemplate={<p className="m-0">Prevuci ovde dokument sa predmetima.</p>}
                                chooseLabel="Izaberi fajl"
                                uploadLabel="Sačuvaj"
                                cancelOptions={{ icon: '', iconOnly: true, className: 'display-none' }}
                                customUpload
                                accept="text/csv"
                                uploadHandler={() => this.uploadUvozPredmeta()}
                            />
                        </div>
                    </div>
                </form>

                <div className="form-container">
                    <div className="mb-3">
                        <Messages ref={this.toastRef} />
                    </div>
                </div>
            </div>
        );
    }

    async uploadUvozPredmeta() {
        let form = new FormData();
        let files = this.predmetiFileUploadRef.current.getFiles();

        if (files.length === 0) {
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Morate izabrati fajl sa predmetima." });
            return;
        }

        for (var index = 0; index < files.length; index++) {
            var element = files[index];
            form.append('uploadedPredmetiAdvokata', element);
        }

        let response = await fetchService.saveForm('uvozIzvozPredmetaAdvokata', form, 'POST');

        if (!response.success) {
            let errorMessage = response.message ? 'Greška: ' + response.message : '';
            this.toastRef.current.show({ severity: 'error', detail: "Došlo je do greške. " + errorMessage, sticky: true });
        }
        else {
            if (response.responseValue.itemsWithIssueOnSave.length === 0) {
                this.toastRef.current.show({ severity: 'success', detail: "Uspešno ste sačuvali predmete.", sticky: true });
            } else {
                let issueItemsMessage = '';
                response.responseValue.itemsWithIssueOnSave.forEach(item => {
                    issueItemsMessage = issueItemsMessage + '\n ' + item;
                });
                this.toastRef.current.show({ severity: 'warn', detail: "Niste sve predmete uspešno sačuvali. Kod sledećih stavki je došlo do greške: " + issueItemsMessage, sticky: true });
            }
        }

        this.predmetiFileUploadRef.current.clear();
    }

}
