export class DateTimeService {

    static formatDateTimeToString(vremeUnosa, includeSeconds) {
        if (!vremeUnosa)
            return null;

        let dateTime = new Date(vremeUnosa);

        let day = dateTime.getDate();
        day = day < 10 ? `0${day}` : day;

        let month = dateTime.getMonth() + 1;
        month = month < 10 ? `0${month}` : month;

        let year = dateTime.getFullYear();

        let hour = dateTime.getHours();
        hour = hour < 10 ? `0${hour}` : hour;

        let minute = dateTime.getMinutes();
        minute = minute < 10 ? `0${minute}` : minute;

        let formatted = `${day}.${month}.${year}  ${hour}:${minute}`;

        if (includeSeconds) {
            let seconds = dateTime.getSeconds();
            seconds = seconds < 10 ? `0${seconds}` : seconds;
        }

        return `${formatted}h`;
    }

    static formatDateTimeToDateString(vremeUnosa) {
        if (!vremeUnosa)
            return null;

        let dateTime = new Date(vremeUnosa);

        let day = dateTime.getDate();
        day = day < 10 ? `0${day}` : day;

        let month = dateTime.getMonth() + 1;
        month = month < 10 ? `0${month}` : month;

        let year = dateTime.getFullYear();

        let formatted = `${day}.${month}.${year}`;

        return `${formatted}`;
    }

    static formatDateTimeToTimeString(vremeUnosa) {
        if (!vremeUnosa)
            return null;

        let dateTime = new Date(vremeUnosa);

        let hour = dateTime.getHours();
        hour = hour < 10 ? `0${hour}` : hour;

        let minute = dateTime.getMinutes();
        minute = minute < 10 ? `0${minute}` : minute;

        let formatted = `${hour}:${minute}`;

        return `${formatted}h`;
    }

    static formatDateTimeToDateQueryString(vremeUnosa) {
        if (!vremeUnosa)
            return null;

        let dateTime = new Date(vremeUnosa);

        let day = dateTime.getDate();
        day = day < 10 ? `0${day}` : day;

        let month = dateTime.getMonth() + 1;
        month = month < 10 ? `0${month}` : month;

        let year = dateTime.getFullYear();

        let formatted = `${year}-${month}-${day}`;
        return formatted;
    }

    static getCombinedDateTime(date, time) {
        if (date && time)
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(),
                time.getHours(), time.getMinutes(), time.getSeconds());

        if (date)
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(),
                0, 0, 0);

        if (time) {
            let dateNow = new Date();
            return new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(),
                time.getHours(), time.getMinutes(), time.getSeconds());
        }

        return null;
    }

    static getFirstDateOfYear(year) {
        if (!year) {
            return null;
        }

        return new Date(year, 1, 1, 0, 0, 0);
    }

    static getDateFromTimeString(time) {
        if (!time) {
            return null;
        }

        const timeArray = time.split(":");

        if (timeArray.length !== 2)
            return null;

        let hours = parseInt(timeArray[0]);
        let minutes = parseInt(timeArray[1]);

        if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
            return null
        }

        let dateNow = new Date();
        return new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(),
            hours, minutes, 0);
    }

    static getTimeZoneName() {
        let timeZoneName = 'Central Europe Standard Time';
        try {
            timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch (e) {
        }

        return timeZoneName;
    }
}
