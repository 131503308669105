import React, { Component } from 'react';
import fetchService from '../FetchService';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import CloseLineWhiteIcon from '../icons/close-line-white.svg';
import SaveLineWhiteIcon from '../icons/save-line-white.svg';
import DeleteBinLineWhiteIcon from '../icons/delete-bin-line-white.svg';
import { DateTimeService } from '../DateTimeService';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { HelpTooltip } from '../HelpTooltip';

export class RacunEdit extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();
        this.formRef = React.createRef();

        this.state = {
            loading: true,
            racunPredmeta: {
                racunPredmetaId: '',
                predmetId: '',
                opis: '',
                datumUnosa: '',
                iznos: 0,
                valuta: 1,
                isplacen: false,
                tipRacunaId: null,
                odgovornoLiceId: ''
            },
            imePrezimeKlijenta: '',
            brojPredmeta: '',
            tipoviRacuna: [],
            valute: []
        };

        this.saveDataToApi = this.saveDataToApi.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }

    async componentDidMount() {
        if (this.props.racunPredmeta.id) {
            let dateTime = new Date(this.props.racunPredmeta.datum);
            this.setState({
                racunPredmeta: {
                    ...this.props.racunPredmeta,
                    datumUnosa: dateTime
                },
                imePrezimeKlijenta: this.props.imePrezimeKlijenta,
                brojPredmeta: this.props.brojPredmeta,
                loading: false
            });
        } else {
            let dateTime = new Date();
            this.setState({
                racunPredmeta: {
                    ...this.state.racunPredmeta,
                    predmetId: this.props.predmetId,
                    datumUnosa: dateTime
                }
            })
        }

        let tipoviRacuna = this.props.tipoviRacuna ?? await this.props.onTipoviRacunaMount();
        let valute = this.props.valute ?? await this.props.onValuteMount();

        this.setState({
            imePrezimeKlijenta: this.props.imePrezimeKlijenta,
            brojPredmeta: this.props.brojPredmeta,
            tipoviRacuna: tipoviRacuna,
            valute: valute,
            loading: false
        });
    }

    async saveDataToApi() {
        if (!this.formRef.current.checkValidity()) {
            this.formRef.current.classList.add('was-validated');
            return;
        }

        this.setState({ loading: true });

        const method = this.state.racunPredmeta.id ? 'PUT' : 'POST';
        const bodyInput = {
            "RacunPredmetaId": this.state.racunPredmeta.id,
            "PredmetId": this.state.racunPredmeta.predmetId,
            "Opis": this.state.racunPredmeta.opis,
            "Datum": DateTimeService.getCombinedDateTime(this.state.racunPredmeta.datumUnosa),
            "Iznos": this.state.racunPredmeta.iznos,
            "Valuta": this.state.racunPredmeta.valuta,
            "Isplacen": this.state.racunPredmeta.isplacen,
            "TipRacunaId": this.state.racunPredmeta.tipRacunaId,
            "OdgovornoLiceId": this.state.racunPredmeta.odgovornoLiceId
        };

        let response = await fetchService.save('racunPredmeta', bodyInput, method);

        this.setState({ loading: false });

        if (!response.success) {
            let errorMessage = response.message ? 'Greška: ' + response.message : '';
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom čuvanja računa predmeta. " + errorMessage, sticky: true });
        }
        else {
            this.props.onSaveClick();
        }
    }

    async deleteItem() {
        this.setState({ loading: true });

        let response = await fetchService.delete(`racunPredmeta?racunPredmetaId=${this.state.racunPredmeta.id}`);

        this.setState({ loading: false });

        if (!response.success) {
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom brisanja računa predmeta. Greška: " + response.message, sticky: true });
        }
        else {
            this.props.onSaveClick();
        }
    }

    cancelEdit() {
        this.props.onCancelClick();
    }

    confirmDelete(event) {
        confirmDialog({
            trigger: event.currentTarget,
            message: 'Da li želite da obrišete ovaj račun predmeta?',
            header: 'Brisanje računa',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => { this.deleteItem() },
            acceptLabel: 'Da',
            rejectLabel: 'Ne'
        });
    };

    renderForm() {
        return (
            <div>
                <div className="mb-3 form-header">
                    <button className="btn btn-primary btns" onClick={this.saveDataToApi}>
                        <img alt="" src={SaveLineWhiteIcon} />
                        &nbsp;
                        Sačuvaj
                    </button>
                    <button hidden={this.state.racunPredmeta.id ? false : true} className="btn btn-danger btns" onClick={this.confirmDelete}>
                        <img alt="" src={DeleteBinLineWhiteIcon} />
                        &nbsp;
                        Briši
                    </button>
                    <button className="btn btn-secondary btns" onClick={this.cancelEdit}>
                        <img alt="" src={CloseLineWhiteIcon} />
                        &nbsp;
                        Odustani
                    </button>
                </div>

                <form className='form-group' ref={this.formRef}>
                    <div className="form-container">
                        <div className="mb-3">
                            <label htmlFor="imePrezimeKlijenta" className="form-label">Klijent</label>
                            <input readOnly className="form-control" id="imePrezimeKlijenta" value={this.state.imePrezimeKlijenta ?? ''} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="brojPredmeta" className="form-label">Broj predmeta</label>
                            <input readOnly className="form-control" id="brojPredmeta" value={this.state.brojPredmeta ?? ''} />
                        </div>

                        <div className="row">

                            <div className="mb-3 col-md-6">
                                <label className="form-label">Datum *</label>
                                <br />
                                <Calendar id="datumUnosa" className={`control-max-width-200 ${this.state.racunPredmeta.datumUnosa ? '' : 'p-invalid'}`} value={this.state.racunPredmeta.datumUnosa ?? ''}
                                    onChange={(e) => this.setState({ racunPredmeta: { ...this.state.racunPredmeta, datumUnosa: e.target.value } })}
                                    dateFormat="dd.mm.yy" required />
                            </div>

                            <div className="mb-3 col-md-6">
                                <label htmlFor="tipRacunaId" className="form-label">Tip računa</label>
                                <div>
                                    <Dropdown id="tipRacunaId"
                                        value={this.state.racunPredmeta.tipRacunaId}
                                        onChange={(e) => this.setState({ racunPredmeta: { ...this.state.racunPredmeta, tipRacunaId: e.target.value } })}
                                        options={this.state.tipoviRacuna}
                                        optionLabel="naziv"
                                        optionValue="id"
                                        filter
                                        showClear
                                        className="w-full p-dropdown-fullwidth" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="mb-3 col-md-6">
                                <label htmlFor="iznos" className="form-label">Iznos *</label>
                                <InputNumber inputId="iznos" value={this.state.racunPredmeta.iznos ?? 0}
                                    required className="form-control-prime" 
                                    onValueChange={(e) => this.setState({ racunPredmeta: { ...this.state.racunPredmeta, iznos: e.target.value ?? 0 } })}
                                    minFractionDigits={2} maxFractionDigits={2} />
                            </div>

                            <div className="mb-3 col-md-6">
                                <label htmlFor="valuta" className="form-label">Valuta *</label>
                                <div>
                                    <Dropdown id="valuta"
                                        value={this.state.racunPredmeta.valuta}
                                        onChange={(e) => this.setState({ racunPredmeta: { ...this.state.racunPredmeta, valuta: e.target.value } })}
                                        options={this.state.valute}
                                        optionLabel="naziv"
                                        optionValue="id"
                                        filter
                                        showClear
                                        className="w-full p-dropdown-fullwidth"
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="opis" className="form-label">Opis</label>
                            <textarea className="form-control" id="opis" value={this.state.racunPredmeta.opis ?? ''} rows="3"
                                onChange={(e) => this.setState({ racunPredmeta: { ...this.state.racunPredmeta, opis: e.target.value } })}>
                            </textarea>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="isplacen" className="form-label">Isplaćen</label>
                            &nbsp;&nbsp;
                            <InputSwitch id="isplacen" checked={this.state.racunPredmeta.isplacen} className="form-control"
                                onChange={(e) => this.setState({ racunPredmeta: { ...this.state.racunPredmeta, isplacen: e.value } })} />
                        </div>

                        <div className="mb-3 col-md-4">
                            <label htmlFor="odgovornoLiceId" className="form-label">Odgovorno lice</label>
                            <HelpTooltip>
                                <p className="mb-3">
                                    Advokat kancelarije odgovoran za zapis predmeta.
                                </p>
                            </HelpTooltip>
                            <div>
                                <Dropdown id="odgovornoLiceId"
                                    value={this.state.racunPredmeta.odgovornoLiceId}
                                    onChange={(e) => this.setState({ racunPredmeta: { ...this.state.racunPredmeta, odgovornoLiceId: e.target.value } })}
                                    options={this.props.odgovornaLica}
                                    optionLabel="imePrezime"
                                    optionValue="id"
                                    filter
                                    showClear
                                    className="w-full p-dropdown-fullwidth" />
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : this.renderForm();

        return (
            <div>
                {contents}

                <Toast ref={this.toastRef} />
                <ConfirmDialog />
            </div>
        );
    }
}
