import React, { Component } from 'react';
import fetchService from '../FetchService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { DateTimeService } from '../DateTimeService';
import { Badge } from 'primereact/badge';


export class KalendarZaMesec extends Component {
    constructor(props) {
        super(props);

        this.state = {
            kalendarData: [],
            loading: true,
            selectedMonth: null,
            selectedYear: null
        };

        this.selectedMonthChanged = this.selectedMonthChanged.bind(this);
        this.selectedYearChanged = this.selectedYearChanged.bind(this);
    }

    componentDidMount() {
        let dateTimeNow = new Date();
        let year = DateTimeService.getFirstDateOfYear(dateTimeNow.getFullYear());
        let month = dateTimeNow.getMonth() + 1;

        this.setState({
            selectedYear: this.props.initYear ?? year,
            selectedMonth: this.props.initMonth ?? month
        }, () => { this.loadData(); });
    }

    render() {
        let contents =
            this.state.loading
                ? <p><em>Učitavanje...</em></p>
                : this.renderKalendar();

        return (
            <div>
                {contents}
            </div>
        );
    }

    renderKalendar() {
        let monthOptions = [
            { id: 1, naziv: "Januar" },
            { id: 2, naziv: "Februar" },
            { id: 3, naziv: "Mart" },
            { id: 4, naziv: "April" },
            { id: 5, naziv: "Maj" },
            { id: 6, naziv: "Jun" },
            { id: 7, naziv: "Jul" },
            { id: 8, naziv: "Avgust" },
            { id: 9, naziv: "Septembar" },
            { id: 10, naziv: "Oktobar" },
            { id: 11, naziv: "Novembar" },
            { id: 12, naziv: "Decembar" }
        ]

        return (
            <div>
                <div className="form-header flex justify-content-start">
                    <div>
                        <Dropdown id="mesec"
                            value={this.state.selectedMonth}
                            onChange={this.selectedMonthChanged}
                            options={monthOptions}
                            optionLabel="naziv"
                            optionValue="id"
                            className="control-max-width-200" />
                    </div>
                    <div>
                        <Calendar id="godina" className="control-max-width-200" value={this.state.selectedYear ?? ''}
                            onChange={this.selectedYearChanged}
                            view="year" dateFormat="yy" />
                    </div>
                </div>

                <DataTable value={this.state.kalendarData}
                    showGridlines
                    tableStyle={{ width: '100%', maxWidth: '1400px' }}
                    className="custom-datatable calendar-month"
                    tableClassName='table'
                    emptyMessage="Nema unetih podataka."
                >
                    <Column field="ponedeljak" body={this.itemTemplate} header={this.headerTemplate('P', 'onedeljak')} style={{ width: '14%' }}></Column>
                    <Column field="utorak" body={this.itemTemplate} header={this.headerTemplate('U', 'torak')} style={{ width: '14%' }}></Column>
                    <Column field="sreda" body={this.itemTemplate} header={this.headerTemplate('S', 'reda')} style={{ width: '14%' }}></Column>
                    <Column field="cetvrtak" body={this.itemTemplate} header={this.headerTemplate('Č', 'etvrtak')} style={{ width: '14%' }}></Column>
                    <Column field="petak" body={this.itemTemplate} header={this.headerTemplate('P', 'etak')} style={{ width: '14%' }}></Column>
                    <Column field="subota" body={this.itemTemplate} header={this.headerTemplate('S', 'ubota')} style={{ width: '14%' }}></Column>
                    <Column field="nedelja" body={this.itemTemplate} header={this.headerTemplate('N', 'edelja')} style={{ width: '14%' }}></Column>
                </DataTable>
            </div>
        );
    }

    headerTemplate = (dayNameFirstLetter, dayNameRest) => {
        return <div>
            {dayNameFirstLetter}
            <span className='hide-on-768'>{dayNameRest}</span>
        </div>;
    }

    itemTemplate = (kalendarDataItem, e) => {
        if (!kalendarDataItem) {
            return <div></div>;
        }

        let item = kalendarDataItem[e?.field];

        if (!item?.dan) {
            return <div></div>;
        }

        let badgeValueRokoviLarge = `Rok-${item?.brojRokova ?? 0}`;
        let badgeValueRocistaLarge = `Roč-${item?.brojRocista ?? 0}`;
        let badgeValueTerminiLarge = `Ter-${item?.brojTermina ?? 0}`;

        let badgeValueRokoviVisible = (item?.brojRokova ?? 0) <= 0;
        let badgeValueRocistaVisible = (item?.brojRocista ?? 0) <= 0;
        let badgeValueTerminiVisible = (item?.brojTermina ?? 0) <= 0;


        let selectedYearValue = this.state.selectedYear.getFullYear();
        let selectedMonthValue = this.state.selectedMonth - 1;

        let dateForLink = item?.dan ? new Date(selectedYearValue, selectedMonthValue, item.dan, 0, 0, 0) : null;

        let newDate = new Date();
        let currentYear = newDate.getFullYear();
        let currentMonth = newDate.getMonth();
        let currentDay = newDate.getDate();

        let isActiveDay = currentYear === selectedYearValue && currentMonth === selectedMonthValue && currentDay === item.dan;

        return <div className='calendar-month-item-template' style={{ cursor: 'pointer' }}
            onClick={() => {
                if (!dateForLink) {
                    return;
                }

                this.props.onDatumSelected(dateForLink);
            }}
        >
            <div className={`text-center calendar-month-day-number mb-1-for-more-than-720 ${isActiveDay ? 'calendar-month-day-number-active' : ''}`}>
                <b>{item?.dan}</b>
            </div>
            <div className='mb-2'>
                <span><Badge hidden={badgeValueRokoviVisible} value={badgeValueRokoviLarge} severity='danger'></Badge>&nbsp;</span>
                <span><Badge hidden={badgeValueRocistaVisible} value={badgeValueRocistaLarge} severity='warning'></Badge>&nbsp;</span>
                <span><Badge hidden={badgeValueTerminiVisible} value={badgeValueTerminiLarge} severity='info'></Badge></span>
            </div>
        </div>;
    }

    async loadData() {
        this.setState({
            loading: true
        });

        let godina = this.state.selectedYear?.getFullYear();

        let requestUrl = `advokatMonthKalendar?month=${this.state.selectedMonth}&year=${godina}`;
        const response = await fetchService.get(requestUrl);

        this.setState({
            kalendarData: response.success ? response.responseValue : [],
            loading: false
        });
    }

    selectedMonthChanged(e) {
        this.setState({
            selectedMonth: e.target.value
        }, () => {
            this.loadData();
            this.props.onDatumChanged(this.state.selectedMonth, this.state.selectedYear);
        })
        
    }

    selectedYearChanged(e) {
        this.setState({
            selectedYear: e.target.value
        }, () => {
            this.loadData();
            this.props.onDatumChanged(this.state.selectedMonth, this.state.selectedYear);
        });
    }
}
