import React, { Component } from 'react';
import { ScrollTop } from 'primereact/scrolltop';

import { TutorijalHeader } from './Tutorijal01Header';
import { Kontakt } from './06Kontakt';

import './home.css';

export class UsloviKoriscenja extends Component {
    componentDidMount() {
        document.title =
            this.props.contenttype === 'uslovi-koriscenja' ? "Zapisnik - Uslovi korišćenja" :
                this.props.contenttype === 'pravila-privatnosti' ? "Zapisnik - Pravila privatnosti" : "Zapisnik - Rokovnik za advokate";
    };

    render() {
        var itemsToRender =
            this.props.contenttype === 'uslovi-koriscenja' ? this.usloviKoriscenja :
                this.props.contenttype === 'pravila-privatnosti' ? this.pravilaPrivatnosti : [];


        return (
            <div className="main-wrapper">
                <TutorijalHeader />

                <div id='uslovi-koriscenja' className="container my-4 px-5 py-2">
                    <div className='mt-3'>
                        {
                            itemsToRender.map((item, i) => {
                                return (
                                    <div key={i}>
                                        {this.itemTemplate(item.clanBroj, item.title, item.paragraphs)}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <Kontakt />

                <ScrollTop />
            </div>
        );
    }

    itemTemplate(clanBroj, title, paragraphs) {
        let renderedClan = clanBroj ? <h2>Član {clanBroj}.</h2> : null;

        return (
            <div>
                <div className='mb-3'>
                    {renderedClan}
                    <h2>
                        {title}
                    </h2>
                </div>

                <div className='mb-5'>
                    {
                        paragraphs.map((itemText, i) => {
                            return (
                                <div key={i}>
                                    {this.renderItemTemplateText(itemText)}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    };

    renderItemTemplateText(itemText) {
        if (Array.isArray(itemText)) {
            return (
                <ul>
                    {
                        itemText.map((itemText, i) => {
                            return (
                                <li key={i}>
                                    {itemText}
                                </li>
                            )
                        })
                    }
                </ul>
            );
        }

        return (
            <p>
                {itemText}
            </p>
        );
    }


    usloviKoriscenja =
        [
            {
                'clanBroj': null,
                'title': 'Zapisnik.rs - Uslovi korišćenja',
                'paragraphs': [
                    'Ovim uslovima korišćenja (u nastavku: Uslovi korišćenja) agencija EFREM ITS PIROT, PIB: 110201055 (u nastavku: Agencija) uspostavila je pravila i uslove korišćenja web stranice zapisnik.rs (uključujući pripadajuće online i offline aplikacije) (u nastavku: Web stranica) u vlasništvu Agencije. Ukoliko za pojedine usluge postoje posebni uslovi korišćenja, primena istih će imati prednost pred primenom Uslova korišćenja.',
                    'Sledom navedenog, pre korišćenja Web stranice, Uslove korišćenja potrebno je pažljivo pročitati.'
                ]
            },
            {
                'clanBroj': 1,
                'title': 'Sadržaj Web stranice',
                'paragraphs': [
                    'Web stranica predstavlja online platformu koja omogućava korišćenje servisa namenjenih advokatskim kancelarijama, samostalnim advokatima kao i bilo kome ko servise pronađe korisnim.',
                    'Korisnikom Web stranice smatra se svaka fizička i pravna osoba koja pristupa Web stranici i/ili koristi bilo koje sadržaje i/ili usluge/servise dostupne na Web stranici, uključujući registrovane i neregistrovane korisnike kao i poslovne subjekte s kojima Agencija ima sklopljene ugovore o pružanju usluga.'
                ]
            },
            {
                'clanBroj': 2,
                'title': 'Korišćenje Web stranice',
                'paragraphs': [
                    'Pristupanjem i korišćenjem sadržaja Web stranice, nezavisno od postupka registracije, stupate u ugovorni odnos s Agencijom, te potvrđujete da ste u celosti pročitali, razumeli i prihvatili primenu Uslova korišćenja i Politike privatnosti.',
                    'Korišćenje Web stranice moguće je samo i isključivo uz pristanak na Uslove korišćenja te ukoliko iste ne prihvatate iz bilo kojeg razloga, nije Vam dozvoljeno korišćenje Web stranice.',
                    'Korisnik je isključivo odgovoran za snošenje bilo kojeg troška koji može nastati korišćenjem Web stranice.',
                    'Preporučuje se da se za korišćenje aplikacije Zapisnik koristi Google Chrome.'
                ]
            },
            {
                'clanBroj': 3,
                'title': 'Postupak registracije',
                'paragraphs': [
                    'Korišćenje pojedinih sadržaja Web stranice moguće je samo pod uslovom provedenog postupka registracije. Korisnik je dužan prilikom registracije navesti tačne, istinite i potpune podatke potrebne za postupak registracije; suprotno postupanje smatraće se kršenjem Uslova korišćenja.',
                    'Agencija u svakom slučaju zadržava pravo da odbije registraciju ili ukine postojeću registraciju odnosno korisnički račun ili da uskrati korišćenje pojedinih sadržaja Web stranice, po vlastitoj proceni i bez prethodne najave, a posebno u slučaju kršenja Uslova korišćenja.',
                ]
            },
            {
                'clanBroj': 4,
                'title': 'Podaci o korisničkom nalogu',
                'paragraphs': [
                    'Svi korisnici Web stranice dužni su čuvati podatke o svom korisničkom nalogu te je korisnik isključivo odgovoran za svu i bilo koju štetu koja može nastati kao posledica korišćenja korisničkog naloga od strane druge osobe. Zapisnik se obavezuje da neće neovlašćeno pristupati podacima dobijenim od Korisnika niti prosleđivati trećim licima podatke Korisnika.',
                ]
            },
            {
                'clanBroj': 5,
                'title': 'Obaveze korisnika',
                'paragraphs': [
                    'Svi korisnici Web stranica i online servisa, dužni su u celosti postupati prema svim važećim propisima, uz uvažavanje i pristojno ophođenje sa svim drugim korisnicima. Korisnik je dužan nadoknaditi svaku i bilo koju štetu koja nastane Agenciji, drugim korisnicima i/ili trećim osobama kao posledica nezakonitog korišćenja Web stranica.',
                ]
            },
            {
                'clanBroj': 6,
                'title': 'Uslovi korišćenja za korisnike',
                'paragraphs': [
                    'Web stranica omogućava registraciju i korišćenje servisa u skladu sa odabranim paketom.',
                    'Korisnik je u celosti i isključivo odgovoran za unesen sadržaj i sve propratne dokumentacije koje je uneo i dodao kroz servise na Web stranici.',
                ]
            },
            {
                'clanBroj': 7,
                'title': 'Isključenje odgovornosti Agencije',
                'paragraphs': [
                    'Agencija nije učesnik pravnih poslova i komunikacija koje se obavljaju putem elektronskih poruka kroz sistem Agencije i u koje korisnici međusobno samostalno ulaze, niti u pogledu navedenog snosi bilo kakvu direktnu ili indirektnu odgovornost.',
                    'Agencija nije odgovorna bilo kojim osobama za bilo koju vrstu štete koja može nastati direktno ili indirektno kao posledica korišćenja Web stranice ili korišćenja drugih web stranica i sadržaja kojima se može pristupiti s Web stranice, ili kao posledica nemogućnosti korišćenja istih.',
                    'Agencija ne garantuje:',
                    [
                        'tačnost, istinitost i potpunost dokumenata ili bilo kojih podataka sadržanih u njima ili bilo kojih drugih sadržaja unesenih od strane korisnika;',
                        'tačnost, istinitost i potpunost podataka o korisnicima;',
                        'poslovnu sposobnost, ovlašćenje, registraciju ili postojanje bilo kojeg drugog uslova potrebnog u skladu s propisima.'
                    ],
                    'Nadalje, Agencija ne garantuje:',
                    [
                        'da na Web stranici neće biti pogrešaka i/ili da će funkcionisati bez prekida u svakom trenutku; Agencija garantuje dostupnost od najmanje 99% vremena, osim kod uticaja više sile;',
                        'da sadržaj na Web stranici neće biti obavljen bez pogrešaka te da implementacija takvog sadržaja neće narušavati intelektualna prava trećih.'
                    ],
                    'Agencija nije odgovorna bilo kojim osobama za bilo koju vrstu štete koja može nastati direktno ili indirektno kao posledica nemogućnosti korišćenja Web stranice usled kvara, greške ili sličnog događaja.',
                    'Agencija nije odgovorna za fakture, predračune i druge dokumente, koje se šalju preko Zapisnik servisa od strane Korisnika.',
                    'Agencija nije odgovorna bilo kojim osobama za bilo koju vrstu štete koja može nastati direktno ili indirektno kao posledica nesavesnog korišćenja podataka dostupnih na Web stranici.',
                    'Korisnik je dužan Agenciji nadoknaditi sve i bilo koje troškove vezane u zahteve ili pravne postupke trećih osoba poduzete u vezi sa sadržajem oglasa ili drugim postupanjem korisnika.'
                ]
            },
            {
                'clanBroj': 8,
                'title': 'Ograničenje upotrebe Web stranice',
                'paragraphs': [
                    'Agencija zadržava pravo onemogućiti korišćenje Web stranice, uključujući ukinuti postojeću registraciju, svim korisnicima koji na bilo koji način krše Uslove korišćenja ili bilo koje primenjive propise te zadržava pravo protiv svakog takvog korisnika pokrenuti odgovarajuće postupke, uključujući i postupak radi naknade štete.'
                ]
            },
            {
                'clanBroj': 9,
                'title': 'Zaštita ličnih podataka',
                'paragraphs': [
                    'Agencija prikuplja i obrađuje lične podatke korisnika.'
                ]
            },
            {
                'clanBroj': 10,
                'title': 'Zaštita prava intelektualnog vlasništva',
                'paragraphs': [
                    'Prava koja se odnose na Web stranicu, uključujući tekst, baze podataka i programski kod, format i sve ostale (statičke i/ili animirane) materijale, zvukove, formate, software, nazive (uključujući i domenu) pripadaju isključivo Agenciji te nije dozvoljena promena, umnožavanje, distribuiranje, prodaja ni jednog dela Web stranice osim ako nije drugačije navedeno u sporazumu s Agencijom, niti je dozvoljeno kopirati sadržaj Web stranice u celini ili delimično bez pisanog odobrenja Agencije.'
                ]
            },
            {
                'clanBroj': 11,
                'title': 'Promene Uslova korišćenja',
                'paragraphs': [
                    'Agencija je ovlašćeno u svakom trenutku izmeniti Uslove korišćenja, Web stranicu i/ili bilo koje sadržaje u njima dostupne te su korisnici dužni upoznati se sa svim izmenama Uslova korišćenja. Izmene Uslova korišćenja stupaju na snagu danom njihove objave na Web stranici. Korišćenjem Web stranice, smatra se da je korisnik pristao na izmenjene Uslove korišćenja.'
                ]
            },
            {
                'clanBroj': 12,
                'title': 'Prava korisnika',
                'paragraphs': [
                    'Za eventualne reklamacije i prigovore, potrebno je obratiti se pisanim putem na adresu elektronske pošte info@zapisnik.rs. Agencija će na reklamaciju odnosno prigovor odgovoriti u roku od 15 dana.',
                    'Korisnici su ovlašćeni jednostrano raskinuti ugovor s Agencijom gašenjem svog korisničkog naloga. U slučaju spora, potrošač ima pravo koristiti se vansudskim načinom rešavanja spora u skladu sa Zakonom o zaštiti potrošača.'
                ]
            },
            {
                'clanBroj': 13,
                'title': 'Merodavno pravo i nadležnost',
                'paragraphs': [
                    'Na ove Uslove korišćenja primenjuje se pravo Republike Srbije. U slučaju bilo kakvog spora vezanog za tumačenje, sadržaj, primenu ili kršenje Uslova korišćenja, nadležan je Osnovni Sud u Pirotu, Republika Srbija.'
                ]
            },
            {
                'clanBroj': 14,
                'title': 'Načini plaćanja',
                'paragraphs': [
                    'Omogućujemo Vam plaćanje naših servisa plaćanjem putem interneta, po principu plaćanja po predračunu (virmansko plaćanje). Na svoju e-mail adresu ćete dobiti predračun sa svim podacima potrebnim za uplatu, uključujući broj računa na koji trebate uplatiti vrednost porudžbine. Uplatu potom možete izvršiti korišćenjem internet bankarstva ili načinom na koji inače plaćate svoje račune.'
                ]
            },
            {
                'clanBroj': 15,
                'title': 'Nalog Korisnika',
                'paragraphs': [
                    'Aplikacija Zapisnik će sve izmene i ispravke na programu besplatno integrisati u nalog Korisnika. Korisnik može u svakom trenutku prekinuti korišćenje programa slanjem emaila sa zahtevom. Zahtev može uključivati i brisanje svih podataka korisnika.',
                    'Tokom korišćenja aplikacije, Korisnik može pismenim zahtevom tražiti da mu se dostave svi do tada uneti dokumenti na Zapisnik aplikaciju.',
                    'Svi podaci iz naloga Korisnika se nalaze na serverima koje je odabrao Zapisnik tim, za koje smatra da su najsigurniji za korišćenje. Za sve podatke korisnika, vrši se pravljenje rezervne kopije svih podataka jednom dnevno.'
                ]
            },
            {
                'clanBroj': 16,
                'title': 'Serveri i sigurnost podataka',
                'paragraphs': [
                    'Kompletan Zapisnik servis zasnovan je najmodernijim IT hardverskim i softverskim tehnologijama. Sigurnost je na najvišem nivou i zasnovana je na sledećim tehnologijama:',
                    [
                        'HTTPS sertifikat za identifikaciju servera.',
                        'Enkripcija za sigurnu komunikaciju. Web serveri koriste enkripciju, kao što je SSL ili TLS, da bi zaštitili osetljive informacije koje se prenose između servera i klijenata.',
                        'Firewall-ovi koji se koriste za zaštitu web server od neovlašćenog pristupa i zlonamernih napada.',
                        'Web serveri se ažuriraju redovno kako bi se ispravile poznate sigurnosne ranjivosti.',
                        'Web serveri primenjuju napredne politike za lozinke, kao što su zahtevanje kompleksnih lozinki.',
                        'Web serveri imaju implementirane kontrole pristupa kako bi se ograničilo koji korisnici i sistemi mogu pristupiti osetljivim resursima.',
                        'Web serveri vode detaljne zapise svih aktivnosti i prate sumnjive aktivnosti.',
                        'Pravljenje kopija i procedura za vraćanje podataka nakon katastrofe podržane su kako bi se zaštitili od gubitka podataka. Kopije podataka se prave jednom dnevno.',
                        'Zaštita od zlonamernog softvera, kao što su virusi i trojanci.',
                        'Zaštita od DDoS (Distributed Denial of Service) napada.'
                    ],
                    'Agencija zadržava pravo izmena i/ili dopuna Uslova korišćenja. Izmenjene će se primenjivati na sve buduće korisnike i na korisnike koji su pre usvajanja novih uslova već započeli pretplatu, pod uslovom da tim izmenama i dopunama ne trpe bilo kakvu štetu.'
                ]
            }
        ];

    pravilaPrivatnosti =
        [
            {
                'clanBroj': null,
                'title': 'Zapisnik.rs - Pravila privatnosti',
                'paragraphs': [
                    'Hvala vam što se želite informisati kako koristimo Vaše podatke kada posetite našu web stranicu.'
                ]
            },
            {
                'clanBroj': null,
                'title': 'Koje su ključne stvari koje treba da znam?',
                'paragraphs': [
                    'Korisnikom Web stranice smatra se svaka fizička i pravna osoba koja pristupa Web stranici i/ili koristi bilo koje sadržaje i/ili usluge dostupne na Web stranici, uključujući registrovane i neregistrovane korisnike kao i poslovne subjekte sa kojima Agencija ima sklopljene ugovore o pružanju usluga.'
                ]
            },
            {
                'clanBroj': null,
                'title': 'Ko se može registrovati?',
                'paragraphs': [
                    'Naša web stranica namenjena je punoletnim, radno sposobnim osobama starijim od 18 godina.'
                ]
            },
            {
                'clanBroj': null,
                'title': 'Šta su lični podaci?',
                'paragraphs': [
                    'Lični podaci su sve informacije koje možemo koristiti za identifikaciju i potvrđivanje vašeg identiteta. Ponekad će nam ti podaci jasno reći ko ste – na primer, vaše ime – ali možda ćemo i iz drugih podataka moći zaključiti ko ste. Telefoni i drugi uređaji koji se povezuju na Internet takođe imaju posebne adrese pomoću kojih nam možete reći ko ste.'
                ]
            },
            {
                'clanBroj': null,
                'title': 'Koje podatke prikupljate o meni?',
                'paragraphs': [
                    'Podaci koje prikupljamo o vama ograničeni su na:',
                    [
                        'Podaci o identitetu kao što su vaše ime i prezime, email adresa, adresa/lokacija.',
                        'Podaci o transakcijama, uključujući informacije o paketima usluga koje kupujete kod nas, ograničene informacije o plaćanju (većina informacija o tome ide ravno banci) i povraćaj novca.',
                        'Poruke, uključujući sve e -poruke koje nam šaljete i sve komentare upućene nama ili poruke između nas na društvenim medijima',
                        'Tehničke informacije o telefonu ili uređaju koje ste koristili za posetu našoj web stranici',
                        'Podaci o korišćenju o tome koliko često posećujete našu web stranicu, koje stranice pregledavate te koliko dugo i kako koristite našu web stranicu (na primer, koje vrste sadržaja ste gledali i koliko dugo te koje izbore donosite prilikom korišćenja web stranice)',
                        'Povratne informacije o tome šta mislite o našoj web stranici, a koje bismo mogli zatražiti da ispunite anketom'
                    ]
                ]
            },
            {
                'clanBroj': null,
                'title': 'Gde uzimate moje podatke?',
                'paragraphs': [
                    'Obično ćemo vaše podatke primati direktno od vas kada postavite vaš nalog. Ali ako vaš telefon ili uređaj delite sa nekim drugim, od njih možemo prikupljati podatke o vama.'
                ]
            },
            {
                'clanBroj': null,
                'title': 'Za šta koristite moje podatke?',
                'paragraphs': [
                    'Prema zakonu, vaše podatke možemo koristiti samo ako ispunjavamo jedno od šest pravila. Oni se nazivaju i „pravni osnovi“.',
                    'Ključna pravila na koja se oslanjamo pri korišćenju vaših podataka su:',
                    [
                        'moramo koristiti vaše podatke da bismo učinili nešto na šta smo se dogovorili, ili zahtevati da učinite nešto na šta ste pristali, prema bilo kojem sporazumu između nas, na primer, pod uslovima koji se primenjuju kada koristite naše web stranice (ugovor)',
                        'dali ste nam dozvolu za korišćenje vaših podataka iz određenog razloga (pristanak)',
                        'moramo koristiti vaše podatke u skladu sa zakonom (zakon)',
                        'moramo koristiti vaše podatke ako za to imamo dobar razlog, ako smo sigurni da biste to očekivali i da vaša prava neće biti ugrožena (dobar razlog)'
                    ],
                    'Razlozi korišćenja vaših podataka i pravila na koja se oslanjamo pri tome (u zagradama) su sledeći:',
                    [
                        'Omogućavanje korišćenja naše web stranice (ugovor ili dobar razlog)',
                        'Učiniti nešto što smo se dogovorili, ili zahtevati od vas (ili nekog drugog) da učini nešto što ste vi (ili oni) pristali, prema bilo kojem sporazumu, na primer, pod uslovima koji se primenjuju kada koristite naše web stranica (ugovor ili dobar razlog)',
                        'Šaljemo vam naše poruke putem e-pošte, o kojima se možete predomisliti u bilo kojem trenutku',
                        'Osiguravanje da svi koji posete našu web stranicu to mogu učiniti sigurno (dobar razlog)',
                        'Razumevanje načina na koji našu web stranicu koristite vi i drugi korisnici, tako da ih možemo popraviti ako se pojave problemi i poboljšati ih (dobar razlog)',
                        'Odgovaranje na sve poruke koje nam pošaljete e – mailom ili na društvenim medijima (dobar razlog)',
                        'Osiguravanje poštovanja svih pravila o zdravlju i sigurnosti i zaštiti korisnika te odgovaranje na pitanja službenih organizacija poput policije (zakon)'
                    ]
                ]
            },
            {
                'clanBroj': null,
                'title': 'Sa kim ćete podeliti moje podatke?',
                'paragraphs': [
                    'Vaše podatke nećemo deliti ni sa kim sa kim ih ne moramo deliti.',
                    'Jedini ljudi koji će imati pristup vašim podacima su:',
                    [
                        'članovi našeg tima',
                        'organizacije koje nam pružaju alate koji su nam potrebni za pokretanje naše web stranice, kao što su naš pružatelj usluga hostinga, dobavljač biltena putem e – maila i pružatelji usluga ili alati koji nam pomažu da shvatimo kako koristite našu web stranicu i šta se događa ako nastanu bilo kakvi problemi',
                        'službene organizacije poput policije, iako bi to bilo vrlo retko'
                    ]
                ]
            },
            {
                'clanBroj': null,
                'title': 'Gde čuvate moje podatke?',
                'paragraphs': [
                    'Vaše podatke čuvamo na zaštićenim računarima kojima se može pristupiti putem interneta pod nazivom „serveri“. Oni pripadaju drugim organizacijama. Većina poslužitelja koje koristimo su bazirani negde u Evropi, ali neki od ovih servera mogu biti smešteni u drugim zemljama.'
                ]
            },
            {
                'clanBroj': null,
                'title': 'Kako čuvate moje podatke na sigurnom?',
                'paragraphs': [
                    'Postoje različite tehnologije i druga rešenja kako bi vaši podaci bili sigurni. Ovo uključuje:',
                    [
                        'pobrinuti se da veza između vašeg telefona ili uređaja i naših servera bude sigurna kako nas niko ne bi mogao „prisluškivati“',
                        'korišćenje jakih lozinki na svim našim sistemima',
                        'samo malim grupama ljudi omogućiti pristup našim sistemima',
                        'potpisivanje ugovora sa našim osobljem i pružateljima usluga što znači da moraju čuvati vaše podatke na sigurnom i ne deliti ih ni sa kim',
                        'imate uspostavljen plan za rešavanje onoga što se događa ako vašim podacima pristupi neko ko im ne bi trebao imati pristup ili ako se vaši podaci izgube ili na bilo koji način promene'
                    ]
                ]
            },
            {
                'clanBroj': null,
                'title': 'Koliko dugo čuvate moje podatke?',
                'paragraphs': [
                    'Vaše podatke čuvaćemo onoliko koliko nam trebaju iz razloga zbog kojih smo ih i prikupili.'
                ]
            },
            {
                'clanBroj': null,
                'title': 'Brisanje korisničkih podataka',
                'paragraphs': [
                    'Korisnici mogu zahtevati brisanje svih svojih podataka sa naših servera nakon što više ne budu želeli koristiti Zapisnik aplikaciju. Da biste podneli zahtev za brisanje podataka, pošaljite e-mail poruku sa istog e-mail naloga koji ste koristili pri registraciji, na sledeću adresu: info@zapisnik.rs. U e-mailu navedite da želite da se svi vaši podaci obrišu sa naših servera.',
                    'Nakon što primimo vaš e-mail sa zahtevom, preduzećemo odgovarajuće korake kako bismo u što kraćem roku izbrisali vaše podatke. Imajte na umu da će neki podaci možda ostati u rezervnim kopijama ili arhivama nakon brisanja radi očuvanja integriteta sistema, ali nećemo ih koristiti ni deliti na bilo koji način.'
                ]
            },
            {
                'clanBroj': null,
                'title': 'Šta su kolačići?',
                'paragraphs': [
                    'Kolačići su male tekstualne datoteke pohranjene na vašem telefonu ili uređaju i koriste se za osiguravanje da naša web stranica radi kako treba i pomažu nam da vas razlikujemo od ostalih posetioca naše web stranice.',
                    'Za rad naše web stranice potrebni su neki kolačići, pa će se oni automatski koristiti. Bilo koji kolačići koje smatramo korisnima iz drugog razloga neće se koristiti ako na njih ne pristanete putem našeg natpisa za kolačiće.'
                ]
            },
            {
                'clanBroj': null,
                'title': 'Koja prava imam?',
                'paragraphs': [
                    'Zakon vam pruža velika prava kada je u pitanju način na koji koristimo vaše podatke. Zavisno o činjenicama, možda nećete moći koristiti sva ova prava.',
                    'Pristup - Imate pravo biti obavešteni koristimo li vaše podatke i pravo zahtevati kopiju podataka o vama zajedno s određenim informacijama o tome kako koristimo vaše podatke.',
                    'Ispravka - Imate pravo zatražiti od nas da ispravimo sve podatke koje posedujemo o vama, a koji nisu tačni ili nisu potpuni.',
                    'Brisanje - Imate pravo zatražiti od nas da izbrišemo ili uklonimo sve podatke o vama koji postoje ako nema valjanog razloga da ih nastavimo držati ili ako ste nam prigovorili korišćenje vaših podataka.',
                    'Ograničenje - Imate pravo zatražiti od nas da ograničimo način na koji čuvamo vaše podatke, na primer, da potvrdimo njihovu tačnost ili naše razloge za njihovo držanje.',
                    'Prigovor - Imate pravo prigovoriti na to da mi posedujemo bilo koju informaciju o vama koja se zasniva na bilo kojem od dobrih razloga koje smo gore opisali. Takođe, imate pravo da uložite prigovor na to što vaše podatke držimo u marketinške svrhe.',
                    'Prenosivost - Imate pravo primiti ili zahtevati da vam prenesemo kopiju podataka koje posedujemo o vama ako ste nam dali pristanak da koristimo takve podatke ili moramo vaše podatke koristiti kao deo našeg dogovora s vama.',
                    'Žalbe - Imate pravo podneti žalbu u vezi s načinom na koji prikupljamo i koristimo vaše podatke.',
                ]
            },
            {
                'clanBroj': null,
                'title': 'Šta ako imam pitanje?',
                'paragraphs': [
                    'Možete nas pitati i slanjem e -pošte na info@zapisnik.rs.'
                ]
            }
        ]
}
