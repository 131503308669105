import React, { Component } from 'react';
import './layout/layout.css';
import LayoutClass from './layout/layout.js';
import { SidebarMenu } from './SidebarMenu';
import MenuBlackIcon from './icons/menu-line-black.svg';
import Cookies from 'js-cookie';

export class Layout extends Component {
    static displayName = Layout.name;

    LayoutInstance = new LayoutClass();
    componentDidMount() {
        document.title = this.props.pageTitle ? this.props.pageTitle : 'Zapisnik';
        this.LayoutInstance.mountLayout();
    };

    render() {
        let sideBarMenuCollapsed = '';
        let sideBarMenuCollapsedCookie = Cookies.get('SideBarMenuCollapsed');
        if (sideBarMenuCollapsedCookie) {
            sideBarMenuCollapsed = (sideBarMenuCollapsedCookie.toLowerCase() === 'true') ? 'collapsed' : '';
        }

        return (
            <div className="layout has-sidebar fixed-sidebar fixed-header">

                <aside id="sidebar" className={`sidebar break-point-lg ${sideBarMenuCollapsed}`}>
                    <SidebarMenu />
                </aside>

                <div id="overlay" className="overlay"></div>

                <div className="layout">
                    <main className="content">
                        <a id="btn-toggle" className="sidebar-toggler break-point-lg">
                            <img src={MenuBlackIcon} />
                        </a>

                        {this.props.children}

                    </main>

                    <div className="overlay"></div>
                </div>



            </div>


        );
    }
}

