import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DateTimeService } from '../DateTimeService';
import FileAddWhiteIcon from '../icons/file-add-white.svg';
import { RokEdit } from './RokEdit';
import { HelpTooltip } from '../HelpTooltip';

export class RokoviPredmeta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rokovi: [],
            loading: true,
            editMode: false,
            editingItem: {}
        };

        this.rowSelected = this.rowSelected.bind(this);
        this.newItemTriggered = this.newItemTriggered.bind(this);
        this.onRokEditSaveClick = this.onRokEditSaveClick.bind(this);
        this.onRokEditCancelClick = this.onRokEditCancelClick.bind(this);
    }

    async componentDidMount() {
        let rokovi = this.props.rokovi ?? await this.props.onRokoviMount();
        let odgovornaLica = this.props.odgovornaLica ?? await this.props.onOdgovornaLicaMount();

        this.setState({
            rokovi: rokovi,
            odgovornaLica: odgovornaLica,
            loading: false
        });
    }

    renderRokoviPredmeta(rokovi) {
        const footer = `Ukupno stavki: ${rokovi ? rokovi.length : 0}.`;

        return (
            <div>
                <div className="form-header">
                    <button className="btn btn-primary btns" onClick={this.newItemTriggered}>
                        <img alt="" src={FileAddWhiteIcon} />
                        &nbsp;
                        Rok
                    </button>
                    <HelpTooltip>
                        <p>
                            Rokovi predmeta su informacije o postavljenim rokovima vezanih za predmet.
                        </p>
                        <p>
                            Ovde možete unositi sve zvanične i nezvanične podatke o postavljenim rokovima predmeta.
                        </p>
                        <p>
                            Rokovi mogu biti postavljeni od strane suda, a možete i sami sebi postaviti rokove kako biste poboljšali ažurnost predmeta.
                        </p>
                        <p>
                            Svi upisani rokovi će se automatski pojaviti u kalendar.
                        </p>
                    </HelpTooltip>
                </div>

                <DataTable value={rokovi}
                    dataKey="id"
                    sortField="datum" sortOrder={-1}
                    tableStyle={{ minWidth: '50rem' }} className="custom-datatable"
                    emptyMessage="Nema unetih podataka."
                    footer={footer}
                    selectionMode="single" onSelectionChange={(e) => this.rowSelected(e.value)} 
                >
                    <Column field="datum" body={this.datumTemplate} sortable header="Vreme" style={{ width: '20%' }}></Column>
                    <Column field="naziv" header="Naziv" sortable style={{ width: '30%' }}></Column>
                    <Column field="opis" header="Opis" sortable style={{ width: '40%' }}></Column>
                    <Column field="imePrezimeOdgovornogLica" header="Odgovorno lice" style={{ width: '10%' }}></Column>
                </DataTable>
            </div>
        );
    }

    datumTemplate = (rokPredmeta) => {
        return DateTimeService.formatDateTimeToString(rokPredmeta.datum);
    };

    rowSelected(item) {
        this.setState({
            editingItem: item,
            editMode: true
        });
    }

    newItemTriggered() {
        this.setState({
            editingItem: {},
            editMode: true
        });
    }

    onRokEditSaveClick() {
        this.setState({
            loading: true,
            editMode: false
        }, async () => {
            let rokovi = await this.props.onRokoviMount();
            this.setState({
                rokovi: rokovi,
                loading: false
            });
        });
    }

    onRokEditCancelClick() {
        this.setState({
            editMode: false
        });
    }

    render() {
        let displayDataContent = this.state.editMode
            ? <RokEdit rokPredmeta={this.state.editingItem} predmetId={this.props.predmet.id}
                imePrezimeKlijenta={this.props.imePrezimeKlijenta} brojPredmeta={this.props.predmet.brojPredmetaFull}
                onSaveClick={this.onRokEditSaveClick} onCancelClick={this.onRokEditCancelClick}
                odgovornaLica={this.state.odgovornaLica}
            />
            : this.renderRokoviPredmeta(this.state.rokovi);

        let contents =
            this.state.loading
                ? <p><em>Učitavanje...</em></p>
                : displayDataContent;

        return (
            <div>
                {contents}
            </div>
        );
    }
}
