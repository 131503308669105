import React, { Component } from 'react';
import { UvozTipovaPredmeta } from './UvozTipovaPredmeta';
import { IzvozTipovaPredmeta } from './IzvozTipovaPredmeta';

export class UvozIzvozTipovaPredmetaContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <UvozTipovaPredmeta />
                <IzvozTipovaPredmeta />
            </div>
        );
    }
}
