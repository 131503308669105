import React, { Component } from 'react';
import { Toast } from 'primereact/toast';
import { TipPredmetaOsnovniPodaciView } from './TipPredmetaOsnovniPodaciView';
import { TipPredmetaOsnovniPodaciEdit } from './TipPredmetaOsnovniPodaciEdit';
    
export class TipPredmetaOsnovniPodaciContainer extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();

        this.state = {
            loading: true,
            editMode: false
        };

        this.setEditMode = this.setEditMode.bind(this);
        this.saveClicked = this.saveClicked.bind(this);
    }

    componentDidMount() {
        this.setState({
            tipPredmeta: this.props.tipPredmeta,
            loading: false,
            editMode: !this.props.tipPredmeta || !this.props.tipPredmeta.id ? true : false
        });
    }

    setEditMode(editMode) {
        this.setState({
            editMode: editMode
        });
    }

    saveClicked(tipPredmeta) {
        this.setState({
            tipPredmeta: tipPredmeta
        }, () => {
            this.setEditMode(false);
            this.toastRef.current.show({ severity: 'success', summary: 'Uspešno sačuvano', detail: "Uspešno ste sačuvali tip predmeta." });
        });

        this.props.onTipPredmetaUpdate(tipPredmeta);
    }

    render() {
        let tipPredmetaOsnovniPodaciView = this.state.editMode
            ? <TipPredmetaOsnovniPodaciEdit tipPredmeta={this.state.tipPredmeta} onCancelClick={() => this.setEditMode(false)} onSaveClick={(tipPredmeta) => this.saveClicked(tipPredmeta)} />
            : <TipPredmetaOsnovniPodaciView tipPredmeta={this.state.tipPredmeta} onAzurirajClick={() => this.setEditMode(true)} />;

        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : tipPredmetaOsnovniPodaciView;

        return (
            <div>
                {contents}
                <Toast ref={this.toastRef} />
            </div>
        );
    }
}
