class ConstantsClass {
    ANIMATION_DURATION() {
        return 300;
    }

    SIDEBAR_EL() {
        return document.getElementById('sidebar');
    }

    SUB_MENU_ELS() {
        return document.querySelectorAll(
            '.menu > ul > .menu-item.sub-menu'
        );
    }

    FIRST_SUB_MENUS_BTN() {
        return document.querySelectorAll(
            '.menu > ul > .menu-item.sub-menu > a'
        );
    }

    INNER_SUB_MENUS_BTN() {
        return document.querySelectorAll(
            '.menu > ul > .menu-item.sub-menu .menu-item.sub-menu > a'
        );
    }
}

export default ConstantsClass;