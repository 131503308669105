import React, { Component } from 'react';
import fetchService from '../FetchService';

export class IzvozTipovaPredmeta extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <h2 className='mb-3 page-title'>Izvoz tipova predmeta</h2>

                <div className="form-container">
                    <div className="mb-3">
                        <p>
                            U ovom delu možete izesti svoje tipove predmeta u eksternu datoteku u ".csv" formatu.
                        </p>
                        <div className="form-container">
                            <div className="mb-3">
                                <button className="btn btn-primary" onClick={() => this.downloadIzvozTipovaPredmeta()}>
                                    Izvoz tipova predmeta
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async downloadIzvozTipovaPredmeta() {
        const dataBlob = await fetchService.getBlob('uvozIzvozTipovaPredmeta');
        this.pushBlobToUser(dataBlob.responseValue, "tipovi-predmeta.csv");
    }

    pushBlobToUser(dataBlob, fileName) {
        var objectURL = URL.createObjectURL(dataBlob);
        const link = document.createElement('a');
        link.href = objectURL;
        link.download = fileName;
        link.click();
    }
}
