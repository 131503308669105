import React, { Component } from 'react';
import fetchService from '../FetchService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { HelpTooltip } from '../HelpTooltip';
import { DateTimeService } from '../DateTimeService';
import { MultiSelect } from 'primereact/multiselect';
import { Sidebar } from 'primereact/sidebar';
import { Calendar } from 'primereact/calendar';
import { Badge } from 'primereact/badge';
import Download2FillIcon from '../icons/download-2-fill.svg';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import EqualizerLineIcon from '../icons/equalizer-line.svg';

export class PregledZapisa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zapisi: [],
            loading: true,
            tableLoading: true,
            lazyState: {
                first: 0,
                rows: 10,
                page: 0,
                sortField: 'vremeUnosa',
                sortOrder: -1,
                filter: ''
            },
            typingTimeout: 0,
            totalRecords: 0,
            tipoviPredmeta: [],
            nosiociPredmeta: [],
            tipoviSuda: [],
            advancedFilterValues: {
                dateFrom: null,
                dateTo: null,
                opis: '',
                selectedTipoviPredmeta: [],
                selectedNosiociPredmeta: [],
                klijent: '',
                selectedTipoviSuda: [],
                mesto: '',
                brojPredmeta: '',
                selectedOdgovornaLica: []
            },
            lastSavedAdvancedFilterValues: {},
            advancedFilterOpen: false,
            advancedFilterBadgeValue: 0
        };
        this.filterReference = React.createRef();

        this.confirmDownloadIzvozStavki = this.confirmDownloadIzvozStavki.bind(this);
        this.downloadIzvozStavki = this.downloadIzvozStavki.bind(this);
    }

    componentDidMount() {
        this.populatePodaci();

        this.getTipoviPredmeta();
        this.getNosiociPredmeta();
        this.getTipoviSuda();
    }

    renderPregledZapisa(zapisiPredmeta) {
        const footer = `Ukupno stavki: ${this.state.totalRecords}.`;

        return (
            <div>
                <DataTable value={zapisiPredmeta}
                    dataKey="id"
                    lazy totalRecords={this.state.totalRecords}
                    first={this.state.lazyState.first}
                    onPage={this.onPage}
                    onSort={this.onSort} sortField={this.state.lazyState.sortField} sortOrder={this.state.lazyState.sortOrder}
                    header={this.renderHeader}
                    footer={footer}
                    paginator rows={10}
                    tableStyle={{ minWidth: '50rem' }} className="custom-datatable"
                    tableClassName='table'
                    rowClassName={this.rowClass}
                    loading={this.state.tableLoading}
                    emptyMessage="Nema unetih podataka."
                    selectionMode="single" onSelectionChange={(e) => window.location = '/predmet?id=' + e.value.predmetId} >

                    <Column field="imeKlijenta" header="Klijent" body={this.imePrezimeTemplate} sortable style={{ width: '15%', minWidth: '250px' }}></Column>
                    <Column field="tipPredmeta" header="Tip" sortable style={{ width: '10%', minWidth: '250px' }}></Column>

                    <Column field="sud" header="Sud" sortable style={{ width: '15%', minWidth: '250px' }}></Column>
                    <Column field="mesto" header="Mesto" sortable style={{ width: '15%', minWidth: '150px' }}></Column>

                    <Column field="brojPredmeta" header="Broj" sortable style={{ width: '15%', minWidth: '150px' }}></Column>

                    <Column field="vremeUnosa" body={this.vremeUnosaTemplate} sortable header="Vreme" style={{ width: '10%', minWidth: '150px' }}></Column>
                    <Column field="opis" header="Opis" style={{ width: '20%', minWidth: '300px' }}></Column>

                    <Column field="imePrezimeOdgovornogLica" header="Odgovorno lice" style={{ minWidth: '250px' }}></Column>
                    <Column field="imePrezimeNosiocaPredmeta" header="Nosilac predmeta" style={{ minWidth: '250px' }}></Column>
                </DataTable>
            </div>
        );
    }

    vremeUnosaTemplate = (zapisPredmeta) => {
        return DateTimeService.formatDateTimeToString(zapisPredmeta.vremeUnosa);
    }

    imePrezimeTemplate = (zapisPredmeta) => {
        return `${zapisPredmeta.imeKlijenta} ${zapisPredmeta.prezimeKlijenta}`;
    };

    renderHeader = () => {
        const customIcons = (
            <React.Fragment>
                <h2>Filteri</h2>
                <div>
                    <button className="btn btn-primary btns" onClick={() => { this.populatePodaci(); this.setState({ advancedFilterOpen: false }); }}>
                        Primeni
                    </button>
                    <button className="btn btn-secondary btns" onClick={() => {
                        this.setState({
                            advancedFilterOpen: false,
                            advancedFilterValues: {
                                dateFrom: null,
                                dateTo: null,
                                opis: '',
                                selectedTipoviPredmeta: [],
                                selectedNosiociPredmeta: [],
                                klijent: '',
                                selectedTipoviSuda: [],
                                mesto: '',
                                brojPredmeta: '',
                                selectedOdgovornaLica: []
                            }
                        }, () => { this.populatePodaci(); });
                    }}>
                        Očisti
                    </button>
                </div>

            </React.Fragment>
        );

        return (
            <div className="form-header flex justify-content-between">
                <div>
                    <button className="btn btn-primary" onClick={this.confirmDownloadIzvozStavki}>
                        <img alt="" src={Download2FillIcon} />
                    </button>
                </div>

                <div className="flex justify-content-end">
                    <div className="p-input-icon-left mx-3">
                        <i className="pi pi-search" />
                        <InputText value={this.state.lazyState.filter} placeholder="Traži..." ref={this.filterReference} className='search-table'
                            onChange={this.onFilterChange} onKeyDown={this.onFilterKeyDown} />
                    </div>
                    <div>
                        <button className="btn btn-primary btns p-overlay-badge" onClick={() => { this.setState({ advancedFilterOpen: true }); }}>
                            <img alt="" src={EqualizerLineIcon} />
                            <span className="hide-on-xs">
                                &nbsp;Filteri
                            </span>
                            <Badge value={this.state.advancedFilterBadgeValue} severity="warning" hidden={this.state.advancedFilterBadgeValue == 0} ></Badge>
                        </button>
                    </div>

                    <Sidebar visible={this.state.advancedFilterOpen} position="right" icons={customIcons}
                        className='advanced-filter-container'
                        onHide={() => { this.setState({ advancedFilterOpen: false, advancedFilterValues: this.state.lastSavedAdvancedFilterValues }); }}>

                        <div>
                            <label htmlFor="filterDateFrom" className="form-label">Datum od</label>
                            <Calendar id="filterDateFrom"
                                value={this.state.advancedFilterValues.dateFrom ?? ''}
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, dateFrom: e.target.value } })}
                                dateFormat="dd.mm.yy"
                                className="w-full advanced-filter-item mb-3" />

                            <label htmlFor="filterDateTo" className="form-label">Datum do</label>
                            <Calendar id="filterDateTo"
                                value={this.state.advancedFilterValues.dateTo ?? ''}
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, dateTo: e.target.value } })}
                                dateFormat="dd.mm.yy"
                                className="w-full advanced-filter-item mb-3" />

                            <label htmlFor="filterOpis" className="form-label">Opis</label>
                            <InputText id='filterOpis'
                                value={this.state.advancedFilterValues.opis}
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, opis: e.target.value } })}
                                onKeyDown={this.onAdvancedFilterKeyDown}
                                className='w-full advanced-filter-item mb-3' />

                            <label htmlFor="filterOdgovornoLiceId" className="form-label">Odgovorno lice</label>
                            <MultiSelect id='filterOdgovornoLiceId'
                                value={this.state.advancedFilterValues.selectedOdgovornaLica}
                                options={this.state.nosiociPredmeta}
                                optionLabel="imePrezime"
                                optionValue="id"
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, selectedOdgovornaLica: e.target.value } })}
                                display="chip"
                                filter
                                showClear
                                className="w-full advanced-filter-item mb-3" />

                            <hr />

                            <label htmlFor="filterTipPredmetaId" className="form-label">Tip predmeta</label>
                            <MultiSelect id='filterTipPredmetaId'
                                value={this.state.advancedFilterValues.selectedTipoviPredmeta}
                                options={this.state.tipoviPredmeta}
                                optionLabel="naziv"
                                optionValue="id"
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, selectedTipoviPredmeta: e.target.value } })}
                                display="chip"
                                filter
                                showClear
                                className="w-full advanced-filter-item mb-3" />

                            <label htmlFor="filterNosilacPredmetaId" className="form-label">Nosilac predmeta</label>
                            <MultiSelect id='filterNosilacPredmetaId'
                                value={this.state.advancedFilterValues.selectedNosiociPredmeta}
                                options={this.state.nosiociPredmeta}
                                optionLabel="imePrezime"
                                optionValue="id"
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, selectedNosiociPredmeta: e.target.value } })}
                                display="chip"
                                filter
                                showClear
                                className="w-full advanced-filter-item mb-3" />

                            <label htmlFor="filterKlijent" className="form-label">Klijent</label>
                            <InputText id='filterKlijent'
                                value={this.state.advancedFilterValues.klijent}
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, klijent: e.target.value } })}
                                onKeyDown={this.onAdvancedFilterKeyDown}
                                className='w-full advanced-filter-item mb-3' />

                            <label htmlFor="filterTipSudaId" className="form-label">Tip suda</label>
                            <MultiSelect id='filterTipSudaId'
                                value={this.state.advancedFilterValues.selectedTipoviSuda}
                                options={this.state.tipoviSuda}
                                optionLabel="naziv"
                                optionValue="id"
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, selectedTipoviSuda: e.target.value } })}
                                display="chip"
                                filter
                                showClear
                                className="w-full advanced-filter-item mb-3" />

                            <label htmlFor="filterMesto" className="form-label">Mesto</label>
                            <InputText id='filterMesto'
                                value={this.state.advancedFilterValues.mesto}
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, mesto: e.target.value } })}
                                onKeyDown={this.onAdvancedFilterKeyDown}
                                className='w-full advanced-filter-item mb-3' />

                            <label htmlFor="filterBrojPredmeta" className="form-label">Broj predmeta</label>
                            <InputText id='filterBrojPredmeta'
                                value={this.state.advancedFilterValues.brojPredmeta}
                                onChange={(e) => this.setState({ advancedFilterValues: { ...this.state.advancedFilterValues, brojPredmeta: e.target.value } })}
                                onKeyDown={this.onAdvancedFilterKeyDown}
                                className='w-full advanced-filter-item mb-3' />


                        </div>
                    </Sidebar>
                </div>
            </div>
        );
    }

    render() {
        let contents =
            this.state.loading
                ? <p><em>Učitavanje...</em></p>
                : this.renderPregledZapisa(this.state.zapisi);

        return (
            <div>
                <h2 className='mb-3 page-title' id="tabelLabel">
                    Pregled zapisa predmeta
                    <HelpTooltip>
                        Pregled zapisa svih predmeta.
                    </HelpTooltip>
                </h2>
                {contents}

                <ConfirmDialog />
            </div>
        );
    }

    async populatePodaci() {
        this.setState({
            tableLoading: true
        });

        await this.getPodaci();

        this.setState({
            loading: false,
            tableLoading: false
        });
    }

    getBodyInput() {
        let bodyInput =
        {
            "Rows": this.state.lazyState.rows,
            "Page": this.state.lazyState.page + 1,
            "SortField": this.state.lazyState.sortField,
            "SortOrder": this.state.lazyState.sortOrder,
            "Filter": this.state.lazyState.filter,
            "AdditionalFilters": {
                "Opis": this.state.advancedFilterValues.opis,
                "DateFrom": this.state.advancedFilterValues.dateFrom,
                "DateTo": this.state.advancedFilterValues.dateTo,
                "TipoviPredmetaIds": this.state.advancedFilterValues.selectedTipoviPredmeta ?? [],
                "NosiociPredmetaIds": this.state.advancedFilterValues.selectedNosiociPredmeta ?? [],
                "Klijent": this.state.advancedFilterValues.klijent,
                "TipoviSudovaIds": this.state.advancedFilterValues.selectedTipoviSuda ?? [],
                "Mesto": this.state.advancedFilterValues.mesto,
                "BrojPredmeta": this.state.advancedFilterValues.brojPredmeta,
                "OdgovornaLicaIds": this.state.advancedFilterValues.selectedOdgovornaLica ?? []
            },
            "TimeZoneName": DateTimeService.getTimeZoneName()
        };

        return bodyInput;
    }

    async getPodaci() {
        let bodyInput = this.getBodyInput();

        this.state.lastSavedAdvancedFilterValues = this.state.advancedFilterValues;

        let advancedFilterBadgeValue = 0;
        if (this.state.advancedFilterValues.dateFrom) advancedFilterBadgeValue++;
        if (this.state.advancedFilterValues.dateTo) advancedFilterBadgeValue++;
        if (this.state.advancedFilterValues.opis.length > 0) advancedFilterBadgeValue++;
        if (this.state.advancedFilterValues.selectedTipoviPredmeta.length > 0) advancedFilterBadgeValue++;
        if (this.state.advancedFilterValues.selectedNosiociPredmeta.length > 0) advancedFilterBadgeValue++;
        if (this.state.advancedFilterValues.klijent.length > 0) advancedFilterBadgeValue++;
        if (this.state.advancedFilterValues.selectedTipoviSuda.length > 0) advancedFilterBadgeValue++;
        if (this.state.advancedFilterValues.mesto.length > 0) advancedFilterBadgeValue++;
        if (this.state.advancedFilterValues.brojPredmeta.length > 0) advancedFilterBadgeValue++;
        if (this.state.advancedFilterValues.selectedOdgovornaLica.length > 0) advancedFilterBadgeValue++;

        this.state.advancedFilterBadgeValue = advancedFilterBadgeValue;

        let response = await fetchService.post('pregledZapisa', bodyInput);
        const data = response.success ? response.responseValue : [];

        this.setState({
            zapisi: data.zapisi,
            totalRecords: data.totalCount
        });
    }

    async getTipoviPredmeta() {
        let requestUrl = `tipoviPredmeta?rows=500&page=1&sortField=naziv&sortOrder=1&active=true`;

        const response = await fetchService.get(requestUrl);
        const data = response.success ? response.responseValue : [];

        let emptyTipPredmeta = [{ id: '', naziv: '< bez tipa >' }];

        this.setState({
            tipoviPredmeta: emptyTipPredmeta.concat(data.tipoviPredmeta)
        });
    }

    async getNosiociPredmeta() {
        let requestUrl = `nosiociPredmeta`;

        const response = await fetchService.get(requestUrl);
        const data = response.success ? response.responseValue : [];

        let emptyNosilacPredmeta = [{ id: '', imePrezime: '< bez nosioca >' }];

        this.setState({
            nosiociPredmeta: emptyNosilacPredmeta.concat(data)
        });
    }

    async getTipoviSuda() {
        let data = [
            { id: 1, naziv: 'Neodredjen' },
            { id: 2, naziv: 'Vrhovni kasacioni' },
            { id: 3, naziv: 'Apelacioni' },
            { id: 4, naziv: 'Viši' },
            { id: 5, naziv: 'Osnovni' },
            { id: 6, naziv: 'Upravni' },
            { id: 7, naziv: 'Privredni' },
            { id: 8, naziv: 'Prekršajni' },
            { id: 9, naziv: 'Prekršajni apelacioni' }
        ];

        this.setState({
            tipoviSuda: data
        });

        return data;
    }

    onPage = (event) => {
        this.setState(
            {
                lazyState: {
                    first: event.first,
                    rows: event.rows,
                    page: event.page,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                    filter: this.state.lazyState.filter
                }
            },
            () => this.populatePodaci()
        );
    }

    onSort = (event) => {
        this.setState(
            {
                lazyState: {
                    first: event.first,
                    rows: event.rows,
                    page: this.state.lazyState.page,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                    filter: this.state.lazyState.filter
                }
            },
            () => this.populatePodaci()
        );
    }


    onFilterChange = (event) => {
        const value = event.target.value;
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            lazyState: {
                ...this.state.lazyState,
                filter: value
            },
            typingTimeout: setTimeout(function () {
                self.populatePodaci();
            }, 800)
        });
    }

    onFilterKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (this.state.typingTimeout) {
                clearTimeout(this.state.typingTimeout);
            }

            this.setState({
                typingTimeout: 0
            });

            this.populatePodaci();
        }
    }

    onAdvancedFilterKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.populatePodaci();
            this.setState({ advancedFilterOpen: false });
        }
    }

    confirmDownloadIzvozStavki(event) {
        confirmDialog({
            trigger: event.currentTarget,
            message: 'Da li želite da preuzmete podatke i sačuvate ih na disk?',
            header: 'Preuzimanje podataka',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-info',
            accept: () => { this.downloadIzvozStavki() },
            acceptLabel: 'Da',
            rejectLabel: 'Ne'
        });
    };

    async downloadIzvozStavki() {
        let bodyInput = this.getBodyInput();

        const dataBlob = await fetchService.getBlob('pregledZapisaExport', bodyInput, 'POST');
        this.pushBlobToUser(dataBlob.responseValue, "zapisi.csv");
    }

    pushBlobToUser(dataBlob, fileName) {
        var objectURL = URL.createObjectURL(dataBlob);
        const link = document.createElement('a');
        link.href = objectURL;
        link.download = fileName;
        link.click();
    }
}
