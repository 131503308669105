import React, { Component } from 'react';
import fetchService from '../FetchService';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import CloseLineWhiteIcon from '../icons/close-line-white.svg';
import SaveLineWhiteIcon from '../icons/save-line-white.svg';
import DeleteBinLineWhiteIcon from '../icons/delete-bin-line-white.svg';
import { DateTimeService } from '../DateTimeService';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputSwitch } from 'primereact/inputswitch';
import { HelpTooltip } from '../HelpTooltip';
import { InputMask } from "primereact/inputmask";
import { MultiSelect } from 'primereact/multiselect';

export class AdvokatKancelarijeEdit extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();
        this.formRef = React.createRef();

        this.state = {
            loading: true,
            advokat: {
                ime: '',
                prezime: '',
                email: '',
                isAdmin: false,
                kancelarijaTimIds: []
            },
            nazivKancelarije: ''
        };

        this.saveDataToApi = this.saveDataToApi.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }

    componentDidMount() {
        if (this.props.advokat.id) {
            this.setState({
                advokat: {
                    ...this.props.advokat
                },
                nazivKancelarije: this.props.kancelarija.naziv,
                loading: false
            });
        } else {
            this.setState({
                advokat: {
                    ...this.state.advokat,
                    kancelarijaId: this.props.kancelarija.id
                },
                nazivKancelarije: this.props.kancelarija.naziv,
            })
        }

        this.setState({
            loading: false
        });
    }

    async saveDataToApi() {
        if (!this.formRef.current.checkValidity()) {
            this.formRef.current.classList.add('was-validated');
            return;
        }

        this.setState({ loading: true });

        const method = this.state.advokat.id ? 'PUT' : 'POST';
        const bodyInput = {
            "Id": this.state.advokat.id,
            "KancelarijaId": this.props.kancelarija.id,
            "Email": this.state.advokat.email,
            "IsAdmin": this.state.advokat.isAdmin,
            "KancelarijaTimIds": this.state.advokat.kancelarijaTimIds
        };

        let response = await fetchService.save('kancelarijaAdvokat', bodyInput, method);

        this.setState({ loading: false });

        if (!response.success) {
            let errorMessage = response.message ? 'Greška: ' + response.message : '';
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom čuvanja advokata. " + errorMessage, sticky: true });
        }
        else {
            this.props.onSaveClick();
        }
    }

    async deleteItem() {
        this.setState({ loading: true });

        let response = await fetchService.delete(`kancelarijaAdvokat?kancelarijaId=${this.props.kancelarija.id}&kancelarijaAdvokatId=${this.state.advokat.id}`);

        this.setState({ loading: false });

        if (!response.success) {
            this.toastRef.current.show({ severity: 'error', summary: 'Greška', detail: "Došlo je do greške prilikom brisanja advokata. Greška: " + response.message, sticky: true });
        }
        else {
            this.props.onSaveClick();
        }
    }

    cancelEdit() {
        this.props.onCancelClick();
    }

    confirmDelete(event) {
        confirmDialog({
            trigger: event.currentTarget,
            message: 'Da li želite da obrišete ovog advokata?',
            header: 'Brisanje advokata',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => { this.deleteItem() },
            acceptLabel: 'Da',
            rejectLabel: 'Ne'
        });
    };

    renderForm() {
        return (
            <div>
                <div className="mb-3 form-header">
                    <button className="btn btn-primary btns" onClick={this.saveDataToApi}>
                        <img alt="" src={SaveLineWhiteIcon} />
                        &nbsp;
                        Sačuvaj
                    </button>
                    <button hidden={this.state.advokat.id ? false : true} className="btn btn-danger btns" onClick={this.confirmDelete}>
                        <img alt="" src={DeleteBinLineWhiteIcon} />
                        &nbsp;
                        Briši
                    </button>
                    <button className="btn btn-secondary btns" onClick={this.cancelEdit}>
                        <img alt="" src={CloseLineWhiteIcon} />
                        &nbsp;
                        Odustani
                    </button>
                </div>

                <form className='form-group' ref={this.formRef}>
                    <div className="form-container">
                        <div className="mb-3">
                            <label htmlFor="nazivKancelarije" className="form-label">Kancelarija</label>
                            <input readOnly className="form-control" id="nazivKancelarije" value={this.state.nazivKancelarije ?? ''} />
                        </div>

                        <div className="row">
                            <div className="mb-3 col-sm-6">
                                <label htmlFor="ime" className="form-label">Ime</label>
                                <input readOnly className="form-control" id="ime" value={this.state.advokat.ime ?? ''} />
                            </div>
                            <div className="mb-3 col-sm-6">
                                <label htmlFor="prezime" className="form-label">Prezime</label>
                                <input readOnly className="form-control" id="prezime" value={this.state.advokat.prezime ?? ''} />
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input required className="form-control" id="email" value={this.state.advokat.email ?? ''}
                                readOnly={this.state.advokat.id ? 'readOnly' : ''}
                                onChange={(e) => this.setState({ advokat: { ...this.state.advokat, email: e.target.value } })} />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="isAdmin" className="form-label">Administrator</label>
                            &nbsp;&nbsp;&nbsp;
                            <InputSwitch id="isAdmin" checked={this.state.advokat.isAdmin} className="form-control"
                                onChange={(e) => this.setState({ advokat: { ...this.state.advokat, isAdmin: e.value } })} />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="timovi" className="form-label">Timovi</label>
                            <MultiSelect id='timovi'
                                value={this.state.advokat.kancelarijaTimIds}
                                options={this.props.kancelarija.timovi}
                                optionLabel="naziv"
                                optionValue="id"
                                onChange={(e) => this.setState({ advokat: { ...this.state.advokat, kancelarijaTimIds: e.target.value } })}
                                display="chip"
                                filter
                                showClear
                                className="w-full p-dropdown-fullwidth form-control" />
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : this.renderForm();

        return (
            <div>
                {contents}

                <Toast ref={this.toastRef} />
                <ConfirmDialog />
            </div>
        );
    }
}
