import React, { Component } from 'react';
import { Toast } from 'primereact/toast';
import { TipRacunaOsnovniPodaciView } from './TipRacunaOsnovniPodaciView';
import { TipRacunaOsnovniPodaciEdit } from './TipRacunaOsnovniPodaciEdit';
    
export class TipRacunaOsnovniPodaciContainer extends Component {
    constructor(props) {
        super(props);
        this.toastRef = React.createRef();

        this.state = {
            loading: true,
            editMode: false
        };

        this.setEditMode = this.setEditMode.bind(this);
        this.saveClicked = this.saveClicked.bind(this);
    }

    componentDidMount() {
        this.setState({
            tipRacuna: this.props.tipRacuna,
            loading: false,
            editMode: !this.props.tipRacuna || !this.props.tipRacuna.id ? true : false
        });
    }

    setEditMode(editMode) {
        this.setState({
            editMode: editMode
        });
    }

    saveClicked(tipRacuna) {
        this.setState({
            tipRacuna: tipRacuna
        }, () => {
            this.setEditMode(false);
            this.toastRef.current.show({ severity: 'success', summary: 'Uspešno sačuvano', detail: "Uspešno ste sačuvali tip računa." });
        });

        this.props.onTipRacunaUpdate(tipRacuna);
    }

    render() {
        let tipRacunaOsnovniPodaciView = this.state.editMode
            ? <TipRacunaOsnovniPodaciEdit tipRacuna={this.state.tipRacuna} onCancelClick={() => this.setEditMode(false)} onSaveClick={(tipRacuna) => this.saveClicked(tipRacuna)} />
            : <TipRacunaOsnovniPodaciView tipRacuna={this.state.tipRacuna} onAzurirajClick={() => this.setEditMode(true)} />;

        let contents = this.state.loading
            ? <p><em>Učitavanje...</em></p>
            : tipRacunaOsnovniPodaciView;

        return (
            <div>
                {contents}
                <Toast ref={this.toastRef} />
            </div>
        );
    }
}
