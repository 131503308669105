import React, { Component } from 'react';
import './home.css';
import { Accordion, AccordionTab } from 'primereact/accordion';

export class NajcescaPitanja extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id='najcesca-pitanja' className="container px-5 py-5 home-accordion">
                <div>
                    <h2 className="mb-3 display-5 fw-bold lh-1 text-body-emphasis pb-2 border-bottom">Najčešća pitanja</h2>

                    <div className="row flex-lg-row align-items-center">
                        <Accordion>

                            <AccordionTab header="Da li je početak korišćenja aplikacije komplikovan?" className='lead'>
                                <p className="m-0 lead">
                                    Ne, vrlo je jednostavno. 
                                    Registruje se nalog kao na svakom drugom website-u, i odmah možete da počnete da unosite Vaše predmete i klijente. 
                                    Možete početi sa najosnovnijim podacima vezanim za predmet, poput klijenta, broja predmeta i ročišta, 
                                    a kad budete videli koliko je sve ostalo jednostavno, vrlo brzo ćete početi da unosite sve podatke.
                                </p>
                            </AccordionTab>

                            <AccordionTab header="Trebaće mi puno vremena da unesem sve predmete. Da li moram sve da unesem odmah?" className='lead'>
                                <p className="m-0">
                                    Ne, naravno da ne. 
                                    Naše iskustvo je da je najbolje odrediti jedan dan za početak korišćenja aplikacije, 
                                    i od tog dana svaki predmet na kojem radite u tom trenutku unesete u aplikaciju, ukoliko se već ne nalazi tamo.
                                    Iskustvo sa našim klijentima nam govori da kada shvate da je za unos predmeta potrebno 2 do 3 minuta, vrlo brzo unesu sve svoje predmete.
                                </p>
                            </AccordionTab>

                            <AccordionTab header="Da li je moguće dodati nove opcije u aplikaciji?" className='lead'>
                                <p className="m-0">
                                    Naravno, ukoliko imate ideju za dodatne opcije koje bi bile od koristi svim advokatima kontaktirajte nas i zajedno ćemo prodiskutovati o tome.
                                </p>
                            </AccordionTab>

                            <AccordionTab header="Da li je moguća modifikacija aplikacije shodno isključivo mojim potrebama?" className='lead'>
                                <p className="m-0">
                                    Da, moguća je i modifikacija aplikacije samo za Vas. 
                                    Kontaktirajte nas na neki od načina navedenih u kontakt sekciji, pa ćemo zajedno razraditi ideju i prilagoditi aplikaciju samo za Vas.
                                </p>
                            </AccordionTab>

                            <AccordionTab header="Da li mogu da izgubim sve podatke?" className='lead'>
                                <p className="m-0">
                                    Ne! Svi Vaši podaci su smešteni na našim serverima kojima redovno radimo backup verzije. 
                                    Na taj način upravo možete pristupiti Vašim podacima sa bilo kog računara ili mobilnog telefona.
                                </p>
                            </AccordionTab>

                            <AccordionTab header="Da li mogu da delim predmete sa drugim advokatima?" className='lead'>
                                <p className="m-0">
                                    Naravno, aplikacija je upravo za to i predviđena, i samo korišćenje je vrlo jednostavno i intuitivno. 
                                    Ukoliko Vaša kancelarija ima više zapošljenih advokata, dovoljno je da administrator kancelarije napravi timove advokata po potrebama kancelarije, 
                                    a onda se predmeti rasporedjuju i dodeljuju advokatima koji za to imaju pravo pristupa.
                                </p>
                            </AccordionTab>

                            <AccordionTab header="Da li mogu da delim predmete sa drugim advokatskim kancelarijama?" className='lead'>
                                <p className="m-0">
                                    Naravno, ukoliko imate saradnike na predmetu, pravo pristupa isključivo tom predmetu možete dodeliti i advokatima saradnicima. 
                                    Dovoljno je napraviti jedan tim kom bi pripadali advokati saradnici, i onda tom timu dodeliti pristup samo predmetima na kojima sarađujete.
                                </p>
                            </AccordionTab>

                            <AccordionTab header="Da li su moji podaci bezbedni?" className='lead'>
                                <p className="m-0">
                                    Naravno, svi podaci su osigurani i obezbedjeni standardima enkripcije koji se koriste na svim većim i manjim web aplikacijama. 
                                    Naš tim ima decenijsko iskustvo u izradi sličnih platformi i aplikacija, stalno se usavršava, i upravo je to iskustvo iskorišćeno za izradu Zapisnika.
                                </p>
                            </AccordionTab>

                        </Accordion>
                    </div>
                </div>
            </div>
        );
    }
}
