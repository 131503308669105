import React, { Component } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { DateTimeService } from '../DateTimeService';
import fetchService from '../FetchService';

export class IstorijaPredmeta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            istorija: [],
            loading: true
        };

    }

    async componentDidMount() {
        let istorija = await this.loadIstorija();

        this.setState({
            istorija: istorija,
            loading: false
        });
    }

    async loadIstorija() {
        let predmetId = this.props.predmet.id;
        let requestUrl = `predmetiAdvokataLogs?predmetId=${predmetId}`;

        const response = await fetchService.get(requestUrl);
        const data = response.success ? response.responseValue : [];
        
        return data;
    }

    renderIstorijaPredmeta(istorija) {
        if(istorija.length == 0) {
            return <div className='m-3'><b>Ne postoje zapisi u istoriji o predmetu ili nemate dozvolu za gledanje istorije.</b></div>
        }

        return (
            istorija.map((item) =>
            (
                <div key={item.timestamp}>
                    {this.renderSingleItem(item)}
                </div >
            ))

        );
    }

    render() {
        let displayDataContent = this.renderIstorijaPredmeta(this.state.istorija);

        let contents =
            this.state.loading
                ? <p><em>Učitavanje...</em></p>
                : displayDataContent;

        return (
            <div>
                {contents}
            </div>
        );
    }

    renderSingleItem(item) {
        var timestamp = DateTimeService.formatDateTimeToString(item.timestamp, true);
        let legend = `${timestamp} - ${item.advokat}`;

        let values = item.values.map((valueItem) => <li key={Math.floor(Math.random() * 99999).toString()}>{valueItem.naziv}: {this.getValue(valueItem)}</li>);

        return (
            <Fieldset legend={legend} className='mt-3 mx-2'>
                <p className="m-0">
                    <b>{item.scope} - {item.akcija}</b>
                </p>
                <ul className="m-0">{values}</ul>
            </Fieldset>
        );
    }

    getValue(itemValue) {
        if (itemValue.type == 'DateTimeOffset') {
            return DateTimeService.formatDateTimeToString(itemValue.value, true);
        }

        if (itemValue.type == 'Double') {
            return itemValue.value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }

        return itemValue.value;
    }
}
